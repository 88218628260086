import React, { useContext } from "react";
import { RouteAssignmentsContext } from "../RouteAssignments";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { Col, Space } from "antd";
import StopTagType from "../../components/StopTagType";
import { STOP_TYPE_WAYPOINT } from "../../../../app/utils/models/modelConstants/modelConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";

const FinalStopList = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"STOPS"} />
      <br />
      {state.selectedRoute?.routeData?.stops?.map((s) => (
        <Space size={0} align={"start"}>
          <StopTagType stop={s} />
          <Space direction={"vertical"} size={0}>
            {s?.type !== STOP_TYPE_WAYPOINT ? (
              <TextBody text={`#${s?.invoice?.invoiceNumber}`} />
            ) : (
              s?.displayName ?? ""
            )}
            <TextSubtitle text={s?.address ?? ""} />
          </Space>
          <br />
        </Space>
      ))}
    </Col>
  );
};

export default FinalStopList;
