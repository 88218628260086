export const firstNameSorter = (arr) => {
  return arr.sort(function (a, b) {
    let nameA = a.firstName.toLowerCase(),
      nameB = b.firstName.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });
};

export const sortByValue = (arr, value) => {
  return arr.sort(function (a, b) {
    let nameA = a?.[value]?.toLowerCase(),
      nameB = b?.[value]?.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });
};
