import React, { useRef } from "react";
import ReactToPrint from 'react-to-print';
import { Card, Typography, Button } from "antd";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import moment from "moment";
import QRInvoiceTable from "./QRInvoiceTable";
import InvoiceItemList from "./InvoiceItemList";
import { Stack, Tooltip } from "@mui/material";
import Iconify from "../../../app/iconify";

const { Title } = Typography;

const InvoiceView = React.forwardRef((props, ref) => {

  const selectedInvoice = props.selectedInvoice
  const refreshInvoiceData = props.refreshInvoiceData
  const dailyAvailable = props.dailyAvailable

  const renderDateRange = (selectedInvoice) => {
    const start = moment(selectedInvoice.rentalDateStart.toDate()).format(
      "MMM Do YYYY"
    );
    const end = moment(selectedInvoice.rentalDateEnd.toDate()).format(
      "MMM Do YYYY"
    );
    return <TextBody text={`${start} - ${end}`} />;
  };

  return (
    <div ref={ref}>
      {selectedInvoice && (
        <Card
          size={"small"}
          title={
            <Stack gap={2} direction={'row'} alignItems={'center'}>
            <Title level={4}>
              #{selectedInvoice.invoiceNumber} -{" "}
              {selectedInvoice.customer.customerDisplayName}
            </Title>
            
            {
              selectedInvoice.notes && (
                <Tooltip title={selectedInvoice.notes}>
                <Iconify icon={"material-symbols:chat"} color={"green"}/>
              </Tooltip>
              )
            }
           

            </Stack>
          }
          extra={renderDateRange(selectedInvoice)}
          style={{ minHeight: "80vh" }}
        >
          <InvoiceItemList
            dailyAvailable={dailyAvailable}
            selectedInvoice={selectedInvoice}
            refreshInvoiceData={refreshInvoiceData}
          />
        </Card>
      )}
    </div>
  );
});

export default InvoiceView;

export const PrintQRByInvoice = ({
  selectedInvoice,
  refreshInvoiceData,
  dailyAvailable
}) => {
  
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button>Print Quick Report</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <InvoiceView
            dailyAvailable={dailyAvailable}
              selectedInvoice={selectedInvoice}
              refreshInvoiceData={(invoice) => {
                refreshInvoiceData(invoice);
              }}
            ref={(el) => (componentRef = el)}
          />
        </div>
      </div>
    </>
  );
};
