import React from "react";
import { Select } from "antd";

const QrSelectFilter = ({
  placeholder,
  filters,
  setFilters,
  availableFilters,
  filterType,
}) => {
  return (
    <Select
      showSearch
      allowClear={true}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
      onChange={(id) => {
        setFilters({
          ...filters,
          [filterType]: id,
        });
      }}
      placeholder={placeholder}
      value={filters[filterType]}
      style={{ width: "300px" }}
    >
      <Select.Option value={null} key={"all"}>
        {placeholder}
      </Select.Option>
      {availableFilters[filterType].map((f) => (
        <Select.Option value={f.id} key={f.id}>
          {f.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default QrSelectFilter;
