import React from "react";
import { Button, Card, Space } from "antd";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import moment from "moment";
import { TimeMachine } from "../../../../../app/utils/time/timeMachine";
const WaypointCard = ({ data }) => {
  console.log(data);
  const configureTime = (m) => {
    if (!m) return null;
    const t = new moment(m).format("hh:mm a");
    console.log(t);
    return t;
  };

  return (
    <Card
      title={"Details"}
      bordered={false}
      size={"small"}
      style={{ marginTop: "-12px" }}
      bodyStyle={{ padding: "8px" }}
    >
      <Space direction={"horizontal"}>
        <TextBody style={{ fontWeight: "bold" }} text={"Description:"} />
        {data?.description ?? "None"}
      </Space>
      <br />
      <Space direction={"horizontal"}>
        <TextBody style={{ fontWeight: "bold" }} text={"Contact Number:"} />
        {data?.phone ?? "None"}
      </Space>
      <br />
      <Space direction={"horizontal"}>
        <TextBody style={{ fontWeight: "bold" }} text={"Time:"} />
        {data.time ? TimeMachine(data.time).hoursText : "None"}
      </Space>
    </Card>
  );
};

export default WaypointCard;
