import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

import React, { useContext, useEffect, useState } from "react";
import { useRouteSelect } from "../../../../../app/services/hooks/useRouteSelect";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
} from "../../../../../app/ui/colorConstants";
import EditRouteModal from "./EditRouteModal";
import { RouteContext } from "../../../NewRoute";
import { Box, Button, Grid, IconButton, Stack, Typography, darken } from '@mui/material';

export const RouteSelect = ({ route }) => {
  const { actions, state } = useContext(RouteContext);
  const { tagStyle, routeId, color, hidden } = useRouteSelect(
    route,
    state.currentRouteId,
    state.hiddenRoutes
  );

  const [edit, setEdit] = useState({
    visible: false,
    data: null,
  });
  const closeModal = () => {
    setEdit({
      visible: false,
      data: null,
    });
  };

  return (
    <Box sx={{ my: 1, mb: 1 }}>
      <Grid
        direction={"row"}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Grid item xs={8}>
          <Stack direction={"row"} alignItems={"center"}>
            <DragIndicatorIcon />
            <Button
              variant="contained"
              
              sx={{
                ':hover': {
                  backgroundColor: darken(route?.color, 0.2),
                  color: 'white',
                  boxShadow: `0px 0px 0px 2px ${darken(route?.color, 0.4)} inset`,
                },
                justifyContent: "flex-start",
                marginRight: "4px",
                borderColor: "transparent",
                textAlign: "left",
                width: "100%",
                ...tagStyle,
              }}
              key={`${route.id}}`}
              onClick={() => actions.setCurrentRouteId(route?.id)}
            >
              {route?.name ?? ""}
            </Button>
          </Stack>
        </Grid>
        <Grid item span={4}>
          <Stack
            direction={"horizontal"}
            style={{ float: "right", marginTop: "2px" }}
          >
            <IconButton
              aria-label="visibility"
              onClick={() => {
                if (hidden) {
                  actions.unhideRoute({ routeId });
                } else {
                  actions.hideRoute({ routeId });
                }
              }}
              key={`showOnlyBtn${routeId}`}
              size={"small"}
              sx={{
                backgroundColor: COLOR_LIGHT_GRAY,
                color: hidden ? COLOR_DARK_BG_0 : color,
                borderColor: "transparent",
                opacity: hidden ? 0.35 : 1,
              }}
            >
              {!hidden ? (
                <VisibilityIcon key={`eye${routeId}`} />
              ) : (
                <VisibilityOffIcon key={`eyeinvisible${routeId}`} />
              )}
            </IconButton>

            <IconButton
              aria-label="visibility"
              size={"small"}
              style={{
                borderColor: "transparent",
                backgroundColor: COLOR_LIGHT_GRAY,
              }}
              onClick={() => {
                setEdit({
                  visible: true,
                  data: route,
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </Stack>
        </Grid>
        <EditRouteModal
          visible={edit?.visible}
          data={edit?.data}
          handleFinish={({ name, color }) => {
            actions.updateNameColor({
              name,
              color,
              routeId,
            });
            closeModal();
          }}
          handleDelete={() => {
            actions.deleteRoute(routeId).then(() => {
              closeModal();
            });
          }}
          onCancel={() => closeModal()}
        />
      </Grid>
    </Box>
  );
};
