import { createSlice } from "@reduxjs/toolkit";
import notificationError from "../../app/system-components/toasters/notificationError";

export const initialState = {
  cart: [],
  rentalDates: {
    rentalDateStart: null,
    rentalDateEnd: null,
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    /* From documentation: Insert reducers an object, where the keys will become action type strings,
            and the functions are reducers that will be run when that action type is dispatched.*/
    saveCart(state, { payload }) {
      state.cart = payload;
    },
    saveRentalRange(state, { payload }) {
      state.rentalDates = payload;
    },
    clearCart(state) {
      state.rentalDates = {
        rentalDateStart: null,
        rentalDateEnd: null,
      };
      state.cart = [];
    },
  },
});

// Export keys as actions
export const { saveCart, saveRentalRange, clearCart } = cartSlice.actions;

// Export selector as name from slice
export const cartSelector = (state) => state.cart;

// Default export for reducer to get referenced in root reducer
export default cartSlice.reducer;

// Thunk actions
export const setCart = (payload) => async (dispatch) => {
  try {
    dispatch(saveCart(payload));
  } catch (err) {
    console.log(err);
  }
};
export const setRentalDates = (payload) => async (dispatch) => {
  try {
    dispatch(saveRentalRange(payload));
  } catch (err) {
    console.log(err);
  }
};

export const setClearedCart = () => async (dispatch) => {
  try {
    dispatch(clearCart());
  } catch (err) {
    console.log(err);
  }
};
