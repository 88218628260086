import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import "../../App.less";
import { Affix, Button, Dropdown, Input, Menu, PageHeader, Space } from "antd";
import CreateCategoryModal from "./components/CreateCategoryModal";
import { useFirestore } from "react-redux-firebase";
import { categoryQueries } from "../../app/services/firestore/queries/categoryQueries";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import EditCategoryModal from "./components/EditCategoryModal";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { sortBy } from "../../app/utils/sorters/sortBy";
import { ROUTE_ADMIN_CREATE_INVENTORY } from "../../app/routes";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { COLOR_PURPLE_0 } from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import InventoryDataGrid from "./components/InventoryDataGrid";
import { useFirestoreContext } from "../../app/global-context/FirestoreContext";

const { Search } = Input;

const ItemDrawer = lazy(() => import("./components/ItemDrawer"));

const AdminInventory = ({ history }) => {

  const firestore = useFirestore();

  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [manageCategoriesModal, setManageCategoriesModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);

  const [viewEditCategoryModal, setViewEditCategoryModal] = useState({
    visible: false,
    category: null,
  });

  const [itemDrawer, setItemDrawer] = useState({
    visible: false,
    item: null,
  });
  
// Memoized handler for setting itemDrawer state
const handleSetItemDrawer = useCallback(
  (drawerState) => {
    setItemDrawer(drawerState);
  },
  []
);

const loadInventory = useCallback(async () => {
  setLoadingInventory(true);
  const data = await inventoryQueries(
    { firestore },
    fsOrgPrefix
  ).fetchInventory();
  setInventory(sortBy(data, "name"));
  setLoadingInventory(false);
}, [firestore, fsOrgPrefix]);


const handleItemDrawerComplete = useCallback(() => {
  setItemDrawer({ visible: false, item: null });
  loadInventory();
}, [loadInventory]);

const loadCategories = useCallback(async () => {
  setLoadingCategories(true);
  const cats = await categoryQueries({ firestore }, fsOrgPrefix).fetchCategories();
  setCategories(sortBy(cats, "name"));
  setLoadingCategories(false);
}, [firestore, fsOrgPrefix]);


  useEffect(() => {
    // load categories
    fsOrgPrefix && loadCategories();
    fsOrgPrefix && loadInventory();
  }, [fsOrgPrefix, loadCategories, loadInventory]);


  // Memoize category overlay menu
  const categoryOverlay = useMemo(() => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => setManageCategoriesModal(true)}
        style={{ height: "40px" }}
      >
        <div>
          Create Category <PlusCircleOutlined style={{ margin: "0px 8px" }} />
        </div>
      </Menu.Item>
      {categories.map((c) => (
        <Menu.Item
          key={c.id}
          onClick={() => setViewEditCategoryModal({ visible: true, category: c })}
        >
          <strong>{c.name}</strong>
          <br />
          <TextSubtitle text={c.description} />
        </Menu.Item>
      ))}
    </Menu>
  ), [categories]);

    // Memoize the extra buttons for PageHeader
    const pageHeaderExtra = useMemo(() => (
      <Space size={12}>
        <Dropdown overlay={categoryOverlay} trigger="click">
          <Button>Categories</Button>
        </Dropdown>
        <Button
          type="primary"
          onClick={() => history.push(ROUTE_ADMIN_CREATE_INVENTORY)}
        >
          Add Inventory Item
        </Button>
      </Space>
    ), [categoryOverlay, history]);

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Inventory" extra={pageHeaderExtra} />
      </Affix>
      <div className="admin-inventory-list" style={{ marginTop: "0px" }}>
        {inventory && (
          <InventoryDataGrid
            setItemDrawer={handleSetItemDrawer}
            onDuplicateComplete={loadInventory}
            onDeleteComplete={loadInventory}
            data={inventory}
            onEditComplete={loadInventory}
            loadingInventory={loadingInventory}
            loadingCategories={loadingCategories}
            categories={categories}
          />
        )}
        <Suspense fallback={<div>Loading Item Details...</div>}>
          <ItemDrawer
            visible={itemDrawer.visible}
            handleCloseDrawer={setItemDrawer}
            item={itemDrawer}
            categories={categories}
            onComplete={handleItemDrawerComplete}
            userData={userData}
            orgData={orgData}
            fsOrgPrefix={fsOrgPrefix}
          />
        </Suspense>
      </div>
      <CreateCategoryModal
        visible={manageCategoriesModal}
        onCancel={() => setManageCategoriesModal(false)}
        onComplete={() => {
          setManageCategoriesModal(false);
          loadCategories().then(() => {
            notificationConfirm("Category created", "");
          });
        }}
      />
      <EditCategoryModal
        visible={viewEditCategoryModal.visible}
        category={viewEditCategoryModal.category}
        onComplete={() => {
          setViewEditCategoryModal({
            visible: false,
            category: null,
          });
          loadCategories();
        }}
        onCancel={() => {
          setViewEditCategoryModal({
            visible: false,
            category: null,
          });
        }}
      />
    </div>
  );
};

export default AdminInventory;
