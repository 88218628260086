import {
  InputNumber,
  Select,
  Checkbox,
  Divider,
  Input,
  Space,
  Button,
} from "antd";
import React, { useState } from "react";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import {
  INV_ITEM_RATE,
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE_RANGE,
} from "../../../../../app/utils/models/modelConstants/modelConstants";
import Login from "../../../../login/Login";

const { Option } = Select;
export const renderFieldType = (
  dataIndex,
  inputRef,
  save,
  availableItems,
  selectedItems,
  record,
  saveCustomRate,
  typedItem,
  setTypedItem
) => {
  const selectedItem = () => {
    return (
      <Select
        dropdownMatchSelectWidth={false}
        bordered={false}
        onSearch={setTypedItem}
        style={{ margin: 0, padding: 0 }}
        ref={inputRef}
        onSelect={(e) => save(e, dataIndex)}
        onBlur={(e) => save(record?.id, dataIndex)}
        showSearch
        allowClear
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        <Option
          key={"createNewItem"}
          value={"createNewItem"}
          style={{ fontWeight: "bold" }}
        >
          {typedItem === "" ? "+ Create item" : `+ ${typedItem}`}
        </Option>
        {availableItems.map((i) => (
          <Option key={i.id} value={i.id}>
            {i.name}
          </Option>
        ))}
      </Select>
    );
  };

  const selectedDescription = () => {
    const desc = record.selectedDescription
      ? record.selectedDescription
      : record.description;

    return (
      <Input.TextArea
        autoSize
        ref={inputRef}
        placeholder={desc}
        onBlur={(e) => {
          save(e, dataIndex);
        }}
        onPressEnter={(e) => save(e, dataIndex)}
      />
    );
  };

  const selectedQty = () => {
    return (
      <InputNumber
        min={0}
        onPressEnter={(e) => save(e, dataIndex)}
        onBlur={(e) => save(e, dataIndex)}
        ref={inputRef}
        style={{ width: "60px" }}
      />
    );
  };

  const selectedRate = () => {
    let customAmt = 0;
    const configureDurationOpts = (type) => {
      if (type === "sale") {
        return <Option value={"none"}>None</Option>;
      } else {
        const opts = [
          { label: "Per hour", value: "hour" },
          { label: "Per day", value: "day" },
          { label: "Per week", value: "week" },
          { label: "Per month", value: "month" },
        ];
        return opts.map((o) => <Option value={o.value}>{o.label}</Option>);
      }
    };
    const handleCustomRate = (r) => {
      // create custom rate obj with unique name
      const rate = {
        [INV_ITEM_RATE]: customAmt,
        [INV_ITEM_RATE_RANGE]: r,
        [INV_ITEM_RATE_NAME]: `Custom ${
          record.rates ? record.rates.length + 1 : 1
        }`,
      };
      saveCustomRate(rate, dataIndex);
    };
    return (
      <Select
        onClear={() => save(null, dataIndex)}
        style={{ margin: 0, padding: 0 }}
        ref={inputRef}
        onSelect={(e) => {
          if (e) {
            save(e, dataIndex);
          } else {
          }
        }}
        // onBlur={(e) => save(record.selectedRate, dataIndex)}
        showSearch
        allowClear
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <span style={{ marginLeft: "12px" }}>
              <TextSubtitle text={"CUSTOM RATE"} />
            </span>
            <br />
            <Space>
              <InputNumber
                placeholder={"Amount"}
                onChange={(amt) => {
                  customAmt = amt;
                }}
                min={0}
                style={{ margin: "8px 4px 8px 12px", width: "100px" }}
                formatter={(value) =>
                  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                value={customAmt ? customAmt : null}
              />
              <Select
                placeholder={"Duration"}
                style={{ margin: "8px 4px 8px 0px", width: "120px" }}
                onSelect={(r) => handleCustomRate(r)}
              >
                {configureDurationOpts(record.type)}
              </Select>
            </Space>
          </div>
        )}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {record &&
          record.rates &&
          record.rates.map((r) => {
            return (
              <Option key={r.rateName} value={r.rateName}>
                {`${r.rateName} (${r.rate}
                ${record.type === "sale" ? null : ` per ${r.rateRange}`})`}
              </Option>
            );
          })}
      </Select>
    );
  };

  const selectedDuration = () => {
    const durationMeta =
      record.selectedRateMeta && record.selectedRateMeta.rateRange
        ? record.selectedRateMeta.rateRange
        : "";
    return (
      <InputNumber
        placeholder={durationMeta}
        min={0}
        onPressEnter={(e) => save(e, dataIndex)}
        onBlur={(e) => save(e, dataIndex)}
        ref={inputRef}
        style={{ width: "100px" }}
      />
    );
  };
  const selectedTaxable = () => {
    return <Checkbox defaultChecked={record.isTaxable} />;
  };
  const cells = {
    selectedItemId: selectedItem,
    selectedQty: selectedQty,
    selectedRate: selectedRate,
    selectedDuration: selectedDuration,
    selectedTaxable: selectedTaxable,
    selectedDescription: selectedDescription,
  };
  return cells[dataIndex]();
};
