import { Modal } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT } from "../../../../app/routes";
import { customerWrites } from "../../../../app/services/firestore/writes/customerWrites";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import { authSelector } from "../../../auth/authSlice";
import { Customer } from "../../../../app/interfaces/customer";
import { WarningFilled } from "@ant-design/icons";

export interface ContextConfig {
  selectedCustomer?: Customer;
  visible: boolean;
  x: number;
  y: number;
}

export interface CustomerTableMenuControl {
  handleCustomerClick: () => void;
  contextConfig: ContextConfig;
  setContextMenuConfig: React.Dispatch<React.SetStateAction<ContextConfig>>;
  handleDeactivate: () => void;
  handleReactivate: () => void;
  handleReceivePayment: () => void;
  resetContext: () => void;
}

export const useCustomerContextMenu = ({
  handleEdit,
  onDeleteComplete,
}: {
  handleEdit: (c: Customer) => void;
  onDeleteComplete: () => void;
}): CustomerTableMenuControl => {
  const { fsOrgPrefix } = useSelector(authSelector);

  const [contextConfig, setContextMenuConfig] = useState<ContextConfig>({
    selectedCustomer: undefined,
    visible: false,
    x: 0,
    y: 0,
  });
  const [saving, setSaving] = useState(false);
  const firestore = useFirestore();
  const history = useHistory();

  const handleDeactivate = () => {
    setContextMenuConfig((e) => ({ ...e, visible: false }));
    const onOk = async () => {
      if (contextConfig.selectedCustomer) {
        setSaving(true);
        const writer = customerWrites({ firestore }, fsOrgPrefix);
        if (writer) {
          try {
            await writer.editCustomer(contextConfig.selectedCustomer.id, {
              isActive: false,
            });

            onDeleteComplete();
            notificationConfirm("Customer made inactive", "");
          } catch (err) {
            console.log(err);
            notificationError(
              "Something went wrong",
              "Customer could not be made inactive"
            );
          } finally {
            setSaving(false);
          }
        }
      }
    };
    Modal.confirm({
      title: "Make Inactive?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "If you are integrated with Quickbooks, this customer will also be made inactive in your Quickbooks account.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk: onOk,
      onCancel: () => {},
    });
  };

  const handleReactivate = () => {
    setContextMenuConfig((e) => ({ ...e, visible: false }));
    const onOk = async () => {
      if (contextConfig.selectedCustomer) {
        setSaving(true);
        const writer = customerWrites({ firestore }, fsOrgPrefix);
        if (writer) {
          try {
            await writer.editCustomer(contextConfig.selectedCustomer.id, {
              isActive: true,
            });
            onDeleteComplete();
            notificationConfirm("Customer made active", "");
          } catch (err) {
            console.log(err);
            notificationError(
              "Something went wrong",
              "Customer could not be made active"
            );
          } finally {
            setSaving(false);
          }
        }
      }
    };
    Modal.confirm({
      title: "Make Active?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "If you are integrated with Quickbooks, this customer will also be made active in your Quickbooks account.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk: onOk,
      onCancel: () => {},
    });
  };

  const handleReceivePayment = () => {
    contextConfig.selectedCustomer?.id &&
      history.push(
        `${ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT}${contextConfig.selectedCustomer.id}`
      );
  };
  const handleCustomerClick = () => {
    setContextMenuConfig((e) => ({ ...e, visible: false }));
    if (contextConfig.selectedCustomer)
      handleEdit(contextConfig.selectedCustomer);
  };

  const resetContext = () =>
    setContextMenuConfig({
      x: 0,
      y: 0,
      selectedCustomer: undefined,
      visible: false,
    });

  return {
    handleCustomerClick,
    contextConfig,
    setContextMenuConfig,
    handleDeactivate,
    handleReactivate,
    handleReceivePayment,
    resetContext,
  };
};
