import React, { useContext, useMemo } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import { ReceivePaymentModalContext } from "../ReceivePayment";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { centsToDollars } from "../../../app/utils/models/checkers/checkCurrencyToDollars";
import NumberFormat from "react-number-format";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { JustifiPaymentForm } from "../../sharedComponents/justifi/JustifiPaymentForm";
import { Box } from "@mui/material";
import { Customer } from "../../../app/interfaces/customer";
import { OrganizationData } from "../../../app/interfaces/organizationData";

type Props = {
  customer: Customer;
  orgData: OrganizationData;
  total: number;
  credit: number;
  refNo: string;
  fulfilled: any[];
  onClose: () => void;
}

const JustifiPaymentModal = ({customer, orgData, total, credit, refNo, fulfilled, onClose }: Props) => {

  return (
    <Box sx={{p: 2}}>
      <TextBody
        text={`Payment from: ${customer?.customerDisplayName}`}
      />
      <br />
      <Space>
        <TextSubtitle text={"Available credit (Automatically applied):"} />
        <TextSubtitle
          text={`$${customer?.credit?.toLocaleString(
            "us",
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          )}`}
        />
      </Space>
      <br />
      <br />
      <Space>
        <TextBody text={"Payment amount: "} />
        <NumberFormat
          value={centsToDollars(total * 100).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Space>

      <br />
      <Space>
        <TextBody text={"Added credit: "} />
        <NumberFormat
          value={centsToDollars(credit).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Space>

      <Divider />

      <JustifiPaymentForm customer={customer} orgData={orgData} total={total} credit={credit} refNo={refNo} fulfilled={fulfilled} onClose={onClose} />
      </Box>
  );
};

export default JustifiPaymentModal;
