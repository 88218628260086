import React from "react";
import { COLOR_LIGHT_GRAY, COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Space } from "antd";

const LocationTag = ({ contactName, address }) => {
  return (
    <div
      style={{
        margin: "12px 0",
        padding: "6px",
        borderRadius: "4px",
        backgroundColor: COLOR_LIGHT_GRAY,
        display: "inline-block",
      }}
    >
      <Space>
        <EnvironmentOutlined
          style={{ marginRight: "4px", color: COLOR_RED_0, alignItems: "top" }}
        />
        <div>
          {contactName ? <div>{contactName}</div> : null}
          {address}
        </div>
      </Space>
    </div>
  );
};

export default LocationTag;
