import React, { useState } from "react";
import { COLOR_PURPLE_0, COLOR_RED_0 } from "../../../app/ui/colorConstants";
import {
  Badge,
  Button,
  Card,
  Col,
  List,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { FileTextFilled, ArrowRightOutlined } from "@ant-design/icons";
import { configureNewInvoiceFromCart } from "../../../app/utils/models/configure/invoiceTable/configureNewSingleItem";
import { ROUTE_CREATE_INVOICE_FROM_CART } from "../../../app/routes";
import notificationError from "../../../app/system-components/toasters/notificationError";

const { Title } = Typography;
const InventoryCart = ({ cart, setCart, rentalDates, history, clearCart }) => {
  const [cartVisible, setCartVisible] = useState(false);
  
  const viewCart = () => {
    setCartVisible(!cartVisible);
  };

  const handleRemove = (item) => {
    const updated = cart.filter((i) => i.id !== item.id);
    setCart([...updated]);
  };

  const removeAll = () => {
    setCart([]);
    setCartVisible(false);
  };

  const handleConvertCart = async () => {
    const payload = configureNewInvoiceFromCart(cart, rentalDates);
    history.push({
      pathname: ROUTE_CREATE_INVOICE_FROM_CART,
      state: { detail: payload },
    });
  };

  return (
    <div>
      {cartVisible && (
        <Card
          title={
            <Row justify={"space-between"} style={{ marginTop: "8px" }}>
              <Col>
                <Title level={4}> Cart </Title>
              </Col>
              <Col>
                <Space>
                  <Button
                    type={"link"}
                    disabled={cart.length === 0}
                    onClick={removeAll}
                  >
                    Clear Cart
                  </Button>
                  <Button
                    type={"primary"}
                    onClick={() => {
                      if (
                        rentalDates.rentalDateStart === null ||
                        rentalDates.rentalDateEnd === null
                      ) {
                        notificationError("Please Select Valid Date Range");
                        return;
                      }
                      handleConvertCart().then(() => {
                        clearCart();
                      });
                    }}
                  >
                    Create Invoice <ArrowRightOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
          }
          size={"small"}
          style={{
            position: "fixed",
            bottom: "80px",
            right: "32px",
            height: "500px",
            width: "500px",
            boxShadow: "8px 12px 8px rgb(0,0,0, .15)",
          }}
        >
          <br />
          <List
            locale={{ emptyText: "No items" }}
            style={{ height: "400px", overflowY: "auto", marginTop: "-20px" }}
            size="small"
            dataSource={cart}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tag>{item.count}</Tag>,
                  <Button
                    type={"link"}
                    style={{ color: COLOR_RED_0 }}
                    onClick={() => handleRemove(item)}
                  >
                    Remove
                  </Button>,
                ]}
              >
                <Space>{item.name}</Space>
              </List.Item>
            )}
          />
        </Card>
      )}
      <Row
        align={"middle"}
        style={{
          padding: cart.length !== 0 ? "0 16px" : "0 14px",
          width: cart.length !== 0 ? "120px" : "50px",
          height: "50px",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          background: COLOR_PURPLE_0,
          borderRadius: "200px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={viewCart}
      >
        <Badge
          count={cart.length}
          style={{ borderColor: "transparent" }}
          offset={[-114, 0]}
        >
          <Space>
            <FileTextFilled style={{ color: "#FFF", fontSize: "24px" }} />
            {cart.length !== 0 && (
              <Button
                onClick={viewCart}
                type={"primary"}
                style={{
                  borderRadius: "16px",
                  marginRight: "8px",
                  fontWeight: "bold",
                  backgroundColor: "#37179B",
                  borderWidth: 0,
                }}
              >
                View
              </Button>
            )}
          </Space>
        </Badge>
      </Row>
    </div>
  );
};

export default InventoryCart;
