export const ConfigureEditQuickReportProps = (currentInvoice, newQrItems) => {
  const { qrItems, ...rest } = currentInvoice;
  const items = newQrItems.map((item) => {
    const exists =
      qrItems &&
      qrItems.find(
        (i) => i.id === item.id && i.isBundleChild === item.isBundleChild
      );
    if (!exists) return item;
    const { completed, notes, hasIssue, issueDescription, ...rest } = item;
    return {
      completed: exists.completed ? exists.completed : 0,
      notes: exists.notes ? exists.notes : "",
      hasIssue: exists.hasIssue ? exists.hasIssue : false,
      issueDescription: exists.issueDescription ? exists.issueDescription : "",
      ...rest,
    };
  });
  return items;
};
