import notificationError from "../../../system-components/toasters/notificationError";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  RECEIVE_METHOD_PICKUP,
  RETURN_METHOD_DROPOFF,
  ROW_TYPE_SUBTOTAL,
} from "../modelConstants/modelConstants";
import { dollarToCents } from "./configureCurrencyToCents";
import onlyUnique from "../../filters/filterOnlyUnique";
import { configureQuickReportProps } from "./configureQuickReportProps";
import { addOrgUtcOffsetToInvoice } from "./wrapper/addOrgUtcOffsetToInvoice";
import { firelikeId } from "../checkers/firelikeid";

export const configureInvoice = (orgTimezone) => {

  const checkUndefined = (i) => {
    if (typeof i === "undefined") {
      return null;
    }
    return i;
  };

  function newInvoice(payload, createdById, orgData, currentInvoice, isEdit) {
    
    if (!payload || !createdById || !orgData) {
      return notificationError("Could not create invoice", "");
    }

    const {
      customer,
      additionalContactId,
      rentalRange,
      selectedItems,
      invoiceTotal,
      taxMetaData,
      formDetails,
      customerAddressChecked,
      type,
      deliveryFee,
      nextInvoiceNumber,
      deliveryFeeTaxable,
      selectedTags,
      totalWeight,
    } = payload;

    const configureDeliveryDetails = (
      customer,
      formDetails,
      customerAddressChecked,
      selectedItems
    ) => {

      const createShippingAddressStr = (values) => {
        if (
          values.deliveryCustomerShippingAddress === "" ||
          values.deliveryCustomerShippingCity === "" ||
          values.deliveryCustomerShippingState === "" ||
          values.deliveryCustomerShippingZip === ""
        )
          return "";
        return `${values.deliveryCustomerShippingAddress}, ${values.deliveryCustomerShippingCity}, ${values.deliveryCustomerShippingState} ${values.deliveryCustomerShippingZip}`;
      };

      const createOnSiteAddressStr = (values) => {
        if (
          values.onSiteCustomerShippingAddress === "" ||
          values.onSiteCustomerShippingCity === "" ||
          values.onSiteCustomerShippingState === "" ||
          values.onSiteCustomerShippingZip === ""
        )
          return "";
        return `${values.onSiteCustomerShippingAddress}, ${values.onSiteCustomerShippingCity}, ${values.onSiteCustomerShippingState} ${values.onSiteCustomerShippingZip}`;
      };

      // delivery details
      const configureReceiveAddr = (receiveMethod) => {
        if (receiveMethod === RECEIVE_METHOD_PICKUP || !customer) {
          return {
            deliveryCustomerDisplayName: null,
            deliveryCustomerShippingAddress: null,
            deliveryCustomerShippingCity: null,
            deliveryCustomerShippingState: null,
            deliveryCustomerShippingZip: null,
            deliveryCustomerShippingAddressStr: null,
          };
        }
        if (!customerAddressChecked.receive) {
          const payload = {
            deliveryCustomerDisplayName:
              formDetails.deliveryCustomerDisplayName,
            deliveryCustomerShippingAddress:
              formDetails.deliveryCustomerShippingAddress,
            deliveryCustomerShippingCity:
              formDetails.deliveryCustomerShippingCity,
            deliveryCustomerShippingState:
              formDetails.deliveryCustomerShippingState,
            deliveryCustomerShippingZip:
              formDetails.deliveryCustomerShippingZip,
          };
          return {
            deliveryCustomerShippingAddressStr:
              createShippingAddressStr(payload),
            ...payload,
          };
        } else {
          const payload = {
            deliveryCustomerDisplayName: customer[CUSTOMER_DISPLAY_NAME],
            deliveryCustomerShippingAddress:
              customer[CUSTOMER_SHIPPING_ADDRESS],
            deliveryCustomerShippingCity: customer[CUSTOMER_SHIPPING_CITY],
            deliveryCustomerShippingState: customer[CUSTOMER_SHIPPING_STATE],
            deliveryCustomerShippingZip: customer[CUSTOMER_SHIPPING_ZIP],
          };
          return {
            deliveryCustomerShippingAddressStr:
              createShippingAddressStr(payload),
            ...payload,
          };
        }
      };
      const configureReturnAddr = (returnMethod) => {
        if (returnMethod === RETURN_METHOD_DROPOFF || !customer) {
          return {
            onSiteCustomerDisplayName: null,
            onSiteCustomerShippingAddress: null,
            onSiteCustomerShippingCity: null,
            onSiteCustomerShippingState: null,
            onSiteCustomerShippingZip: null,
            onSiteCustomerShippingAddressStr: null,
          };
        }
        if (!customerAddressChecked.return) {
          const payload = {
            onSiteCustomerDisplayName: formDetails.onSiteCustomerDisplayName,
            onSiteCustomerShippingAddress:
              formDetails.onSiteCustomerShippingAddress,
            onSiteCustomerShippingCity: formDetails.onSiteCustomerShippingCity,
            onSiteCustomerShippingState:
              formDetails.onSiteCustomerShippingState,
            onSiteCustomerShippingZip: formDetails.onSiteCustomerShippingZip,
          };
          return {
            onSiteCustomerShippingAddressStr: createOnSiteAddressStr(payload),
            ...payload,
          };
        } else {
          const payload = {
            onSiteCustomerDisplayName: customer[CUSTOMER_DISPLAY_NAME],
            onSiteCustomerShippingAddress: customer[CUSTOMER_SHIPPING_ADDRESS],
            onSiteCustomerShippingCity: customer[CUSTOMER_SHIPPING_CITY],
            onSiteCustomerShippingState: customer[CUSTOMER_SHIPPING_STATE],
            onSiteCustomerShippingZip: customer[CUSTOMER_SHIPPING_ZIP],
          };
          return {
            onSiteCustomerShippingAddressStr: createOnSiteAddressStr(payload),
            ...payload,
          };
        }
      };

      const configureReceiveReturnTimes = (formDetails) => {
        if (!formDetails.rentalDates)
          return {
            rentalDates: [],
            // start
            rentalDateStart: null,
            hasSpecifiedReceiveTime: false,
            specifiedReceiveTime: null,
            specifiedReceiveTimeEnd: null,
            hasReceiveWindow: false,
            // end
            rentalDateEnd: null,
            hasSpecifiedReturnTime: false,
            specifiedReturnTime: null,
            specifiedReturnTimeEnd: null,
            hasReturnWindow: false,
          };
        const start = checkUndefined(formDetails.rentalDates[0].toDate(), null);
        const end = checkUndefined(formDetails.rentalDates[1].toDate(), null);

        const hasReceiveTime = checkUndefined(
          formDetails.hasSpecifiedReceiveTime
        );
        const hasReturnTime = checkUndefined(
          formDetails.hasSpecifiedReturnTime
        );
        const receiveTime = formDetails.specifiedReceiveTime
          ? formDetails.specifiedReceiveTime.toDate()
          : null;

        const addedReceiveTime = (receiveTime, start) => {
          const receiveTimeOnly = new Date(receiveTime);
          receiveTimeOnly.setHours(0, 0, 0, 0);
          const dayDate = new Date(receiveTime - receiveTimeOnly);
          const startDate = new Date(start);
          return new Date(dayDate.getTime() + startDate.getTime());
        };
        const addedReturnTime = (returnEndTime, returnTime, end) => {
          const rt = returnEndTime ? returnEndTime : returnTime;
          let returnTimeOnly = new Date(rt);
          returnTimeOnly.setHours(0, 0, 0, 0);
          const cloneReturnTime = new Date(rt);
          const addTime = rt
            ? cloneReturnTime.getTime() - returnTimeOnly.getTime()
            : 0;
          const endDate = new Date(end);
          const newTime =
            addTime !== 0 ? new Date(endDate).setHours(0, 0, 0, addTime) : null;
          const updatedTime = newTime ? new Date(newTime) : endDate;
          return updatedTime;
        };

        const returnTime = formDetails.specifiedReturnTime
          ? formDetails.specifiedReturnTime.toDate()
          : null;

        const receiveEndTime = formDetails.specifiedReceiveTimeEnd
          ? formDetails.specifiedReceiveTimeEnd.toDate()
          : null;

        const returnEndTime = formDetails.specifiedReturnTimeEnd
          ? formDetails.specifiedReturnTimeEnd.toDate()
          : null;

        // get beginning of day for start and end
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 0, 0);
        const mobileEnd = new Date(end);
        mobileEnd.setHours(12, 0, 0);

        const mobileEndBeforeOffset = mobileEnd.toISOString().substring(0, 10);

        const rentalStart = hasReceiveTime
          ? addedReceiveTime(receiveTime, start)
          : start;

        const rentalEnd = hasReturnTime
          ? addedReturnTime(returnEndTime, returnTime, end)
          : end;

        const rentalTurnaroundEnd = (rentalEnd, selectedItems) => {
          const dayStart = new Date(rentalEnd);
          const turnaround = selectedItems.items.map((i) => i.turnaround);

          const sorted = turnaround.sort(function (a, b) {
            return b - a;
          });
          if (!sorted[0]) return dayStart;

          const msPerHr = 3600000;
          const bufferMs = msPerHr * sorted[0] * 24;
          const buffer = new Date(dayStart).setMilliseconds(bufferMs);
          return new Date(buffer);
        };
        
        const mobileStartBeforeOffset = rentalStart
          .toISOString()
          .substring(0, 10);

        return {
          // form rental dates
          rentalDates: [rentalStart, rentalEnd],
          // start
          rentalDateStart: rentalStart,
          mobileStartDate: mobileStartBeforeOffset,
          hasSpecifiedReceiveTime: hasReceiveTime,
          specifiedReceiveTime: hasReceiveTime
            ? addedReceiveTime(receiveTime, start)
            : null,
          specifiedReceiveTimeEnd: receiveEndTime
            ? addedReceiveTime(receiveEndTime, start)
            : null,
          hasReceiveWindow: !!receiveEndTime,
          // end
          rentalDateEnd: rentalEnd,
          mobileEndDate: mobileEndBeforeOffset,
          hasSpecifiedReturnTime: hasReturnTime,
          specifiedReturnTime: hasReturnTime
            ? addedReturnTime(null, returnTime, end)
            : null,
          specifiedReturnTimeEnd: returnEndTime
            ? addedReturnTime(returnEndTime, returnTime, end)
            : null,
          hasReturnWindow: !!returnEndTime,
          rentalTurnaroundEnd: rentalTurnaroundEnd(rentalEnd, selectedItems),
        };
      };

      return {
        customerQueryHook: checkUndefined(formDetails.customerQueryHook),
        customerAddressChecked: customerAddressChecked,
        receiveMethod: checkUndefined(formDetails.receiveMethod),
        returnMethod: checkUndefined(formDetails.returnMethod),
        ...configureReceiveReturnTimes(formDetails),
        ...configureReceiveAddr(formDetails.receiveMethod),
        ...configureReturnAddr(formDetails.returnMethod),
      };
    };

    const configureSelectedItems = (selectedItems) => {
      // selectedItems -> convert rates & create query hooks
      let selectedItemsQueryHook = [];
      let selectedCategoriesQueryHook = [];
      let bundleItemIds = [];
      let selectedCategories = [];

      // let bIds = [];
      const items = selectedItems.items.map((i) => {

        if (i.rowType === ROW_TYPE_SUBTOTAL) {
          const { rowTotal, selectedTaxable, ...rest } = i;
          return {
            rowTotal: dollarToCents(rowTotal),
            selectedTaxable: false,
            ...rest,
          };
        }

        const {
          rates,
          rowTotal,
          selectedRateMeta,
          bundleItemsQueryHook,
          ...rest
        } = i;

        i.bundleItems &&
          i.bundleItems.forEach((i) => {
            bundleItemIds.push(i.bundleItemId);
          });
        i.categoriesQueryHook &&
          i.categoriesQueryHook.forEach((h) => {
            selectedCategoriesQueryHook.push(h);
          });
        i.categories &&
          i.categories.forEach((c) => {
            selectedCategories.push({
              name: c.name,
              id: c.id,
            });
          });

        selectedItemsQueryHook.push(i.id);

        const convertedRates = rates.map((r) => {
          return {
            rateName: r.rateName,
            rateRange: r.rateRange,
            rate: dollarToCents(r.rate),
            id: r.id ? r.id : firelikeId(),
          };
        });

        if (!selectedRateMeta)
          return {
            rates: convertedRates,
            rowTotal: dollarToCents(i.rowTotal),
            selectedRateMeta: null,
            ...rest,
          };

        const { rate, ...metaRest } = selectedRateMeta && selectedRateMeta;

        return {
          rates: convertedRates,
          rowTotal: dollarToCents(i.rowTotal),
          selectedRateMeta: {
            rate: dollarToCents(rate),
            ...metaRest,
          },
          ...rest,
        };
      });

      const resetItemKeys = items.map((i, index) => {
        return {
          ...i,
          key: index,
          listIndex: index,
        };
      });

      return {
        selectedItems: {
          items: resetItemKeys,
          count: selectedItems.count,
        },
        categoriesQueryHook: selectedCategoriesQueryHook.filter(onlyUnique),
        selectedItemsQueryHook: selectedItemsQueryHook,
        bundleItemsQueryHook: bundleItemIds.filter(onlyUnique),
        selectedCategories: selectedCategories,
      };
    };

    const configureInvoiceTotal = (invoiceTotal) => {
      // invoiceTotal -> convert to cents
      const { taxAmt, subtotal, total, totalDiscountNumber, ...rest } =
        invoiceTotal;
      return {
        taxAmt: dollarToCents(taxAmt),
        subtotal: dollarToCents(subtotal),
        total: dollarToCents(total),
        totalDiscountNumber: dollarToCents(totalDiscountNumber),
        ...rest,
      };
    };

    const configureFormDetails = (formDetails, currentInvoice) => {
      return {
        // added meta details
        orgId: orgData.id,
        createdBy: createdById,
        createdOn: currentInvoice ? currentInvoice.createdOn : new Date(),
        updatedBy: createdById,
        updatedOn: new Date(),
        // invoiceNumber: incrementInvoiceCount(),
        invoiceSource: "adelie",
        notes: formDetails.notes,
        invoicePaymentDueDate: formDetails.invoicePaymentDueDate
          ? formDetails.invoicePaymentDueDate?.toDate()
          : null,
        eventDate: formDetails.eventDate
          ? formDetails.eventDate?.toDate()
          : null,
      };
    };

    const invoicePayload = {
      customer: customer,
      rentalRange: rentalRange,
      isSalesInvoice: formDetails.isSalesInvoice ?? false,
      type: type,
      qbId: currentInvoice?.qbId ?? null,
      syncToken: currentInvoice?.syncToken ?? null,
      status: null,
      updateSource: "adelie",
      taxMetaData: taxMetaData,
      ...configureQuickReportProps(selectedItems, type, currentInvoice),
      ...configureFormDetails(formDetails, currentInvoice),
      ...configureSelectedItems(selectedItems),
      ...configureInvoiceTotal(invoiceTotal),
      ...configureDeliveryDetails(
        customer,
        formDetails,
        customerAddressChecked,
        selectedItems
      ),
      additionalContactId: additionalContactId ?? null,
      contactPhoneNumber: formDetails?.contactPhoneNumber ?? null,
      salesRepName: formDetails?.salesRepName ?? null,
      tagsQueryHook: selectedTags || [],
      deliveryFee: dollarToCents(deliveryFee),
      totalWeight,
      deliveryFeeTaxable,
      invoiceNumber: nextInvoiceNumber ? nextInvoiceNumber : null,
      receiveVenueQueryHook: formDetails?.receiveVenueQueryHook ?? null,
      returnVenueQueryHook: formDetails?.returnVenueQueryHook ?? null,
    };

    return addOrgUtcOffsetToInvoice({
      invoice: invoicePayload,
      orgTimezone: orgTimezone,
    })
    .onWrite();
  }

  return {
    newInvoice: newInvoice, // configure new inventory item
  };
};
