import { routeColors } from "./routeColors";

export const GenerateColor = (routes) => {
  const count = routes.length ? routes.length + 1 : 0;
  const index = count % 10;
  return routeColors?.[index] ?? "#6138E9";
};

// RECHARTS PIE COLORS
export const PS_PIE_COLORS = [
  "#35dda4",
  "#EE7574",
  "#FD8766",
  "#5ADFE2",
  "#4D60F2",
  "#6BB5FF",
  "#8766EA",
  "#BB65D3",
  "#EEC774",
];
