import notificationError from "../../../system-components/toasters/notificationError";
import { VENUE_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkVenue } from "../../../utils/models/checkers/checkVenue";
import { VENUE_NAME } from "../../../utils/models/modelConstants/modelConstants";
import { sortBy } from "../../../utils/sorters/sortBy";

export function venueQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );

  const ref = firestore.collection(`${orgPrefix}${VENUE_COLLECTION}`);

  async function fetchVenues() {
    const snap = await ref.get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkVenue(s));
    });
    return sortBy(list, VENUE_NAME);
  }

  async function fetchActiveVenues() {
    const snap = await ref.where("isActive", "==", true).get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkVenue(s));
    });
    return sortBy(list, VENUE_NAME);
  }

  async function fetchVenue(id) {
    const snap = await ref.doc(id).get();
    return checkVenue(snap);
  }
  
  return {
    fetchVenues: fetchVenues, // fetch venues
    fetchVenue: fetchVenue, // fetch venue
    fetchActiveVenues: fetchActiveVenues, // fetch active venues only
  };
}
