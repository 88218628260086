import React, { useContext } from "react";
import { Button, Card, Col, message, Modal, Row, Space, Steps } from "antd";
import { ROUTE_INVENTORY, ROUTE_ROUTES } from "../../../app/routes";
import {
  COLOR_DARK_BG_1,
  COLOR_LIGHT_GRAY,
} from "../../../app/ui/colorConstants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { RouteContext } from "../NewRoute";

const { Step } = Steps;
const RouteBuilderHeader = () => {
  const history = useHistory();
  const { state, actions } = useContext(RouteContext);
  return (
    <Card size={"small"} className="route-header">
      <Row wrap={false} align={"middle"} justify={"space-between"}>
        <Col flex={"60px"}>
          {state.step !== 0 && (
            <Button
              onClick={() => {
                if (state.step === 1) {
                  actions.resetRouteData();
                }
                actions.setStep(state.step - 1);
              }}
              style={{
                backgroundColor: COLOR_LIGHT_GRAY,
                color: COLOR_DARK_BG_1,
              }}
              ghost={true}
            >
              <Space direction={"horizontal"} size={8}>
                <ArrowLeftOutlined />
              </Space>
            </Button>
          )}
        </Col>
        <Col flex={"auto"}>
          <Steps size="small" current={state.step}>
            <Step
              style={{ cursor: "pointer" }}
              onClick={() => {
                actions.resetRouteData();
              }}
              title={
                state.date?.string !== "" ? state.date?.string : "Select Date"
              }
            />
            <Step
              title="Configure Routes"
              style={{ cursor: "pointer", opacity: state.step === 0 ? 0.5 : 1 }}
              onClick={() => {
                if (state.step === 0)
                  return message.warn("Select day to continue");
                actions.setStep(1);
              }}
            />
            <Step
              style={{ cursor: "pointer", opacity: state.step === 0 ? 0.5 : 1 }}
              title="Assign Employees and Vehicles"
              onClick={() => {
                if (state.step === 0)
                  return message.warn("Select day to continue");
                actions.setStep(2);
              }}
            />
          </Steps>
        </Col>
        <Col flex={"60px"} style={{ marginLeft: "16px" }}>
          <Button
            onClick={() => {
              Modal.confirm({
                title: `Exit Route Builder?`,
                icon: <></>,
                okText: "Ok",
                onOk() {
                  actions.resetRouteData();
                  history.push(ROUTE_INVENTORY);
                },
              });
            }}
            style={{
              backgroundColor: COLOR_LIGHT_GRAY,
              color: COLOR_DARK_BG_1,
            }}
            ghost={true}
          >
            <Space direction={"horizontal"} size={8}>
              Exit
            </Space>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default RouteBuilderHeader;
