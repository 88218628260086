import { DocumentReference, DocumentData } from '@firebase/firestore-types';

import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  alpha,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { fileData } from "../../file-manager/file-thumbnail";

import { functions } from "../../../app/config/firebase";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Rowing } from "@mui/icons-material";
import FileManagerNewFileBox from "../../file-manager/file-manager-new-file-box";
import { CheckFile } from "../../file-manager/file";
import {
  Automation,
  AutomationTrigger,
  allTriggers,
} from "../../../app/interfaces/automation";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import Iconify from "../../../app/iconify";


const EmailAutomationMaker = ({
  handleClose,
  save,
  automation,
  orgData,
  templates,
}: {
  handleClose: () => void;
  save: () => void;
  automation?: Automation;
  orgData: OrganizationData;
  templates: any[];
}) => {
  const db = useFirestore();
  const firebase = useFirebase();
  const triggers: AutomationTrigger[] = allTriggers;

  const [showMoreInstructions, setShowMoreInstructions] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState<AutomationTrigger | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [daysAfter, setDaysAfter] = useState<number | null>(null);
  const [daysBefore, setDaysBefore] = useState<number | null>(null);

  const [sendOnSelected, setSendOnSelected] = useState(false);
  const [sendAfterSelected, setSendAfterSelected] = useState(false);
  const [sendBeforeSelected, setSendBeforeSelected] = useState(false);
  const [sendImmediatelySelected, setSendImmediatelySelected] = useState(false);
  const [reviewBeforeSend, setReviewBeforeSend] = useState(true);

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [automationName, setAutomationName] = useState(automation?.name || "");

  useEffect(() => {
    if (automation) {
      setSelectedTrigger(automation.automationTrigger);
      setSelectedTemplate(automation.emailTemplateIds[0] || null);
      setDaysAfter(automation.daysAfter || null);
      setDaysBefore(automation.daysBefore || null);
      setAutomationName(automation.name);
      setReviewBeforeSend(automation.reviewFirst || true);

      setSendOnSelected(automation.sendOnSelected)
      setSendAfterSelected(automation.sendAfterSelected)
      setSendBeforeSelected(automation.sendBeforeSelected)
      setSendImmediatelySelected(automation.sendImmediatelySelected)

    }
  }, [automation]);

  // Function to handle changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "on":
        setSendOnSelected(event.target.checked);
        break;
      case "after":
        setSendAfterSelected(event.target.checked);
        break;
      case "before":
        setSendBeforeSelected(event.target.checked);
        break;
      case "immediately":
        setSendImmediatelySelected(event.target.checked);
        break;
      case "reviewBefore":
        setReviewBeforeSend(event.target.checked);
        break;
      default:
        break;
    }
  };

  const saveAutomation = async () => {
    let ref: any= db
      .collection("orgs")
      .doc(orgData.id)
      .collection("automations");

    if (automation) {
      ref = ref.doc(automation.id);
    } else {
      ref = ref.doc();
    }

    const automationData: Partial<Automation> = {
      id: ref.id,
      name: automationName,
      daysAfter: daysAfter || null,
      daysBefore: daysBefore || null,
      sendOnSelected,
      sendAfterSelected,
      sendBeforeSelected,
      sendImmediatelySelected,
      emailTemplateIds: [selectedTemplate || ""],
      automationTrigger: selectedTrigger || undefined,
      triggerType: selectedTrigger?.trigger,
      reviewFirst: reviewBeforeSend,
      object: "invoice",
    };

    if (automation) {
      await ref.update(automationData);
    } else {
      await ref.set(automationData);
    }

    enqueueSnackbar("Automation Saved", { variant: "success" });
  };

  const deleteAutomation = async () => {
    let ref: any = db
      .collection("orgs")
      .doc(orgData.id)
      .collection("automations")
  
    if (automation) {
      ref = ref.doc(automation.id);
    }

    await ref.delete();

    enqueueSnackbar("Automation Deleted", { variant: "success" });

    handleClose();
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {automationName ? automationName : "New Automation"}
          </Typography>
          <Button autoFocus color="inherit" onClick={saveAutomation}>
            Save
          </Button>
          {automation && (
            <Button
              autoFocus
              variant="contained"
              color="error"
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Grid
          container
          direction="row"
          gap={2}
          justifyContent="center"
          alignContent="center"
        >
          <Grid
            container
            item
            direction={"column"}
            xs={12}
            gap={2}
            lg={8}
            xl={5}
          >
            <Box
              sx={{
                p: 2,
                width: "100%",
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.warning.light, 0.25),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
              <Stack>
                <Stack mb={showMoreInstructions ? 1 : 0} alignItems={'center'} direction={"row"} justifyContent={"space-between"}>
                  <Stack>
                  <Typography sx={{fontWeight: 'bold'}}>How does an email automation work?</Typography>
                  <Typography fontSize={12} sx={{fontWeight: 'bold'}}>CURRENTLY IN BETA: While in Beta all scheduled emails must be reviewed for day of sending. Please inform us of any bugs you see.</Typography>
                  </Stack>
                  {!showMoreInstructions ? (
                    <Button sx={{width: '150px'}} onClick={() => setShowMoreInstructions(true)}>
                      Learn More
                    </Button>
                  ) : (
                    <Button onClick={() => setShowMoreInstructions(false)}>
                      Show Less
                    </Button>
                  )}
                </Stack>

                {showMoreInstructions && (
                  <>

                    <Typography gutterBottom>
                      Email automations are a way to automatically send emails
                      to your customers based on certain triggers. For example,
                      you can automatically send a thank you email to a customer
                      when they pay their invoice. Depending on the trigger type you can schedule emails
                      to be sent out at a later date, earlier date, immediately, or review them first
                      before sending them out.
                    </Typography>

                    <Typography gutterBottom>
                      Emails are scheduled for sending based on a 
                      <span style={{ fontWeight: 'bold', color: "black" }}> trigger</span>. Triggers
                      occur when a selected criteria is met. For example, when
                      an invoice is paid, or when an order is delivered. Note
                      that simply creating an automation does not immediately
                      send or schedule any emails. Emails are only sent or scheduled
                      when the trigger occurs.
                    </Typography>

                    <Typography>
                      All scheduled emails that are not reviewed are sent out at 7:00 AM PST
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>

            <Stack gap={2}>
              <TextField
                id="outlined-basic"
                value={automationName}
                onChange={(e) => setAutomationName(e.target.value)}
                label="Template Name"
                variant="outlined"
                fullWidth
              />

              <Box
                sx={{
                  p: 2,
                  width: "100%",
                  borderRadius: 1,

                  border: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
              >
                <Stack direction="row" gap={2} justifyContent={"space-between"}>
                  <Stack alignItems={"center"} direction={"row"} gap={1}>
                    <Iconify icon="fluent:number-circle-1-16-filled" />
                    <Typography variant="h6">
                      When the following action occurs:
                    </Typography>
                  </Stack>

                  <Autocomplete
                    id="trigger-selector"
                    options={triggers}
                    getOptionLabel={(option) => option.displayName}
                    value={selectedTrigger}
                    onChange={(event, newValue) => {
                      setSelectedTrigger(newValue);
                      setSendOnSelected(false);
                      setSendAfterSelected(false);
                      setSendBeforeSelected(false);
                      setSendImmediatelySelected(false);
                      setDaysAfter(null);
                      setDaysBefore(null);
                      setReviewBeforeSend(true);

                      if (automationName === "") {
                        setAutomationName(newValue?.displayName ?? "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Trigger"
                        variant="outlined"
                        sx={{ width: "300px" }}
                      />
                    )}
                  />
                </Stack>
              </Box>

              <Box
                sx={{
                  p: 2,
                  width: "100%",
                  borderRadius: 1,

                  border: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
              >
                <Stack direction="row" gap={2} justifyContent={"space-between"}>
                  <Stack alignItems={"center"} direction={"row"} gap={1}>
                    <Iconify icon="fluent:number-circle-2-16-filled" />
                    <Typography variant="h6">
                      Send the following email using this template:
                    </Typography>
                  </Stack>

                  <Autocomplete
                    id="template-selector"
                    options={templates}
                    value={selectedTemplate}
                    getOptionLabel={(option) => option.templateName}
                    onChange={(event, newValue) => {
                      setSelectedTemplate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Email Template"
                        variant="outlined"
                        sx={{ width: "300px" }}
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>

            {selectedTrigger && selectedTemplate && (
              <Box
                sx={{
                  p: 2,
                  width: "100%",
                  borderRadius: 1,
                  border: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
              >
                <FormGroup>
                  <Stack gap={2}>
                    <Stack alignItems={"center"} direction={"row"} gap={1}>
                      <Iconify icon="fluent:number-circle-3-16-filled" />
                      <Typography variant="h6">Schedule this email:</Typography>
                    </Stack>

                    {selectedTrigger.sendTypes.includes("immediate") && (
                      <Stack direction={"column"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={true}
                              checked={sendImmediatelySelected}
                              onChange={handleChange}
                              name="immediately"
                            />
                          }
                          label="Send Immediately Once Trigger Occurs (COMING SOON)"
                        />
                      </Stack>
                    )}

                    {selectedTrigger.sendTypes.includes("on") && (
                      <Stack direction={"column"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sendOnSelected}
                              onChange={handleChange}
                              name="on"
                            />
                          }
                          label="On Day Of Trigger"
                        />
                      </Stack>
                    )}

                    {selectedTrigger.sendTypes.includes("before") && (
                      <Stack direction={"column"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sendBeforeSelected}
                              onChange={handleChange}
                              name="before"
                            />
                          }
                          label="(N) Days Before: "
                        />

                        {sendBeforeSelected && (
                          <Stack alignItems={"center"} direction="row" gap={2}>
                            <Typography variant="h6">Send</Typography>
                            <TextField
                              size="small"
                              sx={{ width: "130px" }}
                              type="number"
                              label="Days Before"
                              value={daysBefore}
                              inputProps={{ min: 1 }}
                              onChange={(event) =>
                                setDaysBefore(parseInt(event.target.value))
                              }
                            />
                            <Typography variant="h6">
                              days before the trigger occurs.
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    )}

                    {selectedTrigger.sendTypes.includes("after") && (
                      <Stack direction={"column"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sendAfterSelected}
                              onChange={handleChange}
                              name="after"
                            />
                          }
                          label="(N) Days After: "
                        />

                        {sendAfterSelected && (
                          <Stack alignItems={"center"} direction="row" gap={2}>
                            <Typography variant="h6">Send</Typography>
                            <TextField
                              size="small"
                              sx={{ width: "130px" }}
                              type="number"
                              inputProps={{ min: 1 }}
                              label="Days After"
                              value={daysAfter}
                              onChange={(event) =>
                                setDaysAfter(parseInt(event.target.value))
                              }
                            />
                            <Typography variant="h6">
                              days after the trigger occurs.
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </FormGroup>
              </Box>
            )}

            {selectedTrigger && selectedTemplate && (
              <Box
                sx={{
                  p: 2,
                  width: "100%",
                  borderRadius: 1,
                  border: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
              >
                <Stack direction="row" gap={2} justifyContent={"space-between"}>
                  <Stack alignItems={"center"} direction={"row"} gap={1}>
                    <Iconify icon="fluent:number-circle-4-16-filled" />
                    <Typography variant="h6">Review First</Typography>
                    <Tooltip
                      title={
                        <Typography fontSize={14}>
                          Note: While this feauture is in Beta, all email created by automation must be reviewed before sending, so this option is always enabled.
                          If you want to first review any scheduled emails for a
                          given day, you may do so by checking this box. This
                          will hold any emails scheduled for review for that
                          day. This does not apply to emails scheduled for
                          immediate sending. You can review and send all emails
                          scheduled for review for that day in the dashboard.
                        </Typography>
                      }
                      arrow
                    >
                      <IconButton size="small">
                        <HelpOutlineIcon sx={{ color: "text.secondary" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        disabled={true}
                        onChange={handleChange}
                        name="reviewBefore"
                      />
                    }
                    label="Require Review Before Sending"
                  />
                </Stack>
              </Box>
            )}

            {selectedTrigger &&
              selectedTemplate &&
              (sendAfterSelected ||
                sendBeforeSelected ||
                sendImmediatelySelected ||
                sendOnSelected) && (
                <Box
                  sx={{
                    p: 2,
                    width: "100%",
                    borderRadius: 1,
                    bgcolor: (theme) =>
                      alpha(theme.palette.success.light, 0.25),
                    border: (theme) => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Stack
                    direction="column"
                    gap={2}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="h6">Preview</Typography>
                    <Typography>
                      {`This automation will run when triggered by the action: ${selectedTrigger.displayName}`}
                    </Typography>
                    <Typography>
                      {`It will prepare emails to be sent out using the template: ${selectedTemplate.templateName}`}
                    </Typography>

                    {sendAfterSelected && (
                      <Typography>
                        {`The email will be sent ${
                          daysAfter ?? 1
                        } days after the trigger occurs.`}
                      </Typography>
                    )}
                    {sendBeforeSelected && (
                      <Typography>
                        {`The email will be sent ${
                          daysBefore ?? 1
                        } days before the trigger occurs.`}
                      </Typography>
                    )}
                    {sendOnSelected && (
                      <Typography>
                        {`The email will be sent on the day the trigger occurs.`}
                      </Typography>
                    )}
                    {sendImmediatelySelected && (
                      <Typography>
                        {`The email will be sent immediately after the trigger occurs.`}
                      </Typography>
                    )}

                    {reviewBeforeSend && (
                      <Typography>
                        {`Any emails scheduled with this automation will be held for review before sending. In the dashboard, for any given day, you can review and send all emails scheduled for review for that day. This does not apply to emails scheduled for immediate sending.`}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              )}
          </Grid>
        </Grid>

        <Dialog open={confirmDeleteDialog}>
          <Box sx={{ p: 2 }}>
            <Typography gutterBottom sx={{ mb: 2 }} fontWeight={"bold"}>
              Confirm Delete Automation
            </Typography>
            <Typography gutterBottom sx={{ mb: 2 }} fontWeight={"bold"}>
              This will delete this automation and all scheduled emails
              associated with it.
            </Typography>
            <Stack direction="row" gap={2}>
              <Button
                sx={{ backgroundColor: "gray" }}
                variant="contained"
                onClick={() => setConfirmDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={deleteAutomation}>
                Delete
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </Container>
    </>
  );
};

export default EmailAutomationMaker;
