import { Table } from "antd";
import { useFirestoreFetch } from "../../../../app/services/hooks/fetch/useFirestoreFetch";


import { invoices } from "../../../../app/services/firestore/queries/invoiceQueries";
import convertTimeToDayString from "../../../../app/utils/time/convertTimeToDayString";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { ROUTE_EDIT_INVOICE } from "../../../../app/routes";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";

const CustomerInvoiceHistory = ({
  customerId,
}) => {
  const { orgData } = useSelector(authSelector);

  const { data, fetching } = useFirestoreFetch(
    invoices(orgData?.orgTimezone).fetchInvoicesByCustomer,
    customerId && customerId
  );

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
      defaultSortOrder: "descend",
      render: (text, record) => {
        return (
          <Link
            to={`${ROUTE_EDIT_INVOICE}${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </Link>
        );
      },
      onCell: (record) => ({
        onClick: () => {
          // Navigate to invoice details
          //   history.push(`${ROUTE_EDIT_INVOICE}${record.id}`);

          <Link
            to={`${ROUTE_EDIT_INVOICE}${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
          />;
        },
      }),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return `$${record.total.toFixed(2)}`;
      },
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        return `$${(record.total - record.balanceFulfilled).toFixed(2)}`;
      },
    },
    {
      title: "Rental Start",
      dataIndex: "rentalDateStart",
      key: "rentalDateStart",
      render: (date, record) => {
        const str = date && convertTimeToDayString(date);
        return <TextBody text={str} />;
      },
      sorter: (a, b) => a.rentalDateStart - b.rentalDateStart,
    },
  ];

  return (
 
      <div>
        <Table
          loading={fetching}
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          sortDirections={["ascend", "descend"]}
        />
      </div>
  );
};

export default CustomerInvoiceHistory;
