import { useEffect, useState } from "react";
import {
  ROUTE_ROLE_CREW,
  ROUTE_ROLE_DRIVER,
  ROUTE_ROLE_LOADER,
} from "../../utils/models/modelConstants";
import {
  COLOR_BLUE_0,
  COLOR_DARK_BG_1,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
} from "../../ui/colorConstants";

export function useRouteAssignmentBtns({ route, userId, routes }) {
  const deselectedStyle = {
    background: COLOR_LIGHT_GRAY,
    borderColor: "transparent",
    color: COLOR_DARK_BG_1,
    fontWeight: "normal",
  };
  const [driverProps, setDriverProps] = useState({
    selected: false,
    buttonStyle: deselectedStyle,
  });
  const [loaderProps, setLoaderProps] = useState({
    selected: false,
    buttonStyle: deselectedStyle,
  });
  const [crewProps, setCrewProps] = useState({
    selected: false,
    buttonStyle: deselectedStyle,
  });
  const [roles, setRoles] = useState([]);
  const [isMultiAssigned, setIsMultiAssigned] = useState(false);

  const driverStyle = {
    background: COLOR_RED_0,
    borderColor: "transparent",
    color: "#FFF",
    fontWeight: "bold",
  };
  const loaderStyle = {
    background: COLOR_BLUE_0,
    borderColor: "transparent",
    color: "#FFF",
    fontWeight: "bold",
  };
  const crewStyle = {
    background: COLOR_GREEN_0,
    borderColor: "transparent",
    color: "#FFF",
    fontWeight: "bold",
  };

  useEffect(() => {
    const user = route?.assignedEmployees?.find((e) => e?.id === userId);
    const roles = user?.roles ?? [];
    const isDriver = roles.includes(ROUTE_ROLE_DRIVER);
    const isLoader = roles.includes(ROUTE_ROLE_LOADER);
    const isCrew = roles.includes(ROUTE_ROLE_CREW);

    setDriverProps({
      selected: isDriver,
      buttonStyle: isDriver ? driverStyle : deselectedStyle,
    });
    setLoaderProps({
      selected: isLoader,
      buttonStyle: isLoader ? loaderStyle : deselectedStyle,
    });
    setCrewProps({
      selected: isCrew,
      buttonStyle: isCrew ? crewStyle : deselectedStyle,
    });
    let rolesList = [];
    if (isDriver) {
      rolesList.push(ROUTE_ROLE_DRIVER);
    }
    if (isLoader) {
      rolesList.push(ROUTE_ROLE_LOADER);
    }
    if (isCrew) {
      rolesList.push(ROUTE_ROLE_CREW);
    }
    setRoles(rolesList);
  }, [route, userId]);

  useEffect(() => {
    const assignedInRoutes = routes?.filter(
      (r) => r.employeesQueryHook.includes(userId) && r?.id !== route?.id
    );
    if (assignedInRoutes?.length > 0) {
      setIsMultiAssigned(true);
    } else {
      setIsMultiAssigned(false);
    }
  }, [routes, userId, route]);

  return {
    driverProps,
    loaderProps,
    crewProps,
    roles,
    isMultiAssigned,
  };
}
