import { FetchRouteDirections } from "../../../batchRequests/fetchRouteDirections";
import { GetStepLatLngs } from "../../../../../domains/routes/map/helpers/getStepLatLngs";
import { StopPayload } from "../../../../../domains/routes/map/helpers/stopPayload";
import { RoutePayload } from "../../../../../domains/routes/map/helpers/routePayload";
import notificationError from "../../../../system-components/toasters/notificationError";

export const FetchPolyline = async ({ route, firebase, setUpdating }) => {
  setUpdating(true);
  return new Promise((resolve, reject) => {
    let needsUpdate = [];
    let updatePayloads = [];

    route?.stops?.map((stop) => {
      if (stop?.updatePolyline) {
        needsUpdate.push(stop);
        updatePayloads.push({
          origin: { lat: stop?.fromLat, lng: stop?.fromLng },
          destination: { lat: stop?.lat, lng: stop?.lng },
        });
      }
    });
    FetchRouteDirections({ firebase }, updatePayloads)
      .then((responses) => {
        const newStops = responses?.map((res, index) => {
          const stepData = res?.routes?.[0]?.legs?.[0]?.steps ?? [];
          const polylineStr = res?.routes?.[0]?.overview_polyline?.points ?? "";
          const polySteps = GetStepLatLngs(stepData);
          const stop = needsUpdate[index];
          return StopPayload(stop).updatePolyline(polylineStr);
        });
        let routePayload = route;
        newStops?.map((newStop) => {
          routePayload = RoutePayload(routePayload).updateStop({
            stop: newStop,
          });
        });
        setUpdating(false);
        resolve(routePayload);
      })
      .catch((err) => {
        console.log(err);
        notificationError(
          "Something went wrong",
          "Could not fetch route data. Please refresh the page and try again."
        );
        setUpdating(false);
        resolve([]);
      });
  });
};
