import { Invoice } from "../../../app/interfaces/invoice";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  RETURN_METHOD_DROPOFF,
  RETURN_METHOD_ONSITE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { DateRange, PickerChangeHandlerContext, TimePicker, TimeValidationError } from "@mui/x-date-pickers-pro";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import Iconify from "../../../app/iconify";
import { format } from "date-fns";
import { Customer } from "../../../app/interfaces/customer";
import { Venue } from "../../../app/interfaces/venue";
import { ReturnDetailsProps } from "../types/ReceiveReturnDetails";
import { AddressAutocomplete } from "../../sharedComponents/AddressAutoComplete";
import firebase from 'firebase/app';

type Props = {
  orgData: OrganizationData;
  invoice?: Invoice;
  receiveReturnDetails: ReturnDetailsProps;
  customer: Customer | null;
  rentalRange: DateRange<Date>;
  venues: any[];
  updateParentState: (state: ReturnDetailsProps) => void;
};

export function ReturnDetailsMUI({ orgData, invoice, receiveReturnDetails, customer, rentalRange, venues, updateParentState }: Props) {
  
  const [returnBy, setReturnBy] = useState<string>(receiveReturnDetails.returnBy);

  useEffect(() => {
    if (!invoice) {
      setUseCustomerAddressReturn(receiveReturnDetails.useCustomerAddressReturn);
    }
  }, [receiveReturnDetails]);
  
  const responsiveFontSize = useResponsiveFontSize();
  const theme = useTheme();
  const isInitialMount = useRef(true);
  const { isMDAndUp, isMDAndDown, isLGAndUp } = useCurrentBreakpoint();

  const [manualReturnName, setManualReturnName] = useState<string>(invoice?.onSiteCustomerDisplayName ?? "");
  const [manualReturnAddress, setManualReturnAddress] = useState<string>(receiveReturnDetails.manualReturnAddress);
  const [manualReturnCity, setManualReturnCity] = useState<string>(receiveReturnDetails.manualReturnCity);
  const [manualReturnState, setManualReturnState] = useState<string>(receiveReturnDetails.manualReturnState);
  const [manualReturnZip, setManualReturnZip] = useState<string>(receiveReturnDetails.manualReturnZip);

  
  // MARK: Customer Hook
  const [useCustomerAddressReturn, setUseCustomerAddressReturn] = useState<boolean>(() => {
    if (receiveReturnDetails.returnBy === RETURN_METHOD_DROPOFF) {
      return false;
    }
    return customer ? invoice?.customerAddressChecked?.return ?? true : false;
  });

  const [customerHasChanged, setCustomerHasChanged] = useState<boolean>(false);
  const prevCustomerRef = useRef<Customer | null>(customer); // Use null or appropriate type for initial value

  useEffect(() => {
    if (isInitialMount.current) {
      // Skip the effect on initial mount
      isInitialMount.current = false;
    } else {

       // Get the previous customer value
       const previousCustomer = prevCustomerRef.current;

       if (previousCustomer === null && customer !== null) {
        setUseCustomerAddressReturn(true);
       }

      // Run this code only when the customer changes after the initial mount
      if (customer) {
        setReturnAddressFormatted(customer.customerShippingAddressStr);
        setManualReturnName(customer.customerDisplayName);
        setCustomerHasChanged(true);
      }

      prevCustomerRef.current = customer;

    }
  }, [customer]);

  useEffect(() => {
    if (useCustomerAddressReturn) {
      if (customerHasChanged) {
        setReturnAddressFormatted(customer?.customerShippingAddressStr ?? "");
      } else {
        setReturnAddressFormatted(invoice?.deliveryCustomerShippingAddressStr ?? "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCustomerAddressReturn]);





  const [returnAddressFormatted, setReturnAddressFormatted] = useState<string>(() => {
    if (receiveReturnDetails.returnBy === RETURN_METHOD_DROPOFF) {
      return orgData.companyAddress;
    } else {
      return customer?.customerShippingAddressStr ?? "";
    }
  });



  // MARK: Auto Address Hook
  const [returnPlace, setReturnPlace] = useState<google.maps.places.PlaceResult | null>(null);

  // When returnPlace changes, update the manual properties
  useEffect(() => {
    if (returnPlace) {
      setManualReturnAddress(returnPlace.name ?? "");
      setManualReturnCity(returnPlace.address_components?.find((component) => component.types.includes("locality"))?.long_name ?? "");
      setManualReturnState(returnPlace.address_components?.find((component) => component.types.includes("administrative_area_level_1"))?.short_name ?? "");
      setManualReturnZip(returnPlace.address_components?.find((component) => component.types.includes("postal_code"))?.long_name ?? "");
    }
  }, [returnPlace]);

  const handleReturnAddress = (selectedPlace: google.maps.places.PlaceResult | null) => {
    if (selectedPlace) {
      setReturnAddressFormatted(selectedPlace.formatted_address ?? "");
      setReturnPlace(selectedPlace);
    } else {
      setReturnAddressFormatted("");
      setReturnPlace(null);
    }
  };


  // MARK: Specify Time
  const [specifiedReturnTime, setSpecifiedReturnTime] = useState<Date | null>(invoice?.specifiedReturnTime?.toDate() ?? null);
  const [specifiedReturnTimeEnd, setSpecifiedReturnTimeEnd] = useState<Date | null>(invoice?.specifiedReturnTimeEnd?.toDate() ?? null);

  const [specifyReturnTime, setSpecifyReturnTime] = useState<boolean>(invoice?.hasSpecifiedReturnTime ?? false);
  const [selectedReturnVenue, setSelectedReturnVenue] = useState<string>(invoice?.returnVenueQueryHook ?? "");



  // MARK: Venue Select
  // If a venue is selected for return, update the manual properties. And clear the selected place
  const handleVenueSelect = (event: any) => {

    const venueId = event.target.value;
    setSelectedReturnVenue(venueId);
    
    if (venues && venueId) {
      // Find the matching venue.
      const venue = venues.find((venue) => venue.id === venueId) as Venue;
      if (venue) {
        setReturnPlace(null);

        setManualReturnName(venue.venueContactName);
        setManualReturnAddress(venue.venueAddress);
        setManualReturnCity(venue.venueCity);
        setManualReturnState(venue.venueState);
        setManualReturnZip(venue.venueZip);
      }
    }
  }

  // export const RETURN_METHOD_ONSITE = "onSite";
  // export const RETURN_METHOD_DROPOFF = "dropOff";


  // MARK: Return Method Change
  const handleReturnMethodChange = (method: string) => {
    if (method === RETURN_METHOD_DROPOFF) {
      setReturnAddressFormatted(orgData.companyAddress)
      if (orgData.companyOpenTime) {
        setSpecifyReturnTime(true);
        const time = (orgData.companyOpenTime as  firebase.firestore.Timestamp).toDate();
        setSpecifiedReturnTime(time);
      }
      if (orgData.companyCloseTime) {
        const time = (orgData.companyCloseTime as firebase.firestore.Timestamp).toDate();
        setSpecifiedReturnTimeEnd(time);
      }
    } else {
      if (customer) {
        setReturnAddressFormatted(customer.customerShippingAddressStr);
      }
    }
    setReturnBy(method);
  }



  // MARK: Use Customer Address
  useEffect(() => {
    if (useCustomerAddressReturn) {
        returnPlace && setReturnPlace(null);

        setReturnAddressFormatted(customer?.customerShippingAddressStr ?? "");

        // set the manual properties to the customer's address
        setManualReturnName(customer?.customerDisplayName ?? "");
        setManualReturnAddress(customer?.customerShippingAddress ?? "");
        setManualReturnCity(customer?.customerShippingCity ?? "");
        setManualReturnState(customer?.customerShippingState ?? "");
        setManualReturnZip(customer?.customerShippingZip ?? "");

        setSelectedReturnVenue("");
        setManualReturnName(customer?.customerDisplayName ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCustomerAddressReturn]);




  // MARK: Update Parent State
  // When any number of some state properties change, i need to update the parent state
  useEffect(
    () => {
      const updateData: ReturnDetailsProps = {
        returnBy,
        returnPlace,
        useCustomerAddressReturn,
        specifyReturnTime,
        selectedReturnVenue,
        specifiedReturnTime,
        specifiedReturnTimeEnd,
        manualReturnAddress,
        manualReturnCity,
        manualReturnState,
        manualReturnZip,
        onSiteCustomerDisplayName: manualReturnName,
      };

      updateParentState(updateData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      returnBy,
      returnPlace,
      useCustomerAddressReturn,
      specifyReturnTime,
      selectedReturnVenue,
      specifiedReturnTime,
      specifiedReturnTimeEnd,
      manualReturnAddress,
      manualReturnCity,
      manualReturnState,
      manualReturnZip,
      manualReturnName,
    ]
  );


  // MARK: - Render
  return (
    <>
      {/* MARK: - Return */}
      <Stack gap={1}>
        <Stack direction={isMDAndDown ? "column" : "row"} gap={2}>
          <Stack direction={"row"} gap={2} alignItems={isMDAndDown ? "flex-start" : "center"}>
            <Stack>
              <Stack gap={1} alignItems="center" direction="row">
                <Typography fontWeight="bold">{rentalRange[1] && format(rentalRange[1], "MMMM do, yyy")}</Typography>
                <Iconify icon="iconoir:log-in" width={22} height={22} />
              </Stack>
              <Typography fontSize={responsiveFontSize}>Customer returns items by:</Typography>
            </Stack>

            <FormControl style={{ width: "160px" }}>
              <TextField
                id="sales-rep"
                inputProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                InputLabelProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                select
                onChange={(event) => handleReturnMethodChange(event.target.value)}
                value={returnBy}
                size="small"
              >
                <MenuItem sx={{ fontSize: responsiveFontSize }} key="onSite" value="onSite">
                  <Typography fontSize={responsiveFontSize}>On Site Pickup</Typography>
                </MenuItem>
                <MenuItem sx={{ fontSize: responsiveFontSize }} key="dropOff" value="dropOff">
                  <Typography fontSize={responsiveFontSize}>Customer Drop Off</Typography>
                </MenuItem>
              </TextField>
            </FormControl>
          </Stack>

          <Stack direction={isMDAndUp ? "row" : "column"}>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ style: { fontSize: responsiveFontSize } }}
                  checked={specifyReturnTime}
                  onChange={(e) => {
                    setSpecifyReturnTime(e.target.checked);
                    if (!e.target.checked) {
                      setSpecifiedReturnTime(null);
                      setSpecifiedReturnTimeEnd(null);
                    }
                  }}
                />
              }
              label={<Typography fontSize={responsiveFontSize}>Specify Time</Typography>}
            />

            {specifyReturnTime && (
              <Stack direction={"row"} gap={2}>
                <TimePicker
                  value={specifiedReturnTime}
                  label="Start"
                  slotProps={{
                    textField: {
                      size: "small",
                      inputProps: {
                        style: { fontSize: responsiveFontSize },
                      },
                      InputLabelProps: {
                        style: { fontSize: responsiveFontSize },
                      },
                    },
                    field: {
                      clearable: true,
                      onClear: () => setSpecifiedReturnTime(null),
                    },
                  }}
                  onChange={(value: any, context: PickerChangeHandlerContext<TimeValidationError>) => {
                    const { validationError } = context;
                    if (validationError) {
                      console.error("Invalid time:", validationError);
                    } else {
                      setSpecifiedReturnTime(value);
                    }
                  }}
                />

                <TimePicker
                  label="End (optional)"
                  value={specifiedReturnTimeEnd}
                  slotProps={{
                    textField: {
                      size: "small",
                      inputProps: {
                        style: { fontSize: responsiveFontSize },
                      },
                      InputLabelProps: {
                        style: { fontSize: responsiveFontSize },
                      },
                    },
                    field: {
                      clearable: true,
                      onClear: () => setSpecifiedReturnTimeEnd(null),
                    },
                  }}
                  onChange={(value: any, context: PickerChangeHandlerContext<TimeValidationError>) => {
                    const { validationError } = context;
                    if (validationError) {
                      console.error("Invalid time:", validationError);
                    } else {
                      setSpecifiedReturnTimeEnd(value);
                    }
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        {returnBy === RETURN_METHOD_ONSITE && (
          <FormControlLabel
            sx={{
              display: "inline-flex",
              alignItems: "center",
              width: "fit-content", // Ensures the width is only as wide as the contents
            }}
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={useCustomerAddressReturn}
                onChange={(e) => setUseCustomerAddressReturn(e.target.checked)}
              />
            }
            label={
              <Typography color={!customer ? theme.palette.text.disabled : theme.palette.text.primary} fontSize={responsiveFontSize}>
                Use Customer Address
              </Typography>
            }
            disabled={!customer}
          />
        )}

        {returnBy === RETURN_METHOD_DROPOFF ? (
          <Box
            sx={{
              p: 1,
              background: theme.palette.grey[200],
              borderRadius: "4px",
              display: "inline-flex", // Use inline-flex to align items horizontally
              alignItems: "center", // Center-align the icon and text vertically
            }}
          >
            <Iconify sx={{ mr: 1 }} icon="fluent:home-12-filled" color={theme.palette.text.secondary} />
            <Typography fontSize={responsiveFontSize}>{returnAddressFormatted}</Typography>
          </Box>
        ) : useCustomerAddressReturn ? (
          <Box
            sx={{
              p: 1,
              background: theme.palette.grey[200],
              borderRadius: "4px",
              display: "inline-flex", // Use inline-flex to align items horizontally
              alignItems: "center", // Center-align the icon and text vertically
            }}
          >
            <Iconify sx={{ mr: 1 }} icon="tabler:map-pin-filled" color={theme.palette.text.secondary} />
            <Typography fontSize={responsiveFontSize}>{returnAddressFormatted}</Typography>
          </Box>
        ) : (
          <Stack gap={1}>
            <FormControl
              style={{
                maxWidth: "100%",
                width: "400px",
                minWidth: "200px",
              }}
            >
              <TextField
                inputProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                InputLabelProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                label="Contact Name (Optional)"
                size="small"
                defaultValue={customer?.customerDisplayName ?? ""}
                onChange={(event) => setManualReturnName(event.target.value)}
                value={manualReturnName}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 1,
                }}
              />
            </FormControl>

            <Stack gap={2} alignItems={"center"} direction={"row"}>
              <FormControl
                style={{
                  maxWidth: "100%",
                  width: "400px",
                  minWidth: "200px",
                }}
              >
                <TextField
                  label="Select Venue"
                  size="small"
                  select
                  id={"return-venue"}
                  inputProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  onChange={handleVenueSelect}
                  value={selectedReturnVenue}
                  InputProps={{
                    endAdornment: selectedReturnVenue ? (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => setSelectedReturnVenue("")}
                        >
                          <Iconify icon="carbon:close-filled" />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                >

                  {venues.map((venue) => (
                    <MenuItem sx={{ fontSize: responsiveFontSize }} key={venue.id} value={venue.id}>
                      <Typography fontSize={responsiveFontSize}>{venue.venueName}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <Typography variant="caption">or</Typography>
            </Stack>

            {returnPlace ? null : (
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <AddressAutocomplete orgData={orgData} onSelect={handleReturnAddress} />
                <Typography variant="caption">or</Typography>
              </Stack>
            )}

            {/* MARK: Manual Entry */}
            <Box
              component="form"
              sx={{
                mt: 2,
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <TextField
                inputProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Use Autocomplete">
                        <IconButton
                          aria-label="clear text"
                          onClick={() => {
                            setReturnPlace(null);
                          }}
                          edge="end"
                        >
                          <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: responsiveFontSize },
                }}
                size="small"
                label="Address"
                onChange={(event) => setManualReturnAddress(event.target.value)}
                value={manualReturnAddress}
                variant="outlined"
                fullWidth
              />
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  inputProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  size="small"
                  label="City"
                  onChange={(event) => setManualReturnCity(event.target.value)}
                  value={manualReturnCity}
                  variant="outlined"
                  sx={{ flex: 2 }}
                />
                <TextField
                  inputProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  size="small"
                  label="State"
                  onChange={(event) => setManualReturnState(event.target.value)}
                  value={manualReturnState}
                  variant="outlined"
                  sx={{ flex: 1 }}
                />
                <TextField
                  inputProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  InputLabelProps={{
                    style: { fontSize: responsiveFontSize },
                  }}
                  size="small"
                  label="ZIP Code"
                  onChange={(event) => setManualReturnZip(event.target.value)}
                  value={manualReturnZip}
                  variant="outlined"
                  sx={{ flex: 1 }}
                />
              </Box>
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
}