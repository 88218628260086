import React, { useEffect, useState } from "react";
import { INVOICE } from "../../utils/models/modelConstants/modelConstants";

export function useInvoiceHeaderBtns(currentInvoice) {
  const [show, setShow] = useState({
    delete: false,
    draft: false,
    estimate: false,
    invoice: false,
    invoiceSend: false,
    receivePayment: false,
    signatureRequest: false,
  });
  useEffect(() => {
    if (!currentInvoice) {
      // new invoice
      setShow({
        delete: false,
        draft: true,
        estimate: true,
        invoice: true,
        invoiceSend: true,
        receivePayment: true,
        signatureRequest: false,
      });
    } else if (currentInvoice.type === INVOICE) {
      // invoice
      setShow({
        delete: true,
        draft: false,
        estimate: false,
        invoice: true,
        invoiceSend: true,
        receivePayment: true,
        signatureRequest: true,

      });
    } else {
      // existing draft or estimate
      setShow({
        delete: true,
        draft: true,
        estimate: true,
        invoice: true,
        invoiceSend: false,
        receivePayment: true,
        signatureRequest: true,
      });
    }
  }, [currentInvoice]);
  return { show };
}
