import React, { useState, useRef } from "react";
import { invoices } from "../../../app/services/firestore/queries/invoiceQueries";
import { authSelector } from "../../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { i18n } from "../../../i18n";
import { Button } from "antd";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../../app/iconify";
function formatReceiveTimeRange(invoice) {
  const options = { hour: "numeric", minute: "numeric" };
  let timeRange = "";
  if (invoice?.hasSpecifiedReceiveTime) {
    if (invoice?.specifiedReceiveTime?.toDate()) {
      const startTime = invoice?.specifiedReceiveTime
        ?.toDate()
        ?.toLocaleTimeString(undefined, options);
      timeRange = startTime;
      if (invoice?.specifiedReceiveTimeEnd) {
        const endTime = invoice?.specifiedReceiveTimeEnd
          ?.toDate()
          ?.toLocaleTimeString(undefined, options);
        timeRange = timeRange + ` - ${endTime}`;
      }
    }
    return timeRange;
  } else {
    return i18n('common.noneSpecified');
  }
}
function formatReturnTimeRange(invoice) {
  const options = { hour: "numeric", minute: "numeric" };
  let timeRange = "";
  if (invoice?.hasSpecifiedReturnTime) {
    const startTime = invoice.specifiedReturnTime
      ?.toDate()
      ?.toLocaleTimeString(undefined, options);
    timeRange = startTime;
    if (invoice?.specifiedReturnTimeEnd) {
      const endTime = invoice?.specifiedReturnTimeEnd
        ?.toDate()
        ?.toLocaleTimeString(undefined, options);
      timeRange = timeRange + ` - ${endTime}`;
    }
    return timeRange;
  } else {
    return i18n('common.noneSpecified');
  }
}
const InvoiceBOM = React.forwardRef((props, ref) => {
  const invoice = props.invoice;
  const { orgData } = useSelector(authSelector);
  const formattedDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date?.toLocaleDateString(undefined, options);
  };
  const lineItems = () => {
    const lines = [];
    const showItemName = orgData.showItemName ?? true;
    invoice?.selectedItems?.items.forEach((item) => {
      if (item?.bundleItems && item?.bundleItems?.length === 0) {
        lines.push(
          <tr style={{ borderBottom: "1px solid #eee" }}>
            <td style={{ textAlign: "left" }}>
              {showItemName ? item.name : item.selectedDescription}
            </td>
            <td style={{ textAlign: "right", paddingRight: "30px" }}>
              {item?.selectedQty}
            </td>
          </tr>
        );
      } else if (item.bundleItems) {
        const bundleItems = item.bundleItems;
        lines.push(
          <tr style={{ borderBottom: "1px solid #eee" }}>
            <td style={{ textAlign: "left" }}>
              {showItemName ? item.name : item.selectedDescription}
            </td>
            <td style={{ textAlign: "right", paddingRight: "30px" }}>
              {item.selectedQty}
            </td>
          </tr>
        );
        bundleItems.forEach((bi) => {
          lines.push(
            <tr style={{ borderBottom: "1px solid #eee" }}>
              <td
                style={{ textAlign: "left", paddingLeft: "25px" }}
              >{`- ${bi.bundleItemName}`}</td>
              <td
                style={{ textAlign: "right", paddingRight: "30px" }}
              >{`Total ${bi.bundleItemQty * item.selectedQty}    (${bi.bundleItemQty
                } per bundle)`}</td>
            </tr>
          );
        });
      }
    });
    return lines;
  };
  return (
    <div ref={ref}>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          padding: "30px",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          {/* Company Logo and Invoice Data */}
          <tbody>
            <tr className="top">
              <td className="title" style={{ width: "30%" }}>
                <img
                  id="logo"
                  src={orgData.logoUrl}
                  style={{ width: "100%", maxWidth: 150 }}
                  alt="logo"
                />
              </td>
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        {i18n("pdf.invoiceString")} #: {invoice?.invoiceNumber}
                        <br />
                        {i18n("invoices.rentalStart")}:{" "}
                        {formattedDate(invoice?.rentalDateStart?.toDate())}
                        <br />
                        {i18n("invoices.rentalEnd")}:{" "}
                        {formattedDate(invoice?.rentalDateEnd?.toDate())}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="information">
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <br />
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        {invoice?.customer?.customerDisplayName}
                        <br />
                        {invoice?.customer?.email}
                        <br />
                      </td>
                    </tr>
                    <br />
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          cellPadding={1}
          cellSpacing={1}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          <tbody>
            <tr className="heading" style={{ borderBottom: "1px solid #eee" }}>
              <td style={{ textAlign: "left", width: "55%" }}>
                {i18n("pdf.columnItem")}
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "45%",
                  paddingRight: "30px",
                }}
              >
                {i18n("pdf.colQty")}
              </td>
            </tr>
          </tbody>
          {lineItems()}
        </table>
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{
            marginTop: 15,
            width: "100%",
            lineHeight: "inherit",
            textAlign: "left",
          }}
        >
          <tbody>
            <tr className="heading">
              <td>
                {/* Receive and Return Details: */}
                {i18n("pdf.receiveReturnDetails")}:
              </td>
              <td />
            </tr>
            <br />
            <tr>
              <td>
                <strong>
                  {/* Customer Receives Items Via: */}
                  {i18n("pdf.customerReceivesVia")}:
                </strong>
                &nbsp;
                {invoice?.receiveMethod === "delivery"
                  ? i18n("pdf.delivery")
                  : i18n("pdf.customerPickup")}
              </td>
              <td style={{ textAlign: "left" }}>
                {/* <strong>Items Returned Via:</strong> */}
                <strong>{i18n("pdf.returnedVia")}:</strong>
                &nbsp;{" "}
                {invoice?.returnMethod === "onSite"
                  ? i18n("pdf.pickup")
                  : i18n("pdf.customerDropOff")}
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <strong>{i18n("pdf.address")}:</strong>
                <br />{" "}
                {invoice?.receiveMethod === "delivery"
                  ? invoice?.deliveryCustomerShippingAddressStr
                  : orgData.companyAddress}
              </td>
              <td style={{ textAlign: "left" }}>
                <strong>{i18n("pdf.address")}:</strong>
                <br />{" "}
                {invoice?.returnMethod === "onSite"
                  ? invoice?.onSiteCustomerShippingAddressStr
                  : orgData.companyAddress}
              </td>
            </tr>
            <br />
            <tr>
              <td>
                {/* <strong>Date/Time(Range):</strong> */}
                <strong>{i18n("pdf.timeRange")}:</strong>
                <br />
                {formattedDate(invoice?.rentalDateStart?.toDate())}
                <br />
                {formatReceiveTimeRange(invoice, orgData)}
              </td>
              <td style={{ textAlign: "left" }}>
                {/* <strong>Date/Time(Range):</strong> */}
                <strong>{i18n("pdf.timeRange")}:</strong>
                <br />
                {formattedDate(invoice?.rentalDateEnd?.toDate())}
                <br />
                {formatReturnTimeRange(invoice, orgData)}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          {i18n("pdf.additionalNotes")}:&nbsp;{invoice?.notes}
        </p>
      </div>
    </div>
  );
});
export const PrintInvoiceBOMComponent = ({ invoice, handleComplete }) => {
  
  const [invoiceToPrint, setInvoiceToPrint] = useState(null)
  const { orgData, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: invoice.invoiceNumber,
    onBeforeGetContent: 
      async () => {
        const res = await handleComplete({
          type: invoice.type,
          redirect: null,
        });
        const refreshedInvoice = await invoices(
          orgData?.orgTimezone
        ).fetchInvoice({ firestore }, fsOrgPrefix, {
          id: res?.invoiceId,
        });
        setInvoiceToPrint(refreshedInvoice);
        return;
      }
  })
  return (
    <>
      <div>
        <Button onClick={handlePrint}>Print Pull Sheet</Button>
        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <InvoiceBOM invoice={invoiceToPrint} ref={componentRef} />
        </div>
      </div>
    </>
  );
};

export const PrintMUIInvoiceBOMComponent = ({ invoice, handleComplete }) => {

  const [invoiceToPrint, setInvoiceToPrint] = useState(null);

  let componentRef = useRef();  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: invoice.invoiceNumber,
    onBeforeGetContent: async () => {
      const refreshed = await handleComplete();
      setInvoiceToPrint(refreshed);
      return;
    },
  });

  return (
    <Tooltip title="Print Pull Sheet">
      <IconButton onClick={handlePrint} aria-label="notifications">
        <Iconify icon="mingcute:print-fill" />
        <div style={{ display: "none" }}>
          <InvoiceBOM invoice={invoiceToPrint} ref={componentRef} />
        </div>
      </IconButton>
    </Tooltip>
  );
};