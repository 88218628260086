import React, { useEffect, useState } from "react";
import { Badge, Button, InputNumber, Space, Tag } from "antd";
import {
  COLOR_GREEN_0,
  COLOR_RED_0,
} from "../../../app/ui/colorConstants";
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

const QuickAdd = ({ setCart, cart, itemId, itemMax, item }) => {
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const added = cart.find((i) => i.id === itemId);
    if (added) {
      setCount(added.count);
    }
  }, []);

  const handleClick = () => {
    setActive(true);
  };
  const handleAdd = () => {
    if (count === 0) return handleRemove();
    const currCart = cart.filter((i) => i.id !== itemId);
    const i = {
      ...item,
      id: item.id,
      count: count,
      name: item.name,
    };
    setCart([...currCart, i]);
    setActive(false);
  };
  const handleRemove = () => {
    const currCart = cart.filter((i) => i.id !== item.id);
    setCart([...currCart]);
  };
  const setValue = (v) => {
    setCount(v);
  };
  return (
    <div>
      <Space>
        <div>
          {count !== 0 && !active ? (
            <Badge
              offset={[-10, 0]}
              count={
                <CloseCircleFilled
                  onClick={handleRemove}
                  style={{ color: COLOR_RED_0, fontSize: "16px" }}
                />
              }
            >
              <Tag
                style={{
                  fontSize: "24px",
                  padding: "0 12px",
                  lineHeight: "50px",
                  fontWeight: "bold",
                  borderColor: "transparent",
                  color:
                    itemMax && itemMax < count ? COLOR_RED_0 : COLOR_GREEN_0,
                }}
              >
                {count}
              </Tag>
            </Badge>
          ) : null}
        </div>
        <div>
          {active && (
            <InputNumber
              min={0}
              autoFocus={true}
              onChange={setValue}
              defaultValue={count}
              style={{ width: "80px" }}
              onPressEnter={() => {
                handleAdd();
              }}
            />
          )}
        </div>
        {!active && (
          <Button type={"link"} onClick={handleClick}>
            {count !== 0 ? <EditOutlined /> : <PlusOutlined />}
          </Button>
        )}
        {active && (
          <Button onClick={() => handleAdd()} type={"primary"}>
            Save
          </Button>
        )}
      </Space>
    </div>
  );
};

export default QuickAdd;
