import { TimeMachine } from "../../time/timeMachine";
import { formatDateOnRead } from "../../time/formatTimezoneOffset";

export const CheckRoute = (snap, orgTimezone) => {
  const route = snap.data();
  return {
    id: route?.id ?? "",
    listIndex: route?.listIndex ?? 0,
    startLat: route?.startLat ?? 0,
    startLng: route?.startLng ?? 0,
    startAddrStr: route?.startAddrStr ?? "",
    invoicesQueryHook: route?.invoicesQueryHook ?? [], // invoiceIds
    employeesQueryHook: route?.employeesQueryHook ?? [], // employee query hooks
    vehiclesQueryHook: route?.vehiclesQueryHook ?? [], // vehicle query hooks
    name: route?.name ?? "",
    color: route?.color ?? "",
    stops: route?.stops ?? [], // array of stops
    hasWarnings: route?.hasWarnings ?? false,
    hasErrors: route?.hasErrors ?? false,
    notes: route?.notes ?? "",
    loadDate: route.loadDate
      ? formatDateOnRead({ date: route.loadDate, orgTimezone })
      : null, // date of load. eventually remove null str
    assignedEmployees: route?.assignedEmployees ?? [],
    assignedVehicles: route?.assignedVehicles ?? [],
    routeDate: route.routeDate
      ? formatDateOnRead({ date: route.routeDate, orgTimezone })
      : null,
    mobileLoadDate: route?.mobileLoadDate ?? null,
    mobileRouteDate: route?.mobileRouteDate ?? null,
    isComplete: route?.isComplete ?? false,
  };
};
