import { notification } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_YELLOW_0 } from "../../ui/colorConstants";

const notificationWarning = (msg, desc) => {
  notification.info({
    icon: <WarningFilled style={{ color: COLOR_YELLOW_0 }} />,
    message: `${msg}`,
    description: desc ? `${desc}` : "",
    placement: "bottomLeft",
    duration: 4,
  });
};
export default notificationWarning;
