import React, { useState, useEffect } from "react";
import { Popover, Space, Tag, Tooltip } from "antd";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";

const { Text } = Typography;

const TagTypeWithTooltip = ({ data, availableInventory }) => {
  
  const [bundleContainsUnavail, setBundleContainsUnavail] = useState(false);
  const [bundleContainsAtCapacity, setBundleContainsAtCapacity] = useState(false);

  useEffect(() => {
    if (data.type === INV_BUNDLE_ITEM) {
      const containsUnavail = data.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock < 0;
      });
      const containsAtCapacity = data.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const bundleItemQty = b?.bundleItemQty ?? 0;
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock === 0;
      });

      setBundleContainsUnavail(containsUnavail);
      setBundleContainsAtCapacity(containsAtCapacity);
    }
  }, [data, availableInventory]);

  const bundleItems = (record) => {
    return (
      <Popover
        placement="right"
        title={"Items in bundle"}
        content={() => bundlePopover(record)}
        trigger="hover"
      >
        <CategoryTag
          style={{ margin: 0, fontSize: "13px", cursor: "pointer" }}
          text={
            <span>
              {bundleContainsUnavail && (
                <ExclamationCircleFilled
                  style={{ color: COLOR_RED_0, marginRight: "8px" }}
                />
              )}
              Bundle
            </span>
          }
        />
      </Popover>
    );
  };

  const bundlePopover = (record) => {
    return (
      <div>
        {record.bundleItems.map((b, index) => {
          const bundleItem = availableInventory?.find((i) => {
            return i.id === b.bundleItemId;
          });
          const bundleItemQty = b?.bundleItemQty ?? 0;
          const availableStock = bundleItem?.availableStock ?? 0;

          // Determine background color and text color based on available stock
          let backgroundColor, color;

          if (availableStock === 0) {
            backgroundColor = COLOR_YELLOW_0;
            color = "#FFF";
          } else if (availableStock < bundleItemQty) {
            backgroundColor = COLOR_RED_0;
            color = "#FFF";
          } else {
            backgroundColor = COLOR_LIGHT_GRAY;
            color = COLOR_DARK_BG_0;
          }

          return (
            <div key={index}>
              <Space>
                <CategoryTag
                  style={{
                    backgroundColor,
                    margin: "2px 4px 2px 0px",
                    color,
                    borderColor: "transparent",
                  }}
                  text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                />
                <TextBody text={b[INV_BUNDLE_ITEM_NAME]} />
                <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
                <TextSubtitle text={`(${availableStock} left)`} />
              </Space>
            </div>
          );
        })}
        <br />
        {bundleContainsUnavail && (
          <Space>
            <Text
              style={{
                fontSize: "13px",
                color: COLOR_RED_0,
              }}
            >
              Some items are overbooked during the selected range
            </Text>
          </Space>
        )}

        {bundleContainsAtCapacity && !bundleContainsUnavail && (
            <Space>
              <Text
                style={{
                  fontSize: "13px",
                  color: COLOR_YELLOW_0,
                }}
              >
                Some items are at capacity during the selected range
              </Text>
            </Space>
          )}
      </div>
    );
  };

  const regularTag = () => {
    return <Tag>{capitalizeFirstLetter(data.type)}</Tag>;
  };

  return <div>{data?.type === INV_BUNDLE_ITEM ? bundleItems(data) : regularTag()}</div>;
};

export default TagTypeWithTooltip;