import moment from "moment";
import convertTimeToDayString from "../../time/convertTimeToDayString";
export const CheckTruck = (snap) => {
  const values = snap.data();
  return {
    id: snap.id,
    initialValue: values?.initialValue ?? 0,
    purchaseDate: values?.purchaseDate ?? null,
    depreciationYears: values?.depreciationYears ?? null,
    serviceDate: values?.serviceDate ?? null,
    description: values?.description ?? "",
    name: values?.name ?? "",
    truckNotes: values?.truckNotes ?? "",
    createdOn: values?.createdOn ?? null,
    isAvailable: values?.isAvailable ?? true,
  };
};
