import React, { createContext, useContext } from "react";
import { Badge, Button, Collapse, Space, Table, Typography } from "antd";
import DraggableRouteContainer from "./DraggableRouteContainer";
import DraggableRouteRow from "./DraggableRouteRow";
import { routeColumns } from "./routeColumns";
import { RouteContext } from "../../../NewRoute";
import { firelikeId } from "../../../../../app/utils/models/checkers/firelikeid";

const { Text } = Typography;
const RouteStopList = ({ data }) => {
  const { actions, state } = useContext(RouteContext);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "block",
          height: "26px",
          backgroundColor: data?.color,
          paddingTop: "2px",
        }}
      >
        {<Text style={{ color: "#FFF", marginLeft: "8px" }}>{data?.name}</Text>}
      </div>
      <Table
        style={{
          height: "calc(60vh - 55px)",
          overflowY: "auto",
        }}
        showHeader={false}
        locale={{ emptyText: "Add a stop" }}
        size={"small"}
        pagination={false}
        dataSource={data?.stops}
        columns={routeColumns({ actions, state })}
        rowKey="index"
        key={firelikeId()}
        onRow={(record, index) => {
          return {
            onClick: (event) => {
              actions.updateSelectedStop({
                invoiceId: record?.invoiceId ?? null,
                type: record?.type ?? null,
              });
            },
          }; // click row
        }}
        components={{
          body: {
            wrapper: (props) => {
              return (
                <DraggableRouteContainer
                  routeId={data?.id}
                  props={props}
                  data={data?.stops}
                />
              );
            },
            row: (props) => {
              const { className, style, ...restProps } = props;
              return (
                <DraggableRouteRow
                key={firelikeId()}
                  restProps={restProps}
                  style={style}
                  className={className}
                  data={data?.stops}
                />
              );
            },
          },
        }}
      />{" "}
    </div>
  );
};

export default RouteStopList;
