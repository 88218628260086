import { useContext } from "react";
import { Space, Button } from "antd";
import { useRouteAssignmentBtns } from "../../../../app/services/hooks/useRouteAssignmentBtns";
import { RouteAssignmentsContext } from "../RouteAssignments";
import {
  ROUTE_ROLE_CREW,
  ROUTE_ROLE_DRIVER,
  ROUTE_ROLE_LOADER,
} from "../../../../app/utils/models/modelConstants";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_YELLOW_0 } from "../../../../app/ui/colorConstants";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";

const RouteAssignmentButtons = ({ route, userId, routes }) => {
  const { driverProps, crewProps, loaderProps, roles, isMultiAssigned } =
    useRouteAssignmentBtns({
      route,
      userId,
      routes,
    });
  const { state, actions } = useContext(RouteAssignmentsContext);
  const handleClick = (type, currentlySelected) => {
    // for each, if selected, remove. if not add
    const newRoles = currentlySelected
      ? roles.filter((r) => r !== type)
      : [...roles, type];
    actions.updateUserRoles({ id: userId, roles: newRoles });
  };
  return (
    <div>
      <Space align={"center"} direction={"horizontal"}>
        {isMultiAssigned && (
          <Space style={{ marginRight: "8px" }}>
            <WarningFilled style={{ color: COLOR_YELLOW_0 }} />
            <TextSubtitle text={"Assigned"} />
          </Space>
        )}
        <Button
          key={`DRIVER_${userId}`}
          onClick={() => handleClick(ROUTE_ROLE_DRIVER, driverProps.selected)}
          style={driverProps.buttonStyle}
        >
          Driver
        </Button>
        <Button
          key={`CREW_${userId}`}
          onClick={() => handleClick(ROUTE_ROLE_CREW, crewProps.selected)}
          style={crewProps.buttonStyle}
        >
          Crew
        </Button>
        <Button
          key={`LOADER_${userId}`}
          onClick={() => handleClick(ROUTE_ROLE_LOADER, loaderProps.selected)}
          style={loaderProps.buttonStyle}
        >
          Loader
        </Button>
      </Space>
    </div>
  );
};

export default RouteAssignmentButtons;
