import { IconButton, IconButtonProps, Theme } from '@mui/material';
import { styled } from '@mui/system';

interface CustomIconButtonProps extends IconButtonProps {
  customcolor?: string;
}

const CustomIconButton = styled(IconButton)<{ customcolor?: string }>(({ theme, customcolor }) => ({
  color: customcolor,
})) as React.FC<CustomIconButtonProps>;

export default CustomIconButton;
