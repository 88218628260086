import React, { useState } from "react";
import Inventory from "../Inventory";
import InventoryCart from "./InventoryCart";
import { useDispatch, useSelector } from "react-redux";
import {
  cartSelector,
  setCart,
  setClearedCart,
  setRentalDates,
} from "../cartSlice";

const InventoryCartWrapper = ({ history }) => {
  const { cart, rentalDates } = useSelector(cartSelector);
  const dispatch = useDispatch();
  const configCart = (items) => {
    dispatch(setCart(items));
  };
  const configDates = (dates) => {
    dispatch(setRentalDates(dates));
  };
  const clearCart = () => {
    dispatch(setClearedCart());
  };
  return (
    <div>
      <Inventory
        cart={cart}
        setCart={configCart}
        rentalDates={rentalDates}
        setRentalDates={configDates}
      />
      <InventoryCart
        cart={cart}
        setCart={configCart}
        rentalDates={rentalDates}
        history={history}
        clearCart={clearCart}
      />
    </div>
  );
};

export default InventoryCartWrapper;
