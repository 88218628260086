import { useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Iconify from '../../app/iconify';
import { Upload } from './upload';
import { useFirebase } from 'react-redux-firebase';
import { fileData } from './file-thumbnail';
import { useSelector } from 'react-redux';
import { authSelector } from '../auth/authSlice';


// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  onUploadsComplete?: VoidFunction;
  //
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  open: boolean;
  onClose: VoidFunction;
}

export default function FileManagerNewFileDialog({
  title = 'Upload Files',
  open,
  onClose,
  onUploadsComplete,
  //
  onCreate,
  onUpdate,
  //
  ...other
}: Props) {

  const { orgData } = useSelector(authSelector);

  const firebase = useFirebase();
  const [files, setFiles] = useState<(File | string)[]>([]);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleUpload = () => {

    const storageRef = firebase.storage().ref();
    
    const uploadBlob = async (file: any) => {
      
      const { key, name = '', size = 0, type = 'doc' } = fileData(file);
      console.log(key, name, size );
      
      const response = await fetch(file.preview);
      const blob = await response.blob();
      const fileRef = storageRef.child(`filemanager/${orgData.id}/${file.name}`);
      const snapshot = await fileRef.put(blob);
      console.log("fileRef", fileRef)
      const downloadURL = await snapshot.ref.getDownloadURL();
      
      // Create a Firestore document for each file after it's uploaded
      const firestoreRef = firebase.firestore().collection("orgs").doc(orgData.id).collection("fileManager").doc();

      const newDoc = {
        id: firestoreRef.id,
        name: name,
        size,
        type,
        url: downloadURL,
        tags: [],
        isFavorited: false,
        shared: null,
        createdAt: new Date(),
        modifiedAt: new Date(),
      }

      await firestoreRef.set(newDoc)
      console.log("Document written for file with ID: ", firestoreRef.id);
  
      return downloadURL;
    };
  
    Promise.all(files.map(fileObj => {
      if (!(fileObj instanceof File)) return null;
      return uploadBlob(fileObj);
    }))
    .then(() => {
      console.info('All files uploaded and documents created');
      onUploadsComplete && onUploadsComplete();
      onClose(); // Close dialog after all uploads
    })
    .catch(error => {
      console.error("Error uploading files or adding document: ", error);
    });
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {" "}
        {title}{" "}
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: "none" }}>
        <Upload
          multiple
          accept={{
            "text/plain": [], // for text files
            "application/pdf": [], // for PDF files
            "image/png": [], // for PNG images
            "image/jpeg": [], // for JPEG images
          }}
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
        >
          Upload
        </Button>

        {!!files.length && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleRemoveAllFiles}
          >
            Remove all
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <Button variant="contained" onClick={onCreate || onUpdate}>
              {onUpdate ? "Save" : "Create"}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}
