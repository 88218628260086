import { INVENTORY_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkInventory } from "../../../utils/models/checkers/checkInventory";
import { fsMultiArrayQuery } from "./fsMultiArrayQuery";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { InventoryItem } from "../../../interfaces/inventoryItem";

interface InventoryQueries {
  fetchInventory: () => Promise<InventoryItem[]>;
  inventoryByIdList: (ids: string[]) => Promise<any>; // Update this type if needed
}

export function inventoryQueries(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
): InventoryQueries {

  if (!orgPrefix) {
    return {
      fetchInventory: async () => {
        throw new Error("Cannot determine organization");
      },
      inventoryByIdList: async (ids: string[]) => {
        throw new Error("Cannot determine organization");
      },
    };
  }

  const ref = firestore.collection(`${orgPrefix}${INVENTORY_COLLECTION}`);

  async function fetchInventory(): Promise<InventoryItem[]> {
    const snap = await ref.get();
    return snap.docs.map((s) => checkInventory(s));
  }

  async function fetchInventoryListByIds(ids: string[]) {
    if (!ids || ids.length === 0) {
      return;
    }
    try {
      const ref = firestore
        .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
        .where("id", "in", ids);
      return await ref.get();
    } catch (error) {
      console.log("ERROR FOR IDS IN: ", error)
    }
  }

  async function inventoryByIdList(ids: string[]) {

    if (!ids || ids.length === 0) {
      return []
    }

    return fsMultiArrayQuery(
      { firestore },
      ids,
      fetchInventoryListByIds,
      checkInventory
    );
  }

  return {
    fetchInventory: fetchInventory,
    inventoryByIdList: inventoryByIdList,
  };
}