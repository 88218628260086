export const AppendPrevLatLng = ({ stops, routeStartLat, routeStartLng }) => {
  return stops?.map((s, index) => {
    const { fromLat, fromLng, updatePolyline, ...rest } = s;
    if (index === 0) {
      return {
        fromLat: routeStartLat,
        fromLng: routeStartLng,
        updatePolyline: true, // TODO: CONFIGURE THIS
        ...rest,
      };
    } else {
      const prevIndex = index - 1;
      const updatedFromLat = stops[prevIndex]?.lat;
      const updatedFromLng = stops[prevIndex]?.lng;
      const sameFromCoordinates =
        updatedFromLat === s.fromLat && updatedFromLng === s.fromLng;
      return {
        fromLat: stops[prevIndex]?.lat,
        fromLng: stops[prevIndex]?.lng,
        updatePolyline: !sameFromCoordinates,
        ...rest,
      };
    }
  });
};
