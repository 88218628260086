import React from "react";
import { Button } from "antd";
import { MinusCircleFilled } from "@ant-design/icons";
import {
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../../../../app/ui/colorConstants";

const RemoveInvItemBtn = ({ handleClick }) => {
  return (
    <Button size="small" type="link" onClick={handleClick} style={{}}>
      <MinusCircleFilled style={{ marginTop: "2px", color: COLOR_RED_0 }} />
    </Button>
  );
};

export default RemoveInvItemBtn;
