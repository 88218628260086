
import { useLoadScript, StandaloneSearchBox, Libraries } from "@react-google-maps/api";
import { useCallback, useRef } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useResponsiveFontSize } from "../../app/hooks/use-responsive-font";
import { OrganizationData } from "../../app/interfaces/organizationData";
import Iconify from "../../app/iconify";


interface AddressAutocompleteProps {
  orgData: OrganizationData;
  onSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const libraries: Libraries = ["places"];

// MARK: - Address Autocomplete
export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ orgData, onSelect }) => {
    const responsiveFontSize = useResponsiveFontSize();
  
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: "AIzaSyCk8zR40fhVRwZjl7TrXS1XyS04q40xapw",
      libraries,
    });
  
    const inputRef = useRef<HTMLInputElement>(null);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  
    const onLoad = useCallback(
      (searchBox: google.maps.places.SearchBox) => {
        const initialLocation = { lat: orgData.lat, lng: orgData.long };
  
        searchBoxRef.current = searchBox;
        if (initialLocation) {
          const defaultBounds = new google.maps.LatLngBounds(initialLocation);
          searchBox.setBounds(defaultBounds);
        }
      },
      [orgData.lat, orgData.long]
    );
  
    const onPlacesChanged = useCallback(() => {
      const places = searchBoxRef.current?.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (place) {
          onSelect(place);
        }
      }
    }, [onSelect]);
  
    if (loadError) {
      return <div>Error loading Google Maps</div>;
    }
  
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
  
    const handleClear = () => {
      if (inputRef.current) {
        inputRef.current.value = "";
        onSelect(null);
      }
    };
  
    return (
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        <TextField
          InputLabelProps={{
            style: { fontSize: responsiveFontSize },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="clear text" onClick={handleClear} edge="end">
                  <Iconify icon="carbon:close-filled" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          inputRef={inputRef}
          variant="outlined"
          label="Search Address"
          sx={{
            maxWidth: "100%",
            width: "400px",
            minWidth: "200px", 
            "& .MuiInputBase-input": {
              fontSize: responsiveFontSize,
            },
          }}
        />
      </StandaloneSearchBox>
    );
  };
  