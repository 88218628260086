import React, { useContext } from "react";
import { Button, Card, Col, Input, Modal, Row, Select, Space } from "antd";
import {
  COLOR_DARK_BG_1,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import LoadDatePicker from "./LoadDatePicker";
import StopTagType from "../../components/StopTagType";
import { STOP_TYPE_WAYPOINT } from "../../../../app/utils/models/modelConstants/modelConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import {
  ROUTE_ROLE_CREW,
  ROUTE_ROLE_DRIVER,
  ROUTE_ROLE_LOADER,
} from "../../../../app/utils/models/modelConstants";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import { RouteAssignmentsContext } from "../RouteAssignments";
import AssignTruck from "./AssignTruck";
import RouteNotes from "./RouteNotes";
import FinalStopList from "./FinalStopList";
import { useValidateRouteComplete } from "../../../../app/services/hooks/useValidateRouteComplete";
import FinalAssignmentList from "./FinalAssignmentList";

const FinalRouteDetails = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  const isComplete = state.selectedRoute?.routeData?.isComplete ?? false;
  const { isValidated } = useValidateRouteComplete(
    state.selectedRoute?.routeData
  );

  const confirmComplete = (bool) => {
    return Modal.confirm({
      title: `Mark as complete and notify assigned users?`,
      icon: <></>,
      okText: "Complete",
      onOk() {
        actions.markComplete(bool);
      },
    });
  };
  const confirmIncomplete = (bool) => {
    return Modal.confirm({
      title: `Mark as incomplete?`,
      icon: <></>,
      okText: "Ok",
      onOk() {
        actions.markComplete(bool);
      },
    });
  };

  return (
    <Col
      flex={"560px"}
      style={{ height: "calc(100vh - 90px)", overflowY: "auto" }}
    >
      <Card
        extra={
          <Space>
            {isComplete && (
              <Button
                disabled={!isValidated}
                onClick={() => confirmIncomplete(false)}
                style={{
                  background: COLOR_LIGHT_GRAY,
                  color: COLOR_DARK_BG_1,
                  borderColor: "transparent",
                }}
              >
                Mark as incomplete
              </Button>
            )}
            <Button
              disabled={!isValidated}
              onClick={() => confirmComplete(true)}
              style={{
                background: COLOR_GREEN_0,
                color: "#FFF",
                borderColor: "transparent",
                fontWeight: "bold",
                opacity: isValidated ? 1 : 0.5,
              }}
            >
              {isComplete ? "Resend notifications" : "Mark Complete & Notify"}
            </Button>
          </Space>
        }
        size={"small"}
        title={"Final Route Details"}
        style={{
          margin: "16px 16px 16px 0",
        }}
      >
        <Row gutter={16}>
          <AssignTruck />
          <LoadDatePicker />
          <RouteNotes />
          <FinalStopList />
          <FinalAssignmentList />
        </Row>
      </Card>
    </Col>
  );
};

export default FinalRouteDetails;
