import React from "react";
import { Typography } from "antd";
import { FONT_SIZE_SUBTITLE } from "../../../ui/styleConstants";
import { COLOR_TEXT_SECONDARY } from "../../../ui/colorConstants";

const { Text } = Typography;
const TextSubtitle = ({ text, ...styleProps }) => {
  return (
    <Text
      style={{
        fontSize: FONT_SIZE_SUBTITLE,
        color: COLOR_TEXT_SECONDARY,
        ...styleProps,
      }}
    >
      {text}
    </Text>
  );
};

export default TextSubtitle;
