import React, { useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";
import { TAX_RATES_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";
import { configureTaxRates } from "../../../app/utils/models/configure/configureTaxRates";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

const TaxEditModal = ({
  setTaxEditModal,
  onTaxEdited,
  taxEditModal,
  refresh,
}) => {
  const { updateDocument, writing } = useFirestoreWrite();
  const [form] = Form.useForm();
  useEffect(() => {
    if (!taxEditModal.rate) return;
    form.setFieldsValue({
      name: taxEditModal.rate.name,
      rate: taxEditModal.rate.rate * 100,
    });
  }, [taxEditModal]);
  const handleFinish = async () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      const payload = { ...configureTaxRates(data) };
      const rateId = taxEditModal.rate.id;
      updateDocument({
        data: {
          collection: TAX_RATES_COLLECTION,
          payload: payload,
          id: rateId,
        },
      }).then(() => {
        form.resetFields();
        refresh().then(() => {
          onTaxEdited({
            id: rateId,
            ...payload,
          });
        });
      });
    });
  };
  return (
    <Modal
      destroyOnClose
      width={400}
      open={taxEditModal.visible}
      closeIcon={<></>}
      title="Save Tax Rate"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            setTaxEditModal({ visible: false, rate: null });
          }}
          disabled={writing}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={writing}
          loading={writing}
        >
          Save Tax Rate
        </Button>,
      ]}
    >
      <div>
        <Form form={form}>
          <Space align={"start"}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `Required.`,
                },
              ]}
              name="name"
              style={{ marginBottom: "12px", width: "240px" }}
            >
              <Input placeholder="Rate Name" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `Required.`,
                },
              ]}
              name="rate"
              style={{ marginBottom: "12px", width: "80px" }}
            >
              <InputNumber max={100} min={0} placeholder="Rate" />
            </Form.Item>{" "}
            <div style={{ marginTop: "5px" }}>%</div>
          </Space>
        </Form>
        <TextSubtitle
          text={"*Invoices currently taxed at this rate will not be affected"}
        />
      </div>
    </Modal>
  );
};

export default TaxEditModal;
