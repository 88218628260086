import notificationError from "../../../system-components/toasters/notificationError";
import { CATEGORIES_COLLECTION } from "../../../utils/models/collections/collectionConstants";

export function categoryWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${CATEGORIES_COLLECTION}`);

  async function createCategory(payload) {
    const newRef = firestore
      .collection(`${orgPrefix}${CATEGORIES_COLLECTION}`)
      .doc();
    return await newRef.set({
      id: newRef.id,
      ...payload,
    });
  }
  async function updateCategory(id, payload) {
    return await ref.doc(id).update(payload);
  }
  async function deleteCategory(id) {
    return await ref.doc(id).delete();
  }
  return {
    createCategory: createCategory, // create category
    updateCategory: updateCategory, // update category
    deleteCategory: deleteCategory, // delete category
  };
}
