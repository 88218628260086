import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import firebase from "firebase/app";


import { Box, Card, Button, TextField, Typography, Stack, Grid } from '@mui/material';
import { OrganizationData } from '../../../app/interfaces/organizationData';
import { useFirestore } from 'react-redux-firebase';
import { Link } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import { ROUTE_CHANGE_LOG } from '../../../app/routes';
import { functions } from '../../../app/config/firebase';

const ZoomSupportWidget = ({
  orgData,
  userData,
}: {
  orgData: OrganizationData;
  userData: any;
}) => {
    
  const db = useFirestore();

  return (
    <Card sx={{ padding: "12px", height: "100%" }}>
    <Grid container direction="column"> 
      <Grid container item direction="row" spacing={1}> 
        {/* <Grid item> 
        <Box sx={{width: "200px", height: "200px"}}>
          <img
            src="/images/support-call.png"
            alt="support call"
            style={{ width: "200px", height: "200px"}}
          />
          </Box>
        </Grid> */}
        <Grid item>
          <Stack spacing={1} justifyContent="center"> 
            <Typography variant="h6" gutterBottom>
              Need help with Adelie?
            </Typography>

            <Typography variant="body1" gutterBottom>
              Click to join a zoom call with a support specialist.
              Available from 8:30 am to 5:00 pm MST. You will join the waiting room and be admitted as soon as possible.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid sx={{mt: 2}} item>
        <Button onClick={() => {
             window.open('https://us02web.zoom.us/j/86092109809?pwd=U21wNTkwMk9mMzFINXRlcW5CRVZTdz09', '_blank')

             const call = functions.httpsCallable('zoomSupportRequested');
             call({orgData})

        }} sx={{ width: "100%" }} variant="contained">
          Click to Join Video Call Support
        </Button>
      </Grid>
    </Grid>
  </Card>
  );
};

export default ZoomSupportWidget;
