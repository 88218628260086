import notificationError from "../../../system-components/toasters/notificationError";
import { ORG_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../domains/auth/authSlice";

const { useState } = require("react");
const { useFirestore } = require("react-redux-firebase");

export function useFirestoreWrite() {
  const [writing, setWriting] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });

  const { authenticated, fsOrgPrefix, orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  // handlers
  const handleErr = (err) => {
    console.log(err);
    setWriting(false);
    setError({
      hasError: true,
      error: err,
    });
    return notificationError("Something went wrong", "Please try again later");
  };
  
  const writeToFsCustom = (writeFn, payload) => {
    setWriting(true);
    writeFn({ firestore }, fsOrgPrefix, payload).then(() => {
      setWriting(false);
    });
  };

  // firestore functions
  const add = async (collection, payload) => {
    const ref = firestore.collection(`${fsOrgPrefix}${collection}`).doc();
    ref
      .set({
        id: ref.id,
        orgId: orgData.id,
        ...payload,
      })
      .catch((err) => {
        console.log(err);
      });
    return ref.id;
  };
  const update = async (collection, payload, id) => {
    const ref = firestore.collection(`${fsOrgPrefix}${collection}`);
    return await ref.doc(id).update(payload);
  };
  const updateOrg = async (payload, id) => {
    const ref = firestore.collection(`${ORG_COLLECTION}`);
    return await ref.doc(id).update(payload);
  };
  const deleteDoc = async (collection, id) => {
    const ref = firestore.collection(`${fsOrgPrefix}${collection}`);
    return await ref.doc(id).delete();
  };

  // write options
  const newDocument = async ({ data: { collection, payload } }) => {
    if (!authenticated) return;
    try {
      setWriting(true);
      return add(collection, payload).then((newId) => {
        setWriting(false);
        return newId;
      });
    } catch (err) {
      handleErr(err);
    }
  };
  const updateDocument = async ({ data: { collection, payload, id } }) => {
    if (!authenticated) return;
    try {
      setWriting(true);
      await update(collection, payload, id).then(() => {
        setWriting(false);
      });
    } catch (err) {
      handleErr(err);
    }
  };
  const updateOrgDocument = async ({ data: { payload, id } }) => {
    if (!authenticated) return;
    try {
      setWriting(true);
      updateOrg(payload, id).then(() => {
        setWriting(false);
      });
    } catch (err) {
      handleErr(err);
    }
  };
  const deleteDocument = async ({ data: { collection, id } }) => {
    if (!authenticated) return;
    try {
      setWriting(true);
      await deleteDoc(collection, id).then(() => {
        setWriting(false);
      });
    } catch (err) {
      handleErr(err);
    }
  };
  const customFn = async (writeFn, payload) => {
    if (!authenticated) return;
    try {
      writeToFsCustom(writeFn, payload);
    } catch (err) {
      handleErr(err);
    }
  };

  return {
    error: error,
    writing: writing,
    newDocument: newDocument,
    updateDocument: updateDocument,
    updateOrgDocument: updateOrgDocument,
    deleteDocument: deleteDocument,
    customFn: customFn,
  };
}
