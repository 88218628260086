import React, { useContext } from "react";
import { Card, Col, Input, List } from "antd";
import RouteAssignmentButtons from "./RouteAssignmentButtons";
import { RouteAssignmentsContext } from "../RouteAssignments";

const AssignmentUserList = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  return (
    <Col flex={"auto"} className="route-user-list">
      <Card
        style={{ margin: "16px" }}
        size={"small"}
        bordered={false}
        title={"Assign Users"}
        extra={
          <Input.Search
            placeholder={"Find users"}
            onChange={(e) => actions.filterUsers(e)}
          />
        }
      >
        <List
          locale={{ emptyText: "No users" }}
          style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 164px)",
          }}
          size="small"
          dataSource={state.sortedUsers}
          renderItem={(item) => {
            return (
              <List.Item
                style={{ height: "50px" }}
                actions={[
                  <RouteAssignmentButtons
                    key={`BTNS_ASSIGNMENTS${item?.id}`}
                    routes={state.routes}
                    route={state.selectedRoute?.routeData}
                    userId={item?.id}
                  />,
                ]}
              >
                <span style={{ marginTop: "0px" }}>
                  {item.firstName} {item.lastName}
                </span>
              </List.Item>
            );
          }}
        />
      </Card>
      <br />
    </Col>
  );
};

export default AssignmentUserList;
