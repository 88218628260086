import React from "react";
import { List, Progress } from "antd";
import {
  renderInvStatusIcon,
  renderOverbookedIcon,
  renderOverbookedIconInv,
  renderStatusIcon,
} from "../helpers/renderStatusIcon";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import {
  COLOR_GRAY_BORDER,
  COLOR_GREEN_0,
  COLOR_PURPLE_0,
  COLOR_TEXT_GRAY_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../../app/ui/colorConstants";
import { QR_STATUS_COMPLETE } from "../../../app/utils/models/modelConstants/modelConstants";

const InventoryList = ({
  data,
  selectedInventory,
  setSelectedInventory,
  dailyAvailable,
}) => {
  const renderProgressBar = (item) => {
    const progress = item.completed / item.qtyRented;
    const complete = progress === 1;
    if (complete)
      return (
        <TextSubtitle
          text={"Complete"}
          style={{ color: COLOR_GREEN_0, fontSize: "13px" }}
        />
      );
    return (
      <Progress
        style={{ width: "200px" }}
        percent={progress * 100}
        size="small"
        strokeColor={COLOR_PURPLE_0}
        format={(p) => ""}
        trailColor={COLOR_TEXT_GRAY_2}
      />
    );
  };

  return (
    <List
      style={{ height: "calc(74vh)", overflowY: "auto" }}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          onClick={() => setSelectedInventory(item)}
          actions={[
            renderOverbookedIconInv(item, dailyAvailable),
            renderInvStatusIcon(item),
          ]}
          className={
            selectedInventory && selectedInventory.id === item.id
              ? "qr-selected-inventory"
              : "qr-inventory"
          }
        >
          <List.Item.Meta
            title={
              <TextBody
                text={`${item.name}`}
                style={{
                  color:
                    selectedInventory && selectedInventory.id === item.id
                      ? COLOR_PURPLE_0
                      : null,
                }}
              />
            }
            description={renderProgressBar(item)}
          />
        </List.Item>
      )}
    />
  );
};

export default InventoryList;
