export const QrItemPayload = () => {
  const flag = ({ item, flag, description }) => {
    const { hasIssue, issueDescription: currentIssueDesc, ...rest } = item;
    return {
      hasIssue: flag,
      issueDescription: description ? description : null,
      ...rest,
    };
  };
  const notes = ({ item, note }) => {
    const { notes: currNote, ...rest } = item;
    return {
      notes: note,
      ...rest,
    };
  };
  const completed = ({ item, count }) => {
    const { completed: currCompleted, ...rest } = item;
    return {
      completed: count,
      ...rest,
    };
  };
  const bundleItem = ({ item, count }) => {
    const { completed: currCompleted, ...rest } = item;
    return {
      completed: count,
      ...rest,
    };
  };
  return {
    flag: flag,
    notes: notes,
    completed: completed,
    bundleItem: bundleItem,
  };
};
