import firebase from 'firebase/app';
import 'firebase/firestore';


export type ActivityLogItem = {
  id: string;
  message: string;
  onCreate: Date | firebase.firestore.Timestamp | string | null;
  invoiceId?: string | null;
  sentByName?: string | null;
  sentById?: string | null;
};

export const checkActivityLogItem = (
  snap: firebase.firestore.DocumentSnapshot
) => {
  const values = snap.data();

  // console.log(values)

  return {
    id: snap.id,
    message: values?.message ?? "",
    onCreate: (values?.onCreate as firebase.firestore.Timestamp).toDate() ?? null,
    invoiceId: values?.invoiceId ?? null,
    sentByName: values?.sentByName ?? null,
    sentById: values?.sentById ??  null,
  } as ActivityLogItem;
};