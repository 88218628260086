import {
  createContext,
  useEffect,
  useState,
} from "react";
import {
  Affix,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  PageHeader,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import { Spinner } from "../../app/ui/spinner";
import {
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useReceivePayment } from "../../app/services/hooks/useReceivePayment";
import moment from "moment";
import {
  COLOR_GREEN_0,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
} from "../../app/ui/colorConstants";
import TextBody from "../../app/system-components/typography/text/TextBody";
import ReceivePaymentTable from "./ReceivePaymentTable";
import notificationError from "../../app/system-components/toasters/notificationError";
import { ConfigurePayment } from "../../app/utils/models/configure/configurePayment";
import { PAYMENT_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import { useHistory } from "react-router-dom";
import {
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_EXTERNAL,
  PAYMENT_METHOD_JUSTIFI,
  PAYMENT_METHOD_STRIPE,
} from "../../app/utils/models/modelConstants/modelConstants";
import { usePayments } from "../../app/services/hooks/usePayments";
import { useModalMgr } from "../../app/services/hooks/useModalMgr";
import StripePaymentModal from "./components/StripePaymentModal";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import DisplayCustomerBalance from "../sharedComponents/DisplayCustomerBalance";
import { ROUTE_EDIT_INVOICE, ROUTE_INVOICES } from "../../app/routes";
import getSymbolFromCurrency from "currency-symbol-map";
import JustifiPaymentModal from "./components/JustifiPaymentModal";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";

export const ReceivePaymentModalContext = createContext();

const ReceivePayment = ({ match, redirectUrl, defaultSelected }) => {

  const { orgData, userData } = useSelector(authSelector);
  const ModalMgr = useModalMgr();
  const history = useHistory();
  const id = match.params.id;

  const {
    invoicesOutstanding,
    invoicesCompleted,
    customerCredit,
    customer,
    fetchComplete,
    submitPayment,
  } = useReceivePayment(id && id);

  const { handleStripePaymentForAmt, fetchingIntent } = usePayments();
  const [showJustifiModal, setShowJustifiModal] = useState(false);
  const [defaultComplete, setDefaultComplete] = useState(false);
  const [paymentAmt, setPaymentAmt] = useState(0);
  const [fulfilled, setFulfilled] = useState([]);
  const [credit, setCredit] = useState(0);

  const [form] = Form.useForm();

  const closeJustifiPaymentModal = () => {
    setShowJustifiModal(false);
    // go back one page
    history.goBack();
  };

  useEffect(() => {
    if (!defaultSelected) {
      setDefaultComplete(true);
    } else {

      const defaultInvoice = invoicesOutstanding.find(
        (invoice) => invoice.id === defaultSelected
      );

      if (invoicesOutstanding.length === 0) return;
      const selectedIsOutstanding = invoicesOutstanding?.find(
        (invoice) => invoice?.id === defaultSelected
      );

      if (!selectedIsOutstanding) return setDefaultComplete(true);

      const payload = {
        id: defaultSelected,
        fulfilled: defaultInvoice?.balanceRemaining ?? 0,
        isFulfilled: true,
      };

      setFulfilled([payload]);
      setPaymentAmt(payload.fulfilled);
      form.setFieldsValue({ receivePaymentInputNumber: payload.fulfilled });
      setDefaultComplete(true);
    }
  }, [defaultSelected, invoicesOutstanding]);

  const updateTable = (data) => {
    // update state from a single item
    const { id } = data;
    // update fulfilledAmt in current state
    const oldState = fulfilled.filter((i) => i.id !== id);
    const updated = [data, ...oldState];
    setFulfilled(updated);
    // issue credit if needed
    let fulfilledTotal = 0;
    updated &&
      updated.map((i) => {
        const amt = i.fulfilled ? i.fulfilled : 0;
        fulfilledTotal = fulfilledTotal + amt;
      });
    form.setFieldsValue({ receivePaymentInputNumber: fulfilledTotal });
    setPaymentAmt(fulfilledTotal);
    setCredit(0);
  };

  const handlePaymentAmt = (amt) => {
    const decAmt =
      isNaN(amt.target.value) || typeof amt.target.value === "undefined"
        ? parseFloat("0").toFixed(2)
        : parseFloat(amt.target.value).toFixed(2);
    const safePaymentAmt = isNaN(parseFloat(decAmt)) ? 0 : parseFloat(decAmt);
    setPaymentAmt(safePaymentAmt);
    let fulfilledTotal = 0;
    fulfilled &&
      fulfilled.map((i) => {
        const amt = i.fulfilled ? i.fulfilled : 0;
        fulfilledTotal = fulfilledTotal + amt;
      });
    const c = safePaymentAmt - fulfilledTotal;
    setCredit(c);
  };

  const updateFromSelectAll = ({ total }) => {
    const safeTotal = isNaN(total) ? 0 : total;
    form.setFieldsValue({ receivePaymentInputNumber: safeTotal });
    setPaymentAmt(safeTotal);
    setCredit(0);
  };

  const handlePayment = () => {
    form.validateFields().then(() => {
      if (!paymentAmt || paymentAmt === 0)
        return notificationError("Payment amount required");
      if (credit && credit < 0)
        return notificationError(
          "Cannot issue payment with negative credit amount"
        );
      const v = form.getFieldsValue();
      const { paymentDate, method, refNo } = v;
      if (paymentDate && method) {
        // fields validated
        Modal.confirm({
          icon: null,
          title: "Process Payment?",
          content: `Process $${paymentAmt.toFixed(2)} payment for ${
            customer.customerDisplayName
          }${credit > 0 ? `and issue credit: $${credit.toFixed(2)}` : ""}?`,
          onOk() {

            if (method === PAYMENT_METHOD_JUSTIFI) {
              setShowJustifiModal(true);
              return;
            }

            const payload = ConfigurePayment({
              payload: {
                customer: customer,
                paymentAmt: paymentAmt,
                creditAmt: credit,
                fulfilled: fulfilled,
                paymentDate: paymentDate,
                method: method,
                refNo: refNo ? refNo : "",
              },
            });

            /**
             * Handle stripe payment if applicable
             */
            if (method === PAYMENT_METHOD_STRIPE) {
              handleStripePaymentForAmt(payload).then((intentData) => {
                const { stripeIntentId, ...rest } = payload;

                console.log("intentData", intentData);

                const secret = intentData.secret;
                const intentId = secret.split("_secret_");
                const updatedPayload = {
                  stripeIntentId: intentId[0],
                  ...rest,
                };

                ModalMgr.loadData({
                  intentData,
                  secret,
                  stripeAccountId: orgData?.stripeAccount ?? null,
                  paymentData: updatedPayload,
                });
              });
            } else {
              return new Promise(async (resolve, reject) => {
                const { status, ...rest } = payload;
                const updatedPayload = {
                  status: "complete",
                  ...rest,
                };
                submitPayment({
                  collection: PAYMENT_COLLECTION,
                  payload: updatedPayload,
                }).then(() => {
                  resolve();
                  notificationConfirm("Payment submitted");
                  history.push(redirectUrl);
                });
              }).catch((err) => {
                console.log(err);
                notificationError("Something went wrong.");
              });
            }
          },
          onCancel() {},
        });
      } else {
        notificationError("Payment date and method required");
      }
    });
  };
  const handleBackClicked = () => {
    if (!match.path.includes(ROUTE_INVOICES) && !defaultSelected)
      return window.history.back();
    history.push(`${ROUTE_EDIT_INVOICE}${defaultSelected}`);
  };

  return (
    <div>
      {orgData && (
        <ReceivePaymentModalContext.Provider value={ModalMgr}>
          <Affix>
            <PageHeader
              className="PageHeader"
              title={"Receive Payment"}
              onBack={handleBackClicked}
              extra={[
                <Space>
                  <Button type={"primary"} onClick={handlePayment}>
                    Submit <ArrowRightOutlined />
                  </Button>
                </Space>,
              ]}
            />
          </Affix>
          <div className="list-margin-top" style={{ marginTop: "68px" }}>
            <Spin
              indicator={Spinner}
              spinning={!fetchComplete || fetchingIntent}
            >
              <div style={{ margin: "12px" }}>
                Receiving payment from:{" "}
                <TextBody
                  text={customer && customer.customerDisplayName}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                />
                <div style={{ float: "right", paddingRight: "8px" }}>
                  <DisplayCustomerBalance customer={customer} />
                </div>
                <br />
                <Form form={form}>
                  <Space direction={"horizontal"}>
                    <Form.Item
                      name={"paymentDate"}
                      required
                      initialValue={moment()}
                    >
                      <DatePicker
                        format={(d) => d.format("MMM Do YYYY")}
                        placeholder={"Payment date"}
                        style={{ width: "200px", marginTop: "12px" }}
                      />
                    </Form.Item>
                    <Form.Item name={"method"} required>
                      <Select
                        placeholder={"Payment method"}
                        style={{ width: "200px", marginTop: "12px" }}
                      >
                        {orgData.stripeAccount && (
                          <Select.Option
                            key={PAYMENT_METHOD_STRIPE}
                            value={PAYMENT_METHOD_STRIPE}
                          >
                            Stripe
                          </Select.Option>
                        )}

                        {orgData.justifiSubAccount && (
                          <Select.Option
                            key={PAYMENT_METHOD_JUSTIFI}
                            value={PAYMENT_METHOD_JUSTIFI}
                          >
                            Justifi
                          </Select.Option>
                        )}

                        <Select.Option
                          key={PAYMENT_METHOD_CASH}
                          value={PAYMENT_METHOD_CASH}
                        >
                          Cash
                        </Select.Option>
                        <Select.Option
                          key={PAYMENT_METHOD_CHECK}
                          value={PAYMENT_METHOD_CHECK}
                        >
                          Check
                        </Select.Option>
                        <Select.Option
                          key={PAYMENT_METHOD_EXTERNAL}
                          value={PAYMENT_METHOD_EXTERNAL}
                        >
                          External System
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={"refNo"} required>
                      <Input
                        placeholder={"Reference no"}
                        style={{ width: "200px", marginTop: "12px" }}
                      />
                    </Form.Item>
                  </Space>
                  <br />
                  <br />
                  <div
                    style={{
                      padding: "0 12px",
                      borderBottom: `1px ${COLOR_TEXT_GRAY_1} solid`,
                      width: "320px",
                      display: "inline-flex",
                    }}
                  >
                    <Space align={"baseline"}>
                      <Space>
                        <TextBody
                          text={
                            getSymbolFromCurrency(orgData.currencyCode) ?? "$"
                          }
                          style={{
                            fontSize: "20px",
                            color: COLOR_TEXT_GRAY_1,
                          }}
                        />
                        <Form.Item
                          name={"receivePaymentInputNumber"}
                          style={{ marginBottom: "0" }}
                        >
                          <InputNumber
                            id={"receivePaymentInputNumber"}
                            onBlur={handlePaymentAmt}
                            onPressEnter={handlePaymentAmt}
                            precision={2}
                            min={0}
                            style={{
                              backgroundColor: "transparent",
                              lineHeight: "50px",
                              fontSize: "24px",
                              width: "300px",
                            }}
                            placeholder={"Payment amount"}
                            bordered={false}
                            size={"large"}
                          />
                        </Form.Item>
                      </Space>
                      {credit !== 0 && (
                        <Tag
                          style={{
                            padding: "4px 8px",
                            fontWeight: "bold",
                            backgroundColor:
                              credit > 0 ? COLOR_GREEN_0 : COLOR_RED_0,
                            color: "#FFF",
                            borderColor:
                              credit > 0 ? COLOR_GREEN_0 : COLOR_RED_0,
                          }}
                        >
                          <span style={{ fontWeight: "normal" }}>
                            CREDIT TO ACCOUNT:
                          </span>{" "}
                          ${credit.toFixed(2)}
                        </Tag>
                      )}
                    </Space>
                  </div>
                </Form>
                <br />
                <ReceivePaymentTable
                  updateFromSelectAll={updateFromSelectAll}
                  setFulfilled={setFulfilled}
                  customer={customer}
                  customerId={id && id}
                  fetchComplete={fetchComplete}
                  invoicesOutstanding={invoicesOutstanding}
                  updateTable={updateTable}
                  fulfilled={fulfilled}
                  defaultSelected={defaultSelected}
                  defaultComplete={defaultComplete}
                  orgData={orgData}
                />
              </div>
            </Spin>
          </div>

          <StripePaymentModal />
        </ReceivePaymentModalContext.Provider>
      )}

      <Dialog open={showJustifiModal} onClose={closeJustifiPaymentModal}>
        <JustifiPaymentModal
          customer={customer}
          orgData={orgData}
          total={paymentAmt}
          credit={credit}
          refNo={form.getFieldValue("refNo") ?? "Justifi Payment"}
          fulfilled={fulfilled}
          onClose={closeJustifiPaymentModal}
        />
      </Dialog>
    </div>
  );
};

export default ReceivePayment;
