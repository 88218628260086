import React, { useEffect, useState } from "react";
import InvoiceView, { PrintQRByInvoice } from "./InvoiceView";
import InventoryView, { PrintQRByInventory } from "./InventoryView";
import { COLOR_LIGHT_GRAY } from "../../../app/ui/colorConstants";
import { Col, Drawer, Row, Space, Radio } from "antd";

import InventoryList from "./InventoryList";
import InvoiceList from "./InvoiceList";
import { GetInventoryFromInvoices } from "../../../app/services/hooks/helpers/qrItems/getInventoryFromInvoices";

const SelectedDayDrawer = ({
  setDrawer,
  drawer,
  invoiceList,
  inventoryList,
  dailyAvailable,
  refreshInvoiceData,
  configRefresh,
  triggerRefresh,
  refreshInvoices,
  selectedDate,
}) => {
  
  const [view, setView] = useState("invoice");
  const [height, setHeight] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState(null);

  useEffect(() => {
    setHeight(window.innerHeight - 76);
    if (drawer.visible) {
      // set selected invoice
      setInitialInvoice(invoiceList);
      setInitialInventory(GetInventoryFromInvoices(invoiceList));
    }
  }, [drawer.visible, view]);

  useEffect(() => {
    if (!selectedInventory) return;
    const newSelected = GetInventoryFromInvoices(invoiceList).find(
      (i) => i.id === selectedInventory.id
    );
    setSelectedInventory(newSelected);
  }, [invoiceList]);

  const setInitialInvoice = (invoiceList) => {
    if (invoiceList.length === 0) return;
    setSelectedInvoice(invoiceList[0]);
  };

  const setInitialInventory = (inventoryList) => {
    if (inventoryList.length === 0) return;
    setSelectedInventory(inventoryList[0]);
  };

  const renderTitle = () => {
    return <Space>{selectedDate.dateStr}</Space>;
  };

  return (
    <Drawer
      bodyStyle={{ backgroundColor: COLOR_LIGHT_GRAY }}
      height={height}
      title={renderTitle()}
      placement={"bottom"}
      closable={false}
      onClose={() => {
        configRefresh();
        setDrawer({
          visible: false,
          data: [],
        });
      }}
      visible={drawer.visible}
      key={"qr-drawer"}
    >
      <Row wrap={false}>
        <Col flex={"260px"} style={{ marginRight: "24px" }}>
          <Radio.Group
            onChange={(v) => {
              setView(v.target.value);
            }}
            defaultValue="invoice"
            buttonStyle="solid"
            style={{ width: "100%", textAlign: "center" }}
          >
            <Radio.Button value="invoice">By Invoice</Radio.Button>
            <Radio.Button value="inventory">By Inventory</Radio.Button>
          </Radio.Group>
          <br />
          <br />
          {view === "invoice" ? (
            <InvoiceList
              dailyAvailable={dailyAvailable}
              data={invoiceList}
              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
            />
          ) : (
            <InventoryList
              dailyAvailable={dailyAvailable}
              data={GetInventoryFromInvoices(invoiceList)}
              selectedInventory={selectedInventory}
              setSelectedInventory={setSelectedInventory}
            />
          )}
        </Col>
        <Col flex={"auto"}>
          {view === "invoice" ? (
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <PrintQRByInvoice
                  dailyAvailable={dailyAvailable}
                  selectedInvoice={selectedInvoice}
                  refreshInvoiceData={(invoice) => {
                    setSelectedInvoice(invoice);
                    refreshInvoiceData(invoice);
                  }}
                />
              </div>

              <InvoiceView
                dailyAvailable={dailyAvailable}
                selectedInvoice={selectedInvoice}
                refreshInvoiceData={(invoice) => {
                  setSelectedInvoice(invoice);
                  refreshInvoiceData(invoice);
                }}
              />
            </div>

          ) : (
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <PrintQRByInventory
                  dailyAvailable={dailyAvailable}
                  selectedInventory={selectedInventory}
                  invoices={invoiceList}
                  refreshInvoiceData={(invoice) => {
                    refreshInvoiceData(invoice);
                  }}
                  refreshInvoices={({
                    selectedInventory: newInv,
                    invoices,
                  }) => {
                    setSelectedInventory({ ...newInv });
                    refreshInvoices({ invoices });
                  }}
                />
              </div>

              <InventoryView
                dailyAvailable={dailyAvailable}
                selectedInventory={selectedInventory}
                invoices={invoiceList}
                refreshInvoiceData={(invoice) => {
                  refreshInvoiceData(invoice);
                }}
                refreshInvoices={({ selectedInventory: newInv, invoices }) => {
                  setSelectedInventory({ ...newInv });
                  refreshInvoices({ invoices });
                }}
              />
            </div>

          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default SelectedDayDrawer;
