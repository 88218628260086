import React from "react";
import { ItemTag } from "../../../../../app/system-components/tags/Tags";
import { Card } from "antd";
import { firelikeId } from "../../../../../app/utils/models/checkers/firelikeid";

const InvoiceItemsCard = ({ items }) => {
  return (
    <Card
      key={firelikeId()}
      title="Invoice Items"
      bordered={false}
      size="small"
      bodyStyle={{ 
        padding: "8px",
        maxHeight: "170px",  // Set a maximum height
        overflowY: "auto"    // Enable vertical scrolling
      }}
    >
      {items?.map((item) => (
        <div key={firelikeId()}>
          <ItemTag key={firelikeId()} name={item?.name} qty={item?.qty} />
        </div>
      ))}
    </Card>
  );
};

export default InvoiceItemsCard;