import { Col, Row, Space } from "antd";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import { sortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { COLOR_TEXT_GRAY_1 } from "../../../../../app/ui/colorConstants";

const InlineSubtotal = ({ record }) => {
  const DragHandle = sortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: COLOR_TEXT_GRAY_1,
        fontSize: "12px",
        marginRight: "12px",
      }}
    />
  ));

  return (
    <Row wrap={false} justify="space-between" align="middle">
      <Col>
        <DragHandle />
        <Space>
          <TextSubtitle
            style={{ color: COLOR_TEXT_GRAY_1, fontSize: "13px" }}
            text={`${record.key + 1}`}
          />
          <TextSubtitle text={"SUBTOTAL"} />
        </Space>
      </Col>
    </Row>
  );
};

export default InlineSubtotal;
