export const es = {
  common: {
    or: 'o',
    cancel: 'Cancelar',
    reset: 'Reiniciar',
    save: 'Guardar',
    search: 'Buscar',
    edit: 'Editar',
    remove: 'Eliminar',
    new: 'Nuevo',
    export: 'Exportar a Excel',
    noDataToExport: 'No hay datos para exportar',
    import: 'Importar',
    discard: 'Descartar',
    yes: 'Si',
    no: 'No',
    pause: 'Pausa',
    areYouSure: '¿Estás seguro?',
    view: 'Ver',
    destroy: 'Eliminar',
    mustSelectARow: 'Debe seleccionar una fila',
    filters: 'Filtros',
    noneSpecified: 'No Especificado',
  },
  app: {
    title: 'Adelie Logistics',
  },
  pdf: {
    updated: 'Actualizado',
    dueBy: 'Fecha De Pago',
    rentalStart: 'Fecha De Entrega',
    rentalEnd: 'Fecha De Recojo',
    deliveryFee: 'Gastos de envío',
    discount: 'Descuento',
    subtotal: 'Total parcial',
    taxes: 'Impuestos',
    total: 'Total',
    amountPaid: 'Cantidad pagada',
    remainingBalance: 'Saldo Pendiente',
    receiveReturnDetails: "Entrega y devolución",
    customerReceivesVia: "Entraga via",
    returnedVia: "Devolución via",
    address: 'Dirección',
    timeRange: 'Fecha/Hora',
    columnItem: 'Artículo',
    columnDescription: 'Descripción',
    colQty: 'Cantidad',
    rate: 'Precio',
    duration: 'Duración',
    venue: 'Lugar',
    contact: 'Contacto',
    delivery: 'Delivery',
    customerPickup: 'Recojo En Local',
    pickup: 'Recojo',
    customerDropOff: 'Devolución en local',
    additionalNotes: 'Notas adicionales',
    invoiceString: 'Orden',
    estimateString: 'Cotización',
  },
  availabilityPage: {
    title: 'Inventario Disponible',
    selectDates: 'Seleccione las fechas de alquiler para comenzar',
  },

  inventory: {
    search: 'Inventario de búsqueda',
    filters: 'Filtros',
  },

  invoices: {
    sales: 'Ventas',
    invoiceSearchPlaceholder: 'Buscar cliente, correo electrónico o factura #',
    newOrder: 'Nuevo orden',
    type: 'Tipo',
    customer: 'Cliente',
    rentalStart: 'Inicio de alquiler',
    rentalEnd: 'Fin del alquiler',
    eventDate: 'Dia Del Evento',
    lastUpdated: 'Last Updated',
    total: 'Total',
    balance: 'Balance',
    days: 'Días',
    email: 'Email',
    status: 'Status',
  },

  sidebar: {
    availability: 'Disponibilidad',
    invoices: 'Ordenes',
    prepare: 'Preparar artículos',
    routes: 'Administrar entrega/recojo',
    reports: {
      title: 'Informes',
      sales: 'Reporte de ventas',
      usage: 'Informe de uso',
      depreciation: 'Informe de depreciación',
    },
    admin: {
      title: 'Administración',
      users: 'Usuarios',
      inventory: 'Inventario',
      customers: 'Clientes',
      venues: 'Lugares',
      trucks: 'Camiones',
      integrations: 'Integraciones',
    },
    settings: {
      title: 'Configuración',
      general: 'General',
      email: 'Email',
    },
  },

  entities: {
    adelieInventoryItem: {
      name: 'adelieInventoryItem',
      label: 'AdelieInventoryItems',
      menu: 'AdelieInventoryItems',
      exporterFileName: 'exportacion_adelieInventoryItem',
      list: {
        menu: 'AdelieInventoryItems',
        title: 'AdelieInventoryItems',
      },
      create: {
        success: 'AdelieInventoryItem guardado con éxito',
      },
      update: {
        success: 'AdelieInventoryItem guardado con éxito',
      },
      destroy: {
        success: 'AdelieInventoryItem eliminado con éxito',
      },
      destroyAll: {
        success: 'AdelieInventoryItem(s) eliminado con éxito',
      },
      edit: {
        title: 'Editar AdelieInventoryItem',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        stockRange: 'Stock',
        stock: 'Stock',
        turnaroundRange: 'Turnaround',
        turnaround: 'Turnaround',
        externalItemId: 'ExternalItemId',
        userIdRange: 'UserId',
        userId: 'UserId',
        categoryIdRange: 'CategoryId',
        categoryId: 'CategoryId',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        createdAtRange: 'Creado el',
      },
      enumerators: {},
      new: {
        title: 'Nuevo AdelieInventoryItem',
      },
      view: {
        title: 'Ver AdelieInventoryItem',
      },
      importer: {
        title: 'Importar AdelieInventoryItems',
        fileName: 'adelieInventoryItem_import_template',
        hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio.',
      },
    },

    adelieInventoryCategory: {
      name: 'adelieInventoryCategory',
      label: 'AdelieInventoryCategories',
      menu: 'AdelieInventoryCategories',
      exporterFileName: 'exportacion_adelieInventoryCategory',
      list: {
        menu: 'AdelieInventoryCategories',
        title: 'AdelieInventoryCategories',
      },
      create: {
        success: 'AdelieInventoryCategory guardado con éxito',
      },
      update: {
        success: 'AdelieInventoryCategory guardado con éxito',
      },
      destroy: {
        success: 'AdelieInventoryCategory eliminado con éxito',
      },
      destroyAll: {
        success: 'AdelieInventoryCategory(s) eliminado con éxito',
      },
      edit: {
        title: 'Editar AdelieInventoryCategory',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        userIdRange: 'UserId',
        userId: 'UserId',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        createdAtRange: 'Creado el',
      },
      enumerators: {},
      new: {
        title: 'Nuevo AdelieInventoryCategory',
      },
      view: {
        title: 'Ver AdelieInventoryCategory',
      },
      importer: {
        title: 'Importar AdelieInventoryCategories',
        fileName: 'adelieInventoryCategory_import_template',
        hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio.',
      },
    },

    adelieInvoice: {
      name: 'adelieInvoice',
      label: 'AdelieInvoices',
      menu: 'AdelieInvoices',
      exporterFileName: 'exportacion_adelieInvoice',
      list: {
        menu: 'AdelieInvoices',
        title: 'AdelieInvoices',
      },
      create: {
        success: 'AdelieInvoice guardado con éxito',
      },
      update: {
        success: 'AdelieInvoice guardado con éxito',
      },
      destroy: {
        success: 'AdelieInvoice eliminado con éxito',
      },
      destroyAll: {
        success: 'AdelieInvoice(s) eliminado con éxito',
      },
      edit: {
        title: 'Editar AdelieInvoice',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        invoiceNumber: 'InvoiceNumber',
        externalInvoiceId: 'ExternalInvoiceId',
        typedShipDateTime: 'TypedShipDateTime',
        typedReturnDateTime: 'TypedReturnDateTime',
        shipDateTimeRange: 'ShipDateTime',
        shipDateTime: 'ShipDateTime',
        returnDateTimeRange: 'ReturnDateTime',
        returnDateTime: 'ReturnDateTime',
        dueDateRange: 'DueDate',
        dueDate: 'DueDate',
        invoiceDateRange: 'InvoiceDate',
        invoiceDate: 'InvoiceDate',
        customerIdRange: 'CustomerId',
        customerId: 'CustomerId',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        createdAtRange: 'Creado el',
      },
      enumerators: {},
      new: {
        title: 'Nuevo AdelieInvoice',
      },
      view: {
        title: 'Ver AdelieInvoice',
      },
      importer: {
        title: 'Importar AdelieInvoices',
        fileName: 'adelieInvoice_import_template',
        hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio.',
      },
    },

    adelieInvoiceItem: {
      name: 'adelieInvoiceItem',
      label: 'AdelieInvoiceItems',
      menu: 'AdelieInvoiceItems',
      exporterFileName: 'exportacion_adelieInvoiceItem',
      list: {
        menu: 'AdelieInvoiceItems',
        title: 'AdelieInvoiceItems',
      },
      create: {
        success: 'AdelieInvoiceItem guardado con éxito',
      },
      update: {
        success: 'AdelieInvoiceItem guardado con éxito',
      },
      destroy: {
        success: 'AdelieInvoiceItem eliminado con éxito',
      },
      destroyAll: {
        success: 'AdelieInvoiceItem(s) eliminado con éxito',
      },
      edit: {
        title: 'Editar AdelieInvoiceItem',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        description: 'Description',
        quantityRange: 'Quantity',
        quantity: 'Quantity',
        rateRange: 'Rate',
        rate: 'Rate',
        invoiceIdRange: 'InvoiceId',
        invoiceId: 'InvoiceId',
        itemIdRange: 'ItemId',
        itemId: 'ItemId',
        isLoaded: 'IsLoaded',
        lineIdRange: 'LineId',
        lineId: 'LineId',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        createdAtRange: 'Creado el',
      },
      enumerators: {},
      new: {
        title: 'Nuevo AdelieInvoiceItem',
      },
      view: {
        title: 'Ver AdelieInvoiceItem',
      },
      importer: {
        title: 'Importar AdelieInvoiceItems',
        fileName: 'adelieInvoiceItem_import_template',
        hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio.',
      },
    },

    adelieCustomer: {
      name: 'adelieCustomer',
      label: 'AdelieCustomers',
      menu: 'AdelieCustomers',
      exporterFileName: 'exportacion_adelieCustomer',
      list: {
        menu: 'AdelieCustomers',
        title: 'AdelieCustomers',
      },
      create: {
        success: 'AdelieCustomer guardado con éxito',
      },
      update: {
        success: 'AdelieCustomer guardado con éxito',
      },
      destroy: {
        success: 'AdelieCustomer eliminado con éxito',
      },
      destroyAll: {
        success: 'AdelieCustomer(s) eliminado con éxito',
      },
      edit: {
        title: 'Editar AdelieCustomer',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        externalCustomerId: 'ExternalCustomerId',
        fullName: 'FullName',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        createdAtRange: 'Creado el',
      },
      enumerators: {},
      new: {
        title: 'Nuevo AdelieCustomer',
      },
      view: {
        title: 'Ver AdelieCustomer',
      },
      importer: {
        title: 'Importar AdelieCustomers',
        fileName: 'adelieCustomer_import_template',
        hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio.',
      },
    },
  },
  auth: {
    tenants: 'Espacios de trabajo',
    profile: {
      title: 'Perfil',
      success: 'Perfil actualizado con éxito',
    },
    createAnAccount: 'Crea una cuenta',
    rememberMe: 'Recuérdame',
    forgotPassword: 'Se te olvidó tu contraseña',
    signin: 'Iniciar Sesión',
    signup: 'Registrarse',
    signout: 'Desconectar',
    alreadyHaveAnAccount: '¿Ya tienes una cuenta? Registrarse.',
    signinWithAnotherAccount: 'Inicia sesión con otra cuenta',
    passwordChange: {
      title: 'Cambia la contraseña',
      success: 'Contraseña cambiada correctamente',
      mustMatch: 'Las contraseñas deben coincidir',
    },
    emailUnverified: {
      message:
        'Confirme su correo electrónico en <strong>{0}</strong> para continuar.',
      submit: 'Reenviar verificación de correo electrónico',
    },
    emptyPermissions: {
      message:
        'Aún no tienes permisos. Espera a que el administrador te otorgue privilegios.',
    },
    passwordResetEmail: {
      message: 'Enviar contraseña restablecer correo electrónico',
      error: 'Correo electrónico no reconocido',
    },
    passwordReset: {
      message: 'Restablecer la contraseña',
    },
    emailAddressVerificationEmail: {
      error: 'Correo electrónico no reconocido',
    },
    verificationEmailSuccess:
      'Correo electrónico de verificación enviado con éxito',
    passwordResetEmailSuccess:
      'Correo electrónico de restablecimiento de contraseña enviado correctamente',
    passwordResetSuccess: 'Contraseña cambiada correctamente',
    verifyEmail: {
      success: 'Correo electrónico verificado con éxito.',
      message:
        'Solo un momento, su correo electrónico está siendo verificado ...',
    },
  },
  tenant: {
    name: 'inquilino',
    label: 'Espacios de trabajo',
    menu: 'Espacios de trabajo',
    list: {
      menu: 'Espacios de trabajo',
      title: 'Espacios de trabajo',
    },
    create: {
      button: 'Crear espacio de trabajo',
      success: 'Espacio de trabajo guardado correctamente',
    },
    update: {
      success: 'Espacio de trabajo guardado correctamente',
    },
    destroy: {
      success: 'Espacio de trabajo eliminado correctamente',
    },
    destroyAll: {
      success: 'Espacio(s) de trabajo eliminado(s) correctamente',
    },
    edit: {
      title: 'Editar espacio de trabajo',
    },
    fields: {
      id: 'Id',
      name: 'Nombre',
      url: 'URL',
      tenantName: 'Nombre del espacio de trabajo',
      tenantId: 'Espacio de trabajo',
      tenantUrl: 'URL del espacio de trabajo',
      plan: 'Plan',
    },
    enumerators: {},
    new: {
      title: 'Nuevo espacio de trabajo',
    },
    invitation: {
      view: 'Ver invitaciones',
      invited: 'Invitado',
      accept: 'Aceptar la invitacion',
      decline: 'Rechazar invitación',
      declined: 'Invitación rechazada con éxito',
      acceptWrongEmail: 'Aceptar invitación con este correo electrónico',
    },
    select: 'Seleccionar espacio de trabajo',
    validation: {
      url: 'La URL de su espacio de trabajo solo puede contener letras minúsculas, números y guiones (y debe comenzar con una letra o número).',
    },
  },
  roles: {
    admin: {
      label: 'Administración',
      description: 'Acceso total a todos los recursos.',
    },
    custom: {
      label: 'Rol personalizado',
      description: 'Acceso personalizado a recursos',
    },
  },
  user: {
    invite: 'Invitación',
    title: 'Usuarios',
    menu: 'Usuarios',
    fields: {
      id: 'Id',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Nombre completo',
      firstName: 'Nombre',
      lastName: 'Apellido',
      status: 'Estado',
      disabled: 'Discapacitado',
      phoneNumber: 'Número de teléfono',
      role: 'Rol',
      createdAt: 'Creado el',
      updatedAt: 'Actualizado el',
      roleUser: 'Rol/Usuario',
      roles: 'Roles',
      createdAtRange: 'Creado el',
      password: 'Contraseña',
      rememberMe: 'Recuérdame',
      oldPassword: 'Contraseña anterior',
      newPassword: 'Nueva contraseña',
      newPasswordConfirmation: 'Nueva confirmación de contraseña',
    },
    enabled: 'Habilitado',
    disabled: 'Discapacitado',
    validations: {
      // eslint-disable-next-line
      email: 'El correo electrónico ${value} no es válido',
    },
    disable: 'Inhabilitar',
    enable: 'Habilitar',
    doEnableSuccess: 'Usuario habilitado con éxito',
    doDisableSuccess: 'Usuario deshabilitado con éxito',
    doDisableAllSuccess: 'Usuario(s) deshabilitado con éxito',
    doEnableAllSuccess: 'Usuario(s) habilitados correctamente',
    doAddSuccess: 'Usuario(s) guardado correctamente',
    doUpdateSuccess: 'Usuario guardado con éxito',
    status: {
      active: 'Activo',
      invited: 'Invitado',
      'empty-permissions': 'Esperando permisos',
    },
    exporterFileName: 'usuarios_exportacion',
    doDestroySuccess: 'Usuario eliminado con éxito',
    doDestroyAllSelectedSuccess: 'Usuario(s) eliminado correctamente',
    edit: {
      title: 'Editar Usuario',
    },
    new: {
      title: 'Invitar Usuario(s)',
      titleModal: 'Nuevo Usuario',
      emailsHint:
        'Separe varias direcciones de correo electrónico utilizando el carácter de coma.',
    },
    view: {
      title: 'Ver Usuario',
      activity: 'Actividad',
    },
    importer: {
      title: 'Importar Usuarios',
      fileName: 'users_import_template',
      hint: 'Las columnas Archivos/Imágenes deben ser las URL de los archivos separados por espacio. Las relaciones deben ser la ID de los registros referenciados separados por espacio. Los roles deben ser los identificadores de roles separados por espacio.',
    },
    errors: {
      userAlreadyExists: 'El usuario con este correo electrónico ya existe',
      userNotFound: 'Usuario no encontrado',
      disablingHimself: 'No puedes inhabilitarte',
      revokingOwnPermission:
        'No puede revocar su propio permiso de administrador',
    },
  },
  plan: {
    menu: 'Planes',
    title: 'Planes',
    free: {
      label: 'Gratis',
      price: '$0',
    },
    growth: {
      label: 'Crecimiento',
      price: '$10',
    },
    enterprise: {
      label: 'Empresa',
      price: '$50',
    },
    pricingPeriod: '/mes',
    current: 'Plan Actual',
    subscribe: 'Suscribir',
    manage: 'Administrar Suscripción',
    cancelAtPeriodEnd: 'Este plan se cancelará al final del período.',
    somethingWrong:
      'Hay algo mal con su suscripción. Vaya a administrar la suscripción para obtener más detalles.',
    notPlanUser: 'No eres el administrador de esta suscripción.',
    demoHintHtml:
      'Sugerencia: Use esas <a href="https://stripe.com/docs/testing#cards" target="_blank" rel="noopener noreferrer">tarjetas de prueba</a> para la demostración.',
  },
  auditLog: {
    menu: 'Registros de auditoría',
    title: 'Registros de auditoría',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separe varias entidades con el carácter de coma.',
    fields: {
      id: 'Id',
      timestampRange: 'Período',
      entityName: 'Entidad',
      entityNames: 'Entidades',
      entityId: 'ID de entidad',
      action: 'Acción',
      values: 'Valores',
      timestamp: 'Fecha',
      createdByEmail: 'Email del usuario',
    },
  },
  settings: {
    title: 'Configuraciones',
    menu: 'Configuraciones',
    save: {
      success:
        'Configuración guardada con éxito. La página se volverá a cargar en {0} segundos para que los cambios surtan efecto.',
    },
    fields: {
      theme: 'Tema',
      logos: 'Logo',
      backgroundImages: 'Imagen de fondo',
    },
    colors: {
      default: 'Defecto',
      cyan: 'Cian',
      'geek-blue': 'Geek Blue',
      gold: 'Oro',
      lime: 'Lima',
      magenta: 'Magenta',
      orange: 'Naranja',
      'polar-green': 'Verde polar',
      purple: 'Púrpura',
      red: 'Rojo',
      volcano: 'Volcán',
      yellow: 'Amarillo',
    },
  },
  dashboard: {
    menu: 'Tablero',
    message:
      'Esta página utiliza datos falsos solo con fines de demostración. Puede editarlo en frontend/view/dashboard/DashboardPage.ts.',
    charts: {
      day: 'Día',
      red: 'Rojo',
      green: 'Verde',
      yellow: 'Amarillo',
      grey: 'Gris',
      blue: 'Azul',
      orange: 'Naranja',
      months: {
        '1': 'Enero',
        '2': 'Febrero',
        '3': 'Marzo',
        '4': 'Abril',
        '5': 'Mayo',
        '6': 'Junio',
        '7': 'Julio',
      },
      eating: 'Comiendo',
      drinking: 'Bebiendo',
      sleeping: 'Dormiendo',
      designing: 'Diseñando',
      coding: 'Codificando',
      cycling: 'Pedalando',
      running: 'Corriendo',
      customer: 'Cliente',
    },
  },
  errors: {
    '403': 'Lo sentimos, no tienes acceso a esta página',
    '404': 'Lo sentimos, la página que visitaste no existe',
    '500': 'Lo sentimos, el servidor informa un error',
    '429': 'Demasiadas solicitudes. Por favor, inténtelo de nuevo más tarde.',
    backToHome: 'Volver a Inicio',
    forbidden: {
      message: 'Prohibido',
    },
    validation: {
      message: 'Ocurrió un error',
    },
    defaultErrorMessage: 'Ops, ocurrió un error',
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Subir',
    image: 'Debes subir una imagen',
    size: 'El archivo es muy grande. El tamaño máximo permitido es {0}',
    formats: 'Formato inválido. Debe ser uno de: {0}.',
  },
  importer: {
    line: 'Línea',
    status: 'Estado',
    pending: 'Pendiente',
    imported: 'Importado',
    error: 'Error',
    total: '{0} importado, {1} pendiente y {2} con error',
    importedMessage: 'Procesado {0} de {1}.',
    noNavigateAwayMessage:
      'No navegue fuera de esta página o la importación se detendrá.',
    completed: {
      success:
        'Importación completada. Todas las filas se importaron correctamente.',
      someErrors:
        'Procesamiento completado, pero algunas filas no se pudieron importar.',
      allErrors: 'Importación fallida. No hay filas válidas.',
    },
    form: {
      downloadTemplate: 'Descargar la plantilla',
      hint: 'Haga clic o arrastre el archivo a esta área para continuar.',
    },
    list: {
      discardConfirm: '¿Estás seguro? Los datos no importados se perderán.',
    },
    errors: {
      invalidFileEmpty: 'El archivo esta vacio',
      invalidFileExcel: 'Solo se permiten archivos de Excel(.xlsx)',
      invalidFileUpload:
        'Archivo inválido. Asegúrese de estar utilizando la última versión de la plantilla.',
      importHashRequired: 'Se requiere hash de importación',
      importHashExistent: 'Los datos ya han sido importados',
    },
  },
  autocomplete: {
    loading: 'Cargando...',
  },
  imagesViewer: {
    noImage: 'Sin imágen',
  },
};

export default es;
