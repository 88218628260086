import React from "react";
import { Card, Divider, Input, Skeleton, Row, Col, Avatar } from "antd";
import * as DOMPurify from "dompurify";

import {
  COLOR_DARK_BG_0,
  COLOR_TEXT_GRAY_0,
  COLOR_TEXT_SECONDARY,
} from "../../../app/ui/colorConstants";
import { PaperClipOutlined, UserOutlined } from "@ant-design/icons";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

const EmailInvoiceExample = ({ pageValues, orgData }) => {
  console.log("REPLACED", pageValues);
  return (
    <div
      style={{
        borderRadius: "4px",
        background: " #FFFFFF",
        boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
        padding: "16px",
        textAlign: "left",
        height: "auto",
      }}
    >
      <Input
        bordered={false}
        disabled
        size="large"
        value={
          pageValues.emailSubject !== ""
            ? pageValues.emailSubject
            : "Subject of email"
        }
        style={{
          color:
            pageValues.emailSubject !== ""
              ? COLOR_DARK_BG_0
              : COLOR_TEXT_GRAY_0,
          cursor: "default",
          fontSize: "20px",
        }}
      />
      <br />
      <Row justify={"start"} align="middle" style={{ width: "100%" }}>
        <Col flex={"35px"}>
          <Avatar
            // size={24}
            icon={<UserOutlined />}
            style={{ marginLeft: "11px" }}
          />
        </Col>
        <Col flex={"auto"}>
          <Input
            bordered={false}
            disabled
            size="default"
            value={
              pageValues.emailFrom !== ""
                ? pageValues.emailFrom
                : "Email sender name"
            }
            style={{
              color:
                pageValues.emailFrom !== ""
                  ? COLOR_DARK_BG_0
                  : COLOR_TEXT_GRAY_0,
              lineHeight: "10px",
              fontWeight: "bold",
              cursor: "default",
              paddingBottom: 0,
              paddingLeft: "6px",
            }}
          />
          <TextSubtitle
            text={"<noreply@quickcountinventory.com>"}
            style={{
              paddingLeft: "6px",
              fontSize: "12px",
              display: "block",
              marginTop: "-4px",
            }}
          />
        </Col>
      </Row>
      <Divider />

      <div
        dangerouslySetInnerHTML={{ __html: pageValues.emailBody }}
        style={{
          color:
            pageValues.emailBody !== "" ? COLOR_DARK_BG_0 : COLOR_TEXT_GRAY_0,
          cursor: "default",
        }}
      />
      <br />
      <Card
        title={
          <div>
            <PaperClipOutlined style={{ marginRight: "4px" }} />
            example-invoice.pdf
          </div>
        }
        size="small"
      >
        <TextBody
          text={
            pageValues.companyName !== ""
              ? pageValues.companyName
              : "Company name"
          }
          style={{
            fontWeight: "bold",
            color:
              pageValues.companyName !== ""
                ? COLOR_DARK_BG_0
                : COLOR_TEXT_GRAY_0,
            cursor: "default",
          }}
        />
        <br />
        <TextSubtitle
          text={
            pageValues.companyAddress !== ""
              ? pageValues.companyAddress
              : "Company Address"
          }
        />
        <br />
        <TextBody text={"Invoice #0001"} />
        <br />
        <Skeleton paragraph={{ rows: 2 }} />
        <br />

        {
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(pageValues.invoiceNotes),
            }}
          />
        }

        <br />
        <br />

        <br />

        <TextSubtitle text={"TERMS & CONDITIONS"} />

        <div
          style={{
            color:
              pageValues.termsConditions !== ""
                ? COLOR_TEXT_SECONDARY
                : COLOR_TEXT_GRAY_0,
            fontSize: "12px",
            height: "200px",
            overflowY: "hidden",
            padding: 0,
            cursor: "default",
          }}
        >
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                pageValues.termsConditions
                  .replaceAll("<p>", "<br>")
                  .replaceAll("</p>", "")
              ),
            }}
          />
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              right: 0,
              bottom: 0,
              left: 0,
              height: "60px",
              background:
                "linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%)",
            }}
          ></div>
        </div>
        <br />
      </Card>
    </div>
  );
};

export default EmailInvoiceExample;
