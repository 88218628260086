import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Typography, Card, Spin } from "antd";
import { Link } from "react-router-dom";
import { ROUTE_FORGOT_PASSWORD, ROUTE_INVENTORY } from "../../app/routes";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import { authSelector, login } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Spinner } from "../../app/ui/spinner";
import notificationError from "../../app/system-components/toasters/notificationError";

const Login = ({ history }) => {
  const { authenticated, fromLogout } = useSelector(authSelector);
  const [authenticating, setAuthenticating] = useState(false);
  const [form] = Form.useForm();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const handleLogin = () => {
    form.validateFields().then(() => {
      setAuthenticating(true);
      const { email, password } = form.getFieldsValue();
      dispatch(
        login(
          { firebase, firestore },
          {
            email: email.trim(),
            password: password,
          },
          history
        )
      )
        .then(() => {
          setAuthenticating(false);
        })
        .catch((err) => {
          console.log("NO USER", err);
        });
    });
  };

  useEffect(() => {
    const subscribeAuth = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        history.push(ROUTE_INVENTORY);
      }
    });

    if (fromLogout) {
      // Clearing store
      window.location.href = window.location.href;
    }
    return () => subscribeAuth();
  }, [authenticated, fromLogout]);

  return (
    <div>
      {fromLogout ? (
        <div style={{ width: " 100%", paddingRight: "56px" }}>
          <Spin
            indicator={Spinner}
            spinning={true}
            style={{
              marginTop: "8em",
              textAlign: "center",
              width: "100%",
            }}
          />
        </div>
      ) : (
        <Row align="middle" justify="space-around" style={{ width: "100%" }}>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={14}
            xl={10}
            style={{
              height: "100vh",
            }}
          >
            <div
              style={{
                padding: "2em 4em",
                display: "block",
                position: "relative",
                top: "50%",
                transform: "translateY(-55%)",
              }}
            >
              <Spin spinning={authenticating} indicator={Spinner}>
                <Card
                  style={{
                    borderWidth: 0,
                    padding: "24px 12px 0px 12px",
                    boxShadow: "0px 2px 12px rgba(26, 26, 53, 0.1)",
                  }}
                >
                  <img
                    onClick={() =>
                      window.open("https://adelielogistics.com", "_blank")
                    }
                    src="../../../images/adelie-logo.svg"
                    alt="adelie_logo"
                    style={{
                      height: "26px",
                      margin: "0em auto auto auto",
                      display: "block",
                      cursor: "pointer",
                    }}
                  />

                  <Form
                    form={form}
                    layout="vertical"
                    style={{ marginTop: "4em" }}
                    hideRequiredMark
                  >
                    <Form.Item
                      name="email"
                      label={<TextSubtitle text={"EMAIL"} />}
                      rules={[
                        {
                          required: true,
                          message: "Enter an email",
                        },
                      ]}
                    >
                      <Input
                        size="'large"
                        placeholder="email"
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={<TextSubtitle text={"PASSWORD"} />}
                      rules={[
                        {
                          required: true,
                          message: "Enter a password",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="password"
                        size="'large"
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ margin: ".6em 0em 0em", fontWeight: "700" }}
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={authenticating}
                        block
                        onClick={handleLogin}
                      >
                        Sign In
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Link to={ROUTE_FORGOT_PASSWORD}>
                        <Button type="link" size="small" block>
                          Forgot Password
                        </Button>
                      </Link>
                    </Form.Item>
                  </Form>
                </Card>
              </Spin>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Login;
