import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Spin, Tooltip, Typography } from "antd";
import CustomizeEmail from "./components/CustomizeEmail";
import { configureEmailSettingsUpdate } from "../../app/utils/models/configure/configureSettingsUpdate";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import notificationError from "../../app/system-components/toasters/notificationError";
import { orgWrites } from "../../app/services/firestore/writes/orgWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { orgQueries } from "../../app/services/firestore/queries/orgQueries";
import { Spinner } from "../../app/ui/spinner";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import EmailTemplateMaker from "./components/EmailTemplateMaker";
import { Box, Chip, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import EmailAutomationMaker from "./components/EmailAutomationMaker";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import EmailReceiptMaker from "./components/EmailReceiptMaker";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminSettingsEmail = () => {
  const {
    userData,
    orgData,
    invoiceEmailSettings,
    estimateEmailSettings,
    fsOrgPrefix,
  } = useSelector(authSelector);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [loadingAutomations, setLoadingAutomations] = useState(false);

  const firestore = useFirestore();
  const [templates, setTemplates] = useState([]);
  const [automations, setAutomations] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null); 
  const [selectedAutomation, setSelectedAutomation] = useState(null); 
  const [open, setOpen] = React.useState(false);
  const [openReceiptTemplate, setOpenReceiptTemplate] = React.useState(false);
  const [openAutomation, setOpenAutomation] = React.useState(false);

  const [receiptEmailTemplate, setReceiptEmailTemplate] = useState(null);


  const handleClickOpen = () => {
    setSelectedTemplate(null);
    setOpen(true);
  };
  const handleAutomationOpen = () => {
    setSelectedAutomation(null);
    setOpenAutomation(true);
  };

  const loadTemplates = async () => {
    setLoadingTemplates(true);
    try {

      let receiptEmailTemplate = null;

      const templatesQuery = await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("templates")
        .doc("email")
        .collection("email")
        .get();
      const templates = [];
      templatesQuery.docs.forEach((doc) => {
        if (doc.id === "receipt-email") {
          // Separate the receipt-email template
          receiptEmailTemplate = doc.data();
        } else {
          templates.push(doc.data());
        }
      });

      if (receiptEmailTemplate) {
        setReceiptEmailTemplate(receiptEmailTemplate);
      }

      setTemplates(templates);
    } catch (error) {
      notificationError("Error", "Failed to load email settings");
    }
    setLoadingTemplates(false);
  };

  const loadAutomations = async () => {
    setLoadingAutomations(true);
    try {
      const automationsQuery = await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("automations")
        .get();
      const automations = [];
      automationsQuery.docs.forEach((doc) => {
        automations.push(doc.data());
      });
      setAutomations(automations);
    } catch (error) {
      notificationError("Error", "Failed to load email settings");
    }
    setLoadingAutomations(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenReceiptTemplate(false);
    loadTemplates();
  };
  const handleCloseAutomation = () => {
    setOpenAutomation(false);
    loadAutomations();
  };

  useEffect(() => {
    
    orgData && loadTemplates();
    orgData && loadAutomations();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData]);

  return (
    <div>
      <Spin spinning={loadingTemplates} indicator={Spinner}>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Settings"
            extra={[
              <Tooltip
                title={
                  !checkSubscriptionLevel("email.templates", orgData)
                    ? "You do not have the required plan for email templates. Requires Plus Plan, you can change this in Admin/Billing"
                    : ""
                }
              >
                <span>
                  <Button
                    disabled={!checkSubscriptionLevel("email.templates", orgData)}
                    type="primary"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    New Email Template
                  </Button>
                </span>
              </Tooltip>,

              <Tooltip
                title={
                  !checkSubscriptionLevel("email.automations", orgData)
                    ? "You do not have the required plan for email automations. Requires Premium Plan, you can change this in Admin/Billing"
                    : ""
                }
              >
                <span>
                  <Button
                    type="primary"
                    disabled={!checkSubscriptionLevel("email.automations", orgData)}
                    onClick={() => {
                      handleAutomationOpen();
                    }}
                  >
                    New Email Automation
                  </Button>
                </span>
              </Tooltip>,
            ]}
          />
        </Affix>
        <div style={{ paddingTop: "68px", margin: "20px" }}>
          <CustomizeEmail settings={invoiceEmailSettings} settingsId={"invoiceEmailSettings"} title={"Customize Invoice Email"} />
          <br />
          <CustomizeEmail settings={estimateEmailSettings} settingsId={"estimateEmailSettings"} title={"Customize Estimate Email"} />

          {/* MARK: System Templates */}
          {receiptEmailTemplate && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">System Email Templates</Typography>

              <List>
                <ListItemButton
                  sx={{
                    backgroundColor: "#fff",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#e0e0e0",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    setOpenReceiptTemplate(true);
                  }}
                >
                  <Stack direction={'row'} gap={2} alignItems={'center'}>

                  <ListItemText primary={"Payment Receipt Email"} />
                  <Chip size="small" label={receiptEmailTemplate.active ? "Currently Active" : "Currently Inactive"} color={receiptEmailTemplate.active ? "success" : "warning"} />
                  </Stack>
                  
                  <Button
                    variant="contained"
                    color={receiptEmailTemplate.active ? "secondary" : "primary"}
                    onClick={async (e) => {
                      e.stopPropagation();

                      // Update state for receiptEmailTemplate
                      setReceiptEmailTemplate({ ...receiptEmailTemplate, active: !receiptEmailTemplate.active });

                      // Update the org document with the new status

                      firestore
                        .collection("orgs")
                        .doc(orgData.id)
                        .collection("templates")
                        .doc("email")
                        .collection("email")
                        .doc("receipt-email")
                        .update({ active: !receiptEmailTemplate.active });
                    }}
                  >
                    {receiptEmailTemplate.active ? "Turn Off Receipts" : "Turn On Receipts"}

                  </Button>
                </ListItemButton>
              </List>
            </Box>
          )}

          {templates.length > 0 && checkSubscriptionLevel("email.templates", orgData) && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Additional Email Templates</Typography>

              <List>
                {templates.map((template) => (
                  <ListItem key={template.id}>
                    <ListItemButton
                      sx={{
                        backgroundColor: "#fff",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#e0e0e0",
                      }}
                      onClick={() => {
                        setSelectedTemplate(template);
                        setOpen(true);
                      }}
                    >
                      <ListItemText primary={template.templateName} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {automations.length > 0 && checkSubscriptionLevel("email.automations", orgData) && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Email Automations</Typography>

              <List>
                {automations.map((automation) => (
                  <ListItem key={automation.id}>
                    <ListItemButton
                      sx={{
                        backgroundColor: "#fff",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#e0e0e0",
                      }}
                      onClick={() => {
                        setSelectedAutomation(automation);
                        setOpenAutomation(true);
                      }}
                    >
                      <ListItemText primary={automation.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <EmailTemplateMaker handleClose={handleClose} orgData={orgData} template={selectedTemplate} />
          </Dialog>

          {receiptEmailTemplate && openReceiptTemplate && (
            <Dialog fullScreen open={openReceiptTemplate} onClose={handleClose} TransitionComponent={Transition}>
              <EmailReceiptMaker handleClose={handleClose} orgData={orgData} template={receiptEmailTemplate} />
            </Dialog>
          )}

          <Dialog fullScreen open={openAutomation} onClose={handleCloseAutomation} TransitionComponent={Transition}>
            <EmailAutomationMaker handleClose={handleCloseAutomation} orgData={orgData} templates={templates} automation={selectedAutomation} />
          </Dialog>
        </div>
      </Spin>
    </div>
  );
};

export default AdminSettingsEmail;
