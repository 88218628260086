import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { SendInvoiceContext } from "../InvoiceDetail";
import {
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Checkbox,
  InputNumber,
  Select,
  Space,
  Tag,
  Typography,
  Tooltip,
} from "antd";
import { useSendInvoice } from "../../../app/services/hooks/useSendInvoice";
import { useSelector } from "react-redux";

import { emailInvoice } from "../../../app/services/cloud-functions/emailInvoice";
import { useFirebase, useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { ROUTE_EDIT_INVOICE, ROUTE_INVOICES } from "../../../app/routes";
import { useHistory } from "react-router-dom";
import {
  INVOICE,
  INVOICE_ESTIMATE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { emailSettingsQuery } from "../../../app/services/firestore/queries/orgQueries";
import { authSelector } from "../../auth/authSlice";
import {
  INVOICES_COLLECTION,
  SEND_RECORDS,
} from "../../../app/utils/models/collections/collectionConstants";
import { dollarToCents } from "../../../app/utils/models/configure/configureCurrencyToCents";
import { Check } from "@mui/icons-material";
import { QuestionCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { COLOR_TEXT_GRAY_1 } from "../../../app/ui/colorConstants";
import InvoicePDF from "./invoice-pdf";
import { PDFViewer } from "@react-pdf/renderer";
import { Button, Stack } from "@mui/material";
import Iconify from "../../../app/iconify";
import { useBoolean } from "../../../app/hooks/use-boolean";
import FileManagerNewFileDialog from "../../file-manager/file-manager-new-file-dialog";
import { Upload, UploadBox } from "../../file-manager/upload";
import FileManagerNewFileBox from "../../file-manager/file-manager-new-file-box";
import { fileData } from "../../file-manager/file-thumbnail";

const { Option } = Select;

// PSR SPECIFIC CONSTANTS *******
const psr = process.env.REACT_APP_PSR_ID;
const tanglewoodlogo = process.env.REACT_APP_TANGLEWOOD_LOGO;
const madherblogo = process.env.REACT_APP_MADHERB_LOGO;

const madHerbalistVenueId = process.env.REACT_APP_MADHERBALIST_VENUE;
const tanglewoodVenueId = process.env.REACT_APP_TANGLEWOOD_VENUE;
// END PSR SPECIFIC CONSTANTS *******

const SendInvoiceModal = ({
  venues,
  invoiceTextData,
  i18n,
  logoData,
  itemImages,
  files,
  signatureData,
}) => {
  const modal = useContext(SendInvoiceContext);
  const { fetching, customerEmail, customer } = useSendInvoice(
    modal?.data?.customerId
  );

  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const upload = useBoolean();

  const [loading, setLoading] = useState(false);
  const [emailSettings, setEmailSettings] = useState(null);
  const [emailRecipients, setEmailRecipients] = useState("");
  const [pdfOrgData, setPdfOrgData] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newAttachments, setNewAttachments] = useState([]);

  const [form] = Form.useForm();

  //onlinePayments Checkbox Toggle Settings
  const [includePaymentLinkChecked, setIncludePaymentLinkCheckedChecked] =
    useState(false);
  const [useDefaultDeposit, setUseDefaultDeposit] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const toggleChecked = () => {
    setIncludePaymentLinkCheckedChecked(!includePaymentLinkChecked);
  };
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onlinePaymentsChecked = (e) => {
    setIncludePaymentLinkCheckedChecked(e.target.checked);
    toggleDisable();
  };

  const handleAddEmail = (e) => {
    console.log(e);
    const newRecipients = emailRecipients + `,${e}`;
    setEmailRecipients(newRecipients);
    form.setFieldsValue({
      recipients: newRecipients,
    });
  };

  const balanceRemaining =
    Math.round(modal.data?.invoice?.balanceRemaining * 100) / 100;
  const stripeAccount = modal.data?.orgData?.stripeAccount;
  const justifiAccount = modal.data?.orgData?.justifiSubAccount;
  const [paymentAmt, setPaymentAmt] = useState(0);

  useEffect(() => {
    if (!orgData) return;

    setLoading(true);

    setPaymentAmt(balanceRemaining);
    emailSettingsQuery({ firestore }, fsOrgPrefix)
      .fetchEmailSettings(orgData.id, modal.data?.settingsId)
      .then(async (settings) => {
        let pdfEmailSettings = settings;
        let pdfOrgData = { ...orgData };

        // **
        // ***
        // ****
        // PSR SPECIFIC
        // We do some custom work for PSR. They have three different entities.
        // PSR, Mad Herbalist, Tanglewood. The PSR is the default email settings,
        // but MH and Tangle have their own custom email settings documents.
        // Additionally, we will want to update the logo, company name,
        if (
          orgData.id === psr &&
          (modal.data?.invoice?.receiveVenueQueryHook === madHerbalistVenueId ||
            modal.data?.invoice?.receiveVenueQueryHook === tanglewoodVenueId)
        ) {
          let venueSettingsId;
          if (modal.data?.invoice?.type === "invoice") {
            if (
              modal.data?.invoice?.receiveVenueQueryHook === madHerbalistVenueId
            ) {
              venueSettingsId = "madHerbalistInvoiceSettings";
            } else {
              venueSettingsId = "tanglewoodInvoiceSettings";
            }
          } else if (modal.data?.invoice?.type === "estimate") {
            if (
              modal.data?.invoice?.receiveVenueQueryHook === madHerbalistVenueId
            ) {
              venueSettingsId = "madHerbalistEstimateSettings";
            } else {
              venueSettingsId = "tanglewoodEstimateSettings";
            }
          }

          const emailSettingsSnap = await firestore
            .collection("orgs")
            .doc(orgData.id)
            .collection("settings")
            .doc(venueSettingsId ?? "invoiceEmailSettings")
            .get();

          pdfEmailSettings = emailSettingsSnap.data();

          if (
            modal.data?.invoice?.receiveVenueQueryHook === madHerbalistVenueId
          ) {
            // Mad Herbalist
            pdfOrgData.logoUrl = madherblogo;
          } else {
            // Tanglewood
            pdfOrgData.logoUrl = tanglewoodlogo;
          }
        }
        // END PSR Specific
        // >>>>>>>>>>>>>>>>

        setPdfOrgData(pdfOrgData);

        setLoading(false);
        if (pdfEmailSettings) {
          setEmailSettings(pdfEmailSettings);
          setEmailRecipients(customerEmail ?? null);
          form.setFieldsValue({
            recipients: customerEmail ?? null,
            emailBody: pdfEmailSettings?.emailBody,
            emailSubject: pdfEmailSettings?.emailSubject,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    customerEmail,
    firestore,
    form,
    fsOrgPrefix,
    modal.data?.settingsId,
    orgData?.id,
  ]);

  const handleAddAttachment = (value) => {
    if (!value) {
      return;
    }
    if (selectedFiles.includes(value)) {
      return;
    }
    const newFiles = [...selectedFiles, value];
    setSelectedFiles(newFiles);
    setSelectedOption(null);
  };
  const handleRemoveAttachment = (removedFileId) => {
    const newFiles = selectedFiles.filter((tagId) => tagId !== removedFileId);
    setSelectedFiles(newFiles);
  };
  
  const handleUpload = async () => {
    const storageRef = firebase.storage().ref();
    const firestoreRefs = []; // Array to store Firestore document references
  
    const uploadBlob = async (file) => {
      const { key, name = '', size = 0, type = 'doc' } = fileData(file);
      console.log(key, name, size);
  
      const response = await fetch(file.preview);
      const blob = await response.blob();
      const fileRef = storageRef.child(`filemanager/${orgData.id}/${file.name}`);
      const snapshot = await fileRef.put(blob);
      console.log("fileRef", fileRef)
      const downloadURL = await snapshot.ref.getDownloadURL();
  
      // Create a Firestore document for each file after it's uploaded
      const firestoreRef = firebase.firestore().collection("orgs").doc(orgData.id).collection("fileManager").doc();
  
      const newDoc = {
        id: firestoreRef.id,
        name: name,
        size,
        type,
        url: downloadURL,
        tags: [],
        isFavorited: false,
        shared: null,
        createdAt: new Date(),
        modifiedAt: new Date(),
      }
  
      await firestoreRef.set(newDoc);
      console.log("Document written for file with ID: ", firestoreRef.id);
  
      firestoreRefs.push(firestoreRef.id); // Store the reference
    };
  
    await Promise.all(newAttachments.map(fileObj => {
      if (!(fileObj instanceof File)) return null;
      return uploadBlob(fileObj);
    }))
    .then(() => {
      console.info('All files uploaded and documents created');
    })
    .catch(error => {
      console.error("Error uploading files or adding document: ", error);
    });
  
    return firestoreRefs; // Return the array of Firestore document references
  };
  
  return (
    <Modal
      title={`${
        modal.data?.sendType === "signatureRequest"
          ? "Send Signature Request"
          : modal.data?.invoice?.type === INVOICE
          ? "Send Invoice"
          : "Send Estimate"
      }`}
      open={modal.visible}
      destroyOnClose
      width={"60vw"}
      cancelButtonProps={{ disabled: modal.loading }}
      onCancel={() => {
        if (modal.data?.isNew) {
          history.push(`${ROUTE_EDIT_INVOICE}${modal.data?.invoice?.id}`);
        } else {
          setSelectedFiles([]);
          modal.hide();
        }
      }}
      onOk={async () => {
        // validate, hit cf then hide
        form.validateFields().then((fields) => {
          modal.ok(async () => {

            let attachmentIds = selectedFiles;

            // First check if there are attachments that need to be uploaded.
            if (newAttachments.length > 0) {
              // If there are, upload them.
              const newAttachmentIds = await handleUpload();
              console.log("newAttachmentIds", newAttachmentIds);
              attachmentIds = [...attachmentIds, ...newAttachmentIds];
            } 

            const replyToEmail = orgData?.useAdminEmailReply
              ? orgData?.adminEmail
              : userData?.email ?? null;
            const name = `${userData?.firstName} ${userData?.lastName}`;
            const sendRecordPayload = {
              createdOn: new Date(),
              createdBy: userData.id,
              paymentAmt: includePaymentLinkChecked
                ? dollarToCents(paymentAmt)
                : dollarToCents(balanceRemaining),
              sendPaymentLink: includePaymentLinkChecked,
              attachments: attachmentIds,
            };

            const ref = firestore.collection(`${fsOrgPrefix}${INVOICES_COLLECTION}/${modal.data?.invoice?.id}/${SEND_RECORDS}`).doc();
            await ref.set({
              id: ref.id,
              ...sendRecordPayload,
            });

            try {
              await emailInvoice(
                { firebase },
                {
                  sendType: modal.data?.sendType,
                  settingsId: modal.data?.settingsId,
                  orgId: orgData?.id,
                  invoiceId: modal.data?.invoice?.id,
                  emails: fields.recipients
                    .toString()
                    .trim()
                    .split(",")
                    .map((e) => e.trim()),
                  subjectLine: fields.emailSubject,
                  emailBody: fields.emailBody,
                  replyTo: {
                    email: replyToEmail,
                    name,
                  },
                  sendPaymentLink: includePaymentLinkChecked,
                  sendRecordId: ref.id,
                }
              );
              notificationConfirm(
                modal.data?.invoice?.type === INVOICE
                  ? "Invoice sent"
                  : "Estimate sent"
              );
              history.push(ROUTE_INVOICES);
            } catch (err) {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Please try again later"
              );
            }
          });
        });
      }}
      okText={"Send"}
      confirmLoading={modal?.loading}
    >
      <Row wrap={false} gutter={14}>
        <Col span={8} className="send-invoice-email-fields">
          <Form form={form} layout={"vertical"}>
            <Form.Item
              label={
                <span>
                  Send to: {customer?.customerDisplayName}. <br /> You can CC
                  more emails by comma separation, or select from customers
                  additional contacts.
                </span>
              }
              name={"recipients"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
              ]}
            >
              <Input placeholder={"email"} value={emailRecipients} />
            </Form.Item>

            {customer &&
              customer.additionalContacts &&
              customer.additionalContacts.length > 0 && (
                <>
                  <Space size={4} align="center">
                    <UserAddOutlined
                      style={{
                        marginRight: "4px",
                        color: COLOR_TEXT_GRAY_1,
                        fontSize: "18px",
                      }}
                    />
                    <Form.Item
                      name="additionalContacts"
                      className="NewInvoiceCustomerForm"
                    >
                      <Select
                        menuItemSelectedIcon=<Check fontSize="10px" />
                        loading={loading}
                        onSelect={handleAddEmail}
                        placeholder={"Select contact"}
                        className={"customer-picker"}
                      >
                        {customer &&
                          customer.additionalContacts &&
                          customer.additionalContacts
                            .filter((c) => c.email)
                            .map((c) => (
                              <Option value={c.email} key={c.id}>
                                {c.email}
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Space>
                  <br />
                </>
              )}

            <Form.Item
              label={"Email subject"}
              name={"emailSubject"}
              rules={[
                {
                  required: true,
                  message: "Subject required",
                },
              ]}
            >
              <Input placeholder={"Subject"} />
            </Form.Item>
            <Form.Item label={"Email body"} name={"emailBody"}>
              <ReactQuill
                name={"emailBody"}
                modules={{
                  toolbar: [["bold", "italic", "underline"], ["link"]],
                }}
                placeholder="Email Body"
                theme="snow"
                style={{
                  height: "100px",
                  marginBottom: "24px",
                }}
              />
            </Form.Item>
            <br />
            <Form.Item
              label={"Online Payments"}
              name={"onlinePayments"}
              style={
                modal.data?.sendType === "signatureRequest" ||
                (stripeAccount === null && justifiAccount === null)
                  ? { display: "none" }
                  : { display: "contents" }
              }
            >
              <Checkbox
                onChange={onlinePaymentsChecked}
                defaultChecked={false}
                checked={includePaymentLinkChecked}
              >
                Include a payment link with this email?{" "}
              </Checkbox>
              <br />
              <br />

              <InputNumber
                value={paymentAmt}
                addonBefore="Payment Amount"
                onChange={setPaymentAmt}
                defaultValue={
                  Math.round(modal.data?.invoice?.balanceRemaining * 100) / 100
                }
                disabled={disabled}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
              <br />
              <br />

              {!disabled && (
                <Checkbox
                  onChange={(e) => {
                    console.log(e.target.checked);
                    const checked = e.target.checked;
                    if (checked) {
                      const depositAmount = (
                        balanceRemaining *
                        (orgData.defaultDepositPercent / 100)
                      ).toFixed(2);
                      setPaymentAmt(depositAmount);
                    } else {
                      setPaymentAmt(balanceRemaining);
                    }
                  }}
                  defaultChecked={false}
                >
                  Apply default security deposit amount
                  {`: ${orgData.defaultDepositPercent}%`}
                </Checkbox>
              )}

              {modal.data?.invoice?.type === INVOICE_ESTIMATE &&
                !orgData.automaticallyConvertEstimate && (
                  <div>
                    <br></br>
                    Note: Estimates will not automatically convert to Invoices
                    until payments are successful. ACH payments may take several
                    days, which means the inventory will not be considered
                    rented until the payment is finalized.
                  </div>
                )}
            </Form.Item>

            <div>
              <br></br>

              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>
                  <Tooltip
                    title={`Emails have a 10MB attachment limit. Priority is given
                to the Invoice PDF, then attachments. If the total size of the
                attachments exceeds 10MB, the remaining attachments will be
                added to the email as links that can be viewed and downloaded
                from the browser by the customer. Do not add any attachment that
                you would not want generally available to the public`}
                  >
                    <QuestionCircleOutlined
                      style={{ color: COLOR_TEXT_GRAY_1 }}
                    />{" "}
                  </Tooltip>
                  You can add attachments to this email from your saved files in
                  the File Manager, or add a new file by clicking the "Upload"
                  button.
                </Typography>
              </Row>

              <br />

              <Select
                value={selectedOption}
                style={{ width: "100%" }}
                placeholder="Add attachment..."
                onSelect={handleAddAttachment}
                optionLabelProp="label"
              >
                {files &&
                  files
                    .filter((t) => !selectedFiles.includes(t.id))
                    .map((tag) => (
                      <Option value={tag.id} label={tag.name} key={tag.id}>
                        <div className="demo-option-label-item">{tag.name}</div>
                      </Option>
                    ))}
              </Select>

              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                {selectedFiles &&
                  selectedFiles.length > 0 &&
                  selectedFiles.map((fileId) => {
                    const file = files.find((file) => file.id === fileId);
                    if (file) {
                      return (
                        <Tag
                          // color={tag.color}
                          key={file.id}
                          closable
                          onClose={() => handleRemoveAttachment(fileId)}
                        >
                          {file.name}
                        </Tag>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>

              <FileManagerNewFileBox onNewAttachments={setNewAttachments} />
            </div>
          </Form>
        </Col>

        <Col
          span={16}
          style={{ paddingLeft: "12px" }}
          className="invoice-pdf-preview"
        >
          <Card
            size={"small"}
            style={{
              overflowY: "scroll",
              height: "70vh",
              width: "100%",
              boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
            }}
          >
            {emailSettings && modal.data?.invoice && pdfOrgData && (
              <PDFViewer
                showToolbar={true}
                style={{
                  position: "absolute",
                  border: 0,
                  height: "100%",
                  width: "100%",
                }}
              >
                <InvoicePDF
                  invoice={modal.data.invoice}
                  orgData={pdfOrgData}
                  venues={venues}
                  emailSettings={emailSettings}
                  customText={invoiceTextData}
                  i18n={i18n}
                  logoData={logoData}
                  itemImages={itemImages}
                  signatureData={signatureData}
                />
              </PDFViewer>
            )}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendInvoiceModal;
