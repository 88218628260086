import React from "react";
import { List } from "antd";
import { renderStatusIcon } from "../helpers/renderStatusIcon";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import {
  COLOR_GREEN_0,
  COLOR_PURPLE_0,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import ItemCompletedTracker from "./ItemCompletedTracker";
import { sortBy } from "../../../app/utils/sorters/sortBy";
import CategorizedItem from "./CategorizedItem";

const InvoiceItemList = ({
  selectedInvoice,
  refreshInvoiceData,
  dailyAvailable,
}) => {

  const configureItems = () => {
    
    if (!selectedInvoice) return [];
    let completed = [];
    let hasIssues = [];
    let inProgress = [];

    const items = sortBy(selectedInvoice.qrItems, "name");

    items.map((i) => {
      if (i.isBundleChild) {
        // if bundle child, categorize based on parent
        const parent = items.find((item) => item.id === i.bundleParentId);
        if (parent) {
          if (parent.qtyRented === parent.completed && parent.completed !== 0) {
            completed.push(i);
          } else if (parent.hasIssue) {
            hasIssues.push(i);
          } else {
            inProgress.push(i);
          }
        }
      } else if (i.qtyRented === i.completed && i.completed !== 0) {
        completed.push(i);
      } else if (i.hasIssue) {
        hasIssues.push(i);
      } else {
        inProgress.push(i);
      }
    });
    
    return [
      {
        status: (
          <TextBody
            text={`Flagged (${
              hasIssues.filter(
                (i) => i.hasIssue && i.bundleChildrenIds.length === 0
              ).length
            })`}
            style={{ color: COLOR_RED_0 }}
          />
        ),
        type: "flag",
        description: "Items that have been flagged",
        list: hasIssues,
      },
      {
        status: (
          <TextBody
            text={`In Progress (${
              inProgress.filter((i) => !i.isBundleChild).length
            })`}
            style={{ color: COLOR_YELLOW_0 }}
          />
        ),
        type: "inProgress",
        description: "Items not completed",
        list: inProgress,
      },
      {
        status: (
          <TextBody
            text={`Completed (${
              completed.filter((i) => !i.isBundleChild).length
            })`}
            style={{ color: COLOR_GREEN_0 }}
          />
        ),
        description: "Completed items",
        type: "complete",
        list: completed,
      },
    ];
  };

  return (
    <List
      style={{ margin: "12px" }}
      itemLayout="horizontal"
      dataSource={selectedInvoice && configureItems()}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <TextBody text={item.status} style={{ fontWeight: "regular" }} />
            }
            description={
              item.list.length !== 0 && (
                <CategorizedItem
                  dailyAvailable={dailyAvailable}
                  items={item.list}
                  invoice={selectedInvoice}
                  refreshInvoiceData={refreshInvoiceData}
                  type={item.type}
                />
              )
            }
          />
        </List.Item>
      )}
    />
  );
};

export default InvoiceItemList;
