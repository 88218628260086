export const UpdateChildrenIfParentComplete = ({
  invoice,
  payload,
  ratio,
  item,
}) => {
  return invoice.qrItems.map((i) => {
    if (i.id === item.id && i.isBundleChild === item.isBundleChild)
      return payload;
    if (item.bundleChildrenIds.includes(i.id) && i.isBundleChild) {
      const { completed, qtyRented, ...childRest } = i;
      return {
        completed: qtyRented * ratio,
        qtyRented: qtyRented,
        ...childRest,
      };
    }
    return i;
  });
};
