import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import AdminInventory from "./AdminInventory";

import { authSelector } from "../auth/authSlice";

// Lazy load the components
const AdminParts = lazy(() => import("./AdminParts"));
const CalendarView = lazy(() => import("./components/calendar/view/calendar-view"));

const InventoryContainer = ({ history }) => {

  const { orgData } = useSelector(authSelector);

  const [tabType, setTabType] = useState(() => {
    // Load from local storage or default to "inventory"
    return localStorage.getItem("adminInventoryTab") || "inventory";
  });
  // Tab change handler

  // Save the selected tab to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("adminInventoryTab", tabType);
  }, [tabType]);

  const handleTabChange = (key) => {
    setTabType(key);
  };


  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Settings" />
      </Affix>
      <div style={{ paddingTop: "68px" }}>
        <div style={{ width: "100%", marginLeft: "14px" }}>
          <Tabs defaultActiveKey="inventory" activeKey={tabType} onChange={handleTabChange}>
            <TabPane tab="Inventory" key="inventory" />

            {orgData && orgData.allowInventoryMaintenance && (
              <>
                <TabPane tab="Maintenance Parts" key="parts" />
                {/* <TabPane tab="Service Items" key="services" /> */}
                <TabPane tab="Maintenance Calendar" key="calendar" />
              </>
            )}
          </Tabs>
        </div>

        {tabType === "inventory" && <AdminInventory history={history} />}

        {tabType === "parts" && (
          <Suspense fallback={<div>Loading Parts...</div>}>
            <AdminParts history={history} />
          </Suspense>
        )}

        {tabType === "calendar" && (
          <Suspense fallback={<div>Loading Calendar...</div>}>
            <CalendarView />
          </Suspense>
        )}

        {/* {tabType === "services" && (< AdminServiceItems history={history}/>)} */}
      </div>
    </div>
  );
};

export default InventoryContainer;
