export const SortInvoicesByDueDate = (data) => {
  let hasDueDate = [];
  let noDueDate = [];
  data &&
    data.map((i) => {
      if (i.invoicePaymentDueDate) {
        hasDueDate.push(i);
      } else {
        noDueDate.push(i);
      }
    });
  return hasDueDate.concat(noDueDate);
};
