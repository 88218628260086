import { INV_ITEM_RATE_NAME } from "../../../../../app/utils/models/modelConstants/modelConstants";

export const saveEditedCells = (props, item, handleSave, rentalRange) => {
  const { availableItems, values, i } = props;

  const saveItemSelected = () => {
    console.log("saving edited cell");
    const propsId = props?.values?.selectedItemId ?? "";
    const selected = availableItems.find((i) => i.id === values.selectedItemId);
    // if (propsId === selected?.id) return;
    const {
      key,
      editable,
      selectedDuration,
      selectedQty,
      id,
      selectedDescription,
    } = item;
    const hasRate = !!selected?.rates?.[0];
    item = {
      id: selected.id,
      editable: editable,
      key: key,
      selectedRate: selected?.rates?.[0]
        ? selected.rates[0]?.rateName
        : "Select rate",
      selectedRateMeta: selected?.rates?.[0] ? selected.rates[0] : null,
      selectedTaxable: selected.isTaxable,
      selectedDuration: 1,
      selectedQty: 1,
      selectedDescription: selected.description,
      ...selected,
    };
    handleSave({ ...item, ...values });
  };
  const saveSelectedDescription = () => {
    const { selectedDescription, ...rest } = item;
    const desc = i !== "" ? i : item.description;
    handleSave({ selectedDescription: desc, ...rest, ...values });
  };
  const saveSelectedQty = () => {
    const { selectedQty, ...rest } = item;
    handleSave({ selectedQty: i, ...rest, ...values });
  };
  const saveSelectedRate = () => {
    const { selectedRateMeta, selectedRate, ...rest } = item;
    const selectedMeta = item.rates.find((r) => r[INV_ITEM_RATE_NAME] === i);
    handleSave({
      selectedRateMeta: selectedMeta,
      selectedRate: i,
      ...rest,
      ...values,
    });
  };
  const saveSelectedDuration = () => {
    const isSaleItem = item.type && item.type === "sale";
    const { selectedDuration, ...rest } = item;
    handleSave({
      ...rest,
      ...values,
      selectedDuration: isSaleItem ? 1 : parseInt(i.target.value),
    });
  };
  const saveCustomRate = () => {
    const { selectedRateMeta, selectedRate, rates, ...rest } = item;

    let newRates = rates ? [...rates] : [];
    newRates.push(i);
    handleSave({
      ...rest,
      ...values,
      selectedRateMeta: i,
      selectedRate: i[INV_ITEM_RATE_NAME],
      rates: newRates,
    });
  };
  return {
    saveItemSelected: saveItemSelected,
    saveSelectedQty: saveSelectedQty,
    saveSelectedRate: saveSelectedRate,
    saveSelectedDuration: saveSelectedDuration,
    saveCustomRate: saveCustomRate,
    saveSelectedDescription: saveSelectedDescription,
  };
};
