/**
 * localizes js date and returns firestore date
 */
import moment from "moment-timezone";
import { TimeMachine } from "./timeMachine";

export const formatDateOnWrite = ({ date, orgTimezone }) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dt = TimeMachine(date).moment;
  const newDate = dt?.toDate() ?? null;
  const formMoment = TimeMachine(newDate).moment;

  const orgOffset = moment.tz.zone(orgTimezone).utcOffset(formMoment);
  const localOffset = moment.tz.zone(localTimezone).utcOffset(formMoment);
  const offset = orgOffset - localOffset;

  const adjustedTime = moment(formMoment).add(offset, "minutes");
  return TimeMachine(adjustedTime).date;
};
export const formatDateOnRead = ({ date, orgTimezone }) => {
  if (!date || !orgTimezone) return null;
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dt = TimeMachine(date).moment;
  const newDate = dt?.toDate() ?? null;
  const formMoment = TimeMachine(newDate).moment;
  const orgOffset = moment.tz.zone(orgTimezone).utcOffset(formMoment);
  const localOffset = moment.tz.zone(localTimezone).utcOffset(formMoment);
  const offset = localOffset - orgOffset;
  const adjustedTime = moment(formMoment).add(offset, "minutes");
  return TimeMachine(adjustedTime).firestoreDate;
};
