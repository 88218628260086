import algoliasearch from "algoliasearch/lite";

const config = require(`./${process.env.REACT_APP_ENVIRONMENT}`);
const algolia_inventory = config.algolia_inventory;

export const inventorySearchClient = algoliasearch(
  algolia_inventory.appid,
  algolia_inventory.searchKey,
  {
    // // Caches responses from Algolia
    // responsesCache: createNullCache(), // or createNullCache()
    //
    // // Caches Promises with the same request payload
    // requestsCache: createInMemoryCache({ serializable: false }), // or createNullCache()
  }
);
export const algoliaInventoryIndex = inventorySearchClient.initIndex(
  algolia_inventory.index
);
