import React, { useEffect, useState } from "react";
import ItemCompletedTracker from "./ItemCompletedTracker";
import {
  COLOR_BLUE_0,
  COLOR_LIGHT_BLUE,
  COLOR_TEXT_GRAY_2,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";

const BundleCategorizedItem = ({
  items,
  parentId,
  invoice,
  refreshInvoiceData,
  dailyAvailable,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const children =
      items &&
      items.filter((i) => i.bundleParentId && i.bundleParentId === parentId);
    setData(children);
  }, [items, parentId]);
  return (
    <div
      style={{
        marginTop: "12px",
        marginLeft: "8px",
        padding: "12px 12px 2px 12px",
        backgroundColor: COLOR_LIGHT_BLUE,
        borderRadius: "4px",
      }}
    >
      <TextBody
        text={"Bundle Items"}
        style={{ color: COLOR_BLUE_0, fontWeight: "bold" }}
      />
      <br />
      <br />
      {data.length !== 0
        ? data.map((d) => (
            <div
              style={{
                backgroundColor: "#FFF",
                borderColor: COLOR_TEXT_GRAY_2,
                borderWidth: "1px",
                // borderStyle: "solid",
                padding: "0px 8px 8px 8px",
                borderRadius: "4px",
                marginBottom: "12px",
              }}
            >
              <ItemCompletedTracker
                dailyAvailable={dailyAvailable}
                refreshInvoiceData={refreshInvoiceData}
                key={`${invoice.id}_${d.id}`}
                item={d}
                type={"bundle"}
                invoice={invoice}
              />
            </div>
          ))
        : null}
    </div>
  );
};

export default BundleCategorizedItem;
