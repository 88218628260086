import React, { useContext } from "react";
import { Marker } from "@react-google-maps/api";
import icon from "./icons/home-icon.svg";
import { RouteContext } from "../../NewRoute";

const RouteMarkerHome = () => {
  const { state } = useContext(RouteContext);
  return (
    <Marker
      key={'home'}
      onClick={() => {
        console.log("clicked");
      }}
      // onLoad={onLoad}
      icon={icon}
      position={{ lat: state.coAddr?.startLat, lng: state.coAddr?.startLng }}
    />
  );
};

export default RouteMarkerHome;
