import React, { useContext, useRef } from "react";
import { Button, Modal } from "antd";
import WaypointModalContent from "./WaypointModalContent";
import { WaypointModalContext } from "../../CreateRoutes";

export const WaypointModal = ({ visible, isLoaded }) => {
  const modal = useContext(WaypointModalContext);
  return (
    <Modal
      destroyOnClose
      width={600}
       open={visible}
      closeIcon={<></>}
      title="Add Waypoint"
      footer={false}
    >
      {visible && isLoaded && (
        <WaypointModalContent  open={visible} loaded={isLoaded} />
      )}
    </Modal>
  );
};

export default WaypointModal;
