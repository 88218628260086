export const UpdateQrItemInArray = ({ invoice, item, payload }) => {
  return (
    invoice &&
    invoice.qrItems.map((i) => {
      if (i.id === item.id && i.isBundleChild === item.isBundleChild)
        return payload;
      return i;
    })
  );
};
