import React, { createContext, useEffect, useState } from "react";
import { Affix, Button, Input, PageHeader, Space } from "antd";
import AddCustomerModal from "./components/modals/AddCustomerModal";
import EditCustomerModal from "./components/modals/EditCustomerModal";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { useCustomerSearch } from "../../app/services/hooks/fetch/firestoreSearch/useCustomerSearch";
import CustomerDataGrid from "./components/CustomerDataGrid";
const { Search } = Input;

export const CustomerTableContext = createContext();

const AdminCustomers = () => {
  const { fsOrgPrefix, userData, orgData } = useSelector(authSelector);
  const [customerModal, setCustomerModal] = useState(false);
  const [edit, setEdit] = useState({
    visible: false,
    selected: null,
  });
  const customers = useCustomerSearch();

  useEffect(() => {
    customers.actions.loadDefault();
  }, [fsOrgPrefix]);

  const handleClose = () => {
    setCustomerModal(false);
  };
  const handleEdit = (c) => {
    setEdit({
      visible: true,
      selected: c,
    });
  };
  const handleCloseEdit = () => {
    setEdit({
      visible: false,
      selected: null,
    });
  };
  return (
    <div>
      <CustomerTableContext.Provider value={customers}>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Customers"
            extra={
              <Space size={12}>
                <Search
                  allowClear={true}
                  key="Searchbar"
                  placeholder="Search"
                  onChange={(e) => customers.actions.onSearch(e.target.value)}
                  style={{
                    width: 280,
                    fontWeight: "bold",
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setCustomerModal(true);
                  }}
                >
                  Add Customer
                </Button>
              </Space>
            }
          />
        </Affix>
        <div className="list-margin-top" style={{ marginTop: "68px" }}>
          <CustomerDataGrid
            orgData={orgData}
            data={customers.state.data}
            loading={customers.state.busy}
            handleEdit={handleEdit}
            onDeleteComplete={() => {
              handleClose();
              customers.actions.refreshDefault();
            }}
          />
        </div>
        <AddCustomerModal
          visible={customerModal}
          onCancel={handleClose}
          onComplete={() => {
            handleClose();
            customers.actions.refreshDefault();
          }}
        />
        <EditCustomerModal
          showPaymentOptions
          visible={edit.visible}
          selected={edit.selected}
          onCancel={handleCloseEdit}
          onContactAdded={() => {
            handleCloseEdit();
            customers.actions.refreshDefault();
          }}
          onComplete={() => {
            handleCloseEdit();
            customers.actions.refreshDefault();
          }}
        />
      </CustomerTableContext.Provider>
    </div>
  );
};

export default AdminCustomers;
