import React, { useContext, useEffect, useRef, useState } from "react";
import {
  LoadScript,
  LoadScriptNext,
  StandaloneSearchBox,
  useLoadScript,
} from "@react-google-maps/api";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  TimePicker,
} from "antd";
import { RouteContext } from "../../../NewRoute";
import { STOP_TYPE_WAYPOINT } from "../../../../../app/utils/models/modelConstants/modelConstants";
import notificationError from "../../../../../app/system-components/toasters/notificationError";
import notificationConfirm from "../../../../../app/system-components/toasters/notificationConfirm";
import { WaypointModalContext } from "../../CreateRoutes";
import moment from "moment";
const WaypointModalContent = () => {
  const { state, actions } = useContext(RouteContext);
  const modal = useContext(WaypointModalContext);
  let searchBox = useRef();
  const [form] = Form.useForm();
  const [str, setStr] = useState("");
  const [latLng, setLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [timeValue, setTimeValue] = useState(null);

  const onTimeChange = (c) => {
    setTimeValue(c);
  };

  useEffect(() => {
    if (!modal.data || !modal.visible) return;
    const values = modal.data;
    setStr(values?.address ?? "");
    setLatLng({
      lat: values?.lat ?? 0,
      lng: values?.lng ?? 0,
    });
    form.setFieldsValue({
      title: values?.waypointMeta?.title,
      description: values?.waypointMeta?.description,
      phone: values?.waypointMeta?.phone,
      time: values?.waypointMeta?.time
        ? new moment(values?.waypointMeta?.time)
        : null,
      type: values?.waypointMeta?.type,
    });
  }, [modal.data]);
  const validateAndCreate = async () => {
    const values = await form.validateFields();
    if (str === "") return notificationError("Valid address required");
    const payload = {
      type: STOP_TYPE_WAYPOINT,
      address: str,
      addrValidated: true,
      lat: latLng.lat,
      lng: latLng.lng,
      invoiceId: `WAYPOINT_CREATED_${new Date().getTime()}`,
      displayName: values.title,
      waypointMeta: {
        title: values.title,
        description: values.description,
        phone: values?.phone ?? null,
        time: values?.time ? new moment(values?.time).toDate() : null,
        type: values?.type ?? "default",
      },
    };
    actions.createWaypoint({
      payload,
    });
    modal.hide();
    notificationConfirm("Waypoint created");
  };
  const validateAndUpdate = async () => {
    const values = await form.validateFields();
    if (str === "") return notificationError("Valid address required");
    const payload = {
      type: STOP_TYPE_WAYPOINT,
      address: str,
      addrValidated: true,
      lat: latLng.lat,
      lng: latLng.lng,
      displayName: values.title,
      waypointMeta: {
        title: values.title,
        description: values.description,
        phone: values?.phone ?? null,
        time: values?.time ? new moment(values?.time).toDate() : null,
        type: values?.type ?? "default",
      },
    };
    actions.updateWaypoint({
      waypoint: modal.data,
      payload,
    });
    modal.hide();
    notificationConfirm("Waypoint updated");
  };

  return (
    <div>
      <div>
        <LoadScriptNext
          googleMapsApiKey={"AIzaSyCk8zR40fhVRwZjl7TrXS1XyS04q40xapw"}
          libraries={["places", "geometry"]}
        >
          <StandaloneSearchBox
            ref={searchBox}
            onLoad={(search) => console.log(search, searchBox)}
            onPlacesChanged={() => {
              const placesData =
                searchBox?.current?.state?.searchBox?.getPlaces() ?? null;
              const formattedAddr = placesData?.[0]?.formatted_address;
              setStr(formattedAddr);
              setLatLng({
                lat: placesData?.[0]?.geometry?.location?.lat(),
                lng: placesData?.[0]?.geometry?.location?.lng(),
              });
            }}
          >
            <Input.Search
              value={str}
              disabled={modal.data}
              onChange={(e) => setStr(e.target.value)}
              placeholder={"Search"}
            />
          </StandaloneSearchBox>
        </LoadScriptNext>
        <Divider />
        <Form layout="vertical" form={form}>
          <Form.Item
            label={"Title"}
            id={"title"}
            name="title"
            style={{ marginBottom: "12px" }}
            rules={[
              {
                required: true,
                message: "Title required",
              },
            ]}
          >
            <Input placeholder={"Title"} />
          </Form.Item>
          <Form.Item
            label={"Description"}
            id={"description"}
            name="description"
            style={{ marginBottom: "12px" }}
            rules={[
              {
                required: true,
                message: "Description required",
              },
            ]}
          >
            <Input value={str} placeholder={"Description"} />
          </Form.Item>
          <Space>
            <Form.Item
              label={"Phone"}
              id={"phone"}
              name="phone"
              style={{ marginBottom: "12px" }}
            >
              <InputNumber placeholder={"Phone"} style={{ width: "150px" }} />
            </Form.Item>
            <Form.Item
              label={"Time"}
              id={"time"}
              name="time"
              style={{ marginBottom: "12px" }}
            >
              <TimePicker
                style={{ width: "150px" }}
                use12Hours
                format="h:mm a"
                value={timeValue}
                onChange={onTimeChange}
              />
            </Form.Item>
            <Form.Item
              initialValue={"default"}
              label={"Icon"}
              id={"type"}
              name="type"
              style={{ marginBottom: "12px" }}
            >
              <Select
                placeholder={"Icon"}
                defaultValue={"default"}
                style={{ width: "150px" }}
              >
                <Select.Option value={"default"}>Waypoint</Select.Option>
              </Select>
            </Form.Item>
          </Space>
        </Form>
      </div>
      <Divider />
      <div style={{ paddingBottom: "32px" }}>
        <Space style={{ float: "right" }} direction={"horizontal"}>
          <Button onClick={() => modal.hide()}>Cancel</Button>
          {!modal.data ? (
            <Button
              type={"primary"}
              onClick={() => {
                validateAndCreate().then(() => {
                  modal.ok(() => {
                    // save to firestore
                  });
                });
              }}
            >
              Add Waypoint
            </Button>
          ) : (
            <Button
              type={"primary"}
              onClick={() => {
                validateAndUpdate().then(() => {
                  modal.ok(() => {
                    // save to firestore
                  });
                });
              }}
            >
              Save Waypoint
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export default WaypointModalContent;
