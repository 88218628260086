import { notification } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { COLOR_GREEN_0 } from "../../ui/colorConstants";

const notificationConfirm = (msg, desc) => {
  notification.info({
    icon: <CheckCircleFilled style={{ color: COLOR_GREEN_0 }} />,
    message: `${msg}`,
    description: desc ? `${desc}` : "",
    placement: "bottomLeft",
    duration: 4,
  });
};

export default notificationConfirm;
