import React from "react";
import { sortableContainer } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const DraggableContainer = ({ selectedItems, setSelectedItems, props }) => {
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const data = selectedItems.items;
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const sortedNew = newData.map((i, ind) => {
        const { key, ...rest } = i;
        return {
          key: ind,
          ...rest,
        };
      });
      setSelectedItems({
        items: sortedNew,
        count: newData.length,
      });
    }
  };
  return (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
};

export default DraggableContainer;
