export const GetStepLatLngs = (stepData) => {
  let path = [];
  stepData?.map((step, index) => {
    if (index === 0) {
      path.push({
        lat: step?.start_location?.lat,
        lng: step?.start_location?.lng,
      });
      path.push({
        lat: step?.end_location?.lat,
        lng: step?.end_location?.lng,
      });
    } else {
      path.push({
        lat: step?.end_location?.lat,
        lng: step?.end_location?.lng,
      });
    }
  });
  return path;
};
