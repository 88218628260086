import { useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import InvoiceItems from "../InvoiceItems";
import InvoiceContextMenu from "./InvoiceContextMenu";
import { ROW_TYPE_SUBTOTAL } from "../../../../app/utils/models/modelConstants/modelConstants";
import { configureNewSingleItem } from "../../../../app/utils/models/configure/invoiceTable/configureNewSingleItem";

const InvoiceContextMenuWrapper = ({
  isSalesInvoice,
  availableItems,
  selectedItems,
  setSelectedItems,
  invoiceTotal,
  setInvoiceTotal,
  rentalRange,
  setToggleNewInvModal,
  currentInvoice,
  customer,
  invoiceTaxRate,
  setInvoiceTaxRate,
  setDeliveryFee,
  deliveryFee,
  deliveryFeeTaxable,
  setDeliveryFeeTaxable,
  getShippingFee,
  editing,
  setEditing,
  loadingAllInventory,
}) => {
  const { userData, orgData } = useSelector(authSelector);
  const [contextMenu, setContextMenu] = useState({
    item: null,
    visible: false,
    x: 0,
    y: 0,
  });

  const configureMenuOpts = (context) => {
    setContextMenu({
      item: context.item,
      visible: context.visible,
      x: context.x,
      y: context.y,
    });
  };
  const handleRemove = () => {
    // remove
    const items = [...selectedItems.items];
    const newItems = items
      .filter((i) => i.key !== contextMenu.item.key)
      .map((i, index) => {
        const { key, ...rest } = i;
        return {
          key: index,
          ...rest,
        };
      });
    setSelectedItems({
      items: newItems,
      count: newItems.length,
    });
  };
  const handleAddItemBelow = () => {
    // add item below
    const newItem = configureNewSingleItem(selectedItems);
    const newItems = [...selectedItems.items];
    newItems.splice(contextMenu.item.key + 1, 0, newItem);
    const updated = newItems.map((i, index) => {
      const { key, ...rest } = i;
      return {
        key: index,
        ...rest,
      };
    });
    setSelectedItems({
      items: updated,
      count: selectedItems.count + 1,
    });
  };
  const handleAddSubtotalBelow = () => {
    // add subtotal
    const newItem = {
      key: contextMenu.item.key + 1,
      rowType: ROW_TYPE_SUBTOTAL,
      rowTotal: 0,
    };
    const newItems = [...selectedItems.items];
    newItems.splice(contextMenu.item.key + 1, 0, newItem);
    const updated = newItems.map((i, index) => {
      const { key, ...rest } = i;
      return {
        key: index,
        ...rest,
      };
    });
    setSelectedItems({
      items: updated,
      count: selectedItems.count + 1,
    });
  };

  return (
    <div>
      {userData && orgData && (
        <InvoiceItems
          isSalesInvoice={isSalesInvoice}
          availableItems={availableItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          invoiceTotal={invoiceTotal}
          setInvoiceTotal={setInvoiceTotal}
          setContextMenu={configureMenuOpts}
          contextMenu={contextMenu}
          rentalRange={rentalRange}
          setToggleNewInvModal={setToggleNewInvModal}
          customer={customer}
          currentInvoice={currentInvoice}
          invoiceTaxRate={invoiceTaxRate}
          setInvoiceTaxRate={setInvoiceTaxRate}
          deliveryFee={deliveryFee}
          setDeliveryFee={setDeliveryFee}
          setDeliveryFeeTaxable={setDeliveryFeeTaxable}
          deliveryFeeTaxable={deliveryFeeTaxable}
          orgData={orgData}
          getShippingFee={getShippingFee}
          editing={editing}
          setEditing={setEditing}
          loadingAllInventory={loadingAllInventory}
        />
      )}
      <InvoiceContextMenu
        item={contextMenu.item}
        visible={contextMenu.visible}
        x={contextMenu.x}
        y={contextMenu.y}
        handleRemove={handleRemove}
        handleAddItemBelow={handleAddItemBelow}
        handleAddSubtotalBelow={handleAddSubtotalBelow}
      />
    </div>
  );
};

export default InvoiceContextMenuWrapper;
