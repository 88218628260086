import React, { useEffect, useState } from "react";
import "firebase/firestore";
import { useSelector } from "react-redux";
import { authSelector } from "../../domains/auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { OneOffContext } from "./OneOffContext";
import { convertImageToBase64, convertPngToJpeg, convertWebPToJpegAndBase64 } from "../utils/image-converter";

// PSR SPECIFIC CONSTANTS *******
const psr = process.env.REACT_APP_PSR_ID;
const tanglewoodlogo = process.env.REACT_APP_TANGLEWOOD_LOGO;
const madherblogo = process.env.REACT_APP_MADHERB_LOGO;

const madHerbalistVenueId = process.env.REACT_APP_MADHERBALIST_VENUE;
const tanglewoodVenueId = process.env.REACT_APP_TANGLEWOOD_VENUE;
// END PSR SPECIFIC CONSTANTS *******

export function OneOffProvider({ children }: { children: React.ReactNode }) {
  const firestore = useFirestore();
  const { orgData } = useSelector(authSelector);

  const [madHerbalistInvoiceSettings, setmadHerbalistInvoiceSettings] = useState<any>(null);
  const [tanglewoodInvoiceSettings, settanglewoodInvoiceSettings] = useState<any>(null);

  const [madHerbalistEstimateSettings, setmadHerbalistEstimateSettings] = useState<any>(null);
  const [tanglewoodEstimateSettings, settanglewoodEstimateSettings] = useState<any>(null);

  const [madHerbalistLogo, setmadHerbalistLogo] = useState<string>("");
  const [tanglewoodLogo, settanglewoodLogo] = useState<string>("");

  const getVenueSpecificSettings = async (venueId: string) => {
    console.log("venueId", venueId);
    console.log("madHerbalistVenueId", madHerbalistVenueId);
    console.log("tanglewoodVenueId", tanglewoodVenueId);  
    
    if (venueId === madHerbalistVenueId) {
      return {
        invoiceSettings: madHerbalistInvoiceSettings,
        estimateSettings: madHerbalistEstimateSettings,
        logo: madHerbalistLogo,
      };
    }

    if (venueId === tanglewoodVenueId) {
      return {
        invoiceSettings: tanglewoodInvoiceSettings,
        estimateSettings: tanglewoodEstimateSettings,
        logo: tanglewoodLogo,
      };
    }

    return null;
  };

  const convertLogo = async (logoUrl: string) => {
    let logoString = "";
    if (logoUrl.includes(".webp") || logoUrl.includes(".WEBP")) {
      logoString = await convertWebPToJpegAndBase64(logoUrl);
    } else if (logoUrl.includes(".png") || logoUrl.includes(".PNG")) {
      logoString = await convertPngToJpeg(logoUrl);
    } else {
      logoString = await convertImageToBase64(logoUrl);
    }

    return logoString;
  };

  useEffect(() => {
    if (!orgData) return;
    if (orgData.id !== psr) {
      console.log("Not PSR");
      return;
    }

    const fetchPSRSpecificSettings = async () => {
      // **
      // ***
      // ****
      // PSR SPECIFIC
      // We do some custom work for PSR. They have three different entities.
      // PSR, Mad Herbalist, Tanglewood. The PSR is the default email settings,
      // but MH and Tangle have their own custom email settings documents.
      // Additionally, we will want to update the logo, company name,

      // MAD HERBALIST
      const madHerbalistEstimateSettingsSnap = await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("settings")
        .doc("madHerbalistEstimateSettings")
        .get();

      const madHerbalistInvoiceSettingsSnap = await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("settings")
        .doc("madHerbalistInvoiceSettings")
        .get();

      const tanglewoodEstimateSettingsSnap = await firestore.collection("orgs").doc(orgData.id).collection("settings").doc("tanglewoodEstimateSettings").get();
      const tanglewoodInvoiceSettingsSnap = await firestore.collection("orgs").doc(orgData.id).collection("settings").doc("tanglewoodInvoiceSettings").get();

      const madHerbalistEstimateSettings = madHerbalistEstimateSettingsSnap.data();
      const madHerbalistInvoiceSettings = madHerbalistInvoiceSettingsSnap.data();
      const tanglewoodEstimateSettings = tanglewoodEstimateSettingsSnap.data();
      const tanglewoodInvoiceSettings = tanglewoodInvoiceSettingsSnap.data();

      const madHerbalistLogo = await convertLogo(madherblogo ?? "");
      const tanglewoodLogo = await convertLogo(tanglewoodlogo ?? "");

      setmadHerbalistEstimateSettings(madHerbalistEstimateSettings);
      setmadHerbalistInvoiceSettings(madHerbalistInvoiceSettings);
      settanglewoodEstimateSettings(tanglewoodEstimateSettings);
      settanglewoodInvoiceSettings(tanglewoodInvoiceSettings);

      setmadHerbalistLogo(madHerbalistLogo);
      settanglewoodLogo(tanglewoodLogo);
    };
    // END PSR Specific
    // >>>>>>>>>>>>>>>>

    fetchPSRSpecificSettings();
  }, [firestore, orgData]);

  return (
    <OneOffContext.Provider
      value={{
        getVenueSpecificSettings,
      }}
    >
      {children}
    </OneOffContext.Provider>
  );
}
