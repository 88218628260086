// @mui
import { useState } from 'react';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import { ActivityLogItem } from '../../app/interfaces/activityLogItem';


// ----------------------------------------------------------------------

interface Props {
  title?: string;
  subheader?: string;
  list: ActivityLogItem[];
}

export default function ActivityLogs({ title, subheader, list, ...other }: Props) {
  const [searchText, setSearchText] = useState('');

  const filteredList = list.filter((item) =>
    item.message.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Card  sx={{
      minHeight: "100%",
      height: "100%",
    }} {...other}>
      <CardHeader title={title} subheader={subheader} />

      <div style={{ paddingInline: "16px" }}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="Search"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div style={{ maxHeight: 'calc(100% - 72px)', overflowY: 'auto' }}>

      <List>
        {filteredList.map((item, index) => (
          <ActivityLogRow
            key={item.id}
            item={item}
            lastTimeline={index === filteredList.length - 1}
          />
        ))}
      </List>
      </div>
    </Card>
  );
}

// ----------------------------------------------------------------------

type ActvityLogProps = {
  item: ActivityLogItem;
  lastTimeline: boolean;
};

function ActivityLogRow({ item, lastTimeline }: ActvityLogProps) {
  const { message, sentByName, onCreate } = item;

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography variant="body1">{sentByName}</Typography>

              <Typography variant="caption" sx={{ color: "text.primary" }}>
                {onCreate?.toLocaleString()}
              </Typography>
            </>
          }
          secondary={
            <Typography variant="body1"  sx={{ color: "text.primary" }}>{message}</Typography>
          }
        />
      </ListItem>
      {!lastTimeline && <Divider />}
    </>
  );
}
