import notificationError from "../../../../app/system-components/toasters/notificationError";

export const RoutesHelper = (routes) => {
  if (!routes) return;

  const helperErr = (msg) => {
    notificationError("Route Helper Error", msg);
  };
  const updateRoute = (route) => {
    return routes.map((r) => {
      if (r.id === route.id) {
        return route;
      } else {
        return r;
      }
    });
  };
  return { updateRoute };
};
