import notificationError from "../../../system-components/toasters/notificationError";
import { CUSTOMER_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkCustomer } from "../../../utils/models/checkers/checkCustomer";
import { CUSTOMER_DISPLAY_NAME } from "../../../utils/models/modelConstants/modelConstants";
import { sortBy } from "../../../utils/sorters/sortBy";

export function customerQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${CUSTOMER_COLLECTION}`);

  async function fetchCustomers() {
    const snap = await ref.get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkCustomer(s));
    });
    return sortBy(list, CUSTOMER_DISPLAY_NAME);
  }
  async function fetchActiveCustomers() {
    const snap = await ref.where("isActive", "==", true).get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkCustomer(s));
    });
    return sortBy(list, CUSTOMER_DISPLAY_NAME);
  }
  async function fetchCustomer(id) {
    const snap = await ref.doc(id).get();
    return checkCustomer(snap);
  }
  return {
    fetchCustomers: fetchCustomers, // fetch customers
    fetchCustomer: fetchCustomer, // fetch customer
    fetchActiveCustomers: fetchActiveCustomers, // fetch active customers only
  };
}
