import React from "react";
import InvMetaDataAlert from "./InvMetaDataAlert";

const InvMetaDataList = ({ invoices, itemId }) => {
  return (
    <div>
      {invoices &&
        invoices.map((i) => (
          <InvMetaDataAlert invoice={i} itemId={itemId} key={i.id} />
        ))}
    </div>
  );
};

export default InvMetaDataList;
