import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../app/system-components/toasters/notificationError";

export const resetPasswordEmail = async ({ firebase }, email, successDesc) => {
  try {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        return notificationConfirm(
          "Success!",
          successDesc
            ? successDesc
            : "Please check your email to complete resetting your password"
        );
      })
      .catch(function (error) {
        console.log("Error:", error);
        return notificationError(
          "Something went wrong",
          "Please contact support if issue persists."
        );
      });
  } catch (error) {
    console.error(error);
  }
};
