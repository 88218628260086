import { Card, Form, Row, Col, Select, InputNumber, Button } from "antd";
import { FC } from "react";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import {
  COLOR_BLUE_0,
  COLOR_TEXT_GRAY_1,
  COLOR_RED_0,
} from "../../../../app/ui/colorConstants";
import {
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_QTY,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";
const { Option } = Select;

export const InventoryBundleList: FC<{
  storeSelections: (id: string) => void;
  inventoryItems: InventoryItem[];
}> = ({ storeSelections, inventoryItems }) => {
  return (
    <Card size="small" style={{ background: "#ebf3fd", marginBottom: "20px" }}>
      <TextSubtitle style={{ color: COLOR_BLUE_0 }} text={"Bundle Items"} />
      <br />
      <div style={{ marginTop: "12px" }}>
        <Form.List name="bundleItems">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Row
                    style={{ width: "100%" }}
                    gutter={12}
                    justify="space-between"
                    align="middle"
                  >
                    <Col span={17}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        key={field.key + INV_BUNDLE_ITEM_ID}
                        name={[field.name, INV_BUNDLE_ITEM_ID]}
                        rules={[{ required: true, message: "Item required" }]}
                      >
                        <Select
                          onChange={storeSelections}
                          placeholder="Select item"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (!option || !option.children) return true;
                            return (
                              option.children[0]
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          filterSort={(optionA, optionB) => {
                            if (
                              !optionA ||
                              !optionA.children ||
                              !optionB ||
                              !optionB.children
                            )
                              return 1;
                            return optionA.children[0]
                              .toString()
                              .toLowerCase()
                              .localeCompare(
                                optionB.children[0].toString().toLowerCase()
                              );
                          }}
                        >
                          {inventoryItems.map((i) => (
                            <Option key={i.id + i.name} value={i.id}>
                              {i.name}{" "}
                              <span style={{ color: COLOR_TEXT_GRAY_1 }}>
                                ({i.description})
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={"auto"}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        key={field.key + INV_BUNDLE_ITEM_QTY}
                        name={[field.name, INV_BUNDLE_ITEM_QTY]}
                        rules={[{ required: true, message: "Qty required" }]}
                      >
                        <InputNumber placeholder={"Qty"} min={0} />
                      </Form.Item>
                    </Col>
                    <Col flex={"40px"}>
                      <MinusCircleOutlined
                        onClick={() => remove(field.name)}
                        style={{
                          color: COLOR_RED_0,
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                </div>
              ))}
              <Form.Item>
                <Button onClick={() => add()} icon={<PlusOutlined />}>
                  Add Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </Card>
  );
};
