import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Form, Input, InputNumber, Space, Table, Tooltip } from "antd";
import { Spinner } from "../../app/ui/spinner";
import moment from "moment";
import convertTimeToDayString from "../../app/utils/time/convertTimeToDayString";
import convertToTime from "../../app/utils/time/convertToTime";
import { ClockCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import getSymbolFromCurrency from 'currency-symbol-map'

const ReceivePaymentTable = ({
  invoicesOutstanding,
  fetchComplete,
  customer,
  customerId,
  updateTable,
  fulfilled,
  setFulfilled,
  updateFromSelectAll,
  defaultSelected,
  defaultComplete,
  orgData
}) => {
  const EditableContext = React.createContext(null);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  useEffect(() => {
    setSelectedInvoices([defaultSelected]);
  }, [defaultSelected]);
  useEffect(() => {
    // update selectedInvoices from any changes in manual input
    const updatedSelected =
      fulfilled &&
      fulfilled
        .filter((i) => i.isFulfilled === true)
        .map((s) => {
          return s.id;
        });
    setSelectedInvoices(updatedSelected);
  }, [fulfilled]);

  const columns = () => {
    return [
      {
        title: "Invoice #",
        editable: false,
        dataIndex: "invoiceNumber",
        width: 100,
      },
      {
        title: "Due",
        dataIndex: "invoicePaymentDueDate",
        width: 200,
        render: (text) => {
          if (text) return moment(text.toDate()).format("MMM Do YYYY");
          return "No Due Date";
        },
      },
      {
        title: "Rental Start",
        editable: false,
        dataIndex: "rentalDateStart",
        render: (text, record) => {
          return (
            <Space>
              {convertTimeToDayString(record.rentalDateStart)}
              {record.specifiedReceiveTime && (
                <Tooltip title={convertToTime(record.rentalDateStart)}>
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        title: "Rental End",
        editable: false,
        dataIndex: "rentalDateEnd",
        render: (text, record) => {
          return (
            <Space>
              {convertTimeToDayString(record.rentalDateEnd)}
              {record.specifiedReturnTime && (
                <Tooltip title={convertToTime(record.rentalDateEnd)}>
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        title: "Total",
        editable: false,
        dataIndex: "total",
        width: 200,
        render: (text) => (
          FormatToLocalCurrency(text, orgData.countryCode, orgData.languageCode, orgData.currencyCode) 
        ),
      },
      {
        title: "Remaining",
        editable: false,
        dataIndex: "balanceRemaining",
        width: 200,
        render: (text) => (
          FormatToLocalCurrency(text, orgData.countryCode, orgData.languageCode, orgData.currencyCode) 
        ),
      },
      {
        title: "Fulfilled",
        editable: true,
        dataIndex: "fulfilledAmt",
        width: 200,
        render: (_, record) => {
          return {
            children: _,
          };
        },
      },
    ];
  };

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    useEffect(() => {
      const convertedFormValues = (fulfilled) => {
        return (
          fulfilled &&
          fulfilled.map((f) => {
            if (!f.id) return;
            return { [f.id]: f.fulfilled };
          })
        );
      };
      form.setFieldsValue({
        ...convertedFormValues(fulfilled),
      });
    }, [fulfilled]);
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = (update) => {
      setEditing(!editing);
      form.setFieldsValue({
        id: update.id,
        fulfilled: update.fulfilled,
      });
    };

    const save = async () => {
      try {
        const values = await form.getFieldsValue();
        const f = values[record.id]
          ? parseFloat(values[record.id]).toFixed(2)
          : null;
        const fulfilledAmt = f ? parseFloat(f) : 0;
        const capAtTotal =
          fulfilledAmt > parseFloat(record?.balanceRemaining.toFixed(2))
            ? parseFloat(record.balanceRemaining.toFixed(2))
            : fulfilledAmt;
        const isFulfilled = capAtTotal.toFixed(2) === record.balanceRemaining.toFixed(2);
        const update = {
          id: record.id,
          fulfilled: capAtTotal !== 0 ? capAtTotal : null,
          isFulfilled: isFulfilled,
        };
        toggleEdit(update);
        handleSave(update);
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      const currValue = fulfilled && fulfilled.find((r) => r.id === record.id);
      childNode = (
        <Space>
           { getSymbolFromCurrency(orgData.currencyCode) ?? "$" }
           
          <Form.Item
            initialValue={currValue ? currValue.fulfilled : null}
            style={{
              margin: 0,
            }}
            name={record.id}
          >
            <InputNumber
              style={{ width: "140px" }}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              precision={2}
            />
          </Form.Item>
        </Space>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const editableColumns = columns(fulfilled).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: updateTable,
        fulfilled: fulfilled,
      }),
    };
  });
  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      const rowKeys = selectedRows.map((r) => r.id);
      setSelectedInvoices(rowKeys);
      if (selected) {
        const payload = {
          id: record.id,
          fulfilled: record?.balanceRemaining ?? 0,
          isFulfilled: true,
        };
        updateTable(payload);
      } else {
        const payload = {
          id: record.id,
          fulfilled: null,
          isFulfilled: false,
        };
        updateTable(payload);
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        let ids = [];
        let total = 0;
        const updatedFulfilled =
          selectedRows &&
          selectedRows.map((r) => {
            const ttlFloat = parseFloat(r?.balanceRemaining?.toFixed(2));
            ids.push(r.id);
            total = total + ttlFloat;
            return {
              id: r.id,
              fulfilled: ttlFloat,
              isFulfilled: true,
            };
          });
        setSelectedInvoices(ids);
        setFulfilled(updatedFulfilled);
        updateFromSelectAll({ total });
      } else {
        setSelectedInvoices([]);
        setFulfilled([]);
        updateFromSelectAll({ total: 0 });
      }
    },
    selectedRowKeys: selectedInvoices,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Table
      pagination={false}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
      size="small"
      columns={editableColumns}
      components={components}
      dataSource={invoicesOutstanding}
      rowKey={"id"}
      loading={{
        spinning: !fetchComplete,
        indicator: Spinner,
      }}
      locale={{ emptyText: "No outstanding invoices" }}
    />
  );
};

export default ReceivePaymentTable;
