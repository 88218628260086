import React, { useState } from "react";
import { centsToDollars } from "../../../app/utils/models/checkers/checkCurrencyToDollars";
import { List, Space, Tag, Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import RefundModal from "../../invoices/components/RefundModal";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import {
  COLOR_LIGHT_GRAY,
  COLOR_LIGHT_GREEN,
  COLOR_TEXT_GRAY_1,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import { Typography } from "antd";

const PaymentList = ({ fetching, data, invoiceId }) => {
  const [loadingRefund, _setLoadingRefund] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [paymentToRefund, setPaymentToRefund] = useState(null);

  function refundClicked(item) {
    setPaymentToRefund(item);
    setShowRefundModal(true);
  }

  function onRefundComplete(success) {
    setShowRefundModal(false);
    if (success) {
      notificationConfirm("Success!", "Refund complete");
    } else {
      notificationError("Error", "Unable to process refund.");
    }
  }

  return (
    <div>
      <List
        loading={fetching}
        locale={{ emptyText: "No payments" }}
        size="small"
        bordered={false}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Space>
                  <NumberFormat
                    style={{ fontWeight: "bold" }}
                    value={item?.total.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />

                  <Tag
                    style={{
                      borderColor: "transparent",
                      backgroundColor: COLOR_LIGHT_GRAY,
                    }}
                  >
                    {capitalizeFirstLetter(item?.paymentMethod ?? "")}
                  </Tag>
                </Space>
              }
              description={
                <Space direction={"vertical"} size={0}>
                  {item?.paymentMethod === "stripe" && (
                    <div>
                      <Space>
                        Processing Fees:
                        <NumberFormat
                          style={{ fontWeight: "bold" }}
                          value={item?.stripeFees?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Space>
                      <br />
                      <Space>
                        Net:
                        <NumberFormat
                          style={{ fontWeight: "bold" }}
                          value={item?.stripeNet?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Space>
                    </div>
                  )}
                  <TextSubtitle text={`Ref No: ${item.refNo}`} />
                  <TextSubtitle
                    style={{ color: COLOR_TEXT_GRAY_1, fontSize: "12px" }}
                    text={item?.paymentDate?.toDate().toLocaleString()}
                  />
                  {item.refunds.length > 0 && (
                    <div style={{ float: "right", marginLeft: "10px" }}>
                      <br />
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Refunds
                      </Typography.Title>

                      {item.refunds.map((item, _index) => {
                        return (
                          <div
                            style={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                              marginBottom: "8px",
                            }}
                          >
                            <TextSubtitle
                              style={{
                                color: COLOR_TEXT_GRAY_1,
                                fontSize: "12px",
                              }}
                              text={"Amount"}
                            />
                            <NumberFormat
                              style={{ fontWeight: "bold", marginLeft: "5px" }}
                              value={centsToDollars(item.amount).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                            <br />
                            <TextSubtitle
                              style={{
                                color: COLOR_TEXT_GRAY_1,
                                fontSize: "12px",
                              }}
                              text={`Date: ${item.date
                                .toDate()
                                .toLocaleString()}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Space>
              }
            />

            <div style={{ position: "relative" }}>
              <Tag
                style={{
                  display: "block",
                  // marginBottom: "10px",
                  position: "absolute",
                  bottom: "20px",
                  right: "10px",
                  borderColor:
                    item.status === "complete" ? "green" : "transparent",
                  backgroundColor:
                    item.status === "complete"
                      ? COLOR_LIGHT_GREEN
                      : item.status === "pending"
                      ? COLOR_YELLOW_0
                      : COLOR_LIGHT_GRAY,
                }}
              >
                {item.status && capitalizeFirstLetter(item.status)}
              </Tag>
              {console.log("item status: ", item.status)}
              {item.status === "complete" ? (
                <Button
                  type="primary"
                  icon={<RollbackOutlined />}
                  loading={loadingRefund}
                  onClick={() => refundClicked(item)}
                  style={{
                    display: "block",
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                  }}
                >
                  Refund Payment
                </Button>
              ) : (
                ""
              )}
            </div>
          </List.Item>
        )}
      />
      <RefundModal
        visible={showRefundModal}
        onCancel={() => setShowRefundModal(false)}
        onComplete={onRefundComplete}
        paymentDoc={paymentToRefund}
        invoiceId={invoiceId}
      />
    </div>
  );
};

export default PaymentList;
