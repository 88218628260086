import { useEffect, useState } from "react";
import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../utils/models/modelConstants/modelConstants";
import pickupIcon from "../../../domains/routes/map/markers/icons/pickup-icon.svg";
import deliveryIcon from "../../../domains/routes/map/markers/icons/delivery-icon.svg";
import pickupIconAssigned from "../../../domains/routes/map/markers/icons/pickup-assigned-icon.svg";
import deliveryIconAssigned from "../../../domains/routes/map/markers/icons/delivery-assigned-icon.svg";
import multiRouteIcon from "../../../domains/routes/map/markers/icons/multi-route.svg";
import multiRouteAssignedIcon from "../../../domains/routes/map/markers/icons/multi-route-assigned-icon.svg";
import sameDayIcon from "../../../domains/routes/map/markers/icons/sameday-icon.svg";
import sameDayAssignedIcon from "../../../domains/routes/map/markers/icons/sameday-assigned-icon.svg";
import waypointIcon from "../../../domains/routes/map/markers/icons/waypoint-icon.svg";
import { GetStopInRoute } from "../../../domains/routes/map/helpers/getStopInRoute";
import onlyUnique from "../../utils/filters/filterOnlyUnique";

export function useMarker({
  stop,
  stops,
  routes,
  selectedRouteId,
  currentTooltip,
}) {
  const [icon, setIcon] = useState("");
  const [groupedCount, setGroupCount] = useState(0);
  const [isAssigned, setIsAssigned] = useState(false);
  const [sharedStops, setSharedStops] = useState([]);
  const [stopNumber, setStopNumber] = useState(null);
  const [showTooltip, setShowTooltip] = useState({
    singleStop: false,
    multiStop: false,
  });

  useEffect(() => {
    const count = stop?.sharedStopInvoiceIds?.length ?? 0;
    const sharedStops = getSharedStops({ stop, stops, routes });
    const inRoute = GetStopInRoute({
      stopInvoiceId: stop?.invoiceId,
      routes,
      routeId: selectedRouteId,
      type: stop?.type,
    });
    if (stop.type === RECEIVE_METHOD_DELIVERY) {
      setIcon(count ? multiRouteIcon : pickupIcon);
    } else if (stop.type === RETURN_METHOD_ONSITE) {
      setIcon(count ? multiRouteIcon : deliveryIcon);
    } else if (stop.type === STOP_TYPE_WAYPOINT) {
      setIcon(waypointIcon);
    } else {
      setIcon("");
    }
    const isSameDay = configureIcon({
      isAssigned: inRoute !== null,
      groupCount: count,
      stopType: stop.type,
      setIcon,
      sharedStops,
    });
    const safeIndex = inRoute?.index ?? 0;
    setStopNumber(
      inRoute !== null && !isSameDay.isSameDay ? `${safeIndex + 1}` : " "
    );
    setSharedStops(sharedStops);
    setGroupCount(count ? `${count + 1}` : null);
    setIsAssigned(inRoute !== null);
  }, [stop, selectedRouteId, stops, routes]);

  const getSharedStops = ({ stop, stops, routes }) => {
    /**
     * Returns array of shared stops in routes, including the stop passed in
     * @type {[]|*|null}
     */
    const sharedInvoiceIds = stop?.sharedStopInvoiceIds ?? null;
    if (!sharedInvoiceIds) return [];
    const shared = stops?.filter((s) => {
      return (
        s.sharedStopInvoiceIds.includes(stop?.invoiceId) ||
        s.invoiceId === stop?.invoiceId
      );
    });
    return shared?.map((s) => {
      const assigned = routes?.find((r) => {
        const inRoute = r.invoicesQueryHook.includes(s.invoiceId);
        const selected = selectedRouteId === r.id;

        if (inRoute) {
          const stopAndTypeInRoute = r?.stops?.find((stop) => {
            return stop?.invoiceId === s.invoiceId && stop?.type === s?.type;
          });
          if (stopAndTypeInRoute) {
            return selected;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      return {
        stop: s,
        isAssigned: !!assigned,
      };
    });
  };
  const configureIcon = ({
    isAssigned,
    groupCount,
    stopType,
    setIcon,
    sharedStops,
  }) => {
    let invoiceNumbers = sharedStops
      ?.map((s) => s?.invoiceNumber ?? null)
      .filter(onlyUnique);
    const hasTwoSharedStopsOnly = sharedStops?.length === 2;
    let isSameDay =
      invoiceNumbers.length === 1 && hasTwoSharedStopsOnly === true;

    const multiAssigned = sharedStops?.filter((s) => s?.isAssigned === true);
    const isMultiComplete = multiAssigned.length === groupCount + 1;

    if (isSameDay) {
      setIcon(multiAssigned.length === 2 ? sameDayAssignedIcon : sameDayIcon);
    } else if (stopType === RECEIVE_METHOD_DELIVERY) {
      setIcon(
        groupCount
          ? isMultiComplete
            ? multiRouteAssignedIcon
            : multiRouteIcon
          : isAssigned
          ? deliveryIconAssigned
          : deliveryIcon
      );
    } else if (stopType === RETURN_METHOD_ONSITE) {
      setIcon(
        groupCount
          ? isMultiComplete
            ? multiRouteAssignedIcon
            : multiRouteIcon
          : isAssigned
          ? pickupIconAssigned
          : pickupIcon
      );
    } else if (stopType === STOP_TYPE_WAYPOINT) {
      setIcon(waypointIcon);
    } else {
      setIcon("");
    }
    return { isSameDay };
  };

  useEffect(() => {
    const sameRoute = selectedRouteId === currentTooltip?.routeId;
    let sharedMatch = true;
    let singleMatch = true;

    // for shared
    const invoiceIds = getSharedStops({ stop, stops, routes })
      ?.map((s) => s?.stop?.invoiceId)
      .filter(onlyUnique);
    if (invoiceIds.length === 0) {
      sharedMatch = false;
    }
    invoiceIds.map((id) => {
      if (!sharedMatch) return;
      sharedMatch = currentTooltip.invoiceIds?.includes(id);
    });

    // for individual
    if (invoiceIds.length === 0) {
      singleMatch = currentTooltip.invoiceIds?.[0]?.stop?.invoiceId;
    } else {
      singleMatch = false;
    }
    setShowTooltip({
      singleStop: singleMatch && sameRoute,
      multiStop: sharedMatch && sameRoute,
    });
  }, [currentTooltip]);
  return {
    lat: stop?.lat,
    lng: stop?.lng,
    icon,
    groupedCount,
    isAssigned,
    sharedStops,
    stopNumber,
    showTooltip,
  };
}
