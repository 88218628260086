import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Badge, Popover, List, ListItem, ListItemText } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface BadgeWithHoverPopoverProps {
  index: number;
  menuItems?: Array<{
    text: string;
    onClick?: () => void;
  }>;
  triggerType: 'hover' | 'rightClick';
}

interface MenuItem {
  text: string;
  onClick?: () => void;
}

interface BadgeWithHoverPopoverProps {
  index: number;
  menuItems?: MenuItem[];
  triggerType: 'hover' | 'rightClick';
}

const RowDragHandle: React.FC<BadgeWithHoverPopoverProps> = React.memo(({ index, menuItems = [], triggerType }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { set: setTimer, clear: clearTimer } = useTimeout();

    const handlePopoverOpen = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (triggerType === 'hover') {
          setAnchorEl(event.currentTarget);
          setTimer(() => setIsOpen(true), 300);
        }
      },
      [triggerType, setTimer]
    );

    const handlePopoverClose = useCallback(() => {
      if (triggerType === 'hover') {
        setTimer(() => {
          setIsOpen(false);
          setAnchorEl(null);
        }, 100);
      } else {
        setIsOpen(false);
        setAnchorEl(null);
      }
    }, [triggerType, setTimer]);

    const handleRightClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (triggerType === 'rightClick') {
          event.preventDefault();
          setAnchorEl(event.currentTarget);
          setIsOpen(true);
        }
      },
      [triggerType]
    );

    const handleMenuItemClick = useCallback(
      (onClick?: () => void) => () => {
        if (onClick) {
          onClick();
        }
        handlePopoverClose();
      },
      [handlePopoverClose]
    );

    const eventHandlers =
      triggerType === 'hover'
        ? {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          }
        : {
            onContextMenu: handleRightClick,
          };

    return (
      <div {...eventHandlers} aria-haspopup="true" aria-controls={isOpen ? `menu-${index}` : undefined}>
        <Badge badgeContent={index + 1} color="primary" sx={{ mr: 2 }}>
          <DragIndicatorIcon />
        </Badge>
        <Popover
          id={`menu-${index}`}
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          slotProps={{
            paper: {
              onMouseEnter: triggerType === 'hover' ? clearTimer : undefined,
              onMouseLeave: triggerType === 'hover' ? handlePopoverClose : undefined,
            },
          }}
        >
          <List>
            {menuItems.map((item, itemIndex) => (
              <ListItem button key={itemIndex} onClick={handleMenuItemClick(item.onClick)}>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    );
  }
);

export default RowDragHandle;


export function useTimeout() {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const set = (callback: () => void, delay: number) => {
    clear();
    timeoutRef.current = setTimeout(callback, delay);
  };

  const clear = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    return clear;
  }, []);

  return { set, clear };
}
