import React from "react";
import { Tag } from "antd";
import {
  COLOR_TEXT_GRAY_2,
} from "../../ui/colorConstants";

export const CategoryTag = ({ text, ...props }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        backgroundColor: COLOR_TEXT_GRAY_2,
        margin: "2px 4px 2px 0px",
      }}
      {...props}
    >
      {text}
    </Tag>
  );
};

export const ItemTag = ({ name, qty, ...styleProps }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        backgroundColor: COLOR_TEXT_GRAY_2,
        margin: "4px",
        ...styleProps,
      }}
    >
      <span style={{ fontWeight: "bold" }}>({qty})</span> {name}
    </Tag>
  );
};

export const ItemTagWithBg = ({ name, qty, ...styleProps }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        margin: "4px",
        ...styleProps,
      }}
    >
      <span style={{ fontWeight: "bold" }}>({qty})</span> {name}
    </Tag>
  );
};
