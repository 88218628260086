import React, { FC } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { Menu, MenuItem } from "@mui/material";
import { CustomerTableMenuControl } from "./useCustomerContextMenu";

const CustomerContextMenu: FC<{ control: CustomerTableMenuControl }> = ({
  control,
}) => {
  const { userData } = useSelector(authSelector);

  return (
    <div>
      {userData && (
        <Menu
          open={
            control.contextConfig.visible &&
            !!control.contextConfig.selectedCustomer
          }
          onClose={control.resetContext}
          anchorReference="anchorPosition"
          anchorPosition={
            control.contextConfig.visible
              ? {
                  top: control.contextConfig.y,
                  left: control.contextConfig.x,
                }
              : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                control.resetContext();
              },
            },
          }}
        >
          <MenuItem onClick={control.handleCustomerClick}>
            Edit/View Customer
          </MenuItem>
          {control.contextConfig.selectedCustomer?.isActive && (
            <MenuItem onClick={control.handleReceivePayment}>
              Receive Payment
            </MenuItem>
          )}
          {control.contextConfig.selectedCustomer && (
            <MenuItem
              onClick={
                control.contextConfig.selectedCustomer.isActive
                  ? control.handleDeactivate
                  : control.handleReactivate
              }
            >
              {control.contextConfig.selectedCustomer.isActive
                ? "Deactivate"
                : "Make Active"}
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

export default CustomerContextMenu;
