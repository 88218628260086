import React from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";
import { TAX_RATES_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";
import { configureTaxRates } from "../../../app/utils/models/configure/configureTaxRates";

const TaxModal = ({ setTaxModal, visible, onTaxCreated, onCancel }) => {
  const { newDocument, writing } = useFirestoreWrite();
  const [form] = Form.useForm();
  const handleFinish = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      newDocument({
        data: {
          collection: TAX_RATES_COLLECTION,
          payload: { ...configureTaxRates(data) },
        },
      }).then((id) => {
        form.resetFields();
        onTaxCreated({
          id: id,
          ...configureTaxRates(data),
        });
      });
    });
  };
  return (
    <Modal
      destroyOnClose
      width={400}
       open={visible}
      closeIcon={<></>}
      title="Add Tax Rate"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            setTaxModal({ visible: false });
            onCancel();
          }}
          disabled={writing}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={writing}
          loading={writing}
        >
          Add Tax Rate
        </Button>,
      ]}
    >
      <div>
        <Form form={form}>
          <Space align={"start"}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `Required.`,
                },
              ]}
              name="name"
              style={{ marginBottom: "12px", width: "240px" }}
            >
              <Input placeholder="Rate Name" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `Required.`,
                },
              ]}
              name="rate"
              style={{ marginBottom: "12px", width: "80px" }}
            >
              <InputNumber max={100} min={0} placeholder="Rate" />
            </Form.Item>{" "}
            <div style={{ marginTop: "5px" }}>%</div>
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default TaxModal;
