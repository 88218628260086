import React, { useMemo, useState, useCallback, createContext } from 'react';
import { WeatherDataFiveDay } from '../../../../app/interfaces/weather-forecast-five';


interface WeatherContextType {
  weather: WeatherDataFiveDay | null;
  fetchFiveDayWeather: (lat: number, lon: number) => void;
}

interface WeatherProviderProps {
  children: React.ReactNode;
}

const WeatherContext = createContext<WeatherContextType | undefined>(undefined);

export const WeatherProvider: React.FC<WeatherProviderProps> = ({ children }) => {
  const [weather, setWeather] = useState<WeatherDataFiveDay | null>(null);

  const fetchFiveDayWeather = useCallback(async (lat: number, lon: number) => {
    const apiKey = "91ae0bd565f15f6b3ca42c97e3be7df9";
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${apiKey}&units=imperial`;
    try {
      const response = await fetch(url);
      const data: WeatherDataFiveDay = await response.json();
      setWeather(data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  }, []);

  const contextValue = useMemo(
    () => ({ weather, fetchFiveDayWeather }),
    [weather, fetchFiveDayWeather]
  );

  return <WeatherContext.Provider value={contextValue}>{children}</WeatherContext.Provider>;
};

export default WeatherContext;
