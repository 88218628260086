import { useState, MouseEvent, useCallback } from "react";
import { configureInventory } from "../../../../app/utils/models/configure/configureInventory";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { inventoryWrites } from "../../../../app/services/firestore/writes/inventoryWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";

export interface ContextConfig {
  selectedInventoryItem?: InventoryItem;
  visible: boolean;
  x: number;
  y: number;
}

export interface AdminInventoryContextMenuControl {
  handleDuplicate: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  contextConfig: ContextConfig;
  configureContextMenu: (event: MouseEvent<HTMLElement>, record: InventoryItem) => void;
  closeContextMenu: () => void; // Added close handler
}

export const useAdminInventoryContextMenu = ({
  setItemDrawer,
  onDuplicateComplete,
  onDeleteComplete,
}: {
  setItemDrawer: React.Dispatch<React.SetStateAction<{
    visible: boolean;
    item?: InventoryItem;
  }>>;
  onDuplicateComplete: () => Promise<void>;
  onDeleteComplete: () => Promise<void>;
}): AdminInventoryContextMenuControl => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();
  
  const [contextConfig, setContextMenuConfig] = useState<ContextConfig>({
    selectedInventoryItem: undefined,
    visible: false,
    x: 0,
    y: 0,
  });

  // Close context menu handler
  const closeContextMenu = useCallback(() => {
    setContextMenuConfig({
      selectedInventoryItem: undefined,
      visible: false,
      x: 0,
      y: 0,
    });
  }, []);

  const handleEdit = useCallback(() => {
    if (contextConfig.selectedInventoryItem) {
      setItemDrawer({
        visible: true,
        item: contextConfig.selectedInventoryItem,
      });
      closeContextMenu();
    }
  }, [contextConfig.selectedInventoryItem, setItemDrawer]);

  const handleDuplicate = useCallback(async () => {
    if (!contextConfig.selectedInventoryItem) return;

    try {
      const payload = configureInventory().duplicateInventoryItem(
        contextConfig.selectedInventoryItem,
        userData.id,
        orgData
      );
      
      const writer = inventoryWrites({ firestore }, fsOrgPrefix);
      if (writer) {
        await writer.createInventory(payload);
        await onDuplicateComplete();
        notificationConfirm("Item duplicated");
      }
    } catch (error) {
      console.error('Duplication error:', error);
      notificationError("Failed to duplicate item", "Please try again later");
    } finally {
      closeContextMenu();
    }
  }, [contextConfig.selectedInventoryItem, userData.id, orgData, firestore, fsOrgPrefix, onDuplicateComplete]);

  const handleDelete = useCallback(() => {
    if (!contextConfig.selectedInventoryItem) return;

    Modal.confirm({
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      title: "Delete inventory item?",
      content: "This cannot be undone",
      okType: "danger",
      onOk: async () => {
        try {
          if (!fsOrgPrefix) {
            notificationError("Something went wrong", "Please try again later");
            return;
          }

          const writer = await inventoryWrites({ firestore }, fsOrgPrefix);
          if (writer) {
            await writer.deleteInventory(contextConfig?.selectedInventoryItem?.id ?? "na");
            await onDeleteComplete();
            notificationConfirm("Item deleted", "");
          }
        } catch (err) {
          console.error('Deletion error:', err);
          notificationError("Something went wrong", "Please try again later");
        } finally {
          closeContextMenu();
        }
      },
      onCancel() {
        closeContextMenu();
      },
    });
  }, [contextConfig.selectedInventoryItem, fsOrgPrefix, firestore, onDeleteComplete]);

  const configureContextMenu = useCallback((
    event: MouseEvent<HTMLElement>,
    record: InventoryItem
  ) => {
    event.preventDefault();
    event.stopPropagation();
    
    // Set up click outside listener
    const handleClickOutside = (e: MouseEvent) => {
      closeContextMenu();
      document.removeEventListener('click', handleClickOutside as any);
    };

    // Only add listener if menu isn't already visible
    if (!contextConfig.visible) {
      document.addEventListener('click', handleClickOutside as any);
    }

    setContextMenuConfig({
      selectedInventoryItem: record,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  }, [contextConfig.visible, closeContextMenu]);

  return {
    handleDuplicate,
    handleDelete,
    handleEdit,
    contextConfig,
    configureContextMenu,
    closeContextMenu,
  };
};

// import { useState, MouseEvent } from "react";
// import { configureInventory } from "../../../../app/utils/models/configure/configureInventory";
// import { useSelector } from "react-redux";
// import { authSelector } from "../../../auth/authSlice";
// import {
//   inventoryWrites,
// } from "../../../../app/services/firestore/writes/inventoryWrites";
// import { useFirestore } from "react-redux-firebase";
// import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
// import { Modal } from "antd";
// import { WarningFilled } from "@ant-design/icons";
// import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
// import notificationError from "../../../../app/system-components/toasters/notificationError";
// import { InventoryItem } from "../../../../app/interfaces/inventoryItem";

// export interface ContextConfig {
//   selectedInventoryItem?: InventoryItem;
//   visible: boolean;
//   x: number;
//   y: number;
// }
// export interface AdminInventoryContextMenuControl {
//   handleDuplicate: () => void;
//   handleDelete: () => void;
//   handleEdit: () => void;
//   contextConfig: ContextConfig;
//   configureContextMenu: (
//     event: MouseEvent<HTMLElement>,
//     record: InventoryItem
//   ) => void;
// }

// export const useAdminInventoryContextMenu = ({
//   setItemDrawer,
//   onDuplicateComplete,
//   onDeleteComplete,
// }: {
//   setItemDrawer: React.Dispatch<
//     React.SetStateAction<{
//       visible: boolean;
//       item?: InventoryItem;
//     }>
//   >;
//   onDuplicateComplete: () => Promise<void>;
//   onDeleteComplete: () => Promise<void>;
// }): AdminInventoryContextMenuControl => {
//   const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
//   const firestore = useFirestore();
//   const [contextConfig, setContextMenuConfig] = useState<ContextConfig>({
//     selectedInventoryItem: undefined,
//     visible: false,
//     x: 0,
//     y: 0,
//   });

//   const handleEdit = () => {
//     setItemDrawer({
//       visible: true,
//       item: contextConfig.selectedInventoryItem,
//     });
//   };

//   const handleDuplicate = () => {
//     // create duplicate
//     if (contextConfig.selectedInventoryItem) {
//       const payload = configureInventory().duplicateInventoryItem(
//         contextConfig.selectedInventoryItem,
//         userData.id,
//         orgData
//       );
//       const writer = inventoryWrites({ firestore }, fsOrgPrefix);
//       if (writer) {
//         writer.createInventory(payload).then(() => {
//           onDuplicateComplete().then(() => {
//             notificationConfirm("Item duplicated");
//           });
//         });
//       }
//     }

//     // open drawer
//   };
//   const handleDelete = () => {
//     Modal.confirm({
//       icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
//       title: "Delete inventory item?",
//       content: "This cannot be undone",
//       okType: "danger",
//       onOk: async () => {
//         try {
//           if (!fsOrgPrefix)
//             return notificationError(
//               "Something went wrong",
//               "Please try again later"
//             );

//           const writer = await inventoryWrites({ firestore }, fsOrgPrefix);
//           if (writer && contextConfig.selectedInventoryItem) {
//             await writer.deleteInventory(
//               contextConfig.selectedInventoryItem.id
//             );
//             await onDeleteComplete();
//             notificationConfirm("Item deleted", "");
//           }
//         } catch (err) {
//           console.log(err);
//           notificationError("Something went wrong", "Please try again later");
//         }
//       },
//       onCancel() {},
//     });
//   };
//   const configureContextMenu = (
//     event: MouseEvent<HTMLElement>,
//     record: InventoryItem
//   ) => {
//     event.preventDefault();
//     event.stopPropagation();
//     event.nativeEvent.stopImmediatePropagation();
//     if (!contextConfig.visible) {
//       document.addEventListener(`click`, function onClickOutside() {
//         setContextMenuConfig({
//           selectedInventoryItem: undefined,
//           visible: false,
//           x: 0,
//           y: 0,
//         });
//         document.removeEventListener(`click`, onClickOutside);
//       });
//     }
//     setContextMenuConfig({
//       selectedInventoryItem: record,
//       visible: true,
//       x: event.clientX,
//       y: event.clientY,
//     });
//   };
//   return {
//     handleDuplicate,
//     handleDelete,
//     handleEdit,
//     contextConfig,
//     configureContextMenu,
//   };
// };
