import { Badge, Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Stack, TextField, Typography, alpha, useTheme } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import Iconify from "../../../app/iconify";
import { useEffect, useRef, useState } from "react";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { makeStyles } from "@mui/styles";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { is } from "date-fns/locale";

// ----------------------------------------------------------------------

type Props = {
  orgData: OrganizationData;
  item: any;
  index: number;
  selectedItems: any[];
  // selected: boolean;
  // onSelectRow: VoidFunction;
  // onViewRow: VoidFunction;
  // onEditRow: VoidFunction;
  handleUpdateSelectedItem: (index: number, item: any) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function SectionDiscountRow({ orgData, item, index, selectedItems, handleUpdateSelectedItem, onDeleteRow }: Props) {
  const responseiveFontSize = useResponsiveFontSize();
  const theme = useTheme();
  const classes = useStyles();

  const [isPercent, setIsPercent] = useState(false);

  const handleChange = (event: any) => {
    console.log(event.target.checked);
    setIsPercent(event.target.checked);
  };

  const { currentBreakpoint, isSMAndDown, isMDAndDown, isMDAndUp } = useCurrentBreakpoint();

  const rateRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);

  const descriptionRef = useRef<HTMLInputElement>(null);

  const [percentDiscount, setPercentDiscount] = useState(item?.percentDiscount || 10);
  const [numberDiscount, setNumberDiscount] = useState(item?.numberDiscount || 1);
  const [discountType, setDiscountType] = useState(item?.discountType || "number");  // "number" or "percent"

  const [itemDescription, setItemDescription] = useState(item?.selectedDescription || "");
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [subtotal, setSubtotal] = useState(item?.rowTotal || 0);

  const calculateSectionTotal = () => {
    let total = 0;

    if (isPercent) {
      let foundSubtotal = false;

      for (let i = item.listIndex - 1; i >= 0 && !foundSubtotal; i--) {
        const currentItem = selectedItems[i];
        if (currentItem.rowType === "subtotal" || currentItem.rowType === "damageWaiver") {
          continue;
        }
        if (currentItem.rowType === "discount") {
          foundSubtotal = true;
          break;
        }
        total += currentItem.rowTotal;
      }

      // Update the subtotal state only once per function call
      setSubtotal(total * (percentDiscount / 100));
    }
  };

  useEffect(() => {
    updateItem();
  }, [subtotal]);

  // recalculate the section total whenever selectedItems or item.index changes
  useEffect(() => {
    if (selectedItems.length === 0) {
      return;
    }
    calculateSectionTotal();
  }, [selectedItems, percentDiscount]);

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    item.rowTotal = subtotal;
    item.percentDiscount = percentDiscount;
    item.selectedTaxable = taxable;

    handleUpdateSelectedItem(item.listIndex, item);
  };

  useEffect(() => {
    updateItem();
  }, [taxable]);

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.warning.lighter, 0.4),
      }}
    >
      <Grid container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="center">
        <Grid item xs={6} lg={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Badge badgeContent={index + 1} color="primary" sx={{mr: 2}}>
              <DragIndicatorIcon />
            </Badge>
            <Typography fontSize={responseiveFontSize}>Discount</Typography>
          </Stack>
        </Grid>

        <Grid item xs={6} md={3} lg={3.5}>
          <TextField
            inputRef={descriptionRef}
            fullWidth
            inputProps={{ style: { fontSize: responseiveFontSize } }}
            InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {isMDAndUp && <Grid item md={1.5} lg={1.5} />}

        <Grid item xs={6} md={3} lg={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <PercentNumberSwitch checked={isPercent} onChange={handleChange} />

            {isPercent ? (
              <TextField
                inputRef={rateRef}
                className={classes.input}
                inputProps={{ style: { fontSize: responseiveFontSize } }}
                InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
                size="small"
                label="Percent"
                type="number"
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={percentDiscount === 0 ? "" : percentDiscount}
                onChange={(e) => setPercentDiscount(Number(e.target.value))}
                // onBlur={updateItem}
                onKeyDown={(e) => {
                  console.log(e.key);
                  if (e.key === "Enter") {
                    if (rateRef.current) {
                      rateRef.current.blur();
                    }
                  }
                }}
              />
            ) : (
              <TextField
                inputRef={amountRef}
                className={classes.input}
                inputProps={{ style: { fontSize: responseiveFontSize } }}
                InputLabelProps={{ style: { fontSize: responseiveFontSize } }}
                size="small"
                label="Amount"
                type="number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ typography: "subtitle2", color: "text.disabled" }}>$</Box>
                    </InputAdornment>
                  ),
                }}
                placeholder="0.00"
                value={subtotal === 0 ? "" : subtotal}
                onChange={(e) => setSubtotal(Number(e.target.value))}
                onBlur={updateItem}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (amountRef.current) {
                      amountRef.current.blur();
                    }
                  }
                }}
              />
            )}
          </Stack>
        </Grid>

        {/* //MARK: - TAXABLE */}
        <Grid item xs={12} sm={6} md={4} lg={1}>
          <FormControlLabel
            control={<Checkbox inputProps={{ style: { fontSize: responseiveFontSize } }} checked={taxable} onChange={(e) => setTaxable(e.target.checked)} />}
            label={<Typography fontSize={responseiveFontSize}>{isMDAndDown ? "Taxable" : "Taxed"}</Typography>}
          />
        </Grid>

        <Grid item xs={0.5}>
          <Typography color={theme.palette.error.dark} fontSize={responseiveFontSize}>{`-${FormatToLocalCurrency(subtotal)}`}</Typography>
        </Grid>

        <Grid item xs={0.5}>
          <Button size="small" color="error" startIcon={<Iconify icon="solar:trash-bin-trash-bold" />} onClick={() => onDeleteRow(item.listIndex)} />
        </Grid>
      </Grid>
    </Box>
  );
}

export const PercentNumberSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 32,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(26px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M7.5 11q-1.45 0-2.475-1.025T4 7.5t1.025-2.475T7.5 4t2.475 1.025T11 7.5T9.975 9.975T7.5 11m0-2q.625 0 1.063-.437T9 7.5t-.437-1.062T7.5 6t-1.062.438T6 7.5t.438 1.063T7.5 9m9 11q-1.45 0-2.475-1.025T13 16.5t1.025-2.475T16.5 13t2.475 1.025T20 16.5t-1.025 2.475T16.5 20m0-2q.625 0 1.063-.437T18 16.5t-.437-1.062T16.5 15t-1.062.438T15 16.5t.438 1.063T16.5 18M5.4 20L4 18.6L18.6 4L20 5.4z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 24,
    height: 24,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
    top: "50%",
    transform: "translateY(-15%)",
  },
}));
