import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import firebase from "firebase/app";


import { Box, Card, Button, TextField, Typography } from '@mui/material';
import { OrganizationData } from '../../../app/interfaces/organizationData';
import { useFirestore } from 'react-redux-firebase';


const FeedbackWidget = ({orgData, userData}: {orgData: OrganizationData, userData: any}) => {
    
  const [feedbackText, setFeedbackText] = useState('');
  const db = useFirestore()

  const handleFeedbackSubmit = async () => {
    const feedbackCollection = db.collection('feedback');
    try {
      await feedbackCollection.add({
        text: feedbackText,
        feature: 'dashboard',
        company: orgData?.companyName,
        userEmail: userData?.email,
        userName: userData?.firstName,
        timestamp: db.FieldValue.serverTimestamp(),
      });
      enqueueSnackbar('Thank you for your feedback!', { variant: 'success' });
      setFeedbackText('');
    } catch (error) {
      enqueueSnackbar('Unable To Submit', { variant: 'error' });
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <Card sx={{ padding: '12px', height: "100%" }}>
      <Typography variant="h6" component="h6">
        Help us build the dashboard!
      </Typography>
      <Typography variant="body2">
        What kind of widgets and views would you like to see on the dashboard?
      </Typography>
      <br />
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-multiline-flexible"
          label="Tell us what would make your life easier!"
          multiline
          maxRows={4}
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 2 }}
          onClick={handleFeedbackSubmit} // Call the submit function when the button is clicked
        >
          Submit
        </Button>
      </Box>
    </Card>
  );
};

export default FeedbackWidget;
