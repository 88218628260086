import React from "react";
import { Checkbox, Popover, Select, Space, Tooltip, Row, Col } from "antd";
import { TagFilled } from "@ant-design/icons";
import {
  COLOR_TEXT_GRAY_1,
  COLOR_YELLOW_0,
} from "../../../../app/ui/colorConstants";
import RemoveInvItemBtn from "./modules/RemoveInvItemBtn";
import { FormatToLocalCurrency } from "../../../../app/utils/currency/formatToLocalCurrency";
import { MenuOutlined, WarningFilled } from "@ant-design/icons";
import { sortableHandle } from "react-sortable-hoc";
import {
  ROW_TYPE_SINGLE_ITEM,
  ROW_TYPE_SUBTOTAL,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import InlineSubtotal from "./rows/InlineSubtotal";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import ItemTaxableCell from "./cells/ItemTaxableCell";

export const invoiceTableColumns = (props, updateSubtotal, updateDiscount) => {
  
  const { isSalesInvoice, handleTaxableChange, handleRemove, selectedItems, orgData } = props;
  const DragHandle = sortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: COLOR_TEXT_GRAY_1,
        fontSize: "12px",
        marginRight: "12px",
      }}
    />
  ));

  let columns = [
    {
      title: "Item",
      dataIndex: "selectedItemId",
      editable: true,
      width: 250,
      render: (_, record) => {
        // singleItem, bundleItem, subtotal
        const rowType = record.rowType ? record.rowType : "";
        if (rowType === ROW_TYPE_SINGLE_ITEM) {
          return (
            <div>
              {!record.selectedRateMeta && record.id && (
                <Tooltip title={"Rate required"}>
                  <WarningFilled
                    style={{ marginRight: "8px", color: COLOR_YELLOW_0 }}
                  />
                </Tooltip>
              )}
              <DragHandle />
              {record.id ? (
                <Space>
                  <TextSubtitle
                    style={{ color: COLOR_TEXT_GRAY_1, fontSize: "13px" }}
                    text={`${record.key + 1}`}
                  />
                  {record.name}
                </Space>
              ) : (
                <Space>
                  <TextSubtitle
                    style={{ color: COLOR_TEXT_GRAY_1, fontSize: "13px" }}
                    text={`${record.key + 1}`}
                  />
                  <span style={{ color: COLOR_TEXT_GRAY_1 }}>
                    {record.name}
                  </span>
                </Space>
              )}
            </div>
          );
        } else if (rowType === ROW_TYPE_SUBTOTAL) {
          return {
            children: (
              <div style={{ padding: "-16px" }}>
                <InlineSubtotal
                  record={record}
                  updateDiscount={(payload) =>
                    updateDiscount({ key: record.key, ...payload })
                  }
                />
              </div>
            ),
            props: {
              style: { backgroundColor: "#F3F7FD" },
              colSpan: isSalesInvoice ? 5 : 6,
            },
          };
        }
      },
    },
    {
      title: "Description",
      dataIndex: "selectedDescription",
      editable: true,
      width: 350,
      render: (_, record) => {
        if (record.rowType === ROW_TYPE_SUBTOTAL) {
          return {
            props: { colSpan: 0 },
          };
        }
        return record.id ? (
          <Row wrap={false} justify="space-between" align="middle" gutter={8}>
            <Col>{_}</Col>
          </Row>
        ) : (
          <span style={{ color: COLOR_TEXT_GRAY_1 }}>-</span>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "selectedQty",
      editable: true,
      width: 80,
      render: (_, record) => {
        if (record.rowType === ROW_TYPE_SUBTOTAL) {
          return {
            props: { colSpan: 0 },
          };
        }
        return {
          children: _,
        };
      },
    },
    {
      title: "Rate",
      dataIndex: "selectedRate",
      editable: true,
      width: 276,
      render: (_, record) => {
        if (record.rowType === ROW_TYPE_SUBTOTAL) {
          return {
            props: { colSpan: 0 },
          };
        }
        return record.selectedRate;
      },
    },
    {
      title: "Duration",
      dataIndex: "selectedDuration",
      editable: isSalesInvoice ? false : true,
      width: isSalesInvoice ? 0 : 75,
      render: (_, record) => {
        if (record.rowType === ROW_TYPE_SUBTOTAL || isSalesInvoice) {
          return {
            props: { colSpan: 0 },
          };
        }
        return {
          children: _,
        };
      },
    },
    {
      title: "Taxable",
      dataIndex: "selectedTaxable",
      // editable: true,
      width: 50,
      render: (_, record) => {
        if (record.rowType === ROW_TYPE_SUBTOTAL)
          return {
            props: { colSpan: 0 },
          };
        return (
          <div style={{ width: "100%", paddingLeft: "16px" }}>
            <ItemTaxableCell
              record={record}
              handleTaxableChange={handleTaxableChange}
            />
          </div>
        );
      },
    },
    {
      title: <div style={{ width: "100%", textAlign: "right" }}>Total</div>,
      dataIndex: "total",
      width: 120,
      render: (_, record) => {
        const handleUpdateSubtotal = (
          selectedItems,
          record,
          updateSubtotal
        ) => {
          const reversedItems = [...selectedItems.items].reverse();
          const index = reversedItems.findIndex((i) => i.key === record.key);
          let end = false;
          let subtotal = 0;
          reversedItems.slice(index + 1).map((i) => {
            if (i.rowType === ROW_TYPE_SUBTOTAL) {
              end = true;
            }
            if (end) return;
            subtotal = subtotal + i.rowTotal;
          });
          if (record.rowTotal === subtotal) return subtotal.toFixed(2);
          updateSubtotal(record.key, subtotal);
        };

        const configureAmtOff = () => {
          if (record.discountType === "percent") {
            return (
              record.rowTotal / (1 - record.discountPercent) -
              record.rowTotal
            ).toFixed(2);
          } else if (record.discountType === "number") {
            return record.discountNumber.toFixed(2);
          }
        };
        return {
          children: (
            <div style={{ textAlign: "right", width: "100%" }}>
              {record.rowType === ROW_TYPE_SUBTOTAL ? (
                <Space>
                  {record.hasDiscount && (
                    <Tooltip
                      title={FormatToLocalCurrency(
                        record.hasDiscount && configureAmtOff(),
                        orgData.countryCode,
                        orgData.languageCode,
                        orgData.currencyCode
                      )}
                    >
                      <TagFilled
                        style={{ marginRight: "8px", color: "#ffb700" }}
                      />
                    </Tooltip>
                  )}
                </Space>
              ) : null}

              <div
                style={{
                  fontWeight:
                    record.rowType === ROW_TYPE_SUBTOTAL ? "bold" : "regular",
                }}
              >
                { FormatToLocalCurrency(
                  record.rowType !== ROW_TYPE_SUBTOTAL
                    ? record.rowTotal.toFixed(2)
                    : handleUpdateSubtotal(
                        selectedItems,
                        record,
                        updateSubtotal
                      ),
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </div>
            </div>
          ),
          props: {
            style: {
              backgroundColor:
                record.rowType === ROW_TYPE_SUBTOTAL ? "#F3F7FD" : null,
            },
          },
        };
      },
    },
    {
      title: "",
      dataIndex: "",
      width: 40,
      render: (_, record) => {
        return {
          children: (
            <RemoveInvItemBtn handleClick={() => handleRemove(record.key)} />
          ),
          props: {
            style: {
              backgroundColor:
                record.rowType === ROW_TYPE_SUBTOTAL ? "#F3F7FD" : null,
            },
          },
        };
      },
    },
  ];

  if (isSalesInvoice) {
    columns.find((c) => c.dataIndex === 'selectedDuration').render = (_, record) => {
        return "--"
    }
  }

  return columns;
};
