import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
} from "../modelConstants/modelConstants";

export const ConfigureStopFromInvoice = ({ invoice, stopType }) => {
  const address = () => {
    if (invoice?.[stopType] === RECEIVE_METHOD_DELIVERY) {
      return invoice?.deliveryCustomerShippingAddressStr ?? "";
    } else if (invoice?.[stopType] === RETURN_METHOD_ONSITE) {
      return invoice?.onSiteCustomerShippingAddressStr ?? "";
    } else {
      return "";
    }
  };
  const coordinates = () => {
    if (invoice?.[stopType] === RECEIVE_METHOD_DELIVERY) {
      return {
        addrValidated: invoice?.deliveryAddrValidated ?? false,
        lat: invoice?.deliveryLat ?? 0,
        lng: invoice?.deliveryLng ?? 0,
      };
    } else if (invoice?.[stopType] === RETURN_METHOD_ONSITE) {
      return {
        addrValidated: invoice?.onSiteAddrValidated ?? false,
        lat: invoice?.onSiteLat ?? 0,
        lng: invoice?.onSiteLng ?? 0,
      };
    } else {
      return {
        addrValidated: false,
        lat: 0,
        lng: 0,
      };
    }
  };

  const generateCoordinates = () => {
    const index = Math.floor(Math.random() * 10);
    const list = [
      {
        addrValidated: true,
        lat: 37.7678927,
        lng: -122.3874072,
      },
      {
        addrValidated: true,
        lat: 37.770986609499715,
        lng: -122.39140698876044,
      },
      {
        addrValidated: true,
        lat: 37.77762780212085,
        lng: -122.39427651275933,
      },
      {
        addrValidated: true,
        lat: 37.77330840694998,
        lng: -122.40492080197805,
      },
      {
        addrValidated: true,
        lat: 37.79114176600161,
        lng: -122.4053991778772,
      },
      {
        addrValidated: true,
        lat: 37.76197448885595,
        lng: -122.42381210513115,
      },
      {
        addrValidated: true,
        lat: 37.7494023911512,
        lng: -122.4161429782297,
      },
      {
        addrValidated: true,
        lat: 37.73131385919186,
        lng: -122.4292357881862,
      },
      {
        addrValidated: true,
        lat: 37.707569516891176,
        lng: -122.414650658993,
      },
      {
        addrValidated: true,
        lat: 37.72389456977841,
        lng: -122.39328737847352,
      },
    ];
    return list[index];
  };

  const mockCoordinates = () => {
    return generateCoordinates();
  };

  const specifiedTimes = () => {
    let start = null;
    let end = null;
    if (invoice?.[stopType] === RECEIVE_METHOD_DELIVERY) {
      start = invoice?.specifiedReceiveTime ?? null;
      end = invoice?.specifiedReceiveTimeEnd ?? null;
    } else if (invoice?.[stopType] === RETURN_METHOD_ONSITE) {
      start = invoice?.specifiedReturnTime ?? null;
      end = invoice?.specifiedReturnTimeEnd ?? null;
    }
    return {
      timeWindowStart: start,
      timeWindowEnd: end,
      hasTimeWindow: start !== null,
    };
  };

  return {
    index: null, // index order
    displayName: invoice?.customer?.customerDisplayName ?? "",
    itemCount: invoice?.qrTotal ?? 0,
    polylineStr: "",
    type: invoice?.[stopType], // onSite, delivery, waypoint
    routeId: null, // id of route
    invoiceId: invoice?.id ?? null, // id of invoice
    address: address(), // address
    waypointMeta: {
      title: "",
      description: "",
      phone: null,
      time: null, //optional
      type: null,
    },
    hasSharedStops: false,
    sharedStopInvoiceIds: [],
    ...coordinates(),
    ...specifiedTimes(),
    invoice: invoice,
  };
};
