import React from "react";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import { COLOR_TEXT_GRAY_1 } from "../../../../../app/ui/colorConstants";

export const EmptyTableText = ({ text }) => {
  return (
    <div style={{ width: "100%", textAlign: "center", padding: "8px" }}>
      <TextBody
        style={{ color: COLOR_TEXT_GRAY_1 }}
        text={text ? text : "No items"}
      />
    </div>
  );
};

export const EmptyTable = () => {
  return (
    <div style={{ width: "100%", textAlign: "center", padding: "8px" }}>
      <TextBody style={{ color: COLOR_TEXT_GRAY_1 }} text={"No items"} />
    </div>
  );
};
