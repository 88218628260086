import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import GeneralInfo from "./components/GeneralInfo";
import GeneralInvoiceSettings from "./components/GeneralInvoiceSettings";
import TagsView from "./components/TagsView";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import { Tooltip } from "@mui/material";

const AdminSettingsGeneral = () => {
  const [tabType, setTabType] = useState("general"); // general, invoice, tags
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);


  const renderTagsTabLabel = (label, conditionForTooltip, tooltipText) => (
    conditionForTooltip
      ? <Tooltip title={tooltipText}><span>{label}</span></Tooltip>
      : label
  );

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Settings" />
      </Affix>
      <div style={{ paddingTop: "68px" }}>
        <div style={{ width: "100%", marginLeft: '14px' }}>
          <Tabs
            defaultActiveKey="general"
            activeKey={tabType}
            onChange={setTabType}
          >
            <TabPane tab="General" key="general" />
            <TabPane tab="Invoice" key="invoice" />
            <TabPane disabled={!checkSubscriptionLevel("settings.tags", orgData)} 
            tab={
              renderTagsTabLabel(
                "Tags", 
                !checkSubscriptionLevel("settings.tags", orgData), 
                "Upgrade your subscription to access Tags"
              )}
           
             key="tags" />
          </Tabs>
        </div>

        {tabType === "general" && (<GeneralInfo />)}
        {tabType === "invoice" && (<GeneralInvoiceSettings />)}
        {tabType === "tags" && (< TagsView/>)}

      </div>
    </div>
  );
};

export default AdminSettingsGeneral;
