/**
 * Adds UTC offset to invoice dates on save and create
 * Currently using MST as UTC offset (420m)
 */
import {
  formatDateOnRead,
  formatDateOnWrite,
} from "../../../time/formatTimezoneOffset";

export const addOrgUtcOffsetToInvoice = ({ invoice, orgTimezone }) => {
  const {
    specifiedReceiveTime,
    specifiedReturnTime,
    specifiedReceiveTimeEnd,
    specifiedReturnTimeEnd,
    rentalTurnaroundEnd,
    rentalDateEnd,
    rentalDateStart,
    rentalDates,
    invoicePaymentDueDate,
    mobileStartDate,
    mobileEndDate,
    eventDate,
    ...rest
  } = invoice;

  const onWrite = () => {
    const configureOffsetRentalDatesForWrite = ({
      rentalDates,
      orgTimezone,
    }) => {
      if (!rentalDates[0] || !rentalDates[1]) return [];
      const start = formatDateOnWrite({ date: rentalDates[0], orgTimezone });
      const end = formatDateOnWrite({ date: rentalDates[1], orgTimezone });
      return [start, end];
    };
    
    const offsetRentalDates = configureOffsetRentalDatesForWrite({
      rentalDates,
      orgTimezone,
    });

    const mobileStart =
      offsetRentalDates.length !== 0
        ? offsetRentalDates[0]?.toISOString().substring(0, 10)
        : null;

    const mobileEnd = new Date(rentalDates[1]);
    mobileEnd.setHours(12, 0, 0);

    const mobileEndIsValid = !isNaN(mobileEnd.getTime());
    const mobileEndBeforeOffset = mobileEndIsValid
      ? mobileEnd?.toISOString().substring(0, 10)
      : null;

    return {
      specifiedReceiveTime: specifiedReceiveTime
        ? formatDateOnWrite({ date: specifiedReceiveTime, orgTimezone })
        : null,
      specifiedReturnTime: specifiedReturnTime
        ? formatDateOnWrite({ date: specifiedReturnTime, orgTimezone })
        : null,
      specifiedReceiveTimeEnd: specifiedReceiveTimeEnd
        ? formatDateOnWrite({ date: specifiedReceiveTimeEnd, orgTimezone })
        : null,
      specifiedReturnTimeEnd: specifiedReturnTimeEnd
        ? formatDateOnWrite({ date: specifiedReturnTimeEnd, orgTimezone })
        : null,
      rentalTurnaroundEnd: rentalTurnaroundEnd
        ? formatDateOnWrite({ date: rentalTurnaroundEnd, orgTimezone })
        : null,
      rentalDateEnd: rentalDateEnd
        ? formatDateOnWrite({ date: rentalDateEnd, orgTimezone })
        : null,
      rentalDateStart: rentalDateStart
        ? formatDateOnWrite({ date: rentalDateStart, orgTimezone })
        : null,
      eventDate: eventDate
        ? formatDateOnWrite({ date: eventDate, orgTimezone })
        : null,

      rentalDates: offsetRentalDates,
      invoicePaymentDueDate: invoicePaymentDueDate
        ? formatDateOnWrite({ date: invoicePaymentDueDate, orgTimezone })
        : null,
      mobileStartDate: mobileStart,
      mobileEndDate: mobileEndBeforeOffset,
      ...rest,
    };
  };
  const onRead = () => {
    const configureOffsetRentalDatesForRead = ({
      rentalDates,
      orgTimezone,
    }) => {
      if (!rentalDates[0] || !rentalDates[1]) return [];
      const start = formatDateOnRead({ date: rentalDates[0], orgTimezone });
      const end = formatDateOnRead({ date: rentalDates[1], orgTimezone });
      return [start, end];
    };
    const offsetRentalDates = configureOffsetRentalDatesForRead({
      rentalDates,
      orgTimezone,
    });
    return {
      specifiedReceiveTime: specifiedReceiveTime
        ? formatDateOnRead({ date: specifiedReceiveTime, orgTimezone })
        : null,
      specifiedReturnTime: specifiedReturnTime
        ? formatDateOnRead({ date: specifiedReturnTime, orgTimezone })
        : null,
      specifiedReceiveTimeEnd: specifiedReceiveTimeEnd
        ? formatDateOnRead({ date: specifiedReceiveTimeEnd, orgTimezone })
        : null,
      specifiedReturnTimeEnd: specifiedReturnTimeEnd
        ? formatDateOnRead({ date: specifiedReturnTimeEnd, orgTimezone })
        : null,
      rentalTurnaroundEnd: rentalTurnaroundEnd
        ? formatDateOnRead({ date: rentalTurnaroundEnd, orgTimezone })
        : null,
      rentalDateEnd: formatDateOnRead({ date: rentalDateEnd, orgTimezone }),
      rentalDateStart: formatDateOnRead({ date: rentalDateStart, orgTimezone }),
      eventDate: eventDate
        ? formatDateOnRead({ date: eventDate, orgTimezone })
        : null,
      rentalDates: configureOffsetRentalDatesForRead({
        rentalDates,
        orgTimezone,
      }),
      mobileStartDate,
      mobileEndDate,
      invoicePaymentDueDate: invoicePaymentDueDate
        ? formatDateOnRead({ date: invoicePaymentDueDate, orgTimezone })
        : null,
      ...rest,
    };
  };
  return { onWrite, onRead };
};
