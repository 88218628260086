import { TAX_RATES_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { CheckTaxRates } from "../../../utils/models/checkers/checkTaxRates";
import Nifty from "../../../utils/Nifty";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { TaxRate } from "../../../interfaces/taxRate";

export function taxRateQueries() {
  async function fetchTaxRates(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string
  ) {
    const ref = firestore.collection(`${orgPrefix}${TAX_RATES_COLLECTION}`);
    const snap = await ref.get();
    const rates = snap.docs.map((s) => CheckTaxRates(s));
    
    const sorted: TaxRate[] = Nifty.SortByString(rates, "name", "ascending ");
    return sorted;
  }
  async function fetchActiveTaxRates(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string
  ) {
    const ref = firestore.collection(`${orgPrefix}${TAX_RATES_COLLECTION}`);
    const snap = await ref.where("isActive", "==", true).get();
    const rates = snap.docs.map((s) => CheckTaxRates(s));

    const sorted: TaxRate[] = Nifty.SortByString(rates, "name", "ascending ");
    return sorted;
  }
  return {
    fetchTaxRates: fetchTaxRates, // fetch rates
    fetchActiveTaxRates: fetchActiveTaxRates, // fetch active rates
  };
}
