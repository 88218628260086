export const genKeyValueList = (payload) => {
  let list = [];
  for (const [k, v] of Object.entries(payload)) {
    list.push({
      key: k,
      value: v,
    });
  }
  return list;
};
