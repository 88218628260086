export const configureCategory = (formValues, createdById, orgId) => {
  return {
    orgId: orgId,
    name: formValues.name,
    description: formValues.description ? formValues.description : "",
    createdBy: createdById,
    createdOn: new Date(),
    updatedOn: new Date(),
    updatedBy: createdById,
  };
};
