import React, { useState, useEffect } from "react";
import { Popover, Space, Tag, Tooltip } from "antd";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";

const { Text } = Typography;

const BundleItemsSummary = ({ data, availableInventory, item, currentQuantity }) => {

  console.log("BundleItemsSummary data: ", data);
  console.log("BundleItemsSummary availableInventory: ", availableInventory);
  console.log("BundleItemsSummary item: ", item);
  console.log("BundleItemsSummary currentQuantity: ", currentQuantity);
  
  const [bundleContainsUnavail, setBundleContainsUnavail] = useState(false);
  const [bundleContainsAtCapacity, setBundleContainsAtCapacity] = useState(false);
  const responsiveFontSize = useResponsiveFontSize();

  useEffect(() => {
    if (data.type === INV_BUNDLE_ITEM) {
      const containsUnavail = data.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock < 0;
      });
      const containsAtCapacity = data.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const bundleItemQty = b?.bundleItemQty ?? 0;
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock === 0;
      });

      setBundleContainsUnavail(containsUnavail);
      setBundleContainsAtCapacity(containsAtCapacity);
    }
  }, [data, availableInventory]);


  const bundleItems = (record) => {
    return (
      <div>
        {record.bundleItems.map((b, index) => {

          /* bundleItem is just a InventoryItem type with the additional fields: 
            availableStock
            unavailableStock
            unavailableEstStock
          */

          const bundleItem = availableInventory?.find((i) => {
            return i.id === b.bundleItemId;
          });

          const bundleItemQty = b?.bundleItemQty ?? 0;
          const availableStock = (bundleItem?.availableStock ?? 0) - (currentQuantity * bundleItemQty);

          // Determine background color and text color based on available stock
          let backgroundColor, color;

          if (availableStock === 0) {
            backgroundColor = COLOR_YELLOW_0;
            color = "#FFF";
          } else if (availableStock < bundleItemQty) {
            backgroundColor = COLOR_RED_0;
            color = "#FFF";
          } else {
            backgroundColor = COLOR_LIGHT_GRAY;
            color = COLOR_DARK_BG_0;
          }

          return (
            <div key={index}>
              <Space>
                <CategoryTag
                  style={{
                    backgroundColor,
                    margin: "2px 4px 2px 0px",
                    color,
                    borderColor: "transparent",
                  }}
                  text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                />
                <TextBody style={{fontSize: responsiveFontSize}} text={b[INV_BUNDLE_ITEM_NAME]} />
                <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
                <TextSubtitle text={`(${availableStock} left)`} />
              </Space>
            </div>
          );
        })}
        <br />
        {bundleContainsUnavail && (
          <Space>
            <Text
              style={{
                fontSize: "13px",
                color: COLOR_RED_0,
              }}
            >
              Some items are overbooked during the selected range
            </Text>
          </Space>
        )}

        {bundleContainsAtCapacity && !bundleContainsUnavail && (
            <Space>
              <Text
                style={{
                  fontSize: "13px",
                  color: COLOR_YELLOW_0,
                }}
              >
                Some items are at capacity during the selected range
              </Text>
            </Space>
          )}
      </div>
    );
  };

  const regularTag = () => {
    return <Tag>{capitalizeFirstLetter(data?.type ?? "...")}</Tag>;
  };

  return (
    <div>
      {data?.type !== undefined && data.type === INV_BUNDLE_ITEM
        ? bundleItems(data)
        : regularTag()}
    </div>
  );
  };

export default BundleItemsSummary;