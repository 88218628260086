import { useEffect, useState } from "react";

import { useFirestore } from "react-redux-firebase";
import notificationError from "../../system-components/toasters/notificationError";
import { invoiceWrites } from "../firestore/writes/invoiceWrites";
import notificationConfirm from "../../system-components/toasters/notificationConfirm";
import { UpdateParentCompletedFromChild } from "./helpers/qrItems/updateParentCompletedFromChild";
import { DetermineInvoiceStatus } from "./helpers/qrItems/determineInvoiceStatus";
import { GetInventoryFromInvoices } from "./helpers/qrItems/getInventoryFromInvoices";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";

export function useBulkUpdateQrItem({
  invoiceCallback,
  dayInvoices,
  selectedInventory,
}) {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });
  const [completed, setCompleted] = useState(0);
  const [qtyRented, setQtyRented] = useState(0);
  const [flagged, setFlagged] = useState(false);
  const [dayInventoryCompleted, setDayInventoryCompleted] = useState(0);
  const [notes, setNotes] = useState(null);
  const [issueDescription, setIssueDescription] = useState("");
  const { authenticated, fsOrgPrefix } = useSelector(authSelector);

  const firestore = useFirestore();

  const callback = async (data) => {
    if (!invoiceCallback) return;
    invoiceCallback(data);
  };
  const updateToFirestore = async (id, payload) => {
    await invoiceWrites({ firestore }, fsOrgPrefix).updateInvoice(id, payload);
  };
  const errorNotif = () => {
    notificationError("Changes could not be saved", "Refresh the page");
  };

  const updateDayInventoryItem = async ({ count }) => {
    if (!dayInvoices || !selectedInventory) return;
    const currentCount = selectedInventory.completed
      ? selectedInventory.completed
      : 0;
    let remaining = count < 0 ? 0 : count;

    const itemId = selectedInventory.id;
    /*
     ** Filter out invoices that don't have the item
     */
    const filteredInvoices = dayInvoices.filter((i) => {
      return i.qrItems.some((item) => item.id === itemId);
    });
    const updatedInvoices = filteredInvoices.map((invoice) => {
      /*
       ** Determine if selectedInventory has bundle children, stand alone, or both
       */
      const hasBundleChildren = selectedInventory.itemList.find(
        (i) => i.isBundleChild && i.id === itemId
      );
      const hasStandAlone = selectedInventory.itemList.find(
        (i) =>
          !i.isBundleChild &&
          i.bundleChildrenIds.length === 0 &&
          i.id === itemId
      );
      let updateParentBundle = null;

      const { qrItems, qrStatus, ...rest } = invoice;
      const updatedQrItems = qrItems.map((qrItem) => {
        /*
         ** Determine if item is bundle child or stand alone
         */
        const isBundleChild = qrItem.isBundleChild;
        const isStandAlone =
          !qrItem.isBundleChild && qrItem.bundleChildrenIds.length === 0;
        const { completed, invoiceNumber, invoiceId, ...rest } = qrItem;
        /*
         ** Guard Statements
         */
        if (qrItem.id !== itemId) return { completed: completed, ...rest }; // return obj if inv items are diff
        // if (remaining === 0) return { completed: completed, ...rest }; // return obj if 0 remaining
        // if (QrItemCompleted(qrItem)) return { completed: completed, ...rest }; // return completed objs
        if (
          (isBundleChild && hasBundleChildren) ||
          (isStandAlone && hasStandAlone)
        ) {
          /*
           ** Handle updating main bundle parents if applicable
           */
          if (isBundleChild) {
            // find parent to get other items in bundle to calculate lowest ratio
            const parent = qrItems.find((p) => p.id === qrItem.bundleParentId);
            if (!parent) return;
            updateParentBundle = parent;
          }
          /*
           ** Calculate how much is needed to fulfill
           */
          const needed = qrItem.qtyRented;
          if (needed <= remaining) {
            // remaining covers needed
            remaining = remaining - needed;
            return {
              completed: qrItem.qtyRented,
              ...rest,
            };
          } else if (needed > remaining) {
            // remaining doesn't cover needed. fill what's left
            const fill = remaining;
            remaining = 0;
            return {
              completed: fill,
              ...rest,
            };
          }
        }
      });
      /*
       ** Determine if we need to update parent bundles
       */
      const newItems = updateParentBundle
        ? UpdateParentCompletedFromChild(updateParentBundle, updatedQrItems)
        : updatedQrItems;
      return {
        qrItems: newItems,
        qrStatus: DetermineInvoiceStatus(newItems),
        ...rest,
      };
    });
    const stateInvoices = dayInvoices
      .filter((i) => {
        return !i.qrItems.some((item) => item.id === itemId);
      })
      .concat(updatedInvoices);
    const updatedSelected = GetInventoryFromInvoices(updatedInvoices).find(
      (i) => i.id === itemId
    );
    /*
     ** Update local state
     */
    setDayInventoryCompleted(updatedSelected.completed);
    callback({
      invoices: [...stateInvoices],
      selectedInventory: updatedSelected,
    });
    if (updatedSelected.completed === updatedSelected.qtyRented) {
      notificationConfirm(`${updatedSelected.name} completed`);
    }
    /*
     ** Configure firestore promise payloads
     */
    const updatedInvoicePromises = updatedInvoices.map((i) => {
      return {
        invoiceId: i.id,
        payload: { qrItems: i.qrItems, qrStatus: i.qrStatus },
      };
    });
    try {
      const promises = updatedInvoicePromises.map(
        (data) =>
          new Promise((resolve, reject) => {
            invoiceWrites({ firestore }, fsOrgPrefix)
              .updateInvoice(data.invoiceId, data.payload)
              .then(() => {
                resolve();
              });
          })
      );
      Promise.all(promises).then((res) => {
        console.log("invoice updates completed");
      });
    } catch (err) {
      console.log(err);
      errorNotif();
    }
  };

  useEffect(() => {}, [authenticated, dayInvoices, selectedInventory]);

  return {
    completed: completed,
    qtyRented: qtyRented,
    dayInventoryCompleted: dayInventoryCompleted,
    callback: callback,
    updateDayInventoryItem: updateDayInventoryItem,
  };
}
