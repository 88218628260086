import React, { useContext } from "react";
import { Col } from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import {
  ROUTE_ROLE_CREW,
  ROUTE_ROLE_DRIVER,
  ROUTE_ROLE_LOADER,
} from "../../../../app/utils/models/modelConstants";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import { RouteAssignmentsContext } from "../RouteAssignments";
const FinalAssignmentList = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  return (
    <div>
      <Col span={24} style={{ marginBottom: "16px" }}>
        <TextSubtitle text={"DRIVERS"} />
        <br />
        {state.selectedRoute?.routeData?.assignedEmployees
          ?.filter((u) => u.roles.includes(ROUTE_ROLE_DRIVER))
          .map((d) => (
            <CategoryTag text={d?.name} />
          ))}
      </Col>
      <Col span={24} style={{ marginBottom: "16px" }}>
        <TextSubtitle text={"CREW"} />
        <br />
        {state.selectedRoute?.routeData?.assignedEmployees
          ?.filter((u) => u.roles.includes(ROUTE_ROLE_CREW))
          .map((d) => (
            <CategoryTag text={d?.name} />
          ))}
      </Col>
      <Col span={24} style={{ marginBottom: "16px" }}>
        <TextSubtitle text={"LOADERS"} />
        <br />
        {state.selectedRoute?.routeData?.assignedEmployees
          ?.filter((u) => u.roles.includes(ROUTE_ROLE_LOADER))
          .map((d) => (
            <CategoryTag text={d?.name} />
          ))}
      </Col>
    </div>
  );
};

export default FinalAssignmentList;
