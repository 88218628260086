import { useCustomerPagination } from "./useCustomerPagination";
import { CUSTOMER_COLLECTION } from "../../../../utils/models/collections/collectionConstants";
import { useCustomerSearchArray } from "./useCustomerSearchArray";
import { useSelector } from "react-redux";

import { useState } from "react";
import Nifty from "../../../../utils/Nifty";
import { authSelector } from "../../../../../domains/auth/authSlice";

export function useCustomerSearch() {
  const { fsOrgPrefix } = useSelector(authSelector);
  const [dataSource, setDataSource] = useState(SOURCE_PAGINATED);
  const [inserted, setInserted] = useState([]);

  const PaginatedQuery = useCustomerPagination(
    `${fsOrgPrefix}${CUSTOMER_COLLECTION}`,
    "customerDisplayName",
    100
  );
  const SearchQuery = useCustomerSearchArray();

  const onSearch = (str) => {
    const searchActive = str?.length > 2;
    if (!searchActive) return searchInUse(false);

    const lowercase = `${str}`.toLowerCase();
    SearchQuery.actions.setStrWithThrottle(lowercase);
    searchInUse(true);
  };

  const searchInUse = (inUse) => {
    if (!inUse) return setDataSource(SOURCE_PAGINATED);
    return setDataSource(SOURCE_SEARCH);
  };

  const configureSource = (source) => {
    let data =
      source === SOURCE_PAGINATED
        ? PaginatedQuery.state.data
        : SearchQuery.state.data;
    if (inserted.length === 0) return data;

    let added = [];
    inserted.map((i, index) => {
      const exists = Nifty.FindObject(data, "id", i.id);
      if (!exists) return added.push(i);
      Nifty.ReplaceObjectInArray(data, "id", i.id, i);
    });
    return [...added, ...data];
  };

  const loadDefault = () => {
    PaginatedQuery.actions.getInitialCustomers();
    searchInUse(false);
  };

  const loadNext = () => {
    PaginatedQuery.actions.loadNext();
    searchInUse(false);
  };

  const refreshDefault = () => {
    PaginatedQuery.actions.refreshInitialLoad();
    searchInUse(false);
  };

  const insertRecord = (record) => {
    const latest = Nifty.FilterObjectsNotMatching(inserted, "id", record.id);
    setInserted([record, ...latest]);
  };

  const insertRecordWithReload = (record) => {
    refreshDefault();
    insertRecord(record);
  };

  return {
    state: {
      dataSource,
      data: [...configureSource(dataSource)],
      busy: PaginatedQuery.state.busy || SearchQuery.state.busy,
      allResultsLoaded: PaginatedQuery.state.allResultsLoaded,
    },
    actions: {
      onSearch,
      loadDefault,
      loadNext,
      refreshDefault,
      insertRecord,
      insertRecordWithReload,
    },
  };
}

const SOURCE_PAGINATED = "paginatedQuery";
const SOURCE_SEARCH = "searchQuery";
