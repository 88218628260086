import notificationError from "../../../../app/system-components/toasters/notificationError";

export const StopPayload = (stop) => {
  const data = {
    index: stop?.index ?? null, // index order
    displayName: stop?.displayName ?? "",
    itemCount: stop?.itemCount ?? 0,
    polylineStr: stop?.polylineStr ?? "",
    fromLat: stop?.fromLat ?? 0,
    fromLng: stop?.fromLng ?? 0,
    updatePolyline: false,
    type: stop?.type ?? "", // pickup, dropoff, waypoint
    routeId: stop?.routeId ?? null, // id of route
    invoiceId: stop?.invoiceId ?? null, // id of invoice
    address: stop?.address ?? "", // address
    addrValidated: stop?.addrValidated ?? false, // validated address
    lat: stop?.lat ?? 0,
    lng: stop?.lng ?? 0,
    hasSharedStops: stop?.hasSharedStops ?? false,
    sharedStopInvoiceIds: stop?.sharedStopInvoiceIds ?? [],
    waypointMeta: {
      title: stop?.waypointMeta?.title ?? "",
      description: stop?.waypointMeta?.description ?? "",
      phone: stop?.waypointMeta?.phone ?? null,
      time: stop?.waypointMeta?.time ?? null, //optional
      type: stop?.waypointMeta?.type ?? null,
    },
    timeWindowStart: stop?.timeWindowStart ?? null,
    timeWindowEnd: stop?.timeWindowEnd ?? null,
    hasTimeWindow: stop?.hasTimeWindow ?? false,
    invoice: stop?.invoice ?? null,
  };

  const payloadErr = (msg) => {
    return notificationError("Error with stop data", msg);
  };
  const updateValue = ({ key, value }) => {
    /**
     * Update single value and return updated payload
     */
    if (!key || !value) return payloadErr("Key or value not specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === key) {
        payload[k] = value;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updateValues = ({ list }) => {
    /**
     * Update values and return updated payload
     * list must be array of { key:str, value:any }
     */
    if (!list) return payloadErr("No list specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      const update = list.find((i) => i.key === k);
      if (!update) {
        payload[k] = v;
      } else {
        payload[k] = update.value;
      }
    }
    return payload;
  };
  const updateIndex = (index) => {
    /**
     * Update index
     */
    if (isNaN(index)) return payloadErr("Invalid index");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "index") {
        payload[k] = index;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updatePolyline = (polylineStr) => {
    /**
     * Update polyline
     */
    if (!polylineStr) return payloadErr("No polyline specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "polylineStr") {
        payload[k] = polylineStr;
      } else if (k === "updatePolyline") {
        payload[k] = false;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updateRouteId = (routeId) => {
    /**
     * Update Route Id
     */
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "routeId") {
        payload[k] = routeId;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updateAddress = (address) => {
    /**
     * Update Address Str
     */
    if (!address) return payloadErr("No address specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "address") {
        payload[k] = address;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const validateAddress = () => {
    /**
     * Validate Address
     */
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "addrValidated") {
        payload[k] = true;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const invalidateAddress = () => {
    /**
     * Invalidate Address
     */
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "addrValidated") {
        payload[k] = false;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updateCoordinates = (lat, lng) => {
    /**
     * Update lat lng
     */
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "lat") {
        payload[k] = lat;
      } else if (k === "lng") {
        payload[k] = lng;
      } else if (k === "updatePolyline") {
        payload[k] = true;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  return {
    updateIndex,
    updateValue,
    updateValues,
    updateAddress,
    updatePolyline,
    updateCoordinates,
    updateRouteId,
  };
};
