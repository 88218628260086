import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFirebase } from "react-redux-firebase";

export const EditCustomerQboSync = ({ customer, orgId }) => {
  const firebase = useFirebase();

  const [syncing, setSyncing] = useState(false);
  const [syncSucceeded, setSyncSucceeded] = useState(false);
  const [error, setError] = useState(null)

  const syncCustomer = async () => {
    setSyncing(true);

    const call = firebase.functions().httpsCallable("manualCustomerSync");

    return call({
      orgId,
      customerId: customer.id,
    })
      .then(async (res) => {
        console.log(res);
        const status = res.data.status;
        if (status === 200) {
          setSyncSucceeded(true);
        } else if (status === 500) {
          const errorDataArray = res.data.error.Fault.Error;
          const errorData = errorDataArray[0];
          const error = errorData || {
            Message: "Sync Failed",
            Detail: "Sorry, we do not know why it failed. Please contact support.",
            Code: "ERROR_CODE",
          };

          setError(error)
        }

        setSyncing(false);
      })
      .catch((err) => {
        setSyncing(false);
        console.log(err);
      });
  };

  return (
    <>
      {customer && customer.qbId && customer.qbId !== "" && (
        <Typography variant="body">
          Nothing to do here! This customer is already synced to QuickBooks.
        </Typography>
      )}

      {customer && (!customer.qbId || customer.qbId === "") && (
        <>
          <Stack>
            {!syncSucceeded && (
              <Typography variant="body" color={"darkred"}>
                This customer has not been properly synced with your QuickBooks
                account.
              </Typography>
            )}

            {syncSucceeded && (
              <Typography variant="body" color={"darkgreen"}>
                Sync successful!
              </Typography>
            )}
            <br />

            {error && (
              <Stack direction={"column"}>
                <Typography color={"darkred"}>Sync Failed. Review the reasons below, correct the issue, save customer, and try again.</Typography>
                <br />
                <Typography color={"darkred"}>
                  {error?.Message ?? ""}
                </Typography>
                <Typography gutterBottom color={"darkred"}>
                  {error?.Detail ?? ""}
                </Typography>
              </Stack>
            )}

            {!syncSucceeded && (
              <LoadingButton
                style={{ width: "200px" }}
                variant="contained"
                color="primary"
                loading={syncing}
                loadingPosition="end"
                onClick={syncCustomer}
                disabled={syncing}
              >
                Sync Customer
              </LoadingButton>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
