import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { checkUndefined } from "./checkUndefined";

export const CheckTaxRates = (
  snap: QueryDocumentSnapshot<DocumentData>,
  isProcessed?: boolean
) => {
  const data: DocumentData = isProcessed ? snap : snap.data();

  return {
    id: snap?.id ?? "",
    name: checkUndefined(data.name, ""),
    orgId: checkUndefined(data.orgId, ""),
    origin: checkUndefined(data.origin, "adelie"),
    isActive: checkUndefined(data.isActive, false),
    rate: checkUndefined(data.rate, 0),
    qbId: checkUndefined(data.qbId, null)
  };
};
