import React, { useContext } from "react";
import { configureMethodText } from "../../helpers/configureMethodText";
import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../../../../app/utils/models/modelConstants/modelConstants";
import {
  DeliveryTag,
  PickupTag,
  WaypointTag,
} from "../../../../../app/system-components/tags/RoutesTags";
import { Button, Space } from "antd";
import { WaypointModalContext } from "../../CreateRoutes";

const ReceiveReturnMethodTag = ({ typeLabel, stop, hideEdit }) => {
  const modal = useContext(WaypointModalContext);
  const type = stop?.type ?? "";
  return (
    <div>
      {type === RECEIVE_METHOD_DELIVERY ? (
        <DeliveryTag text={typeLabel} />
      ) : type === RETURN_METHOD_ONSITE ? (
        <PickupTag text={typeLabel} />
      ) : type === STOP_TYPE_WAYPOINT ? (
        <Space size={2}>
          <WaypointTag text={typeLabel} />
          {!hideEdit && (
            <Button
              size={"small"}
              type={"link"}
              style={{ fontSize: "12px" }}
              onClick={() => modal.loadData(stop).then(() => modal.show())}
            >
              Edit{" "}
            </Button>
          )}
        </Space>
      ) : null}
    </div>
  );
};

export default ReceiveReturnMethodTag;
