import React, { createContext } from "react";
import { Row, Spin } from "antd";
import { useRouteAssignments } from "../../../app/services/hooks/useRouteAssignments";
import FinalRouteDetails from "./components/FinalRouteDetails";
import AssignmentUserList from "./components/AssignmentUserList";
import AssignedRouteList from "./components/AssignedRouteList";

export const RouteAssignmentsContext = createContext();
const RouteAssignments = ({ date }) => {
  const RouteAssignmentMgr = useRouteAssignments({ date });
  return (
    <div>
      <RouteAssignmentsContext.Provider value={RouteAssignmentMgr}>
        <Spin spinning={RouteAssignmentMgr.state.loadingInitialFetch}>
          <Row wrap={false}>
            <AssignedRouteList />
            <AssignmentUserList />
            <FinalRouteDetails />
          </Row>
        </Spin>
      </RouteAssignmentsContext.Provider>
    </div>
  );
};

export default RouteAssignments;
