import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Col, Divider, Form, Input, InputNumber, Radio, Row, Select, InputRef, Switch, FormInstance, Checkbox, Tooltip } from 'antd';
import TextSubtitle from '../../../../app/system-components/typography/text/TextSubtitle';
import {
  INV_BUNDLE_ITEM,
  INV_ITEM_EMBEDDED_SHOP_RATE_ID,
  INV_ITEM_EMBEDDED_SHOP_RATE_MONTH_ID,
  INV_ITEM_EMBEDDED_SHOP_RATE_WEEK_ID,
  INV_ITEM_SHOW_IN_SHOP,
} from '../../../../app/utils/models/modelConstants/modelConstants';
import ImageUploader from '../ImageUploader';
import { useFirestore } from 'react-redux-firebase';
import { inventoryQueries } from '../../../../app/services/firestore/queries/inventoryQueries';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import notificationError from '../../../../app/system-components/toasters/notificationError';
import onlyUnique from '../../../../app/utils/filters/filterOnlyUnique';
import { InventoryBundleList } from './InventoryBundleList';
import { InventoryItemRates } from './InventoryItemRates';
import { InventoryCategory } from '../../../../app/interfaces/inventoryCategory';
import { InventoryItem } from '../../../../app/interfaces/inventoryItem';
import ReactQuill from 'react-quill';
import { check } from 'prettier';
import { checkSubscriptionLevel } from '../../../../app/interfaces/features-set-permissions';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const NewItemDetails: FC<{
  categories: InventoryCategory[];
  form: FormInstance<any>;
  duplicated: boolean;
  saving: boolean;
  onImageUrl: (downloadURL: any, fileName: any) => void;
  removeImg: () => void;
  defaultImg: {
    downloadURL: string;
    fileName: string;
  } | null;
  storedSelections: InventoryItem[];
  setStoredSelections: Dispatch<SetStateAction<InventoryItem[]>>;
}> = ({ categories, form, duplicated, onImageUrl, removeImg, defaultImg, storedSelections, setStoredSelections, saving }) => {
  
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const nameInput = useRef<InputRef | null>(null);
  const [type, setType] = useState('rental');
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const firestore = useFirestore();

  const actualEmbeddedCheckout = orgData?.embeddedCheckoutEnabled;
  const [showInShop, setShowInShop] = useState(false);
  const [autoCalculateStock, setAutoCalculateStock] = useState(false);

  const showInShopFromForm = form.getFieldValue(INV_ITEM_SHOW_IN_SHOP);
  const autoCalculateStockFromForm = form.getFieldValue("autoCalculateStock");

  useEffect(() => {
    setShowInShop(!!form.getFieldValue(INV_ITEM_SHOW_IN_SHOP));
  }, [showInShopFromForm]);

  useEffect(() => {
    setAutoCalculateStock(!!form.getFieldValue("autoCalculateStock"));
  }, [autoCalculateStockFromForm]);

  useEffect(() => {
    const invType = form && form.getFieldValue('type');
    setType(invType);
  }, [storedSelections]);

  useEffect(() => {
    fsOrgPrefix &&
      inventoryQueries({ firestore }, fsOrgPrefix)
        ?.fetchInventory()
        .then((res) => {
          setInventoryItems(res?.filter((i) => i?.type !== 'bundle'));
        });
  }, [saving]);

  useEffect(() => {
    if (duplicated) {
      nameInput.current?.focus();
    }
  }, [duplicated]);

  const storeSelections = (id: string) => {
    // map stored items
    const selected = inventoryItems.find((i) => i.id === id);
    if (!selected) notificationError('Something went wrong', '');
    else setStoredSelections([selected, ...storedSelections].filter(onlyUnique));
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <ImageUploader onImageUrl={onImageUrl} removeImg={removeImg} defaultImg={defaultImg} />
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Form.Item name="type" initialValue="rental">
            <Radio.Group
              options={[
                { label: "Rental Item", value: "rental" },
                { label: "Rental Bundle", value: "bundle", disabled: !checkSubscriptionLevel("inventory.bundles", orgData) },
                { label: "Sale Item", value: "sale" },
              ]}
              onChange={(e) => {
                const fields = form.getFieldsValue();
                form.setFieldsValue({ rates: [] });
                setType(e.target.value);
              }}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Name required",
          },
        ]}
      >
        <Input placeholder="Name" ref={nameInput} />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[
          {
            required: true,
            message: "Description required",
          },
        ]}
      >
        <Input.TextArea placeholder="Description" rows={1} />
      </Form.Item>

      {showInShop && (
        <Form.Item label={"Formatted Website Description"} name={"rtfDescription"}>
          <ReactQuill
            modules={{
              toolbar: [["bold", "italic", "underline"], ["link"]],
            }}
            placeholder="Shop website product description..."
            theme="snow"
            style={{
              height: "100px",
              marginBottom: "24px",
            }}
          />
        </Form.Item>
      )}

      <br />

      <Form.Item label={"Categories"} name="categoriesQueryHook">
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select categories"
          filterOption={(input, option) => {
            if (!option || !option.children) return true;
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          filterSort={(optionA, optionB) => {
            if (!optionA || !optionB || !optionA.children || !optionB.children) return 1;
            return optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase());
          }}
        >
          {categories &&
            categories.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      {actualEmbeddedCheckout && (
        <Form.Item
          name="showInShop"
          initialValue={false}
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                const showInShopSet = !!value;
                const hasSelectedRate = !!form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID);
                if (showInShopSet && !hasSelectedRate) return Promise.reject(new Error("Shop Rate Not Selected"));
                return Promise.resolve();
              },
            },
          ]}
        >
          <Switch
            size="small"
            checked={showInShop}
            onChange={(e) => {
              setShowInShop(e);
              form.setFieldsValue({ showInShop: e });
              if (!e) {
                form.setFieldsValue({
                  [INV_ITEM_EMBEDDED_SHOP_RATE_ID]: null,
                  [INV_ITEM_EMBEDDED_SHOP_RATE_MONTH_ID]: null,
                  [INV_ITEM_EMBEDDED_SHOP_RATE_WEEK_ID]: null,
                });
              }
            }}
          />{" "}
          Show In Shop
        </Form.Item>
      )}

      <InventoryItemRates type={type} form={form} />

      <Divider style={{ margin: "16px 0px" }} />

      <Row gutter={24}>
        <Col>
          <Form.Item name="stock" label={<TextSubtitle text={"STOCK"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="turnaround" label={<TextSubtitle text={"TURN-AROUND (DAYS)"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>
        {type === "bundle" && (
          <Col>
            <Form.Item
              name="autoCalculateStock"
              initialValue={false}
              valuePropName="checked"
            >
              <Switch
                size="small"
                checked={autoCalculateStock}
                onChange={(e) => {
                  console.log(e)
                  setAutoCalculateStock(e);
                  form.setFieldsValue({ autoCalculateStock: e });
                }}
              />{" "}
              Autocalculate Stock
            </Form.Item>
          </Col>
        )}
      </Row>

      {type === INV_BUNDLE_ITEM && <InventoryBundleList storeSelections={storeSelections} inventoryItems={inventoryItems} />}
    </div>
  );
};

export default NewItemDetails;
