import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
import { useFirestoreContext } from "../../global-context/FirestoreContext";

export function useAvailableInventoryPaginated({
  startEndOpts,
  filterInventoryIds,
  invoiceItems,
  ignoreInvoicesForAvailability,
}) {

  const { inventory } = useFirestoreContext();

  const InvoicesInRange = useInvoicesInRange(
    startEndOpts,
    inventory ?? [],
    // invoiceItems,
    ignoreInvoicesForAvailability
  );

  return {
    availableInventory: InvoicesInRange?.data?.filter((i) =>
      filterInventoryIds?.includes(i.id)
    ),
    fullInventory: InvoicesInRange?.data ?? [],
    fetchingInvoices: InvoicesInRange.fetching,
    refreshInvoices: InvoicesInRange.refresh,
  };
}
