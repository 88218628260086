import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../../reducer";
import { api } from "../services/api";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
    }).concat(api.middleware);
  },
  // middleware: getDefaultMiddleware({
  //   // serializableCheck ignores warning from Firebase Login / Redux Toolkit
  //   serializableCheck: {
  //     ignoredActions: [actionTypes.LOGIN],
  //   },
  //   // Only in dev. Can turn on if needed
  //   immutableCheck: false,
  // }),
});
setupListeners(store.dispatch);
