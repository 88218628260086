import React, { useContext } from "react";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { Col, Select } from "antd";
import { RouteAssignmentsContext } from "../RouteAssignments";

const AssignTruck = () => {

  const { state, actions } = useContext(RouteAssignmentsContext);

  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"ASSIGN TRUCK"} />

      {state.selectedRoute?.routeData && state?.trucks && state?.trucks.length > 0 && (
        <Select
          allowClear={true}
          onClear={() => {
            actions.clearTruck();
          }}
          value={state.selectedRoute?.routeData?.vehiclesQueryHook?.length > 0 ? state.selectedRoute?.routeData?.vehiclesQueryHook[0] : null}
          placeholder={"Truck"}
          style={{ width: "100%" }}
          onSelect={(id) => actions.addTruck({ id })}
        >
          {state?.trucks?.map((truck) =>
            truck.isAvailable ? (
              <Select.Option value={truck.id}>
                {truck.name}: {truck.description}
              </Select.Option>
            ) : (
              ""
            )
          )}
        </Select>
      )}
    </Col>
  );
};

export default AssignTruck;
