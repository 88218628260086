import { useEffect, useState } from "react";
import "firebase/storage";
import { CUSTOMER_COLLECTION } from "../../utils/models/collections/collectionConstants";
import { checkCustomer } from "../../utils/models/checkers/checkCustomer";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { useFirebase, useFirestore } from "react-redux-firebase";
import "firebase/storage";

export function useSendInvoice(customerId) {
  const { orgData, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [customer, setCustomer] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(async () => {
    if (!customerId) return;
    setFetching(true);
    const ref = firestore.collection(`${fsOrgPrefix}${CUSTOMER_COLLECTION}`);
    const snap = await ref.doc(customerId).get();
    setCustomer(checkCustomer(snap));
    setFetching(false);
  }, [customerId]);

  const getInvoicePdf = async ({ invoiceId, invoiceNumber }) => {
    setGeneratingPdf(true);
    await generateInvoice({ invoiceId });
    await fetchInvoicePdf({ invoiceId, invoiceNumber });
    setGeneratingPdf(false);
  };

  const fetchInvoicePdf = async ({ invoiceId, invoiceNumber }) => {
    const path = `${fsOrgPrefix}invoices/${invoiceId}/INVOICE_${invoiceNumber}.pdf`;
    const storageRef = firebase.storage().ref();
    const img = await storageRef
      .child(path)
      .getDownloadURL()
      .then((res) => {
        console.log("STORAGEREF", res);
        setImageUrl(res);
      });
  };
  const generateInvoice = async ({ invoiceId }) => {
    const generateCall = firebase.functions().httpsCallable("createInvoice");
    return await generateCall({
      orgId: orgData?.id,
      invoiceId,
      sendType: 'invoice',
      settingsId: 'invoiceEmailSettings'
    });
  };

  return {
    fetching,
    customer,
    customerEmail: customer?.email,
    generatingPdf,
    getInvoicePdf,
    imageUrl,
  };
}
