import { useContext } from "react";
import { Col, DatePicker } from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { RouteAssignmentsContext } from "../RouteAssignments";
import { useFirestore } from "react-redux-firebase";
import { TimeMachine } from "../../../../app/utils/time/timeMachine";
import { authSelector } from "../../../auth/authSlice";
import { useSelector } from "react-redux";

const LoadDatePicker = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();
  const date = state.selectedRoute?.routeData?.loadDate;
  const convertedTime = TimeMachine(date).moment;
  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"LOAD DATE"} />
      <br />
      <DatePicker
        format={(d) => d.format("MMM Do YYYY")}
        onChange={(dateMoment) =>
          actions.updateLoadDate(dateMoment, firestore, orgData)
        }
        value={convertedTime}
        style={{ width: "100%" }}
      />
    </Col>
  );
};

export default LoadDatePicker;
