import React from "react";
import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../../app/utils/models/modelConstants/modelConstants";
import {
  DeliveryTag,
  PickupTag,
  WaypointTag,
} from "../../../app/system-components/tags/RoutesTags";

const StopTagType = ({ stop }) => {
  if (!stop) return null;
  const type = stop?.type ?? null;
  if (!type) return null;
  return (
    <div style={{ width: "100px" }}>
      {type === RECEIVE_METHOD_DELIVERY ? (
        <DeliveryTag text={"DELIVERY"} />
      ) : type === RETURN_METHOD_ONSITE ? (
        <PickupTag text={"PICK UP"} />
      ) : type === STOP_TYPE_WAYPOINT ? (
        <WaypointTag text={"WAYPOINT"} />
      ) : null}
    </div>
  );
};

export default StopTagType;
