import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Divider,
  Space,
  Tooltip,
  Checkbox,
  InputNumber,
  Spin,
} from "antd";
import { centsToDollars } from "../../../app/utils/models/checkers/checkCurrencyToDollars";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";
import { COLOR_TEXT_GRAY_1 } from "../../../app/ui/colorConstants";
import NumberFormat from "react-number-format";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { dollarToCents } from "../../../app/utils/models/configure/configureCurrencyToCents";
import { useFirebase } from "react-redux-firebase";

const RefundModal = ({
  visible,
  onCancel,
  onComplete,
  paymentDoc,
  invoiceId,
}) => {
  const firebase = useFirebase();

  const [saving, setSaving] = useState(false);
  const [checked, setChecked] = useState(false);
  const [paymentAmt, setPaymentAmt] = useState(0);
  const [form] = Form.useForm();
  const [fullRefundAmt, setFullRefundAmt] = useState(0);
  const [refundSum, setRefundSum] = useState(0);
  const fullPayment = () => {
    if (paymentAmt === fullRefundAmt - refundSum) {
      setPaymentAmt(0);
    } else {
      setPaymentAmt(fullRefundAmt - refundSum);
    }
  };

  useEffect(() => {
    if (paymentDoc) {
      setFullRefundAmt(paymentDoc.total);
      const refundSum = checkUndefinedOrNull(paymentDoc.refunds, []).reduce(
        (accumulator, object) => {
          return accumulator + parseFloat(object.amount);
        },
        0
      );
      setRefundSum(centsToDollars(refundSum));
    }
  }, [paymentDoc]);

  const handleFinish = () => {
    console.log("payment amount: ", paymentAmt);
    const refundAmount = dollarToCents(paymentAmt);

    setSaving(true);

    const call = firebase.functions().httpsCallable("processRefund");

    return call({
      amountToRefund: refundAmount,
      paymentDocId: paymentDoc.id,
      orgId: paymentDoc.orgId,
      invoiceId: invoiceId,
    })
      .then(async (res) => {
        setSaving(false);
        onComplete(true);
      })
      .catch((err) => {
        console.log(err);
        onComplete(false);
        setSaving(false);
      });
  };

  return (
    <Modal
      destroyOnClose
      width={650}
      open={visible}
      closeIcon={<></>}
      title={
        <Tooltip title="If the payment was made with Stripe or Justifi, only refunds up to the total amount of each payment are allowed. If there are multiple payments applied to an invoice and the total amount needs to be refunded, you will need to refund each payment individually.">
          Process Refund
        </Tooltip>
      }
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            onCancel(setPaymentAmt(0));
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={
            saving ||
            !paymentAmt ||
            paymentAmt < 0.5 ||
            paymentAmt > fullRefundAmt - refundSum ||
            Object.is(paymentAmt, Number)
          }
          loading={saving}
        >
          Process Refund
        </Button>,
      ]}
    >
      <Spin spinning={saving}>
        <div>
          <Row>
            <Col span={12}>
              <Form>
                <Form.Item name={"refundAmount"}>
                  <InputNumber
                    addonBefore="Refund Amount"
                    onChange={setPaymentAmt}
                    style={{ width: "225px" }}
                    defaultValue={0}
                    value={paymentAmt}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                  <br />
                  <br />
                </Form.Item>
                <Checkbox onChange={fullPayment} defaultChecked={false}>
                  Refund remaining amount:
                  <NumberFormat
                    style={{ fontWeight: "bold", marginLeft: "5px" }}
                    value={(fullRefundAmt - refundSum).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Checkbox>
                {refundSum !== 0 && (
                  <div>
                    Previously Refunded:
                    <NumberFormat
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      value={refundSum.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                )}
              </Form>
            </Col>
            <Col span={12} style={{ paddingLeft: "65px" }}>
              {paymentDoc && (
                <div>
                  <strong>Initial Payment: </strong>
                  <NumberFormat
                    value={paymentDoc.total.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <br />
                  <TextSubtitle text={`Ref No: ${paymentDoc.refNo}`} /> <br />
                  <TextSubtitle
                    style={{ color: COLOR_TEXT_GRAY_1, fontSize: "12px" }}
                    text={paymentDoc?.paymentDate?.toDate().toLocaleString()}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
};

export default RefundModal;
