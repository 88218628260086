// FirestoreContext.tsx
import { createContext, useContext } from "react";

// Define the FirestoreContext
export const OneOffContext = createContext<{
  getVenueSpecificSettings: (venueId: string) => Promise<any>;
}>({
  getVenueSpecificSettings: async () => null, // Provide a default no-op function
});
// Export the FirestoreContext
export const useOneOffContext = () => {
  const context = useContext(OneOffContext);

  if (!context) throw new Error("useOneOffContext must be use inside OneOffProvider");

  return context;
};
