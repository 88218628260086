import React from "react";
import { sortableElement } from "react-sortable-hoc";
import EditableRow from "./EditableRow";

const DraggableBodyRow = ({ className, style, selectedItems, restProps }) => {
  const SortableItem = sortableElement((props) => <EditableRow {...props} />);

  // function findIndex base on Table rowKey props and should always be a right array index
  const index = selectedItems.items.findIndex(
    (x) => x.key === restProps["data-row-key"]
  );
  return <SortableItem index={index} {...restProps} />;
};

export default DraggableBodyRow;
