import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { Tooltip } from "antd";
import { FC } from "react";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { COLOR_TEXT_GRAY_1 } from "../../../../app/ui/colorConstants";
import { getColorForPercentage } from "../../../../app/ui/colorMap";
import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";

export const DepreciationCell: FC<
  GridRenderCellParams<InventoryItem, any, any, GridTreeNodeWithRender>
> = ({ value: depreciation }) => {
  if (
    !depreciation ||
    !depreciation.initialValue ||
    !depreciation.initialDate ||
    !depreciation.depreciationYears
  )
    return (
      <Tooltip title={"Enter all required fields to calculate depreciation"}>
        <QuestionCircleOutlined style={{ color: COLOR_TEXT_GRAY_1 }} />{" "}
      </Tooltip>
    );
  const { initialValue, initialDate, depreciationYears } = depreciation;
  const today = new Date();
  const currentDate: Date = initialDate.toDate();
  const futureDate: Date = initialDate.toDate();
  futureDate.setFullYear(futureDate.getFullYear() + depreciationYears);

  const q = Math.abs(today.getTime() - currentDate.getTime());
  const d = Math.abs(futureDate.getTime() - currentDate.getTime());
  const depreciatedAmt = Math.round((q / d) * 100) / 100;
  const depreciatedPercentage = 1 - depreciatedAmt;
  const depreciatedValue = Math.round(depreciatedPercentage * initialValue);
  return (
    <div>
      {Math.sign(depreciatedPercentage) === 1 ? (
        <TextBody
          text={`$${depreciatedValue.toLocaleString("en", {
            maximumFractionDigits: 2,
          })} (${Math.round(depreciatedPercentage * 100)}%)`}
          style={{
            color: getColorForPercentage(depreciatedPercentage),
          }}
        />
      ) : (
        <TextBody
          text={`$0`}
          style={{
            color: getColorForPercentage(0),
          }}
        />
      )}
    </div>
  );
};
