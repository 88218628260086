import React, { useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";

import _ from "lodash";
import { saveAs } from "file-saver";

import {
  Button,
  Modal,
  Form,
  message,
  Tabs,
  Spin,
  Row,
  Space,
  Col,
  Divider,
  Card,
  Timeline,
  Alert,
  Input,
} from "antd";

import { CheckCircleFilled } from "@ant-design/icons";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { authSelector, refreshOrgState } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { useFirestore } from "react-redux-firebase";
import CustomerInfo from "./components/invoiceItemsAndDetails/CustomerInfo";
import RentalRange from "./components/RentalRange";
import ReceiveDetails from "./components/ReceiveDetails";
import ReturnDetails from "./components/ReturnDetails";
import { configureInvoice } from "../../app/utils/models/configure/configureInvoice";
import {
  RECEIVE_METHOD_DELIVERY,
  RECEIVE_METHOD_PICKUP,
  RETURN_METHOD_ONSITE,
  RETURN_METHOD_DROPOFF,
  ROW_TYPE_SUBTOTAL,
} from "../../app/utils/models/modelConstants/modelConstants";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { Spinner } from "../../app/ui/spinner";
import {
  ROUTE_EDIT_INVOICE,
  ROUTE_INVOICES,
  ROUTE_RECEIVE_PAYMENT_ROOT,
  ROUTE_VIEW_EDIT_INVOICE,
  ROUTE_VIEW_PREVIEW_INVOICE,
} from "../../app/routes";
import notificationError from "../../app/system-components/toasters/notificationError";
import InvoiceContextMenuWrapper from "./components/contextWrapper/InvoiceContextMenuWrapper";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import {
  INVOICES_COLLECTION,
  SEND_RECORDS,
} from "../../app/utils/models/collections/collectionConstants";
import CreateNewItemModal from "./components/CreateNewItemModal";
import { setExistingInvoice } from "./helpers/setExistingInvoice";
import { validateInvoiceType } from "./helpers/validateInvoiceType";
import { capitalizeFirstLetter } from "../../app/utils/casing/capitalizeFirstLetter";
import moment from "moment";
import { UpdateDurationRowTotal } from "./components/invoiceTable/helpers/updateDurationRowTotal";
import { useUnload } from "../../app/services/hooks/useUnload";
import InvoicePaymentHistory from "./components/InvoicePaymentHistory";
import { useHistory } from "react-router-dom";
import { useModalMgr } from "../../app/services/hooks/useModalMgr";
import SendInvoiceModal from "./components/SendInvoiceModal";
import { useInvoiceHeader } from "../auth/useInvoiceHeader";
import { useInvoiceNumberListener } from "../../app/services/hooks/useInvoiceNumberListener";
import { venueQueries } from "../../app/services/firestore/queries/venueQueries";
import { useInvoiceEditor } from "./hooks/useInvoiceEditor";
import { useAvailableInvInvoice } from "../../app/services/hooks/useAvailableInvInvoice";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import SyncQuickBooksModal from "./components/SyncQuickBooksModal";
import InvoiceSendRecords from "./components/InvoiceSendRecords";
import { InvoiceHeader } from "./components/InvoiceHeader";
import { InvoiceActions } from "./components/invoiceItemsAndDetails/InvoiceActions";
import InternalNotesDrawer from "./components/InternalNotesDrawer";
import TabPane from "antd/lib/tabs/TabPane";
import { dollarToCents } from "../../app/utils/models/configure/configureCurrencyToCents";
import { invoices } from "../../app/services/firestore/queries/invoiceQueries";
import { firelikeId } from "../../app/utils/models/checkers/firelikeid";
import { checkInvoices } from "../../app/utils/models/checkers/checkInvoices";
import { emailSettingsQuery } from "../../app/services/firestore/queries/orgQueries";

import { en } from "../../i18n/en";
import { es } from "../../i18n/es";
import { pdf } from "@react-pdf/renderer";

import InvoicePDF from "./components/invoice-pdf";

import {
  convertImageToBase64,
  convertPngToJpeg,
  convertWebPToJpegAndBase64,
} from "../../app/utils/image-converter";
import { CheckFile } from "../file-manager/file";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import { functions, functionsBaseUrl } from "../../app/config/firebase";
import { useFirestoreContext } from "../../app/global-context/FirestoreContext";
import { Box, Checkbox, Stack, Typography } from "@mui/material";

export const SendInvoiceContext = React.createContext();
export const InvoiceEditorContext = React.createContext();
export const AvailableInventoryContext = React.createContext(null);

// PSR SPECIFIC CONSTANTS *******
const psr = process.env.REACT_APP_PSR_ID;
const tanglewoodlogo = process.env.REACT_APP_TANGLEWOOD_LOGO;
const madherblogo = process.env.REACT_APP_MADHERB_LOGO;
  
const madHerbalistVenueId = process.env.REACT_APP_MADHERBALIST_VENUE;
const tanglewoodVenueId = process.env.REACT_APP_TANGLEWOOD_VENUE;
// END PSR SPECIFIC CONSTANTS *******

const InvoiceDetail = ({ id, initialInvoice, cartItems }) => {

  const { users, tags, invoiceTextData, emailInvoiceSettings, emailEstimateSettings } = useFirestoreContext();

  // State Initializers
  const [currentInvoice, setCurrentInvoice] = useState(initialInvoice);
  const [rentalDates, setRentalDates] = useState({
    receiveDate: null,
    returnDate: null,
  });

  // HOOKS
  const [form] = Form.useForm();
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const ModalMgr = useModalMgr();
  const InvoiceEditor = useInvoiceEditor();
  const { nextInvoiceNumber } = useInvoiceNumberListener();
  const { newDocument, updateOrgDocument, updateDocument } =
    useFirestoreWrite();

  // USE STATE
  const [emailSettings, setEmailSettings] = useState(null);
  const [paymentLinkUrl, setPaymentLinkUrl] = useState(null);
  const [venues, setVenues] = useState([]);
  const [isEdit, setIsEdit] = useState(!!id);
  const [customer, setCustomer] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAllInventory, setLoadingAllInventory] = useState(false);
  const [editing, setEditing] = useState(!orgData.deferInventoryLoad);

  const [deliveryFee, setDeliveryFee] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [deliveryFeeTaxable, setDeliveryFeeTaxable] = useState(true);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [tabType, setTabType] = useState("invoice"); // invoice , payments , email
  const [selectedTags, setSelectedTags] = useState([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [makingPaymentLink, setMakingPaymentLink] = useState(false);
  const [i18n, seti18n] = useState(en);

  const [syncingInvoice, setSyncingInvoice] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    items: [],
    count: 0,
  });

  const [invoiceTotal, setInvoiceTotal] = useState({
    taxAmt: 0,
    taxRate: 0,
    subtotal: 0,
    total: 0,
    hasTotalDiscount: false,
    totalDiscountType: "none",
    totalDiscountPercent: 0,
    totalDiscountNumber: 0,
  });

  // NOTE: invoiceTaxRate.rate is only used to keep track of local component state in invoice summary.
  // invoiceTotal.taxRate is whats sent to firestore, along with invoiceTaxRate.taxDetails
  const [invoiceTaxRate, setInvoiceTaxRate] = useState({
    rate: 0,
    taxDetails: null,
  });
  const [isSalesInvoice, setIsSalesInvoice] = useState(false);
  const [rentalRange, setRentalRange] = useState(0);

  const [customerAddressChecked, setCustomerAddressChecked] = useState({
    receive: true,
    return: true,
  });
  const [specifiedTimes, setSpecifiedTimes] = useState({
    receive: false,
    return: false,
  });
  const [receiveMethod, setReceiveMethod] = useState(RECEIVE_METHOD_DELIVERY);
  const [returnMethod, setReturnMethod] = useState(RETURN_METHOD_ONSITE);

  const [toggleNewInvModal, setToggleNewInvModal] = useState({
    visible: false,
    name: "",
    key: null,
  });



    // Developer Only
    const handleCommandShiftP = () => {
      // enqueueSnackbar("Copied Invoice JSON", { variant: "info",
      //   anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
      //  });
      navigator.clipboard.writeText(JSON.stringify(currentInvoice)).catch((err) => console.log(err));
    };
  
    useEffect(
      () => {
        const handleDevData = (event) => {
          if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === "p") {
            event.preventDefault();
            handleCommandShiftP();
          }
        };
  
        window.addEventListener("keydown", handleDevData);
  
        return () => {
          window.removeEventListener("keydown", handleDevData);
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentInvoice]
    );





  const InvoiceInventoryAvailability = useAvailableInvInvoice({
    startEndOps: {
      useTurnaround: true,
      rentalDateEnd: rentalDates.returnDate,
      rentalDateStart: rentalDates.receiveDate,
    },
    inventory,
    invoiceItems: selectedItems.items,
    initialInvoice: currentInvoice,
  });

  const [logoBase64, setLogoBase64] = useState("");
  const [itemImages, setItemImages] = useState([]);
  const [signatureData, setSignatureData] = useState(null);

  const [fileAttachments, setFileAttachments] = useState([]);

  async function fetchFiles() {
    if (!orgData) return;
    const querySnapshot = await firestore.collection("orgs").doc(orgData.id).collection('fileManager').get();
    const files = querySnapshot.docs.map((doc) => CheckFile(doc));
    setFileAttachments(files);
  }

  useEffect(() => {
    fetchFiles();
  }, [orgData]);

  useEffect(() => {
    const convertLogo = async () => {
      const logoUrl = orgData.logoUrl;
      let logoString = "";
      if (logoUrl.includes(".webp") || logoUrl.includes(".WEBP")) {
        logoString = await convertWebPToJpegAndBase64(logoUrl);
      } else if (logoUrl.includes(".png") || logoUrl.includes(".PNG")) {
        logoString = await convertPngToJpeg(logoUrl);
      } else {
        logoString = await convertImageToBase64(logoUrl);
      }
      setLogoBase64(logoString);
    };

    const getSignature = async () => {

      if (!currentInvoice) return;

      const invoice = currentInvoice;
      const invoiceId = invoice.id;

      let signatureUrl= null;
      let date = null;

      if (invoice.paymentSignatures && invoice.paymentSignatures.length > 0) {
        const latest = invoice.paymentSignatures[invoice.paymentSignatures.length - 1];
        date = latest.timestamp || null;

        const bucket = firebase.storage().ref();
        const file = bucket.child(`paymentsignatures/${invoiceId}/paymentsignature-${latest.paymentId}`);
        await file
          .getDownloadURL()
          .then((signedUrls) => {
              signatureUrl = signedUrls;
          })
          .catch((error) => {
            console.error('Unable to get signed urls: ', error);
          });
      }

      // TODO: Might need to make it possible for estimate signature to be an array as well.
      let estimateSignatureUrl = null;
      if (invoice.estimateSignatures && invoice.estimateSignatures.length > 0) {
       
        date = invoice.estimateSignatures[0].timestamp || null;

        const bucket = firebase.storage().ref();
        const file = bucket.child(`estimatesignatures/${invoiceId}/estimatesignature.jpg`);
        await file
          .getDownloadURL()
          .then((signedUrls) => {
            estimateSignatureUrl = signedUrls;
          })
          .catch((error) => {
            console.error('Unable to get signed urls: ', error);
          });
      }

      if (invoice.type === 'estimate' || (invoice.type === 'invoice' && !signatureUrl)) {
        signatureUrl = estimateSignatureUrl;
      }

      const signatureData = { data: signatureUrl, date: date }
      
      setSignatureData(signatureData);
    };

    getSignature();
    convertLogo();

  }, [orgData]);

  useEffect(() => {
    const convertImages = async () => {
      if (!orgData.showInvoiceItemImg) {
        return;
      }

      // Create a copy of the existing itemImages array
      const newItemImages = [...itemImages];

      const promises = selectedItems.items.map((item) => {
        // Return a new promise for each item
        return new Promise(async (resolve, reject) => {
          if (item?.image?.downloadURL) {
            const downloadUrl = item.image.downloadURL;

            let imageStr = "";
            if (downloadUrl.includes("cdn.shopify")) {
              imageStr = downloadUrl;
            } else if (downloadUrl.includes(".webp") || downloadUrl.includes(".WEBP")) {
              imageStr = await convertWebPToJpegAndBase64(downloadUrl).catch(
                reject
              );
            } else if (downloadUrl.includes(".png") || downloadUrl.includes(".PNG")) {
              imageStr = await convertPngToJpeg(downloadUrl).catch(reject);
            } else {
              imageStr = await convertImageToBase64(downloadUrl).catch(reject);
            }

            // Resolve the promise with the item id and imageStr
            resolve({ id: item.id, data: imageStr });
          } else {
            // If there is no downloadURL, resolve the promise with null or appropriate value
            resolve(null);
          }
        });
      });

      Promise.all(promises)
        .then((results) => {
          results.forEach((result) => {
            if (result) {
              const existingItemIndex = newItemImages.findIndex(
                (imgItem) => imgItem.id === result.id
              );
              if (existingItemIndex !== -1) {
                newItemImages[existingItemIndex] = result;
              } else {
                newItemImages.push(result);
              }
            }
          });
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });

      setItemImages(newItemImages);
    };

    convertImages();
  }, [selectedItems]);

  // Handle Actions
  function syncClicked(item) {
    setShowSyncModal(true);
  }

  const handleSalesInvoiceToggle = (value) => {
    setIsSalesInvoice(value);
    handleReceiveMethod(RECEIVE_METHOD_PICKUP);
    handleReturnMethod(RETURN_METHOD_DROPOFF);

    form.setFieldsValue({
      receiveMethod: RECEIVE_METHOD_PICKUP,
      returnMethod: RETURN_METHOD_DROPOFF,
    });
  };

  useEffect(() => {
    if (!orgData) return;

    const languageCode = orgData.languageCode ?? "en";
    let languageTable = en;
    if (languageCode === "en") {
      languageTable = en;
    } else if (languageCode === "es") {
      languageTable = es;
    }

    seti18n(languageTable);

    emailSettingsQuery({ firestore }, fsOrgPrefix)
      .fetchEmailSettings(orgData.id, "invoiceEmailSettings")
      .then((settings) => {
        if (settings) {
          setEmailSettings(settings);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orgData]);

  useEffect(() => {
    if (!customer) return;
    InvoiceEditor.actions.setTaxableSettings(customer);
    // update for tax exemption
    const selectedTaxable = selectedItems.items.map((i) => {
      const exempt = customer?.isTaxExempt ?? false;

      return {
        ...i,
        selectedTaxable: exempt === true ? false : i?.selectedTaxable,
      };
    });
    setSelectedItems({ ...selectedItems, items: selectedTaxable });
  }, [customer]);

  // Invoice Snapshot Listener
  useEffect(() => {
    if (!orgData || !id) return;

    const docRef = firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("invoices")
      .doc(id);

    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        if (doc.exists) {

          /* The properties we are interested are: 
            qbId, 
            invoiceNumber,
            qboInvoiceNumber,   
            lastSyncDate: firebase.firestore.Timestamp;
            lastSyncSuccess: boolean;
          */

          // Update the existing currentInvoice property.
          const updatedInvoice = checkInvoices(doc);

          // Update the currentInvoice state while maintaining other properties
          setCurrentInvoice((prevInvoice) => ({
            ...prevInvoice, // Spread the existing properties
            // Override only the properties you want to update
            qbId: updatedInvoice.qbId || null,
            invoiceNumber: updatedInvoice.invoiceNumber,
            qboInvoiceNumber: updatedInvoice.qboInvoiceNumber || null,
            lastSyncDate: updatedInvoice.lastSyncDate, // converting Timestamp to Date
            lastSyncSuccess: updatedInvoice.lastSyncSuccess || true,
          }));
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.log("Error getting document:", error);
      }
    );

    // Clean up the listener on unmount
    return () => { 
     console.log('Unsubscribing from invoice snapshot listener');
      unsubscribe();
    }
  }, [orgData, id]);


  useEffect(() => {
    if (orgData) {
        if (currentInvoice) {
          const tagsQueryHook = currentInvoice.tagsQueryHook;
          setSelectedTags(tagsQueryHook);
        }
    }
  }, [orgData, tags]);

  useEffect(() => {
    if (orgData.trackInventoryWeight) {
      const totalWeight = selectedItems.items.reduce(
        (accumulator, currentItem) => {
          if (
            currentItem.weight !== undefined &&
            currentItem.selectedQty !== undefined
          ) {
            return accumulator + currentItem.weight * currentItem.selectedQty;
          } else {
            return accumulator;
          }
        },
        0
      );
      setTotalWeight(totalWeight);
    }
  }, [selectedItems.items]);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  useEffect(() => {
    // if (inventory.length === 0) return;
    if (loading) return;
    if (typeof currentInvoice !== "undefined" && currentInvoice.length !== 0) {
      form.setFieldsValue({
        receiveVenueQueryHook: currentInvoice.receiveVenueQueryHook,
        returnVenueQueryHook: currentInvoice.returnVenueQueryHook,
      });

      setExistingInvoice({
        form,
        setIsSalesInvoice,
        setSelectedItems,
        currentInvoice,
        setInvoiceTotal,
        setReceiveMethod,
        setReturnMethod,
        setSpecifiedTimes,
        setRentalRange,
        setCustomer,
        setCustomerAddressChecked,
        setDeliveryFee,
        setDeliveryFeeTaxable,
        setVenues,
        setRentalDates,
      });
    }
  }, [loading]);

  useEffect(() => {

    if (loading) return;
    
    if (
      typeof cartItems !== "undefined" &&
      cartItems.selectedItems.items.length !== 0
    ) {
      const start = moment(cartItems.rentalDates.rentalDateStart);
      const end = moment(cartItems.rentalDates.rentalDateEnd);

      const dueDate = moment(cartItems.rentalDates.rentalDateStart);

      let range = end.diff(start, "days");
      const rr = range === 0 ? 1 : range;
      setRentalRange(rr);

      let updated = UpdateDurationRowTotal(cartItems.selectedItems, rr);

      if (orgData.availabilityToOrderDurationMatch === false) {
        updated.forEach((i) => {
            i.selectedDuration = 1
        });
      }

      setSelectedItems({
        items: updated,
        count: updated.length,
      });
      setRentalDates({
        receiveDate: start.startOf("day").format("MMM Do YYYY"),
        returnDate: end.endOf("day").format("MMM Do YYYY"),
      });

      form.setFieldsValue({
        rentalDates: [start, end],
        invoicePaymentDueDate: dueDate.endOf("day"),
        eventDate: dueDate.startOf("day"),
      });

     
    }
  }, [cartItems, loading]);

  // LOAD VENUES
  useEffect(() => {
    if (!orgData || !fsOrgPrefix) return;
    if (loading) return;
    setLoading(true);
    setDeliveryFeeTaxable(orgData.deliveryFeeTaxable);

    loadVenues();
    loadInitialInventory();
    setLoading(false)
    
  }, [fsOrgPrefix]);

  const loadVenues = () => {
    fsOrgPrefix &&
      venueQueries({ firestore }, fsOrgPrefix)
        .fetchVenues()
        .then((res) => {
          setVenues([...res]);
        });
  };

  // MARK: Load Inventory
  // When editing goes from false to true, load inventory
  useEffect(() => {
    if (editing) {
      setLoadingAllInventory(true);
      loadInventory();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const loadInventory = async () => {
    inventoryQueries({ firestore }, fsOrgPrefix)
    .fetchInventory()
    .then((data) => {
      setInventory(data);
      setLoadingAllInventory(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadingAllInventory(false);
    });
  };

  const loadInitialInventory = async () => {

    // Get the invoice.selectedItems.items ids
    const itemIds = selectedItems.items.map((item) => item.id);

    inventoryQueries({ firestore }, fsOrgPrefix)
    .inventoryByIdList(itemIds)
    .then((data) => {
      setInventory(data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const customerInfoChanged = (value, formItemName) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (customerAddressChecked.receive) {
      form.setFieldsValue({
        [`${RECEIVE_METHOD_DELIVERY}${capitalizeFirstLetter(formItemName)}`]:
          value,
      });
    }
    if (customerAddressChecked.return) {
      form.setFieldsValue({
        [`${RETURN_METHOD_ONSITE}${capitalizeFirstLetter(formItemName)}`]:
          value,
      });
    }
  };

  const handleReceiveMethod = (e) => {
    setReceiveMethod(e);
    if (e === "pickup" && orgData.companyOpenTime && orgData.companyCloseTime) {
      setSpecifiedTimes({
        receive: true,
        return: true,
      });
      form.setFieldsValue({
        hasSpecifiedReceiveTime: true,
        specifiedReceiveTime: moment(orgData.companyOpenTime.toDate()),
        specifiedReceiveTimeEnd: moment(orgData.companyCloseTime.toDate()),
      });
    }
    if (e === "delivery") {
      form.setFieldsValue({
        specifiedReceiveTime: null,
        specifiedReceiveTimeEnd: null,
      });
    }
  };

  const handleReturnMethod = (e) => {
    setReturnMethod(e);
    if (
      e === "dropOff" &&
      orgData.companyOpenTime &&
      orgData.companyCloseTime
    ) {
      setSpecifiedTimes({
        receive: true,
        return: true,
      });
      form.setFieldsValue({
        hasSpecifiedReturnTime: true,
        specifiedReturnTime: moment(orgData.companyOpenTime.toDate()),
        specifiedReturnTimeEnd: moment(orgData.companyCloseTime.toDate()),
      });
    }
    if (e === "onSite") {
      form.setFieldsValue({
        specifiedReturnTime: null,
        specifiedReturnTimeEnd: null,
      });
    }
  };

  function onManualSyncComplete(success) {
    setShowSyncModal(false);
    if (success) {
      notificationConfirm("Success!", "Sync complete");
    } else {
      notificationError(
        "Error",
        "Unable to sync invoice. Please contact Adelie support."
      );
    }
  }

  const handleComplete = async ({ type, redirect }) => {
    const items = [...selectedItems.items].filter(
      (i) => i.id || i.rowType === ROW_TYPE_SUBTOTAL
    );
    const cleanItems = {
      items: items,
      count: items.length,
    };
    setSelectedItems({ items: items, count: items.length });

    return validateInvoiceType({
      type,
      form: form,
      customer: customer,
      rentalRange: rentalRange,
      selectedItems: cleanItems,
      inventory: inventory,
      isEdit: isEdit,
      rentalDates: rentalDates,
      previousSavedInvoice: currentInvoice,
      setCustomerAddressChecked,
      customerAddressChecked,
      showOverbookedError:
        InvoiceInventoryAvailability.state.showOverbookedError,
    }).then(async ({ type, isEdit, hasError }) => {
      if (hasError) {
        message.warning("Missing required fields");
        return { hasError };
      }
      if (isEdit) {
        return await saveExistingInvoice(type, cleanItems, redirect);
      } else {
        return await createNewInvoice(type, cleanItems, redirect);
      }
    });
  };

  const saveExistingInvoice = (type, cleanItems, redirect) => {
    return new Promise((resolve, reject) => {
      if (redirect) {
        setLoading(true);
      }

      const data = {
        customer: customer,
        customerAddressChecked: customerAddressChecked,
        rentalRange: rentalRange,
        selectedItems: cleanItems,
        invoiceTotal: invoiceTotal,
        taxMetaData: invoiceTaxRate.taxDetails
          ? invoiceTaxRate.taxDetails
          : null,
        formDetails: form.getFieldsValue(),
        type: type,
        deliveryFee: deliveryFee ? parseFloat(deliveryFee) : 0,
        deliveryFeeTaxable,
        selectedTags,
        totalWeight,
      };

      const payload = configureInvoice(orgData?.orgTimezone).newInvoice(
        data,
        userData.id,
        orgData,
        currentInvoice
      );

      // The idea here is to compare the current and existing, and if no changes made then don't put up pop up.
      // changesMade(payload);

      // 1. Save the invoice.
      const { invoiceNumber, qbId, syncToken, ...rest } = payload;
      updateDocument({
        data: {
          collection: INVOICES_COLLECTION,
          payload: {
            invoiceNumber: currentInvoice.invoiceNumber,
            ...rest,
          },
          id: id,
        },
      }).then(async () => {
        if (redirect) {
          setLoading(false);
        }

        // 2. Fetch the updated invoice.
        const refreshedInvoice = await invoices(
          orgData?.orgTimezone
        ).fetchInvoice({ firestore }, fsOrgPrefix, {
          id: currentInvoice.id,
        });

        // 3. Set the newly refreshed invoice.
        // This triggers multiple useEffects so be careful.
        setCurrentInvoice(refreshedInvoice);

        if (!redirect) {
          resolve({ invoiceId: currentInvoice.id, invoice: refreshedInvoice });
          notificationConfirm(
            `${capitalizeFirstLetter(type)} updated: #${
              currentInvoice.invoiceNumber
            }`,
            ""
          );
          return;
        }

        if (redirect === "RECEIVE_PAYMENT") {
          let state = { ...history.location.state };
          delete state.invoiceId;
          history.push({
            pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${currentInvoice.customerQueryHook}`,
            state: { invoiceId: currentInvoice.id },
          });
          resolve();
        } else {
          history.push(redirect);
          resolve();
        }

        notificationConfirm(
          `${capitalizeFirstLetter(type)} updated: #${
            currentInvoice.invoiceNumber
          }`,
          ""
        );
      });
    });
  };

  const createNewInvoice = async (type, cleanItems, redirect) => {
    return new Promise(async (res) => {
      if (redirect) {
        setLoading(true);
      }

      const data = {
        customer: customer,
        customerAddressChecked: customerAddressChecked,
        rentalRange: rentalRange,
        selectedItems: cleanItems,
        invoiceTotal: invoiceTotal,
        taxMetaData: invoiceTaxRate.taxDetails
          ? invoiceTaxRate.taxDetails
          : null,
        formDetails: form.getFieldsValue(),
        type: type,
        deliveryFee: deliveryFee ? parseFloat(deliveryFee) : 0,
        deliveryFeeTaxable,
        nextInvoiceNumber,
        totalWeight,
      };

      const payload = configureInvoice(orgData?.orgTimezone).newInvoice(
        data,
        userData.id,
        orgData
      );

      try {
        const newInvoiceId = await newDocument({
          data: {
            collection: INVOICES_COLLECTION,
            payload: payload,
          },
        });

        const nextInvoiceNumber = payload.invoiceNumber;

        const refreshedInvoice = await invoices(
          orgData?.orgTimezone
        ).fetchInvoice({ firestore }, fsOrgPrefix, {
          id: newInvoiceId,
        });

        await updateOrgDocument({
          data: {
            payload: {
              currentInvoiceCount: nextInvoiceNumber,
            },
            id: orgData && orgData.id,
          },
        });

        dispatch(refreshOrgState({ firestore }, orgData)).then(() => {
          if (redirect) {
            setLoading(false);
          }
          notificationConfirm(
            `${capitalizeFirstLetter(type)} created: #${nextInvoiceNumber}`,
            ""
          );
          // if (!redirect) return res({ invoiceId: newInvoiceId });
          if (!redirect) {
            history.replace({
              pathname: `${ROUTE_EDIT_INVOICE}${newInvoiceId}`,
            });
            return res();
          }
          if (redirect === "RECEIVE_PAYMENT") {
            history.push({
              pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${customer.id}`,
              state: { invoiceId: newInvoiceId },
            });
            res();
          } else {
            history.push(redirect);
            res();
          }
        });
      } catch (error) {
        notificationError("Unable to create order.");
      }
    });
  };

  const handleBackClicked = () => {
    Modal.confirm({
      title: "Exit without saving?",
      content: "",
      onOk() {
        history.push(ROUTE_INVOICES);
      },
      onCancel() {},
    });
  };

  // MARK: Save File
  const saveFile = async () => {
    if (!currentInvoice) {
      return;
    }

    const res = await handleComplete({
      type: currentInvoice.type,
      redirect: null,
    });
    const refreshedInvoice = res.invoice;

    if (refreshedInvoice) {
      setDownloadingPDF(true);
      let pdfEmailSettings = currentInvoice.type === "invoice" ? emailInvoiceSettings : emailEstimateSettings;
      let pdfOrgData = { ...orgData };

      // **
      // ***
      // ****
      // PSR SPECIFIC
      // We do some custom work for PSR. They have three different entities.
      // PSR, Mad Herbalist, Tanglewood. The PSR is the default email settings,
      // but MH and Tangle have their own custom email settings documents.
      // Additionally, we will want to update the logo, company name,
      if (
        orgData.id === psr &&
        (refreshedInvoice.receiveVenueQueryHook === madHerbalistVenueId ||
          refreshedInvoice.receiveVenueQueryHook === tanglewoodVenueId)
      ) {
        let venueSettingsId;
        if (refreshedInvoice?.type === "invoice") {
          if (refreshedInvoice.receiveVenueQueryHook === madHerbalistVenueId) {
            venueSettingsId = "madHerbalistInvoiceSettings";
          } else {
            venueSettingsId = "tanglewoodInvoiceSettings";
          }
        } else if (refreshedInvoice?.type === "estimate") {
          if (refreshedInvoice?.receiveVenueQueryHook === madHerbalistVenueId) {
            venueSettingsId = "madHerbalistEstimateSettings";
          } else {
            venueSettingsId = "tanglewoodEstimateSettings";
          }
        }

        const emailSettingsSnap = await firestore
          .collection("orgs")
          .doc(orgData.id)
          .collection("settings")
          .doc(venueSettingsId ?? "invoiceEmailSettings")
          .get();

        pdfEmailSettings = emailSettingsSnap.data();

        if (refreshedInvoice?.receiveVenueQueryHook === madHerbalistVenueId) {
          // Mad Herbalist
          pdfOrgData.logoUrl = madherblogo;
        } else {
          // Tanglewood
          pdfOrgData.logoUrl = tanglewoodlogo;
        }
      }
      // END PSR Specific
      // >>>>>>>>>>>>>>>>

      try {
        const blob = await pdf(
          <InvoicePDF
            invoice={refreshedInvoice}
            orgData={pdfOrgData}
            venues={venues}
            emailSettings={pdfEmailSettings}
            customText={invoiceTextData}
            i18n={i18n}
            logoData={logoBase64}
            itemImages={itemImages}
            signatureData={signatureData}
          />
        ).toBlob();

        saveAs(blob, `${refreshedInvoice.invoiceNumber}.pdf`);
        setDownloadingPDF(false);
        notificationConfirm("Invoice Downloaded");
      } catch (error) {
        console.log(error);
        setDownloadingPDF(false);
        notificationError("Error Downloading Invoice");
      }
    } else {
      notificationError("Please Save Invoice First");
    }
  };

  // MARK: Make Payment Link
  const makePaymentLink = async () => {
    // create a send record.

    await handleComplete({ type: currentInvoice.type, redirect: null });

    setMakingPaymentLink(true);

    const balanceRemaining =
      Math.round(currentInvoice?.balanceRemaining * 100) / 100;

    const sendRecordPayload = {
      createdOn: new Date(),
      createdBy: userData.id,
      paymentAmt: dollarToCents(balanceRemaining),
      sendPaymentLink: true,
    };
    const ref = firestore
      .collection(
        `${fsOrgPrefix}${INVOICES_COLLECTION}/${currentInvoice?.id}/${SEND_RECORDS}`
      )
      .doc();
    await ref.set({
      id: ref.id,
      ...sendRecordPayload,
    });

    // Generate the payment link:

    let paymentsLink = "";
    paymentsLink = `https://payments.adelielogistics.com/?invoice=${currentInvoice?.id}&sendRecord=${ref.id}`;

    let estimateLink = "";
    estimateLink = `https://payments.adelielogistics.com/estimate/?estimate=${currentInvoice?.id}&sendRecord=${ref.id}`;

    let linkToUse = "";
    if (currentInvoice.type === "invoice") {
      linkToUse = paymentsLink;
    } else if (currentInvoice.type === "estimate") {
      linkToUse = estimateLink;
    }

    setMakingPaymentLink(false);
    message.success("Payment Link Copied To Your Clipboard.");
    setPaymentLinkUrl(linkToUse);
    navigator.clipboard.writeText(linkToUse);
  };

  // MARK: Get Delivery Fee
  const getShippingFee = async () => {
    // use fetch to hit the delivery fee https request endpoint. 
    const items = [...selectedItems.items].filter(
      (i) => i.id || i.rowType === ROW_TYPE_SUBTOTAL
    );

    const cleanItems = {
      items: items,
      count: items.length,
    };

    const invoiceData = {
      customer: customer,
      customerAddressChecked: customerAddressChecked,
      rentalRange: rentalRange,
      selectedItems: cleanItems,
      invoiceTotal: invoiceTotal,
      taxMetaData: invoiceTaxRate.taxDetails
        ? invoiceTaxRate.taxDetails
        : null,
      formDetails: form.getFieldsValue(),
      type: currentInvoice.type ?? 'draft',
      deliveryFee: deliveryFee ? parseFloat(deliveryFee) : 0,
      deliveryFeeTaxable,
      selectedTags,
      totalWeight,
    };

    const payload = configureInvoice(orgData?.orgTimezone).newInvoice(
      invoiceData,
      userData.id,
      orgData,
      currentInvoice
    );

    const url = `${functionsBaseUrl}getShippingFee`
    const data = {
      orgId: orgData.id,
      address: {
        line1: payload.deliveryCustomerShippingAddress,
        line2: '',
        city: payload.deliveryCustomerShippingCity,
        state: payload.deliveryCustomerShippingState,
        zipCode: payload.deliveryCustomerShippingZip,
      }
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        // Not throwing an error will allow the function to continue to the next line
        // It's a good idea to throw an error for handling downstream
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log('Success:', responseData.cost);
      return responseData.cost;
    } catch (error) {
      console.error('Error:', error);
    }


  }

  const [chatDrawer, setChatDrawer] = useState({
    visible: false,
    invoice: null,
  });

  const handleChatClick = (i) => {
    setChatDrawer({
      visible: true,
      invoice: i,
    });
  };

  const chatButton = checkSubscriptionLevel("internalNotes", orgData) ? (
    <Button
      onClick={() => {
        if (currentInvoice) {
          handleChatClick(currentInvoice);
        }
      }}
      type="primary"
    >
      Internal Notes
    </Button>
  ) : null;

  return (
    <div>
      <InvoiceEditorContext.Provider value={InvoiceEditor}>
        <SendInvoiceContext.Provider value={ModalMgr}>
          <AvailableInventoryContext.Provider value={InvoiceInventoryAvailability}>
            <Spin spinning={loading} indicator={Spinner}>
              <InvoiceHeader
                tags={tags}
                selectedTags={selectedTags}
                handleBackClicked={handleBackClicked}
                id={id}
                customer={customer}
                currentInvoice={currentInvoice}
                setCurrentInvoice={setCurrentInvoice}
                handleComplete={handleComplete}
              />

              <div className={"main-body"} style={{ marginTop: "50px" }}>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      backgroundColor: "green",
                    }}
                  >
                    <Stack alignItems={'center'}>
                    <Stack
                      gap={2}
                      direction={"row"}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >


                      <Typography color={"white"} fontWeight={"bold"}>
                       🎉 NOW AVAILABLE! Be sure to watch the latest instructions video by clicking learn more.
                      </Typography>
                      <Button
                        onClick={() => {
                          window.open("https://youtu.be/oAeT6H29bUc", "_blank");
                        }}
                        type="primary"
                      >
                        Learn More
                      </Button>

                      <Button
                        onClick={() => {
                          const url = `${window.location.origin}/invoices/preview/${currentInvoice.id}`;
                          window.open(url, "_blank", "noopener,noreferrer");
                        }}
                        type="dashed"
                        style={{ backgroundColor: "green", color: "white" }}

                      >
                        Try It Out!
                      </Button>

                    </Stack>

                    <Typography color={"white"} fontWeight={"bold"}>
                      {`To enable the new version, go to Settings > General > Invoice and Uncheck "Use legacy order view" and reload.`}
                    </Typography>

                    </Stack>

                  </Box>

                  <Tabs defaultActiveKey="invoice" activeKey={tabType} onChange={setTabType} tabBarExtraContent={chatButton}>
                    <TabPane tab="Order" key="invoice" />
                    <TabPane tab="Payment History" key="payments" />
                    <TabPane tab="Email Tracking" key="email" />
                  </Tabs>
                </div>

                {tabType === "invoice" && (
                  <div>
                    <div>
                      <Form form={form} layout="vertical" className="invoice-container">
                        <Row justify="space-between" align="top" wrap={false}>
                          <Row justify="space-between" align="top" wrap={false}>
                            <Space align="top">
                              <CustomerInfo
                                form={form}
                                customer={customer}
                                // onCustomerInfoChanged={(value, formItemName) =>
                                //   customerInfoChanged(value, formItemName)
                                // }
                                setCustomer={setCustomer}
                                currentInvoice={currentInvoice}
                                currentId={id}
                              />
                            </Space>
                          </Row>
                          {users && (
                            <Col style={{ textAlign: "right" }}>
                              <RentalRange
                                users={users}
                                form={form}
                                onSalesInvoiceToggle={handleSalesInvoiceToggle}
                                currentInvoice={currentInvoice}
                                selectedItems={selectedItems}
                                rentalRange={rentalRange}
                                setRentalRange={setRentalRange}
                                rentalDates={rentalDates}
                                setRentalDates={setRentalDates}
                                invoiceTotal={invoiceTotal}
                                orgData={orgData}
                              />
                            </Col>
                          )}
                        </Row>
                        <br />
                        <InvoiceContextMenuWrapper
                          isSalesInvoice={isSalesInvoice}
                          currentInvoice={currentInvoice}
                          availableItems={inventory}
                          selectedItems={selectedItems || []}
                          setSelectedItems={setSelectedItems}
                          invoiceTotal={invoiceTotal}
                          setInvoiceTotal={setInvoiceTotal}
                          rentalRange={rentalRange}
                          setToggleNewInvModal={setToggleNewInvModal}
                          customer={customer && customer}
                          invoiceTaxRate={invoiceTaxRate}
                          setInvoiceTaxRate={setInvoiceTaxRate}
                          deliveryFee={deliveryFee}
                          setDeliveryFee={setDeliveryFee}
                          deliveryFeeTaxable={deliveryFeeTaxable}
                          setDeliveryFeeTaxable={setDeliveryFeeTaxable}
                          getShippingFee={getShippingFee}
                          editing={editing}
                          setEditing={setEditing}
                          loadingAllInventory={loadingAllInventory}
                        />
                        <br />
                        {currentInvoice && invoiceTextData && (
                          <div>
                            <InvoiceActions
                              downloadingPDF={downloadingPDF}
                              saveFile={saveFile}
                              makePaymentLink={makePaymentLink}
                              paymentLinkUrl={paymentLinkUrl}
                              makingPaymentLink={makingPaymentLink}
                              currentInvoice={currentInvoice}
                              orgData={orgData}
                              syncingInvoice={syncingInvoice}
                              syncClicked={syncClicked}
                              handleComplete={handleComplete}
                            />

                            <div
                              style={{
                                textAlign: "right",
                                paddingRight: "58px",
                              }}
                            >
                              {orgData && orgData.trackInventoryWeight && (
                                <>
                                  <TextBody text={`Total Weight: ${totalWeight} (lb/kg)`} />
                                  <br />
                                </>
                              )}

                              <TextBody
                                text={`Fulfilled: ${FormatToLocalCurrency(
                                  currentInvoice?.balanceFulfilled,
                                  orgData.countryCode,
                                  orgData.languageCode,
                                  orgData.currencyCode
                                )}`}
                              />
                              <br />
                              <Space>
                                <TextBody
                                  style={{ fontWeight: "bold" }}
                                  text={`Balance Remaining:  ${FormatToLocalCurrency(
                                    currentInvoice?.balanceRemaining,
                                    orgData.countryCode,
                                    orgData.languageCode,
                                    orgData.currencyCode
                                  )}`}
                                />
                                {currentInvoice?.balanceRemaining <= 0 && <CheckCircleFilled style={{ color: "green" }} />}
                              </Space>

                              {currentInvoice?.estimateSignatures && currentInvoice?.estimateSignatures.length > 0 && (
                                <div>
                                  <Space>
                                    <TextBody style={{ fontWeight: "bold" }} text={`Signed For Estimate`} />

                                    <CheckCircleFilled style={{ color: "green" }} />
                                  </Space>
                                </div>
                              )}

                              {currentInvoice?.paymentSignatures && currentInvoice?.paymentSignatures.length > 0 && (
                                <div>
                                  <Space>
                                    <TextBody style={{ fontWeight: "bold" }} text={`Signed For Invoice`} />

                                    <CheckCircleFilled style={{ color: "green" }} />
                                  </Space>
                                </div>
                              )}
                            </div>
                            <Divider />
                          </div>
                        )}

                        <Card
                          style={{
                            display: isSalesInvoice ? "none" : "block",
                          }}
                          bordered={false}
                          size="small"
                          title={<TextBody style={{ fontWeight: "bold" }} text={"Receive & Return Details"} />}
                        >
                          {rentalRange !== 0 && customer ? (
                            <Timeline style={{ marginTop: "20px" }} className="receive-return-timeline">
                              <ReceiveDetails
                                isSalesInvoice={isSalesInvoice}
                                customer={customer}
                                form={form}
                                venues={venues}
                                customerAddressChecked={customerAddressChecked}
                                handleReceiveMethod={handleReceiveMethod}
                                receiveMethod={receiveMethod}
                                setCustomerAddressChecked={setCustomerAddressChecked}
                                rentalDates={rentalDates}
                                setSpecifiedTimes={setSpecifiedTimes}
                                specifiedTimes={specifiedTimes}
                                currentInvoice={currentInvoice}
                                id={id}
                              />
                              <ReturnDetails
                                isSalesInvoice={isSalesInvoice}
                                customer={customer}
                                venues={venues}
                                form={form}
                                customerAddressChecked={customerAddressChecked}
                                handleReturnMethod={handleReturnMethod}
                                returnMethod={returnMethod}
                                setCustomerAddressChecked={setCustomerAddressChecked}
                                rentalDates={rentalDates}
                                setSpecifiedTimes={setSpecifiedTimes}
                                specifiedTimes={specifiedTimes}
                                currentInvoice={currentInvoice}
                                id={id}
                              />
                            </Timeline>
                          ) : (
                            <Alert message="Select a rental range and customer before configuring." type="warning" showIcon />
                          )}
                        </Card>

                        <br />
                        <Card bordered={false} size="small" title={<TextBody style={{ fontWeight: "bold" }} text={"Notes (Visible to customer on invoice)"} />}>
                          <br />
                          <Form.Item name="notes" initialValue="">
                            <Input.TextArea placeholder="Additional notes" autoSize={{ minRows: 2, maxRows: 10 }} />
                          </Form.Item>
                        </Card>
                      </Form>
                    </div>
                  </div>
                )}

                {tabType === "payments" && currentInvoice && <InvoicePaymentHistory invoiceId={currentInvoice?.id} />}

                {tabType === "email" && currentInvoice && <InvoiceSendRecords invoiceId={currentInvoice?.id} />}
              </div>
            </Spin>

            <CreateNewItemModal
              toggleNewInvModal={toggleNewInvModal}
              setToggleNewInvModal={setToggleNewInvModal}
              loading={loading}
              onComplete={(invId) => {
                // refresh inventory
                setLoading(true);
                inventoryQueries({ firestore }, fsOrgPrefix)
                  .fetchInventory()
                  .then((data) => {
                    setInventory([...data]);
                    setLoading(false);
                    const selected = data.find((i) => i.id === invId);
                    const { key, editable, selectedDuration, selectedQty, ...rest } = selectedItems.items[toggleNewInvModal.key];
                    const item = {
                      ...selected,
                      ...rest,
                      name: selected.name,
                      editable: editable,
                      key: key,
                      selectedItemId: invId,
                      rates: selected.rates ? selected.rates : [],
                      selectedRate: selected.rates && selected.rates[0] ? selected.rates[0].rateName : "Select rate",
                      selectedRateMeta: selected.rates && selected.rates[0] ? selected.rates[0] : null,
                      selectedTaxable: selected.isTaxable,
                      selectedDuration: selectedDuration,
                      selectedQty: selectedQty,
                      selectedDescription: selected.description,
                      uuid: firelikeId(),
                    };
                    const newData = [...selectedItems.items];
                    newData.splice(toggleNewInvModal.key, 1, { ...item });
                    setSelectedItems({
                      items: newData,
                      count: selectedItems.items.length,
                    });
                    setToggleNewInvModal({
                      visible: false,
                      name: "",
                      key: null,
                    });
                  });
              }}
            />
            {currentInvoice && (
              <InternalNotesDrawer
                showTags={true}
                // drawerView
                visible={chatDrawer.visible}
                handleCloseDrawer={setChatDrawer}
                setSelectedTags={setSelectedTags}
                invoice={currentInvoice}
                tags={tags}
                selectedTags={selectedTags}
                onComplete={() => {
                  setChatDrawer({
                    visible: false,
                    item: null,
                  });
                }}
              />
            )}
            {currentInvoice && (
              <div>
                <SyncQuickBooksModal
                  visible={showSyncModal}
                  onCancel={() => setShowSyncModal(false)}
                  onComplete={onManualSyncComplete}
                  invoice={currentInvoice}
                  orgData={orgData}
                  handleComplete={handleComplete}
                />
              </div>
            )}
            <SendInvoiceModal
              venues={venues}
              invoiceTextData={invoiceTextData}
              i18n={i18n}
              logoData={logoBase64}
              itemImages={itemImages}
              files={fileAttachments}
              signatureData={signatureData}
            />
          </AvailableInventoryContext.Provider>
        </SendInvoiceContext.Provider>
      </InvoiceEditorContext.Provider>
    </div>
  );
};

export default InvoiceDetail;
