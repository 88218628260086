import React, { useContext, useEffect, useRef, useState } from "react";
import { Input, Table, Form, Tag, Switch, Row, Col, Card, Space } from "antd";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { CheckCircleFilled } from "@ant-design/icons";
import { COLOR_GREEN_0 } from "../../../app/ui/colorConstants";
import ExpandedInvoiceItem from "./ExpandedInvoiceItem";

const { TextArea } = Input;
const QRInvoiceTable = ({ selectedInvoice }) => {
  console.log(selectedInvoice);
  const EditableContext = React.createContext(null);

  const data = {
    dataSource: [
      {
        key: "0",
        name: "Edward King",
        age: "32",
        address: "London, Park Lane no. 0",
      },
      {
        key: "1",
        name: "Edward King 1",
        age: "32",
        address: "London, Park Lane no. 1",
      },
    ],
    count: 2,
  };

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const tableColumns = [
    {
      title: "Item",
      dataIndex: "name",
    },

    {
      title: "Found",
      dataIndex: "found",
      editable: true,
      width: 120,
    },
    {
      title: "Purchased",
      dataIndex: "purchased",
      editable: true,
      width: 120,
    },
    {
      title: "Subrent",
      dataIndex: "subRent",
      editable: true,
      width: 120,
    },
    {
      title: () => (
        <TextBody
          text={
            <span style={{ fontWeight: "bold" }}>
              <CheckCircleFilled
                style={{ color: COLOR_GREEN_0, marginRight: "4px" }}
              />
              {"Ready"}
            </span>
          }
        />
      ),
      dataIndex: "age",
      editable: true,
      width: 120,
    },
    {
      title: (
        <TextBody
          text={<span style={{ fontWeight: "bold" }}>{"Needed"}</span>}
        />
      ),
      dataIndex: "qtyRented",
      width: 120,
    },
  ];
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: null,
      }),
    };
  });
  return (
    <Table
      size={"small"}
      components={components}
      rowClassName={() => "editable-row"}
      bordered
      dataSource={selectedInvoice && selectedInvoice.qrItems}
      columns={columns}
      expandable={{
        expandedRowRender: (item) => (
          <ExpandedInvoiceItem
            key={item.id}
            item={item}
            selectedInvoice={selectedInvoice}
          />
        ),
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
      pagination={false}
    />
  );
};

export default QRInvoiceTable;
