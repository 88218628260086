// useAvailableInvInvoice.js
import { useEffect, useState, useRef } from "react";
import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
import moment from "moment";
import workerFactory from 'workerize-loader!../workers/workAvailableInvInvoice.js'; // eslint-disable-line import/no-webpack-loader-syntax

export function useAvailableInvInvoice({
  startEndOps,
  inventory,
  invoiceItems,
  initialInvoice,
}) {
    
  const optsStart = startEndOps?.rentalDateStart ?? null;
  const optsEnd = startEndOps?.rentalDateEnd ?? null;
  const start = optsStart
    ? moment(optsStart, "MMM Do YYYY").startOf("day").toDate()
    : null;
  const end = optsEnd
    ? moment(optsEnd, "MMM Do YYYY").endOf("day").toDate()
    : null;

  const stock = useInvoicesInRange(
    {
      useTurnaround: true,
      rentalDateStart: start ?? new Date(),
      rentalDateEnd: end ?? new Date(),
    },
    inventory ?? [],
    initialInvoice?.id ? [initialInvoice.id] : []
  );

  const [availableStock, setAvailableStock] = useState({});
  const [showOverbookedError, setShowOverbookedError] = useState(false);
  
  // Use refs to track if we've already calculated for the current data
  const lastCalculationRef = useRef({
    stockData: null,
    invoiceItems: null,
    inventory: null
  });

  useEffect(() => {
    stock.refresh();
  }, [optsStart, optsEnd, inventory]);

  useEffect(() => {
    if (stock.fetching || !inventory) return;

    const currentData = {
      stockData: stock.data,
      invoiceItems,
      inventory
    };

    // Check if we need to recalculate
    const needsRecalculation = 
      JSON.stringify(currentData) !== JSON.stringify(lastCalculationRef.current);

    if (needsRecalculation) {
      const worker = workerFactory();
      worker.calculateAvailableStock(invoiceItems, stock.data)
        .then(result => {
          console.log("Worker result:", result);
          setAvailableStock(result.availableStock);
          setShowOverbookedError(result.showOverbookedError);
          // Update the last calculation reference
          lastCalculationRef.current = currentData;
        })
        .catch(error => {
          console.error("Worker error:", error);
        })
        .finally(() => {
          worker.terminate();
        });
    }
  }, [stock.data, invoiceItems, inventory, stock.fetching]);

  return {
    state: { availableStock, showOverbookedError },
    actions: {},
  };
}


// THIS ONE WORKS
// // useAvailableInvInvoice.js
// import { useEffect, useState } from "react";
// import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
// import moment from "moment";
// import workerFactory from 'workerize-loader!../workers/workAvailableInvInvoice.js'; // eslint-disable-line import/no-webpack-loader-syntax

// export function useAvailableInvInvoice({
//   startEndOps,
//   inventory,
//   invoiceItems,
//   initialInvoice,
// }) {
//   console.log("Hook received data:", { startEndOps, inventory, invoiceItems, initialInvoice });

//   const optsStart = startEndOps?.rentalDateStart ?? null;
//   const optsEnd = startEndOps?.rentalDateEnd ?? null;
//   const start = optsStart
//     ? moment(optsStart, "MMM Do YYYY").startOf("day").toDate()
//     : null;
//   const end = optsEnd
//     ? moment(optsEnd, "MMM Do YYYY").endOf("day").toDate()
//     : null;

//   const stock = useInvoicesInRange(
//     {
//       useTurnaround: true,
//       rentalDateStart: start ?? new Date(),
//       rentalDateEnd: end ?? new Date(),
//     },
//     inventory ?? [],
//     initialInvoice?.id ? [initialInvoice.id] : []
//   );

//   const [availableStock, setAvailableStock] = useState({});
//   const [showOverbookedError, setShowOverbookedError] = useState(false);

//   useEffect(() => {
//     stock.refresh();
//   }, [optsStart, optsEnd, inventory]);

//   useEffect(() => {
//     if (stock.fetching) return;

//     const worker = workerFactory();

//     worker.calculateAvailableStock(invoiceItems, stock.data)
//       .then(result => {
//         console.log("Worker result:", result);
//         setAvailableStock(result.availableStock);
//         setShowOverbookedError(result.showOverbookedError);
//       })
//       .catch(error => {
//         console.error("Worker error:", error);
//       })
//       .finally(() => {
//         worker.terminate();
//       });

//   }, [initialInvoice, stock.data, invoiceItems, stock.fetching]);

//   return {
//     state: { availableStock, showOverbookedError },
//     actions: {},
//   };
// }

// import { useEffect, useState } from "react";
// import { StartEndOptions, useInvoicesInRange } from "./fetch/useInvoicesInRange";
// import moment from "moment";
// import Nifty from "../../utils/Nifty";
// import {
//   INV_BUNDLE_ITEM,
//   ROW_TYPE_SUBTOTAL,
// } from "../../utils/models/modelConstants/modelConstants";
// import { InventoryItem } from "../../interfaces/inventoryItem";

// /**
//  * Hook to calculate available inventory based on upcoming invoices. 
//  * Gets all invoices in a date range, flattens invoice items, sums quantities
//  * by inventory id, and compares to current quantities to find overbooked items.
//  * 
//  * @param {StartEndOptions} startEndOps - Date range filter parameters
//  * @param {Array} inventory - List of inventory items
//  * @param {Array} invoiceItems - Invoice line items 
//  * @param {Object} initialInvoice - Optional initial invoice to exclude
//  * @returns {Object} State and helpers  
//  */

// export function useAvailableInvInvoice({
//   startEndOps,
//   inventory,
//   invoiceItems,
//   initialInvoice,
// }:{
//   startEndOps?: StartEndOptions,
//   inventory?: InventoryItem[],
//   invoiceItems?: any,
//   initialInvoice?: any
// }) {

//   const optsStart = startEndOps?.rentalDateStart ?? null;
//   const optsEnd = startEndOps?.rentalDateEnd ?? null;
//   const start = optsStart
//     ? moment(optsStart, "MMM Do YYYY").startOf("day").toDate()
//     : null;
//   const end = optsEnd
//     ? moment(optsEnd, "MMM Do YYYY").endOf("day").toDate()
//     : null;

//   const stock = useInvoicesInRange(
//     {
//       useTurnaround: true,
//       rentalDateStart: start ?? new Date(),
//       rentalDateEnd: end ?? new Date(),
//     },
//     inventory ?? [],
//     // invoiceItems,
//     initialInvoice?.id ? [initialInvoice.id] : []
//   );

//   const [availableStock, setAvailableStock] = useState([]);
//   const [showOverbookedError, setShowOverbookedError] = useState(false);

//   useEffect(() => {
//     stock.refresh();
//   }, [optsStart, optsEnd, inventory]);

//   useEffect(() => {
//     if (stock.fetching) return;
//     // flatten and sum current invoice items
//     const flatInvoiceItems = flattenCurrentInvoiceItems(invoiceItems);
//     const flatStockFromUnavailable = flattenStockFromInvoices(stock?.data);
//     const allItems = sumGroupedArrayQtyById(
//       flatInvoiceItems.concat(flatStockFromUnavailable)
//     );
//     setAvailableStock(allItems.totals);

//     // overbooked
//     const hasOverbooked = containsOverbooked(allItems.totals, flatInvoiceItems);
//     setShowOverbookedError(hasOverbooked);
//   }, [initialInvoice, stock.data, invoiceItems, stock.fetching]);

//   return {
//     state: { availableStock, showOverbookedError },
//     actions: {},
//   };
// }

// /**
//  * Flattens the invoice line items into a list with quantities
//  * Includes logic to handle bundle items
//  * 
//  * @param {Array} invoiceItems - Invoice line items
//  * @returns {Array} Flattened list  
// */

// const flattenCurrentInvoiceItems = (invoiceItems: Array<any>): Array<any> => {
//   /**
//    * Flatten all items (bundle, regular, and bundle parents) and sum up quantities
//    * Runs everytime invoice items change
//    */

//   // for each item, if bundle create list of objects with id and qty * bundle qty
//   const itemsRemovedSubtotals = invoiceItems?.filter(
//     (i) => i.type !== ROW_TYPE_SUBTOTAL
//   );
//   let items: any[] = [];
//   itemsRemovedSubtotals?.map((i) => {
//     if (i.type === INV_BUNDLE_ITEM) {
//       const multiplier = i?.selectedQty ?? 0;
//       i?.bundleItems?.forEach((bi: { bundleItemId: any; bundleItemQty: number; }) => {
//         items.push({
//           id: bi?.bundleItemId ?? "",
//           qty: -(bi?.bundleItemQty * multiplier),
//         });
//       });
//     }
//     // other types + bundle parents
//     items.push({
//       id: i?.id,
//       qty: -(i?.selectedQty ?? 0),
//     });
//   });
//   return items;
// };

// /**
//  * Converts array of stock data into flattened list
//  * Sets quantity based on available stock value
//  * 
//  * @param {Array} stock - Stock data from invoices 
//  * @returns {Array} Flattened list
// */
// const flattenStockFromInvoices = (stock: any[]) => {
//   return stock?.map((s) => {
//     return {
//       id: s.id,
//       qty: s?.availableStock ?? 0,
//     };
//   });
// };

// /**
//  * Sums an array of objects by the id property 
//  * @param {Array} items - Array of objects with id and qty
//  * @returns {Object} Totals mapped by id 
// */ 

// const sumGroupedArrayQtyById = (items: any[]) => {
//   const totals = Nifty.GroupArray(items, "id", (arr: any[]) => {
//     let total = 0;
//     arr?.forEach((i: { qty: number; }) => {
//       total = total + i?.qty;
//     });
//     return total;
//   });
//   return { totals };
// };


// /** 
//  * Checks if quantities are overbooked 
//  * Compares to flattened invoice items
//  * 
//  * @param {Object} groupedArray - Sums of quantities by id 
//  * @param {Array} flatInvoiceItems - Flattened invoice items
//  * @returns {boolean} If any overbooked items
// */
// const containsOverbooked = (groupedArray: { [x: string]: number; }, flatInvoiceItems: any[]) => {
//   // determines which flat invoices items are overbooked and returns true if so
//   let hasOverbookedItems = false;
//   flatInvoiceItems?.map((i: { id: string | number; }) => {
//     const isNegative = groupedArray[i.id] < 0;
//     if (!isNegative) return;
//     if (hasOverbookedItems) return;
//     hasOverbookedItems = true;
//   });
//   return hasOverbookedItems;
// };
