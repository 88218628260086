import { ORG_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import notificationError from "../../../system-components/toasters/notificationError";

export function orgWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(ORG_COLLECTION);

  async function updateOrgDoc(id, payload) {
    return await ref.doc(id).update(payload);
  }
  return {
    updateOrgDoc: updateOrgDoc, // update org document
  };
}

export function emailSettingsWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(ORG_COLLECTION) ;

  async function updateEmailSettings(orgId, settingsId, payload) {
    return await ref.doc(orgId).collection('settings').doc(settingsId).update(payload);
  }
  return {
    updateEmailSettings: updateEmailSettings, // update settings document
  };
}
