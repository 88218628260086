import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Button, Form, Input, Modal, Radio, Spin } from "antd";

const TermsConditionsModal = ({
  visible,
  onCancel,
  onUpdateClicked,
  defaultText,
}) => {
  const [text, setText] = useState(defaultText);
  const tcEditor = React.useRef();

  const rteChange = (content, delta, source, editor) => {
    setText(editor.getHTML());
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
  ];

  return (
    <Modal
      destroyOnClose
      width={800}
       open={visible}
      closeIcon={<></>}
      title="Terms and Conditions"
      footer={[
        <Button key="cancel" onClick={() => onCancel()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={async () => {
            await onUpdateClicked(tcEditor?.current?.value);
          }}
        >
          Update Terms & Conditions
        </Button>,
      ]}
    >
      <div>
        <ReactQuill
          name={"termsAndConditions"}
          defaultValue={defaultText}
          ref={tcEditor}
          onChange={rteChange}
          modules={modules}
          formats={formats}
          placeholder="Your company's Terms & Conditions"
          theme="snow"
          style={{
            height: "calc(50vh)",
            marginBottom: "24px",
          }}
        />
      </div>
    </Modal>
  );
};

export default TermsConditionsModal;
