import { Customer } from "../../../app/interfaces/customer";
import { checkUndefined } from "../../../app/utils/models/checkers/checkUndefined";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  RECEIVE_METHOD_PICKUP,
  RETURN_METHOD_DROPOFF,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { FullReceiveReturnDetailsProps } from "../types/ReceiveReturnDetails";

export const configureDeliveryDetailsMUI = (customer: Customer | null, receiveReturnDetails: FullReceiveReturnDetailsProps, customerAddressChecked: any) => {

  // console.log("receveReturnDetails", receiveReturnDetails);
  
  const createShippingAddressStr = (values: any) => {
    if (
      values.deliveryCustomerShippingAddress === "" ||
      values.deliveryCustomerShippingCity === "" ||
      values.deliveryCustomerShippingState === "" ||
      values.deliveryCustomerShippingZip === ""
    )
      return "";
    return `${values.deliveryCustomerShippingAddress}, ${values.deliveryCustomerShippingCity}, ${values.deliveryCustomerShippingState} ${values.deliveryCustomerShippingZip}`;
  };

  const createOnSiteAddressStr = (values: any) => {
    if (
      values.onSiteCustomerShippingAddress === "" ||
      values.onSiteCustomerShippingCity === "" ||
      values.onSiteCustomerShippingState === "" ||
      values.onSiteCustomerShippingZip === ""
    )
      return "";
    return `${values.onSiteCustomerShippingAddress}, ${values.onSiteCustomerShippingCity}, ${values.onSiteCustomerShippingState} ${values.onSiteCustomerShippingZip}`;
  };

  // delivery details
  const configureReceiveAddr = (receiveMethod: string) => {
    if (receiveMethod === RECEIVE_METHOD_PICKUP || !customer) {
      return {
        deliveryCustomerDisplayName: null,
        deliveryCustomerShippingAddress: null,
        deliveryCustomerShippingCity: null,
        deliveryCustomerShippingState: null,
        deliveryCustomerShippingZip: null,
        deliveryCustomerShippingAddressStr: null,
      };
    }

    if (customerAddressChecked.receive) {
      const payload = {
        deliveryCustomerDisplayName: customer[CUSTOMER_DISPLAY_NAME],
        deliveryCustomerShippingAddress: customer[CUSTOMER_SHIPPING_ADDRESS],
        deliveryCustomerShippingCity: customer[CUSTOMER_SHIPPING_CITY],
        deliveryCustomerShippingState: customer[CUSTOMER_SHIPPING_STATE],
        deliveryCustomerShippingZip: customer[CUSTOMER_SHIPPING_ZIP],
      };
      return {
        deliveryCustomerShippingAddressStr: createShippingAddressStr(payload),
        ...payload,
      };
    } else {
      const payload = {
        deliveryCustomerDisplayName: receiveReturnDetails.deliveryCustomerDisplayName,
        deliveryCustomerShippingAddress: receiveReturnDetails.manualReceiveAddress,
        deliveryCustomerShippingCity: receiveReturnDetails.manualReceiveCity,
        deliveryCustomerShippingState: receiveReturnDetails.manualReceiveState,
        deliveryCustomerShippingZip: receiveReturnDetails.manualReceiveZip,
      };
      return {
        deliveryCustomerShippingAddressStr: createShippingAddressStr(payload),
        ...payload,
      };
    }
  };


  const configureReturnAddr = (returnMethod: string) => {
    if (returnMethod === RETURN_METHOD_DROPOFF || !customer) {
      return {
        onSiteCustomerDisplayName: null,
        onSiteCustomerShippingAddress: null,
        onSiteCustomerShippingCity: null,
        onSiteCustomerShippingState: null,
        onSiteCustomerShippingZip: null,
        onSiteCustomerShippingAddressStr: null,
      };
    }
    if (!customerAddressChecked.return) {
      const payload = {
        onSiteCustomerDisplayName: receiveReturnDetails.onSiteCustomerDisplayName,
        onSiteCustomerShippingAddress: receiveReturnDetails.manualReturnAddress,
        onSiteCustomerShippingCity: receiveReturnDetails.manualReturnCity,
        onSiteCustomerShippingState: receiveReturnDetails.manualReturnState,
        onSiteCustomerShippingZip: receiveReturnDetails.manualReturnZip,
      };
      return {
        onSiteCustomerShippingAddressStr: createOnSiteAddressStr(payload),
        ...payload,
      };
    } else {
      const payload = {
        onSiteCustomerDisplayName: customer[CUSTOMER_DISPLAY_NAME],
        onSiteCustomerShippingAddress: customer[CUSTOMER_SHIPPING_ADDRESS],
        onSiteCustomerShippingCity: customer[CUSTOMER_SHIPPING_CITY],
        onSiteCustomerShippingState: customer[CUSTOMER_SHIPPING_STATE],
        onSiteCustomerShippingZip: customer[CUSTOMER_SHIPPING_ZIP],
      };
      return {
        onSiteCustomerShippingAddressStr: createOnSiteAddressStr(payload),
        ...payload,
      };
    }
  };

  return {
    // customerQueryHook: checkUndefined(customer.id),
    // customerAddressChecked: customerAddressChecked,
    // receiveMethod: checkUndefined(receiveReturnDetails.receiveBy),
    // returnMethod: checkUndefined(receiveReturnDetails.returnBy),
    ...configureReceiveAddr(receiveReturnDetails.receiveBy),
    ...configureReturnAddr(receiveReturnDetails.returnBy),
  };
};
