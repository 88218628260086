import { useContext, useMemo } from "react";
import { Divider, Modal, Space } from "antd";
import { ReceivePaymentModalContext } from "../ReceivePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { centsToDollars } from "../../../app/utils/models/checkers/checkCurrencyToDollars";
import NumberFormat from "react-number-format";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const StripePaymentModal = () => {
  const modal = useContext(ReceivePaymentModalContext);
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
  //   stripeAccount: `${modal.data?.stripeAccountId}`,
  // });
const stripeAccount = `${modal.data?.stripeAccountId}`
  const stripePromise = useMemo(
    () => loadStripe(process.env.REACT_APP_STRIPE_PK, { stripeAccount }),
    [stripeAccount],
  )
  const options = {
    // pass the client secret from the previous step
    clientSecret: `${modal.data?.secret}`,
    appearance: {
      theme: "stripe",
    },
  };
  return (
    <Modal destroyOnClose={true} open={!!modal.data} width={800} footer={null}>
      <TextBody
        text={`Payment from: ${modal.data?.paymentData?.customer?.customerDisplayName}`}
      />
      <br />
      <Space>
        <TextSubtitle text={"Available credit (Automatically applied):"} />
        <TextSubtitle
          text={`$${modal.data?.paymentData?.customer?.credit?.toLocaleString(
            "us",
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          )}`}
        />
      </Space>
      <br />
      <br />
      <Space>
        <TextBody text={"Payment amount: "} />
        <NumberFormat
          value={centsToDollars(modal.data?.paymentData?.total).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Space>

      <br />
      <Space>
        <TextBody text={"Added credit: "} />
        <NumberFormat
          value={centsToDollars(modal.data?.paymentData?.credit).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Space>

      <Divider />
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm />
      </Elements>
    </Modal>
  );
};

export default StripePaymentModal;
