import React, { useEffect, useState } from "react";
import { Card, Row, Col, Space, Button } from "antd";
import {
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import ItemCompletedTracker from "./ItemCompletedTracker";
import BundleCategorizedItem from "./BundleCategorizedItem";

const CategorizedItem = ({
  items,
  invoice,
  refreshInvoiceData,
  type,
  dailyAvailable,
}) => {
  const [parentItems, setParentItems] = useState([]);

  useEffect(() => {
    setParentItems(items.filter((i) => i.isBundleChild === false));
  }, [items]);

  const configureColor = (type) => {
    if (type === "flag") {
      return COLOR_RED_0;
    }
    if (type === "inProgress") {
      return COLOR_YELLOW_0;
    }
    return COLOR_GREEN_0;
  };

  return (
    <div style={{ padding: "4px 0" }}>
      {parentItems &&
        parentItems.map((i) => {
          return (
            <div
              style={{
                paddingLeft: "12px",
                borderLeft: `4px ${configureColor(type)} solid`,
              }}
            >
              <Card
                bodyStyle={{ padding: "0 8px 12px 8px" }}
                style={{
                  margin: "12px 0",
                  borderColor: "transparent",
                  boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
                }}
                size={"small"}
              >
                <ItemCompletedTracker
                  dailyAvailable={dailyAvailable}
                  item={i}
                  invoice={invoice}
                  key={i.id}
                  refreshInvoiceData={refreshInvoiceData}
                />
                
                {i.bundleChildrenIds.length !== 0 && (
                  <BundleCategorizedItem
                    dailyAvailable={dailyAvailable}
                    items={items}
                    parentId={i.id}
                    invoice={invoice}
                    refreshInvoiceData={refreshInvoiceData}
                  />
                )}
              </Card>
            </div>
          );
        })}
    </div>
  );
};

export default CategorizedItem;
