import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Input, Row, Select, Space, Tooltip } from "antd";
import "react-phone-number-input/style.css";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_FULL_SHIPPING_ADDRESS_STR,
  INVOICE,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import { customerQueries } from "../../../../app/services/firestore/queries/customerQueries";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import {
  COLOR_BLUE_0,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_0,
  COLOR_TEXT_GRAY_1,
  COLOR_YELLOW_0,
} from "../../../../app/ui/colorConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { getObjFromId } from "../../helpers/getObjFromId";
import {
  EnvironmentOutlined,
  UserOutlined,
  EditOutlined,
  UserAddOutlined,
  WarningFilled,
} from "@ant-design/icons";
import AddCustomerModal from "../../../admin-customers/components/modals/AddCustomerModal";
import { customerWrites } from "../../../../app/services/firestore/writes/customerWrites";
import { configureCustomer } from "../../../../app/utils/models/configure/configureCustomer";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import EditCustomerModal from "../../../admin-customers/components/modals/EditCustomerModal";
import { useCustomerSearch } from "../../../../app/services/hooks/fetch/firestoreSearch/useCustomerSearch";
import { Check, Delete, Phone, PhoneIphone } from "@mui/icons-material";

var root = document.querySelector(":root");
var rootStyles = getComputedStyle(root);
var phoneInputFocus = rootStyles.getPropertyValue("--PhoneInput-color--focus");

root.style.setProperty("--PhoneInput-color--focus", "#fff");

const CustomerInfo = ({
  form,
  // onCustomerInfoChanged,
  setCustomer,
  customer,
  currentInvoice,
  currentId,
}) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [customerInput, setCustomerInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [edit, setEdit] = useState({
    visible: false,
    selected: null,
  });
  const [phoneValue, setPhoneValue] = useState("");

  const firestore = useFirestore();

  const Customers = useCustomerSearch();

  useEffect(() => {
    if (!currentId) return; // new invoice
    // generate text for invoice since its uneditable
    if (currentInvoice.type === INVOICE)
      return Customers.actions.insertRecord({
        id: currentInvoice.customerQueryHook,
        customerDisplayName: currentInvoice.customer.customerDisplayName,
      });
    // invoice or estimate. fetch customer and load customers
    customerQueries({ firestore }, fsOrgPrefix)
      .fetchCustomer(currentInvoice.customerQueryHook)
      .then((res) => {
        Customers.actions.insertRecord(res);
      });
    Customers.actions.refreshDefault();
  }, [currentId]);

  const handleEdit = () => {
    setEdit({
      visible: true,
      selected: customer,
    });
  };

  const renderCustomerAddress = useCallback(() => {
    return (
      <div>
        <UserOutlined
          style={{
            marginRight: "4px",
            color: COLOR_TEXT_GRAY_1,
            fontSize: "18px",
          }}
        />
        <TextBody
          text={`${customer && customer.firstName} ${
            customer && customer.lastName
          }`}
        />

        {orgData &&
          orgData.qboRealmId &&
          orgData.qboRealmId !== "" &&
          (customer.qbId === "" || !customer.qbId) && (
            <span  style={{
              marginLeft: "4px",
            }}>
            <Tooltip
              title={
                "This customer is not linked to a QuickBooks customer and will cause this invoice to fail to sync to QuickBooks. You can still save, but be aware you will need to sync this customer at some point in order to sync this invoice to QuickBooks"
              }
            >
              <WarningFilled
                style={{ marginRight: "8px", color: COLOR_RED_0 }}
              />
            </Tooltip>
            </span >
          )}

          { customer.checkNotesReminder && (
            <span  style={{
              marginLeft: "4px",
            }}>
            <Tooltip
              title={
                `Customer Notes: ${customer.notes}`
              }
            >
              <WarningFilled
                style={{ marginRight: "8px", color: COLOR_YELLOW_0 }}
              />
            </Tooltip>
            </span >
          )}

        <br />
        <EnvironmentOutlined
          style={{
            marginRight: "4px",
            color: COLOR_TEXT_GRAY_1,
            fontSize: "18px",
          }}
        />
        <TextBody
          text={customer && customer[CUSTOMER_FULL_SHIPPING_ADDRESS_STR]}
        />

        <Button size="small" type="link" onClick={() => handleEdit()}>
          <EditOutlined
            style={{
              color: COLOR_BLUE_0,
            }}
          />
        </Button>
      </div>
    );
  }, [customer, loading]);

  const handleCustomerSelected = (e) => {
    const customer = getObjFromId(e, Customers.state.data);
    if (!customer && customerInput !== "") {
      // create customer from input name
      setLoading(true);
      const payload = configureCustomer().newCustomerQuickAdd(
        customerInput,
        userData.id,
        orgData
      );
      customerWrites({ firestore }, fsOrgPrefix)
        .createCustomer(payload)
        .then((id) => {
          const added = {
            id: id,
            ...payload,
          };
          Customers.actions.insertRecord(added);
          const phone = added?.phone ?? null;
          setCustomer(added);
          setCustomerInput("");
          form.setFieldsValue({ customerQueryHook: id });
          form.setFieldsValue({ contactPhoneNumber: phone });
          setLoading(false);
          notificationConfirm("Customer created");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notificationConfirm(
            "Something went wrong",
            "Customer could not be created"
          );
        });
    } else if (customer) {
      // save info to state
      setCustomer(customer);
      setCustomerInput("");
      const phone = customer?.phone ?? null;
      form.setFieldsValue({ contactPhoneNumber: phone });
    } else {
      // new customer
      setCustomer(null);
      setCustomerModal(true);
    }
  };

  const handleAdditionalContactSelected = (id) => {
    const contact = getObjFromId(id, customer.additionalContacts)
    if (contact && contact.phone) {
      setPhoneValue(contact.phone)
      form.setFieldsValue({ contactPhoneNumber: contact.phone });
    }
  }

  const handleClose = () => {
    setCustomerModal(false);
    setCustomer(null);
    form.setFieldsValue({ customerQueryHook: null });
  };

  const { Option } = Select;
  return (
    <div>
      <Form.Item name="customerQueryHook" className="NewInvoiceCustomerForm">
        <Select
          loading={loading}
          disabled={currentInvoice && currentInvoice.type === INVOICE}
          onSearch={(v) => {
            Customers.actions.onSearch(v);
            setCustomerInput(v);
          }}
          allowClear
          onClear={() => {
            setCustomer(null);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          onSelect={handleCustomerSelected}
          showSearch
          onFocus={Customers.actions.loadDefault}
          placeholder={"Select existing customer"}
          className={"customer-picker"}
        >
          <Option
            style={{ fontWeight: "bold" }}
            value={"newCustomer"}
            key={"newCustomerBtn"}
          >
            {customerInput === ""
              ? " + Add New Customer"
              : `+ ${customerInput}`}
          </Option>
          {Customers.state.data.map((c) => (
            <Option value={c.id} key={`${c.id}_${c[CUSTOMER_DISPLAY_NAME]}`}>
              {c[CUSTOMER_DISPLAY_NAME]}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {customer && renderCustomerAddress()}

      <br />

      {customer &&
        customer.additionalContacts &&
        customer.additionalContacts.length > 0 && (
          <>
            <Space size={4} align="center">
              <UserAddOutlined
                style={{
                  marginRight: "4px",
                  color: COLOR_TEXT_GRAY_1,
                  fontSize: "18px",
                }}
              />
              <Form.Item
                name="additionalContacts"
                className="NewInvoiceCustomerForm"
              >
                <Select
                  menuItemSelectedIcon=<Check fontSize="10px" />
                  loading={loading}
                  onSelect={handleAdditionalContactSelected}
                  placeholder={"Select contact"}
                  className={"customer-picker"}
                >
                  {customer &&
                    customer.additionalContacts &&
                    customer.additionalContacts
                      .filter((c) => c.phone)
                      .map((c) => (
                        <Option value={c.id} key={c.id}>
                          {c.name}
                          {c.phone === "" ? "" : `, ${c.phone}`}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Space>
            <br />
          </>
        )}

      <Space size={4} align="center" >
        <Phone
          style={{
            marginRight: "4px",
            color: COLOR_TEXT_GRAY_1,
            fontSize: "18px",
          }}
        />
        <Form.Item style={{ marginTop: "8px" }} name={"contactPhoneNumber"}>
          <Input
            className={"contact-phone-number"}
            // Country="US"
            // enableAreaCodes={true}
            placeholder={"Point Of Contact Phone #"}
            value={phoneValue}
            onChange={setPhoneValue}
          />
        </Form.Item>
      </Space>

      <AddCustomerModal
        visible={customerModal}
        onCancel={handleClose}
        onComplete={async (id) => {
          // completion handler -> get id from newly created customer
          handleClose();
          setLoading(true);

          try {
            const added = await customerQueries(
              { firestore },
              fsOrgPrefix
            ).fetchCustomer(id);
            Customers.actions.insertRecord(added);
            Customers.actions.loadDefault();
            setCustomer(added);
            form.setFieldsValue({ customerQueryHook: id });
            const phone = added?.phone ?? null;
            phone && form.setFieldsValue({ contactPhoneNumber: phone });
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }}
      />
      <EditCustomerModal
        hideDeactivate={true}
        visible={edit.visible}
        selected={edit.selected}
        onCancel={() => {
          setEdit({
            visible: false,
            selected: null,
          });
        }}
        onComplete={() => {

          setLoading(true);
          customerQueries({ firestore }, fsOrgPrefix)
            .fetchCustomer(customer.id)
            .then((res) => {
              Customers.actions.insertRecordWithReload(res);
              form.setFieldsValue({ customerQueryHook: customer.id });
              setCustomer(res);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });

          setEdit({
            visible: false,
            selected: null,
          });
        }}
      />
    </div>
  );
};

export default CustomerInfo;
