import { useEffect, useState } from "react";

export function useValidateRouteComplete(route) {
  const [isValidated, setIsValidated] = useState(false);
  useEffect(() => {
    if (!route) return;
    const hasTruck = route?.assignedVehicles?.length > 0;
    const hasLoadDate = route?.loadDate !== null;
    setIsValidated(hasTruck && hasLoadDate);
  }, [route]);
  return {
    isValidated,
  };
}
