import React, { useState, useEffect } from "react";
import { useFirestoreFetch } from "./fetch/useFirestoreFetch";
import { invoices } from "../firestore/queries/invoiceQueries";
import { customerQueries } from "../firestore/queries/customerQueries";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { SortInvoicesByDueDate } from "./helpers/sortInvoicesByDueDate";
import { useFirestoreWrite } from "./fetch/useFirestoreWrite";

const { useFirestore } = require("react-redux-firebase");

export function useReceivePayment(customerId) {
  const { orgData } = useSelector(authSelector);

  // Fetch invoices by customer. This is the list of invoices shown on the Receive Payments page.
  const { data, fetching } = useFirestoreFetch(
    invoices(orgData?.orgTimezone).fetchInvoicesByCustomer,
    customerId && customerId
  );

  const { newDocument, writing } = useFirestoreWrite();
  const { fsOrgPrefix } = useSelector(authSelector);
  const [customer, setCustomer] = useState(null);
  const [fetchingCustomer, setFetchingCustomer] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [invoicesOutstanding, setInvoicesOutstanding] = useState([]);
  const [invoicesCompleted, setInvoicesCompleted] = useState([]);

  const firestore = useFirestore();

  useEffect(() => {
    if (!customerId || !fsOrgPrefix) return;
    
    setFetchingCustomer(true);
    try {
      customerQueries({ firestore }, fsOrgPrefix)
        .fetchCustomer(customerId)
        .then((c) => {
          if (c) {
            setCustomer(c);
          }
          setFetchingCustomer(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [customerId]);

  useEffect(() => {
    if (!fetchingCustomer && !fetching) {
      setFetchComplete(true);
    } else {
      setFetchComplete(false);
    }
  }, [customerId, fetchingCustomer, fetching]);

  useEffect(() => {
    if (!data) return;
    const filteredByStatus = filterByOutstanding(data);
    setInvoicesOutstanding(SortInvoicesByDueDate(filteredByStatus.outstanding));
    setInvoicesCompleted(SortInvoicesByDueDate(filteredByStatus.completed));
  }, [data]);

  const submitPayment = async ({ collection, payload }) => {
    return await newDocument({
      data: {
        collection: collection,
        payload: payload,
      },
    });
  };

  return {
    fetchingCustomer,
    fetchingInvoices: fetching,
    customer,
    customerCredit:
      customer?.credit?.toLocaleString("us", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) ?? null,
    invoicesOutstanding,
    invoicesCompleted,
    fetchComplete,
    submitPayment,
  };
}

const filterByOutstanding = (invoices) => {
  return {
    outstanding: invoices?.filter((i) => i?.paymentComplete === false),
    completed: invoices?.filter((i) => i?.paymentComplete === true),
  };
};
