import { Geocoder } from "./geocoder";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import { ROUTE_ADMIN_SETTINGS_GENERAL } from "../../../../app/routes";
import React from "react";

export const ValidateOrgAddr = async ({ orgData, setCoAddr, history }) => {
  const coAddrStr = orgData?.companyAddress ?? "";
  try {
    const startCoor = await Geocoder(coAddrStr);
    setCoAddr({
      isValid: true,
      startAddrStr: coAddrStr,
      startLat: startCoor.lat,
      startLng: startCoor.lng,
    });
    return {
      startLat: startCoor.lat,
      startLng: startCoor.lng,
    };
  } catch (err) {
    console.log(err);
    return Modal.info({
      title: "Invalid company address",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content: "Please enter a valid company address in settings to continue.",
      okText: "Go to settings",
      onOk() {
        history.push(ROUTE_ADMIN_SETTINGS_GENERAL);
      },
    });
  }
};
