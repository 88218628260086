// @mui

import { Box, Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";


const RenderInventoryView = ({ filteredInvoices, checkedCategories, showUncategorized }) => {
    // Aggregate items and quantities across all invoices
    const aggregatedItems = filteredInvoices.reduce((acc, invoice) => {
      const items = invoice.selectedItems.items;
      
      items.forEach(item => {
        // Check if item should be included based on categories
        const isItemCategorized = item.categoriesQueryHook?.some(
          categoryId => checkedCategories.includes(categoryId)
        );
        const isItemUncategorized = !item.categoriesQueryHook?.length;
        
        if ((!isItemCategorized && !isItemUncategorized) || 
            (!showUncategorized && isItemUncategorized)) {
          return;
        }
  
        if (item.bundleItems && item.bundleItems.length > 0) {
          // Handle bundle items
          item.bundleItems.forEach(bundleItem => {
            const key = `bundle-${bundleItem.bundleItemId}`;
            if (!acc[key]) {
              acc[key] = {
                name: bundleItem.bundleItemName,
                totalQty: 0,
                isBundle: true,
                parentBundles: []
              };
            }
            acc[key].totalQty += bundleItem.bundleItemQty * item.selectedQty;
            acc[key].parentBundles.push({
              name: item.name,
              qty: item.selectedQty
            });
          });
        } else {
          // Handle regular items
          const key = item.id;
          if (!acc[key]) {
            acc[key] = {
              name: item.name,
              totalQty: 0,
              isBundle: false
            };
          }
          acc[key].totalQty += item.selectedQty;
        }
      });
      
      return acc;
    }, {});
  
    return (
      <Stack spacing={2}>
        <Box sx={{ p: 2, backgroundColor: 'background.neutral' }}>
          <Typography variant="h6">
            Consolidated Inventory List
          </Typography>
        </Box>
        
        {Object.entries(aggregatedItems).map(([key, item]) => (
          <Box key={key} sx={{ p: 1 }}>
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ flex: 1 }}>{item.name}</Typography>
                <Typography fontWeight="bold">
                  {item.totalQty}
                </Typography>
              </Stack>
              
              {item.isBundle && item.parentBundles && (
                <Box sx={{ pl: 2, mt: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    Used in bundles:
                  </Typography>
                  {Array.from(new Set(item.parentBundles.map(b => b.name))).map(bundleName => (
                    <Typography key={bundleName} variant="caption" display="block" sx={{ pl: 1 }}>
                      • {bundleName}
                    </Typography>
                  ))}
                </Box>
              )}
            </Stack>
            <Divider sx={{ mt: 1 }} />
          </Box>
        ))}
      </Stack>
    );
  };
  
  export default RenderInventoryView;