import { Space, Tag } from "antd";
import {
  COLOR_BLUE_0,
  COLOR_DARK_BG_0,
  COLOR_DARK_BG_1,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
} from "../../ui/colorConstants";
import { ClockCircleOutlined } from "@ant-design/icons";
import React from "react";

export const TimeTag = ({ text, styleProps, ...props }) => {
  return (
    <span
      style={{
        fontSize: "13px",
        borderColor: "transparent",
        lineHeight: "20px",
        color: "#FB6851",
        ...styleProps,
      }}
    >
      <Space size={4}>
        <ClockCircleOutlined />
        {text}
      </Space>
    </span>
  );
};

export const DeliveryTag = ({ text, styleProps, ...props }) => {
  return (
    <Tag
      style={{
        marginRight: "0",
        borderColor: "transparent",
        lineHeight: "20px",
        backgroundColor: COLOR_LIGHT_GRAY,
        color: COLOR_BLUE_0,
        fontWeight: "bold",
        ...styleProps,
      }}
    >
      {text}
    </Tag>
  );
};

export const PickupTag = ({ text, styleProps, ...props }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        lineHeight: "20px",
        backgroundColor: COLOR_LIGHT_GRAY,
        color: "#FFB658",
        fontWeight: "bold",
        ...styleProps,
      }}
    >
      {text}
    </Tag>
  );
};

export const WaypointTag = ({ text, styleProps, ...props }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        lineHeight: "20px",
        backgroundColor: COLOR_DARK_BG_1,
        color: "#FFF",
        fontWeight: "bold",
        ...styleProps,
      }}
    >
      {text}
    </Tag>
  );
};

export const GenericTag = ({ text, styleProps, ...props }) => {
  return (
    <Tag
      style={{
        borderColor: "transparent",
        lineHeight: "20px",
        backgroundColor: "white",
        color: "GrayText",
        fontWeight: "bold",
        ...styleProps,
      }}
    >
      {text}
    </Tag>
  );
};