import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { InvoiceEditorContext } from "../../../InvoiceDetail";

const ItemTaxableCell = ({ record, handleTaxableChange }) => {
  const { state, actions } = useContext(InvoiceEditorContext);
  const props = useTaxableCell(record, state.taxSettings.customerIsTaxExempt);
  return (
    <Checkbox
      checked={props.checked}
      disabled={props.disabled}
      onChange={(e) => {
        console.log(e);
        handleTaxableChange(e.target.checked, record);
      }}
    />
  );
};

export default ItemTaxableCell;

export function useTaxableCell(record, customerIsExempt) {
  const [props, setProps] = useState({
    checked: record?.selectedTaxable,
    disabled: false,
  });
  useEffect(() => {
    if (!record)
      return setProps({
        checked: false,
        disabled: false,
      });
    if (customerIsExempt)
      return setProps({
        checked: false,
        disabled: true,
      });
    return setProps({
      checked: record?.selectedTaxable,
      disabled: false,
    });
  }, [record, customerIsExempt]);
  return props;
}
