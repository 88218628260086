import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect } from "react";

export const usePersistentDataGrid = (storageKey: string) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    try {
      const json = localStorage.getItem(storageKey);
      if (json) {
        const gridState = JSON.parse(json);
        // console.log("stored state", gridState);
        apiRef.current.restoreState(gridState);
      }
    } catch (e) {
      console.log(e);
    }
  }); // intentionally leave out dependencies

  const storeGridState = () => {
    const state = apiRef.current.exportState();
    localStorage.setItem(storageKey, JSON.stringify(state));
  };
  return { apiRef, storageKey, storeGridState };
};
