// React imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Ant Design imports
import {
  Affix,
  DatePicker,
  PageHeader,
  Row,
  Col,
  Spin,
  Card,
  Space,
  Divider,
} from "antd";

// Algolia imports
import {
  InstantSearch,
  connectHits,
  Configure,
  RefinementList,
  Pagination,
} from "react-instantsearch-dom";

// Local component imports
import AvailableInventory from "./AvailableInventory";
import CustomAlgoliaSearchbox from "../../app/system-components/search/CustomAlgoliaSearchbox";

// Utility and configuration imports
import { Spinner } from "../../app/ui/spinner";
import { checkInventory } from "../../app/utils/models/checkers/checkInventory";
import {
  algoliaInventoryIndex,
  inventorySearchClient,
} from "../../app/config/algolia_inventory";
import { usePrevious } from "../../app/services/hooks/usePrevious";
import { authSelector } from "../auth/authSlice";
import { Box, CircularProgress, Grid, Container, Tab, Tabs } from "@mui/material";

// Other imports
import moment from "moment";
import { i18n } from "../../i18n";
import "../../App.less";

const { RangePicker } = DatePicker;

const Inventory = ({ cart, setCart, rentalDates, setRentalDates }) => {
  const { orgData } = useSelector(authSelector);
  const [search, setSearch] = useState("");
  const [emptyState, setEmptyState] = useState(true);
  const prevSearch = usePrevious(search);
  const [refresh, setRefresh] = useState(false);
  
  const [selectedTab, setSelectedTab] = useState('all'); // 'all' | 'booked' | 'overbooked'

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const refreshAlgolia = () => {
    setRefresh(false);
  };

  useEffect(() => {
    setRefresh(true);
    if (rentalDates.rentalDateStart && rentalDates.rentalDateEnd) {
      setEmptyState(false);
    }
    refreshAlgolia();
  }, []);

  const handleRange = (e) => {
    if (e) {
      setRentalDates({
        rentalDateStart: e[0].startOf("day").toDate(),
        rentalDateEnd: e[1].endOf("day").toDate(),
      });
      setEmptyState(false);
    } else {
      setRentalDates({
        rentalDateStart: null,
        rentalDateEnd: null,
      });
      setEmptyState(true);
    }
  };

  const Hits = ({ hits }) => {
    if (hits.length === 0) return null;
    let inventoryList = hits ? hits.map(hit => checkInventory(hit, true)) : [];

    const filterInventory = (inventory, selectedTab) => {
      switch (selectedTab) {
        case 'all':
          return inventory;
        case 'booked':
          return inventory.filter(item => item.availableStock < item.stock);
        case 'overbooked':
          return inventory.filter(item => item.availableStock < 0);
        default:
          return inventory;
      }
    };
  
    const filteredInventory = filterInventory(inventoryList, selectedTab);

    return (
      <AvailableInventory
        rentalDates={rentalDates}
        inventory={[...filteredInventory]}
        searchStr={search}
        setCart={setCart}
        cart={cart}
        prevSearch={prevSearch}
        setEmptyState={setEmptyState}
        selectedTab={selectedTab}
      />
    );
  };

  const CustomHits = connectHits(Hits);

  const configureDefaultDates = () => {
    if (
      rentalDates.rentalDateStart !== null &&
      rentalDates.rentalDateEnd !== null
    ) {
      return [
        moment(rentalDates.rentalDateStart),
        moment(rentalDates.rentalDateEnd),
      ];
    } else {
      return [];
    }
  };

  return (
    <div>
      <InstantSearch searchClient={inventorySearchClient} indexName={algoliaInventoryIndex.indexName} refresh={refresh}>
        {<Configure filters={`orgId:${orgData && orgData.id}`} />}
        <Spin indicator={Spinner} style={{ marginTop: "120px" }} spinning={!orgData}>
          <Affix>
            <PageHeader className="PageHeader" backIcon={false} title={i18n("availabilityPage.title")} />
          </Affix>

          <div style={{ padding: "76px 20px 20px" }}>
            <Space className="available-inventory-date-picker">
              <div style={{ textAlign: "left", width: "100%" }}>
                <RangePicker defaultValue={configureDefaultDates} style={{ width: "300px" }} onChange={handleRange} format={(d) => d.format("MMM Do YYYY")} />
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CustomAlgoliaSearchbox />
              </div>
            </Space>
            <Divider />

          {/* {
            !emptyState && (
              <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="All" value="all" />
              <Tab label="Overbooked" value="overbooked" />
              <Tab label="Booked" value="booked" />
            </Tabs>
            )
          } */}
                    
            <Row justify={"space-between"} wrap={false} className="available-inventory-table">
              {/* The actual table is CustomHits */}
              <Col span={18}>
                {refresh === false && <CustomHits />}
                {!emptyState ? <Pagination /> : null}
              </Col>

              {!emptyState ? (
                <Col span={6} style={{ padding: "0 24px" }}>
                  <Card style={{ marginTop: "12px" }} title={i18n("inventory.filters")} size={"small"}>
                    <RefinementList sortBy={"categories.name:asc"} attribute={"categories.name"} limit={100} />
                  </Card>
                </Col>
              ) : null}
            </Row>
          </div>
        </Spin>
      </InstantSearch>
    </div>
  );
};

export default Inventory;
