import { useState } from "react";

import { CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { Invoice } from "../../../../app/interfaces/invoice";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Check, DownloadOutlined, FileCopyRounded, LinkOutlined, SyncOutlined } from "@mui/icons-material";
import { InvoiceTextData } from "../../../admin-settings/components/words-invoice";
import { PrintInvoiceBOMComponent } from "../PrintInvoiceBom";

export const InvoiceActions = ({
  downloadingPDF,
  saveFile,
  makePaymentLink,
  paymentLinkUrl,
  makingPaymentLink,
  currentInvoice,
  orgData,
  syncingInvoice,
  syncClicked,
  handleComplete,
}: {
  downloadingPDF: boolean;
  saveFile: () => Promise<void>;
  makePaymentLink: () => Promise<void>;
  paymentLinkUrl: string;
  makingPaymentLink: boolean;
  currentInvoice: Invoice;
  orgData: any;
  syncingInvoice: boolean;
  syncClicked: (item: any) => void;
  handleComplete: (params: { type: any; redirect: any }) => Promise<any>;
}) => {
  const [copied, setCopied] = useState(false);

  // Copy method
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(paymentLinkUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Stack>
      <Stack direction="row" alignItems="center" mb={1}>
        {(currentInvoice.type === "invoice" ||
          currentInvoice.type === "estimate") && (
          <div style={{ paddingRight: "8px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<LinkOutlined />}
              loading={makingPaymentLink}
              onClick={makePaymentLink}
            >
              Copy Pay Link
            </LoadingButton>
          </div>
        )}

        <div style={{ paddingRight: "8px" }}>
          <LoadingButton
            variant="contained"
            startIcon={<DownloadOutlined />}
            loading={downloadingPDF}
            onClick={saveFile}
          >
            Download Invoice
          </LoadingButton>
        </div>
        
        <PrintInvoiceBOMComponent
          invoice={currentInvoice}
          handleComplete={handleComplete}
        />

        {/* check if connected to quickbooks and whether or not invoice needs to be synced based on presense of qbId */}
        {orgData.qboRealmId !== "" &&
          orgData.qboRealmId !== null &&
          currentInvoice.type === "invoice" && (
            <div style={{ paddingLeft: "8px" }}>
              <Tooltip title="Use this to manually sync invoices, payments and refunds to QuickBooks online if you notice that any of these did not sync automatically when you initially saved them in Adelie.">
                <LoadingButton
                  variant="contained"
                  startIcon={<SyncOutlined />}
                  loading={syncingInvoice}
                  onClick={syncClicked}
                  style={
                    !currentInvoice.qbId || currentInvoice.qbId === ""
                      ? {
                          background: "white",
                          border: "1px solid red",
                          color: "black",
                        }
                      : {
                          background: "white",
                          border: "1px solid green",
                          color: "black",
                        }
                  }
                >
                  Sync
                </LoadingButton>
              </Tooltip>
            </div>
          )}
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
        {paymentLinkUrl && (
          <>
            <TextField
              size="small"
              label="URL"
              value={paymentLinkUrl}
              contentEditable={false}
              variant="outlined"
              sx={{ width: "300px" }}
              InputProps={{
                readOnly: true,
              }}
            />

            <IconButton onClick={copyToClipboard} color="primary">
              {copied ? <Check /> : <FileCopyRounded />}
            </IconButton>
          </>
        )}
      </Stack>
    </Stack>
  );
};
