import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../../../app/utils/models/modelConstants/modelConstants";

export const configureMethodText = (item) => {
  let text = "";
  if (item === RECEIVE_METHOD_DELIVERY) {
    text = "DELIVERY";
  } else if (item === RETURN_METHOD_ONSITE) {
    text = "PICK UP";
  } else if (item === STOP_TYPE_WAYPOINT) {
    text = "WAYPOINT";
  }

  return text;
};
