import { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Button } from "antd";
import { RouteSelect } from "./RouteSelect";
import {
  COLOR_GRAY_BORDER,
  COLOR_TEXT_GRAY_1,
} from "../../../../../app/ui/colorConstants";
import { PlusOutlined } from "@ant-design/icons";
import { RouteContext } from "../../../NewRoute";
import { useFirestoreWrite } from "../../../../../app/services/hooks/fetch/useFirestoreWrite";

const SelectRouteHeader = () => {
  const { actions, state } = useContext(RouteContext);
  const { newDocument, updateDocument, deleteDocument, writing } = useFirestoreWrite();

  const onDragEnd = async (result) => {
    actions.reorderRoutes(result);
  };

  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#FFF",
      }}
    >
      Routes:
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="routes">
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {state.routes?.map((r, idx) => (
                <Draggable key={r.id} draggableId={r.id} index={idx}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <RouteSelect key={r.id} route={r} />
                      
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        style={{
          marginTop: "24px",
          borderColor: "transparent",
          backgroundColor: COLOR_GRAY_BORDER,
        }}
        key={"add_route"}
        onClick={() => actions.addRoute()}
      >
        <PlusOutlined style={{ color: COLOR_TEXT_GRAY_1 }} /> New Route
      </Button>
    </div>
  );
};

export default SelectRouteHeader;
