import React, { useEffect } from "react";
import ReceivePayment from "./ReceivePayment";
import { ROUTE_INVOICES } from "../../app/routes";

const InvoicesReceivePayment = ({ match, history }) => {
  useEffect(() => {
    const invoiceId = history.location.state.invoiceId;
    if (!invoiceId) history.push(ROUTE_INVOICES);
  });
  return (
    <ReceivePayment
      match={match}
      redirectUrl={ROUTE_INVOICES}
      defaultSelected={
        history.location.state.invoiceId && history.location.state.invoiceId
      }
    />
  );
};

export default InvoicesReceivePayment;
