import React from "react";
import { Input } from "antd";

const { Search } = Input;
const StopListSearch = ({ setKeywords }) => {
  return (
    <Search
      onChange={(e) => setKeywords(e.target.value?.toLowerCase())}
      placeholder="Search invoice # or customer"
      style={{ width: "calc(100%)", marginBottom: "12px" }}
    />
  );
};

export default StopListSearch;
