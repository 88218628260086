import { useEffect, useState } from "react";
import { configureMethodText } from "../../../domains/routes/map/helpers/configureMethodText";
import { configureTimeWindow } from "../../../domains/routes/map/helpers/configureTimeWindow";
import Login from "../../../domains/login/Login";

export function useStopCell({ stop, routes, currentRouteId, selectedStop }) {
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [items, setItems] = useState([]);
  const [notes, setNotes] = useState("");
  const [inRoutes, setInRoutes] = useState([]);
  const [typeLabel, setTypeLabel] = useState(null);
  const [timeWindow, setTimeWindow] = useState({ hasWindow: false, text: "" });
  const [currentlySelected, setCurrentlySelected] = useState(false);

  useEffect(() => {
    if (!stop) return;

    // const alsoInRoutes = routes?.filter((r) => {
    //   const isInRoute = r?.stops?.find((s) => {
    //     const inRoute = r?.invoicesQueryHook.includes(stop?.invoiceId);
    //     return inRoute && s?.type === stop?.type;
    //   });
    //   return !!isInRoute;
    // });
    const alsoInRoutes = routes?.filter((route) => {
      // Check if any stop in the route matches both the invoiceId and the type of the given stop
      return route?.stops?.some((routeStop) => {
        return routeStop.invoiceId === stop.invoiceId && routeStop.type === stop.type;
      });
    });
    
    const assigned = alsoInRoutes?.map((r) => {
      return {
        color: r.color,
        routeId: r?.id,
      };
    });

    setInvoiceNumber(stop?.invoice?.invoiceNumber ?? "Unknown invoice");

    setItems(
      stop?.invoice?.selectedItems.items?.map((item) => {
        return {
          name: item?.name ?? "",
          qty: item?.selectedQty ?? 0,
        };
      })
    );

    setNotes(stop?.invoice?.notes ?? "");
    setInRoutes(assigned ? assigned : []);
    setTypeLabel(configureMethodText(stop?.type ?? ""));
    setTimeWindow({
      hasWindow: stop?.hasTimeWindow === true,
      text: configureTimeWindow(stop) ?? "na",
    });

  }, [stop, routes]);

  useEffect(() => {
    setCurrentlySelected(
      selectedStop?.invoiceId === stop?.invoiceId &&
        selectedStop?.stop?.type === stop?.type
    );
  }, [selectedStop]);

  return {
    invoiceNumber,
    items,
    notes,
    inRoutes,
    typeLabel,
    timeWindow,
    currentlySelected,
  };
}
