/**
 * Use when a child bundle item flag is updated
 * Determines if the parent item's hasIssue bool needs to be updated
 */
export const UpdateParentFlagFromChildren = ({
  invoice,
  item,
  itemPayload,
  flag,
}) => {
  return (
    invoice &&
    invoice.qrItems.map((i) => {
      if (i.id === item.id && i.isBundleChild === item.isBundleChild)
        return itemPayload;
      if (i.id === item.bundleParentId && !i.isBundleChild && flag)
        return { ...i, hasIssue: true };
      if (i.id === item.bundleParentId && !i.isBundleChild && !flag) {
        const containsFlags = invoice.qrItems.some((c) => {
          return (
            c.isBundleChild &&
            i.bundleChildrenIds.includes(c.id) &&
            c.id !== item.id &&
            c.hasIssue === true
          );
        });
        return { ...i, hasIssue: containsFlags };
      }
      return i;
    })
  );
};
