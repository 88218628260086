import React from "react";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";

const InvoiceContextMenu = ({
  item,
  visible,
  x,
  y,
  handleRemove,
  handleAddItemBelow,
  handleAddSubtotalBelow,
}) => {
  const { userData } = useSelector(authSelector);

  return (
    <div>
      {visible && userData && item && (
        <ul
          className="tableContextMenu"
          style={{ left: `${x}px`, top: `${y}px`, padding: "4px 0" }}
        >
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleAddItemBelow}
          >
            <TextBody text={"Insert new item"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleAddSubtotalBelow}
          >
            <TextBody text={"Insert new subtotal"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={handleRemove}
          >
            <TextBody text={"Remove"} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default InvoiceContextMenu;
