import { notification } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../ui/colorConstants";

const notificationError = (msg, desc) => {
  notification.info({
    icon: <CloseCircleFilled style={{ color: COLOR_RED_0 }} />,
    message: `${msg}`,
    description: desc ? `${desc}` : "",
    placement: "bottomLeft",
    duration: 4,
  });
};

export default notificationError;
