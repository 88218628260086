import { Button, Divider, Dropdown, Menu, Space } from "antd";
import {
  INVOICE,
  INVOICE_DRAFT,
  INVOICE_ESTIMATE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { COLOR_GREEN_0 } from "../../../app/ui/colorConstants";
import notificationError from "../../../app/system-components/toasters/notificationError";
import {
  ROUTE_INVOICES,
  ROUTE_RECEIVE_PAYMENT_ROOT,
} from "../../../app/routes";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useInvoiceHeaderBtns } from "../../../app/services/hooks/useInvoiceHeaderBtns";
import { CaretDownOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { SendInvoiceContext } from "../InvoiceDetail";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { inventoryQueries } from "../../../app/services/firestore/queries/inventoryQueries";
import { useFirestore } from "react-redux-firebase";
import { invoices } from "../../../app/services/firestore/queries/invoiceQueries";

const InvoiceHeaderBtns = ({
  currentInvoice,
  customerId,
  customer,
  handleDelete,
  handleComplete,
  setCurrentInvoice,
  history,
  id,
}) => {
  const { show } = useInvoiceHeaderBtns(currentInvoice);
  const { orgData, fsOrgPrefix } = useSelector(authSelector);
  const modal = useContext(SendInvoiceContext);
  const firestore = useFirestore();

  const saveMenu = () => {
    return (
      <Menu>
        {show.draft && (
          <Menu.Item
            key="saveDraft"
            id="saveDraft"
            onClick={() =>
              handleComplete({ type: INVOICE_DRAFT, redirect: null })
            }
          >
            Save as Draft
          </Menu.Item>
        )}
        {show.estimate && (
          <Menu.Item
            key="saveEstimate"
            id="saveEstimate"
            onClick={() =>
              handleComplete({
                type: INVOICE_ESTIMATE,
                redirect: null,
              })
            }
          >
            Save as Estimate
          </Menu.Item>
        )}

        {show.invoice && (
          <Menu.Item
            key="saveInvoice"
            id="saveInvoice"
            onClick={() => handleComplete({ type: INVOICE, redirect: null })}
          >
            Save as Invoice
          </Menu.Item>
        )}
        <Divider style={{ margin: "12px 0" }} />
        {show.estimate && (
          <Menu.Item
            disabled={!currentInvoice}
            key="saveSendEstimate"
            id="saveSendEstimate"
            onClick={async () => {
              const res = await handleComplete({
                type: INVOICE_ESTIMATE,
                redirect: null,
              });
              if (res?.hasError) return;
              
              const refreshedInvoice = res?.invoice;
              
              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "estimate",
                settingsId: "estimateEmailSettings",
              });
              modal.show();
            }}
          >
            Send Estimate PDF
          </Menu.Item>
        )}
        {show.invoice && (
          <Menu.Item
            key="saveSendInvoice"
            id="saveSendInvoice"
            disabled={!currentInvoice}
            onClick={async () => {
              const res = await handleComplete({
                type: INVOICE,
                redirect: null,
              });
              if (res?.hasError) return;

              const refreshedInvoice = res?.invoice;
             
              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "invoice",
                settingsId: "invoiceEmailSettings",
              });
              modal.show();
            }}
          >
            Send Invoice PDF
          </Menu.Item>
        )}

        {show.signatureRequest && (
          <Menu.Item
            key="sendSignatureRequest"
            id="sendSignatureRequest"
            disabled={!currentInvoice}
            onClick={async () => {
              const res = await handleComplete({
                type: currentInvoice.type,
                redirect: null,
              });
              
              if (res?.hasError) return;

              const refreshedInvoice = res?.invoice;

              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "signatureRequest",
                settingsId: "invoiceEmailSettings",
              });
              modal.show();
            }}
          >
            Send Signature Request
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Space>

      {id && orgData.id === "thdqZKWV6zwaUGeIfKms" && (
        <Button type="ghost" onClick={() => {
          window.open(`https://business.tapgoods.com/orders/rentals/${id}`, "_blank");
        }}>
          View In Tapgoods
        </Button>
      )}

      {id && (
        <Button type="danger" onClick={() => handleDelete(id)}>
          Delete
        </Button>
      )}
      <Dropdown overlay={saveMenu()} trigger={["click"]}>
        <Button>
          Save <CaretDownOutlined />
        </Button>
      </Dropdown>

      {currentInvoice && currentInvoice.type === INVOICE && (
        <div>
          <Divider type={"vertical"} />
          <Button
            style={{
              backgroundColor: COLOR_GREEN_0,
              borderColor: COLOR_GREEN_0,
              color: "#FFF",
            }}
            onClick={() => {
              handleComplete({ type: INVOICE, redirect: "RECEIVE_PAYMENT" });
            }}
          >
            <span>
              Receive payment <ArrowRightOutlined />
            </span>
          </Button>
        </div>
      )}
    </Space>
  );
};

export default InvoiceHeaderBtns;
