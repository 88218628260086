import { GenerateColor } from "../../../../domains/routes/map/helpers/generateColor";

export const ConfigureNewRoutePayload = ({
  defaultName,
  routes,
  coAddr,
  routeDate,
}) => {
  return {
    // id: id,
    listIndex: routes.length,
    startLat: coAddr?.startLat ?? 0,
    startLng: coAddr?.startLng ?? 0,
    startAddrStr: coAddr?.startAddrStr ?? "",
    invoicesQueryHook: [], // invoiceIds
    employeesQueryHook: [], // employee query hooks
    vehiclesQueryHook: [], // vehicle query hooks
    name: defaultName ? defaultName : "Route",
    color: GenerateColor(routes),
    stops: [], // array of stops
    hasWarnings: false,
    hasErrors: false,
    notes: "",
    loadDate: routeDate, // date of load
    mobileLoadDate: routeDate.toISOString().substring(0, 10),
    assignedEmployees: [],
    assignedVehicles: [],
    routeDate: routeDate,
    mobileRouteDate: routeDate.toISOString().substring(0, 10),
    isComplete: false,
  };
};
