import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { VideoCameraFilled, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import './AttentionTag.css'; // Import the CSS file

const AttentionTag = ({ tooltipTxt = "", arrowDirection, videoUrl }) => {
  const renderArrowIcon = () => {
    if (arrowDirection === 'left') {
      return <ArrowLeftOutlined />;
    } else if (arrowDirection === 'right') {
      return <ArrowRightOutlined />;
    } else if (arrowDirection === 'up') {
      return <ArrowUpOutlined />;
    } else if (arrowDirection === 'down') {
      return <ArrowDownOutlined />;
    }
    return null;
  };

  const renderVideoIcon = () => {
    if (videoUrl) {
      return (
        <a href={videoUrl} target="_blank" rel="noopener noreferrer" style={{marginLeft: '4px'}}>
          <VideoCameraFilled style={{color: 'white'}} />
        </a>
      );
    }
    return null;
  };

  return (
    <Tooltip title={tooltipTxt}>
      <div className="attention-tag">
       <span className="star left-star"></span>
       <div style={{ marginRight: '4px' }}>
        { arrowDirection === "left" && renderArrowIcon()}
       </div>
        NEW!
        {videoUrl && renderVideoIcon()}
        <div style={{ marginLeft: '4px' }}>
          {arrowDirection !== "left" && renderArrowIcon()}
        </div>
        <span className="star right-star"></span>
      </div>
    </Tooltip>
  );
};

AttentionTag.propTypes = {
  tooltipTxt: PropTypes.string.isRequired,
  arrowDirection: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  videoUrl: PropTypes.string,
};

AttentionTag.defaultProps = {
  arrowDirection: 'left',
  videoUrl: '',
};

export default AttentionTag;
