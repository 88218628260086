import { checkUndefined } from "../checkers/checkUndefined";
import onlyUnique from "../../filters/filterOnlyUnique";

export const ConfigureQrItems = (qrItems) => {
  let duplicateChildIds = []; // for keeping track of already summed ids
  let duplicateParentIds = []; // keep track of duplicate parent ids
  const items =
    qrItems &&
    qrItems.map((i) => {
      if (duplicateChildIds.includes(i.id) && i.isBundleChild) return null;
      let duplicateValues = null;
      const dups = qrItems.filter((item) => {
        if (!item.isBundleChild) return false;
        if (item.id === i.id) return true;
      });
      if (dups.length !== 0 && i.isBundleChild) {
        duplicateChildIds.push(i.id);
        duplicateParentIds.push(i.bundleParentId);
        duplicateValues = dups.reduce((p, c) => {
          return {
            qtyRented: p.qtyRented + c.qtyRented,
            completed: p.completed + c.completed,
          };
        });
      }
      return {
        id: checkUndefined(i.id, ""),
        name: checkUndefined(i.name, ""),
        description: checkUndefined(i.description, ""),
        qtyRented: checkUndefined(
          !duplicateValues ? i.qtyRented : duplicateValues.qtyRented,
          0
        ),
        completed: checkUndefined(
          !duplicateValues ? i.completed : duplicateValues.completed,
          0
        ),
        notes: checkUndefined(i.notes, null),
        hasIssue: checkUndefined(i.hasIssue, false),
        issueDescription: checkUndefined(i.issueDescription, null),
        bundleParentId: checkUndefined(i.bundleParentId, null),
        isBundleChild: checkUndefined(i.isBundleChild, false),
        bundleChildrenIds: checkUndefined(i.bundleChildrenIds, []),
      };
    });
  if (duplicateChildIds.length === 0) return items;

  // handle filtered items & duplicates
  const filteredItems = items && items.filter((i) => i !== null);
  const parentIds = duplicateParentIds.filter(onlyUnique);
  const parentObjs =
    parentIds &&
    parentIds.map((id) => {
      const objs =
        filteredItems &&
        filteredItems
          .filter((i) => i.id === id && i.isBundleChild === false)
          .reduce((p, c) => {
            const { qtyRented, completed, ...rest } = p;
            return {
              qtyRented: p.qtyRented + c.qtyRented,
              completed: p.completed + c.completed,
              ...rest,
            };
          });
      return objs;
    });
  return filteredItems
    .filter((i) => !parentIds.includes(i.id))
    .concat(parentObjs);
};
