export const sortBy = (arr, value) => {
  return arr.sort(function (a, b) {
    let nameA = a[value].toLowerCase(),
      nameB = b[value].toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });
};

export const sortByNum = (arr, value) => {
  return arr.sort(function (a, b) {
    let countA = a[value];
    let countB = b[value];
    if (countA < countB)
      //sort string ascending
      return -1;
    if (countA > countB) return 1;
    return 0; //default return value (no sorting)
  });
};

export function sortByStatus(arr) {
  return arr.sort(function (a, b) {
    const prevRatio = a.completed / a.qtyRented;
    const currRatio = b.completed / b.qtyRented;
    if (prevRatio < currRatio)
      //sort string ascending
      return -1;
    if (prevRatio > currRatio) return 1;
    return 0; //default return value (no sorting)
  });
}

export const sortByNumberValue = (arr, numberPropName) => {
  function compare(a, b) {
    if (a[numberPropName] > b[numberPropName]) {
      return -1;
    }
    if (a[numberPropName] < b[numberPropName]) {
      return 1;
    }
    return 0;
  }
  return arr.sort(compare);
};
