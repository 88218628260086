export const COLOR_DARK_BG_0 = "#0f0f11";
export const COLOR_DARK_BG_1 = "#161619";

export const COLOR_TEXT_SECONDARY = "#4a4a4d";
export const COLOR_TEXT_GRAY_0 = "#C0C0C1";
export const COLOR_TEXT_GRAY_1 = "#9898A4";
export const COLOR_TEXT_GRAY_2 = "#EAEAEF";
export const COLOR_GRAY_BORDER = "#F0F0F0";
export const COLOR_LIGHT_GRAY = "#f7f7f7";

export const COLOR_GREEN_0 = "#3BB27C";
export const COLOR_LIGHT_GREEN = "#E3FFF3";

export const COLOR_BLUE_0 = "#5897EA";
export const COLOR_LIGHT_BLUE = "#ebf3fd";

export const COLOR_PURPLE_0 = "#6237E9";

export const COLOR_RED_0 = "#E85858";

export const COLOR_YELLOW_0 = "#E8A258";
export const COLOR_ORANGE = "#F2C280";

export const COLOR_ORANGE_HIGHLIGHT = "#FFF4E6";
