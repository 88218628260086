import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useFirestore } from 'react-redux-firebase';
import { functions } from "../../app/config/firebase";
import { OrganizationData } from '../../app/interfaces/organizationData';
import { enqueueSnackbar } from 'notistack';


interface SendEmailTemplateViewProps {
    orgData: OrganizationData;
    userData: any;
    invoiceIds: string[];
    close: () => void;
    complete: () => void;
  }
  
  const SendEmailTemplateView = ({orgData, userData, invoiceIds, close, complete }: SendEmailTemplateViewProps) => {

    const firestore = useFirestore();
    const [templates, setTemplates] = useState<any[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null); 
    const [loading, setLoading] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedTemplate(event.target.value);
      };

    const loadTemplates = async () => {
        setLoading(true);
        try {
          const templatesQuery = await firestore
            .collection("orgs")
            .doc(orgData.id)
            .collection("templates")
            .doc("email")
            .collection("email")
            .get();
          const fetched: any[] = [];
          templatesQuery.docs.forEach((doc) => {
            fetched.push(doc.data());
          });
          setTemplates(fetched);
        } catch (error) {

        }
        setLoading(false);
      };
    
      useEffect(() => {
        orgData && loadTemplates();
      }, [orgData]);

    if (templates.length === 0) {
      return (
        <Card sx={{ p: 2, width: "500px" }}>
          <Box>
            <Stack gap={2}>
              <Typography variant="h6">Bulk Email</Typography>
              <Typography>
               {`No email templates found. Please create an email template first. This can be done in Settings > Email`}
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  close();
                }}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Card>
      );
    }

    return (
      <Card sx={{ p: 2, width: "500px" }}>
        <Box>
          <Stack gap={2}>
            <Typography variant="h6">Bulk Email</Typography>

            <Typography>
              Send email for {invoiceIds.length} invoices.
            </Typography>

            <Typography>Select a template to send:</Typography>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Template</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTemplate}
                label="Age"
                onChange={handleChange}
              >
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              disabled={!selectedTemplate}
              variant="contained"
              onClick={() => {
                const call = functions.httpsCallable(
                  "invoiceEmailFromTemplate"
                );

                call({
                  orgId: orgData.id,
                  templateId: selectedTemplate,
                  invoiceList: invoiceIds,
                  userId: userData.id
                })
                .catch((error: any) => {
                  enqueueSnackbar("Error sending emails: " + error.message, {
                    variant: "error",
                  });
                });
                
                complete();
              }}
            >
              Send Emails for {invoiceIds.length} Invoices
            </Button>
          </Stack>
        </Box>
      </Card>
    );
}

export default SendEmailTemplateView