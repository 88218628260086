export const firebaseConfig = {
  apiKey: "AIzaSyD3hRBvFETmi0pEtX2xwCqIZF49Zaw9ZOc",
  authDomain: "adelie-logistics-production.firebaseapp.com",
  projectId: "adelie-logistics-production",
  storageBucket: "adelie-logistics-production.appspot.com",
  messagingSenderId: "1005547537970",
  appId: "1:1005547537970:web:b0bd0cd477dcef2c19de2b",
};

export const functionsBaseUrl = "https://us-central1-adelie-logistics-production.cloudfunctions.net/"

  export const algolia_invoices = {
    appid: "W8IP9A0GB1",
    searchKey: "8dfb527b4425118f1dc8c533ea2b6b56",
    index: "invoices",
  };
  
  export const algolia_inventory = {
    appid: "W8IP9A0GB1",
    searchKey: "8dfb527b4425118f1dc8c533ea2b6b56",
    index: "inventory",
  };
  
  export const stripeSubscriptionConfig = {
    publishableKey: "pk_live_ob6poZLAnkQQJ3y5FZoZcy9O",
    pricingTableId: "prctbl_1OvNRb2MgodgWSYZSRXzmw8K",
    portalUrl: "https://billing.stripe.com/p/login/fZe9Ecavg9D43Pq7ss"
  };

  export const justifiConfig = {
    clientId: "live_6e8f2710e4ddd670038056c41315088a"
  }

  export type SubscriptionPlanDetails = {
    stripe_id: string;
    name: string;
    price: number;
    description: string;
    features: string[];
    subName: string;
  };

  export const subscriptionPlanDetails: SubscriptionPlanDetails[] = [
    {
      stripe_id: "price_1OvNEc2MgodgWSYZp85iam70",
      name: "Adelie Basics",
      subName: "basics",
      price: 3500,
      description: "Adelie Basic Plan",
      features: [
        "Unlimited Users",
        "Inventory Tracking",
        "Limited Mobile App Features",
        "Sales and Tax Reports",
        "Unlimited Products",
        "Payment Processing",
        "Embedded Checkout",
      ],
    },
    {
      stripe_id: "price_1OvNEc2MgodgWSYZ2a8hkq0G",
      name: "Adelie Basics",
      subName: "basics",
      price: 34800,
      description: "Adelie Basic Plan",
      features: [
        "Unlimited Users",
        "Inventory Tracking",
        "Limited Mobile App Features",
        "Sales and Tax Reports",
        "Unlimited Products",
        "Payment Processing",
        "Embedded Checkout",
      ],
    },
  
    {
      stripe_id: "price_1OvNEh2MgodgWSYZnY3M7i2b",
      subName: "essentials",
      name: "Adelie Essentials",
      price: 7900,
      description: "Adelie Essentials Plan",
      features: [
        "Everything In Basics",
        "Inventory Item Preparation Tools",
        "Vendors, Venues, and Tags",
        "Invoice Customization",
      ],
    },
    {
      stripe_id: "price_1OvNEh2MgodgWSYZR3RVzQ2I",
      name: "Adelie Essentials",
      subName: "essentials",
      price: 70800,
      description: "Adelie Essentials Plan",
      features: [
        "Everything In Basics",
        "Inventory Item Preparation Tools",
        "Vendors, Venues, and Tags",
        "Invoice Customization",
      ],
    },
  
    {
      stripe_id: "plus_monthly",
      name: "Adelie Plus",
      subName: "plus",
      price: 14900,
      description: "Adelie Plus Plan",
      features: [
        "Everything In Essentials",
        "Routing",
        "Full Mobile App Features",
        "Bundle Items",
        "Email Templates",
        "File Manager and Attachments",
        "Internal Notes and Chat",
        "QuickBooks Integrations",
      ],
    },
    {
      stripe_id: "plus_annual",
      name: "Adelie Plus",
      subName: "plus",
      price: 138000,
      description: "Adelie Plus Plan",
      features: [
        "Everything In Essentials",
        "Routing",
        "Full Mobile App Features",
        "Bundle Items",
        "Email Templates",
        "File Manager and Attachments",
        "Internal Notes and Chat",
        "QuickBooks Integrations",
      ],
    },
  
    {
      stripe_id: "premium_monthly",
      name: "Adelie Premium",
      subName: "premium",
      price: 24900,
      description: "Adelie Premium Plan",
      features: [
        "Everything In Plus",
        "Email Automation",
        "Invoice Saved Filters",
        "Priority Support",
        "Priority Feature Request",
        "Website Integration Assistance",
      ],
    },
    {
      stripe_id: "premium_annual",
      name: "Adelie Premium",
      subName: "premium",
      price: 238800,
      description: "Adelie Premium Plan",
      features: [
        "Everything In Plus",
        "Email Automation",
        "Invoice Saved Filters",
        "Priority Support",
        "Priority Feature Request",
        "Website Integration Assistance",
      ],
    },
  ];