import moment from "moment";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { setRentalDates } from "../../inventory/cartSlice";
import { TimeMachine } from "../../../app/utils/time/timeMachine";

export const setExistingInvoice = ({
  form,
  setIsSalesInvoice,
  setSelectedItems,
  currentInvoice,
  setInvoiceTotal,
  setReceiveMethod,
  setReturnMethod,
  setSpecifiedTimes,
  setRentalRange,
  setCustomer,
  setCustomerAddressChecked,
  setDeliveryFee,
  setDeliveryFeeTaxable,
  setRentalDates,
}) => {
  const itemsResetKeys = currentInvoice.selectedItems.items.map((i, index) => {
    return {
      ...i,
      key: index,
    };
  });
  setSelectedItems({
    items: itemsResetKeys,
    count: currentInvoice.selectedItems.items.length,
  });
  setIsSalesInvoice(currentInvoice.isSalesInvoice);
  setReceiveMethod(currentInvoice.receiveMethod);
  setReturnMethod(currentInvoice.returnMethod);
  setSpecifiedTimes({
    receive: !!currentInvoice.specifiedReceiveTime,
    return: !!currentInvoice.specifiedReturnTime,
  });
  setRentalRange(currentInvoice.rentalRange);
  setRentalDates({
    receiveDate: TimeMachine(currentInvoice?.rentalDateStart)
      .moment?.startOf("day")
      .format("MMM Do YYYY"),
    returnDate: TimeMachine(currentInvoice?.rentalDateEnd)
      .moment?.endOf("day")
      .format("MMM Do YYYY"),
  });
  setCustomer(currentInvoice.customer);
  setCustomerAddressChecked(currentInvoice.customerAddressChecked);
  setInvoiceTotal({
    taxAmt: currentInvoice.taxAmt,
    taxRate: currentInvoice.taxRate,
    subtotal: currentInvoice.subtotal,
    total: currentInvoice.total,
    hasTotalDiscount: currentInvoice.hasTotalDiscount,
    totalDiscountType: currentInvoice.totalDiscountType,
    totalDiscountPercent: currentInvoice.totalDiscountPercent,
    totalDiscountNumber: currentInvoice.totalDiscountNumber,
  });
  setDeliveryFee(currentInvoice?.deliveryFee ?? 0);
  setDeliveryFeeTaxable(currentInvoice?.deliveryFeeTaxable ?? true);
  // set form items
  form.setFieldsValue({
    customerQueryHook: currentInvoice.customerQueryHook,
    notes: currentInvoice.notes,
    contactPhoneNumber: currentInvoice?.contactPhoneNumber ?? null,
    salesRepName: currentInvoice?.salesRepName ?? null,
    invoicePaymentDueDate: currentInvoice.invoicePaymentDueDate
      ? currentInvoice.invoicePaymentDueDate &&
        moment(currentInvoice.invoicePaymentDueDate?.toDate())
      : null,
    eventDate: currentInvoice.eventDate
      ? currentInvoice.eventDate && moment(currentInvoice.eventDate?.toDate())
      : null,
    isSalesInvoice: currentInvoice.isSalesInvoice ?? false,
    rentalDates: currentInvoice.rentalDates.length !== 0 && [
      moment(currentInvoice.rentalDateStart?.toDate()),
      moment(currentInvoice.rentalDateEnd?.toDate()),
    ],
    specifiedReceiveTime: currentInvoice.specifiedReceiveTime
      ? moment(currentInvoice.specifiedReceiveTime.toDate())
      : null,
    specifiedReturnTime: currentInvoice.specifiedReturnTime
      ? moment(currentInvoice.specifiedReturnTime.toDate())
      : null,
    specifiedReceiveTimeEnd: currentInvoice.specifiedReceiveTimeEnd
      ? moment(currentInvoice.specifiedReceiveTimeEnd.toDate())
      : null,
    specifiedReturnTimeEnd: currentInvoice.specifiedReturnTimeEnd
      ? moment(currentInvoice.specifiedReturnTimeEnd.toDate())
      : null,
    hasSpecifiedReceiveTime: currentInvoice.hasSpecifiedReceiveTime,
    hasSpecifiedReturnTime: currentInvoice.hasSpecifiedReturnTime,
    returnMethod: currentInvoice.returnMethod
      ? currentInvoice.returnMethod
      : RETURN_METHOD_ONSITE,
    receiveMethod: currentInvoice.receiveMethod
      ? currentInvoice.receiveMethod
      : RECEIVE_METHOD_DELIVERY,
    [`${currentInvoice.returnMethod}${capitalizeFirstLetter(
      CUSTOMER_DISPLAY_NAME
    )}`]:
      currentInvoice[
        `${currentInvoice.returnMethod}${capitalizeFirstLetter(
          CUSTOMER_DISPLAY_NAME
        )}`
      ],
    [`${currentInvoice.returnMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_ADDRESS
    )}`]:
      currentInvoice[
        `${currentInvoice.returnMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_ADDRESS
        )}`
      ],
    [`${currentInvoice.returnMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_CITY
    )}`]:
      currentInvoice[
        `${currentInvoice.returnMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_CITY
        )}`
      ],
    [`${currentInvoice.returnMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_STATE
    )}`]:
      currentInvoice[
        `${currentInvoice.returnMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_STATE
        )}`
      ],
    [`${currentInvoice.returnMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_ZIP
    )}`]:
      currentInvoice[
        `${currentInvoice.returnMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_ZIP
        )}`
      ],
    // receive
    [`${currentInvoice.receiveMethod}${capitalizeFirstLetter(
      CUSTOMER_DISPLAY_NAME
    )}`]:
      currentInvoice[
        `${currentInvoice.receiveMethod}${capitalizeFirstLetter(
          CUSTOMER_DISPLAY_NAME
        )}`
      ],
    [`${currentInvoice.receiveMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_ADDRESS
    )}`]:
      currentInvoice[
        `${currentInvoice.receiveMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_ADDRESS
        )}`
      ],
    [`${currentInvoice.receiveMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_CITY
    )}`]:
      currentInvoice[
        `${currentInvoice.receiveMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_CITY
        )}`
      ],
    [`${currentInvoice.receiveMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_STATE
    )}`]:
      currentInvoice[
        `${currentInvoice.receiveMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_STATE
        )}`
      ],
    [`${currentInvoice.receiveMethod}${capitalizeFirstLetter(
      CUSTOMER_SHIPPING_ZIP
    )}`]:
      currentInvoice[
        `${currentInvoice.receiveMethod}${capitalizeFirstLetter(
          CUSTOMER_SHIPPING_ZIP
        )}`
      ],
  });
};

const setEventDate = (currentInvoice) => {
  const eDate = currentInvoice.eventDate
    ? currentInvoice.eventDate && moment(currentInvoice.eventDate?.toDate())
    : null;
};
