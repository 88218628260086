import notificationError from "../../../system-components/toasters/notificationError";
import { INVOICE } from "../../../utils/models/modelConstants/modelConstants";
import { invoices } from "../../firestore/queries/invoiceQueries";
import moment from "moment";
import { categoryQueries } from "../../firestore/queries/categoryQueries";
import onlyUnique from "../../../utils/filters/filterOnlyUnique";
import { DayWithinRange } from "../helpers/dayWithinRange";
import { ReduceStockByDay } from "../helpers/reduceStockByDay";
import { INVENTORY_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../domains/auth/authSlice";

const { useState, useEffect } = require("react");
const { useFirestore } = require("react-redux-firebase");

export function useInvoicesInMonth(activeDay) {
  const { authenticated, fsOrgPrefix, orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const startOfMonth = moment(activeDay).startOf("month").toDate();
  const endOfMonth = moment(activeDay).endOf("month").toDate();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    inventory: null,
    category: null,
  });
  const [availableFilters, setAvailableFilters] = useState({
    inventory: [],
    category: [],
  });
  const [filteredData, setFilteredData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [dailyAvailable, setDailyAvailable] = useState([]);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });
  const [refresh, setRefresh] = useState({
    refresh: false,
    count: 0,
  });

  const refreshData = () => {
    setRefresh({
      refresh: true,
      count: 0,
    });
  };

  useEffect(() => {
    const startEndOpts = {
      rentalDateStart: startOfMonth,
      rentalDateEnd: endOfMonth,
    };

    if (!authenticated) return;
    if (refresh.count > 0) return;
    if (!startEndOpts.rentalDateStart || !startEndOpts.rentalDateEnd) return;
    if (fetching) return;

    try {
      setFetching(true);
      setData([]);
      invoices(orgData?.orgTimezone)
        .fetchMonthlyInvoicesByStartDate(
          { firestore },
          fsOrgPrefix,
          startEndOpts
        )
        .then((res) => {
          /**
           * fetch inventory
           */
          const invoices = res.filter((invoice) => invoice.type === INVOICE)
          setData(invoices);
          
          let invFilters = [];
          let catFilterIds = [];
          let catFilters = [];

          res.forEach((invoice) => {
            catFilterIds = [...invoice.categoriesQueryHook, ...catFilterIds];
            invoice.qrItems.forEach((qrItem) => {
              if (invFilters.some((i) => i.id === qrItem.id)) {
                return;
              }
              invFilters.push({
                name: qrItem.name,
                id: qrItem.id,
              });
            });
          });

          if (catFilterIds && catFilterIds.length > 0) {
            categoryQueries({ firestore }, fsOrgPrefix)
              .fetchCategories()
              .then((res) => {
                const ids = catFilterIds.filter(onlyUnique);

                ids &&
                  ids.forEach((i) => {
                    const cat = res.find((c) => c.id === i);
                    if (!cat) console.error("cat is undefined", i, res);
                    else
                      catFilters.push({
                        name: cat.name,
                        id: cat.id,
                      });
                  });

                setAvailableFilters({
                  inventory: [...invFilters],
                  category: [...catFilters],
                });
                setFetching(false);
                setRefresh({ refresh: false, count: refresh.count + 1 });
              });
          } else {
            setAvailableFilters({
              inventory: [...invFilters],
              category: [...catFilters],
            });
            setFetching(false);
            setRefresh({ refresh: false, count: refresh.count + 1 });
          }
        });
    } catch (err) {
      console.log(err);
      notificationError(
        "Something went wrong",
        "Please try again or refresh the page"
      );
      setFetching(false);
      setError({
        hasError: true,
        error: err,
      });
      setRefresh({ refresh: false, count: refresh.count + 1 });
    }
  }, [authenticated, refresh, activeDay]);

  useEffect(() => {
    if (!filters.inventory && !filters.category) {
      setFilteredData([...data]);
    } else {
      const filtered = [...data].filter((d) => {
        let filterInv = false;
        let filterCat = false;
        if (filters.inventory) {
          filterInv =
            d.selectedItemsQueryHook.includes(filters.inventory) ||
            d.bundleItemsQueryHook.includes(filters.inventory);
        } else {
          filterInv = true;
        }
        if (filters.category) {
          filterCat = d.categoriesQueryHook.includes(filters.category);
        } else {
          filterCat = true;
        }
        return filterInv && filterCat;
      });

      setFilteredData([...filtered]);
    }
  }, [filters, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!fsOrgPrefix || !authenticated) return;
    /**
     * Configure list of available per day
     */
    const start = new Date(startOfMonth);
    const end = new Date(endOfMonth);
    let dailyAvailable = [];

    const ids = availableFilters.inventory && availableFilters.inventory.map((i) => i.id).filter((id) => id !== "");

    if (ids && ids.length > 0) {
      
      const items = await fetchDocsFromIds(
        firestore,
        `${fsOrgPrefix}${INVENTORY_COLLECTION}`,
        ids
      );

      let loop = new Date(start);

      while (loop <= end) {
        /**
         * Calculate available items for each day
         * - if day is within invoice range add to day
         * - denote bundle item or item
         * - configure turnaround time
         */
        const dayDate = new Date(loop);
        const dayDateEnd = moment(dayDate).endOf("day");

        const dayInvoices = data.filter((i) => {
          return DayWithinRange({
            tStart: i.rentalDateStart,
            tEnd: i.rentalTurnaroundEnd,
            day: dayDateEnd.toDate().setMinutes(-1),
          });
        });

        const dailyStock = ReduceStockByDay({
          useTurnaround: true,
          inventory: items,
          invoicesInRange: dayInvoices,
          startDate: dayDate,
        });

        dailyAvailable.push({
          date: dayDate,
          stockList: dailyStock,
        });

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      setDailyAvailable([...dailyAvailable]);
    }
  }, [data, authenticated, availableFilters]);

  return {
    data: data,
    fetching: fetching,
    error: error,
    availableFilters: availableFilters,
    filters: filters,
    setFilters: setFilters,
    filteredData: filteredData,
    dailyAvailable: dailyAvailable,
    setAvailableFilters: setAvailableFilters,
    refresh: refreshData,
  };
}


async function fetchDocsFromIds(db, collectionName, ids) {
  const collectionRef = db.collection(collectionName);

  const promises = ids.map(id => {
    return collectionRef.doc(id).get();
  });

  const snapshots = await Promise.all(promises);
  let docs = [];
  snapshots.forEach(snapshot => {
    if (snapshot.exists) {
      docs.push(snapshot.data());
    }
  });
  return docs;
}