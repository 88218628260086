import { useSelector } from "react-redux";
import AddInvoice from "./InvoiceDetail";
import { authSelector } from "../auth/authSlice";
import InvoiceDetail from "./InvoiceDetail";
import MuiInvoice from "../mui-invoice/MuiInvoice";

const CartInvoice = ({ history }) => {
  const { fsOrgPrefix, orgData } = useSelector(authSelector);

  return (
    <div>
      {/* <AddInvoice history={history} cartItems={history.location.state.detail} /> */}

      {orgData.useLegacyInvoiceView ? (
        <AddInvoice history={history} cartItems={history.location.state.detail} />
      ) : (
        <MuiInvoice orgData={orgData} cartItems={history.location.state.detail} />
      )}
    </div>
  );
};

export default CartInvoice;
