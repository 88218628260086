import { useState, useEffect, useCallback } from 'react';

import  { DialogProps } from '@mui/material/Dialog';

import { UploadBox } from './upload';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  onUploadsComplete?: VoidFunction;
  onNewAttachments?: (files: (File | string)[]) => void;
}

export default function FileManagerNewFileBox({
  title = 'Upload Files',
  onNewAttachments,


}: Props) {

  const [files, setFiles] = useState<(File | string)[]>([]);

  useEffect(() => {
      setFiles([]);
  }, []);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
      onNewAttachments && onNewAttachments([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Box>
        <UploadBox
          multiple
          accept={{
            "text/plain": [], // for text files
            "application/pdf": [], // for PDF files
            "image/png": [], // for PNG images
            "image/jpeg": [], // for JPEG images
          }}
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
        />
     </Box>
  );
}
