import notificationConfirm from "../../system-components/toasters/notificationConfirm";
import { useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFirestoreWrite } from "./fetch/useFirestoreWrite";
import { PAYMENT_COLLECTION } from "../../utils/models/collections/collectionConstants";
import { sleep } from "../../utils/Nifty/nifty";

export function useStripePaymentHandler() {
  const { newDocument } = useFirestoreWrite();
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onPaymentSubmit = async ({ event, payload, returnUrl }) => {
    try {
      setProcessing(true);
      await postPaymentDoc(payload);
      sleep(500);
      await postPaymentToStripe(event, returnUrl);
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
    }
  };
  const postPaymentToStripe = async (event, returnUrl) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  const postPaymentDoc = async (payload) => {
    // post to firestore
    await newDocument({
      data: {
        collection: PAYMENT_COLLECTION,
        payload,
      },
    });
  };

  return {
    onPaymentSubmit,
    errorMessage,
    processing,
    stripe,
  };
}
