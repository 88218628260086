import { useFirestoreFetch } from "../../../../app/services/hooks/fetch/useFirestoreFetch";
import { PaymentQueries } from "../../../../app/services/firestore/queries/paymentQueries";
import PaymentList from "../../../sharedComponents/payments/PaymentList";
import { authSelector } from "../../../auth/authSlice";
import { useSelector } from "react-redux";

const PaymentHistoryModal = ({
  customer,
}) => {
  const { orgData } = useSelector(authSelector);
  const { data, fetching } = useFirestoreFetch(
    PaymentQueries(orgData?.orgTimezone).fetchPaymentsByCustomer,
    customer.id
  );
  return (
      <div>
        <PaymentList fetching={fetching} data={data} />
      </div>
  );
};

export default PaymentHistoryModal;
