import { Link } from "react-router-dom";
import { ROUTE_EDIT_INVOICE } from "../../app/routes";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import { i18n } from "../../i18n";
import { capitalizeFirstLetter } from "../../app/utils/casing/capitalizeFirstLetter";
import { TextField, Typography } from "@mui/material";

import { useState } from "react";
import convertTimeToDayString from "../../app/utils/time/convertTimeToDayString";
import { Space } from "antd";

import { Tag, Tooltip } from "antd";
import { ClockCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import convertToTime from "../../app/utils/time/convertToTime";
import CustomIconButton from "../../app/system-components/custom-icon";
import { COLOR_BLUE_0, COLOR_RED_0 } from "../../app/ui/colorConstants";
import MessageIcon from "@mui/icons-material/Message"
import EmailIcon from "@mui/icons-material/Email";
import SyncIcon from "@mui/icons-material/Sync";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import { firelikeId } from "../../app/utils/models/checkers/firelikeid";

const SyncButton = ({ status, row }) => {
  const syncColor = status === null ? COLOR_RED_0 : "green";

  return (
    <CustomIconButton customcolor={syncColor}>
      <SyncIcon customcolor={syncColor} />
    </CustomIconButton>
  );
};

export const getColumns = (orgData, handleEmailsClick, handleChatClick) => {
  function TagsFilterInput(props) {
    const { item, applyValue, focusElementRef = null } = props;

    const [filterValueState, setFilterValueState] = useState(item.value ?? "");

    const handleFilterChange = (event) => {
      const query = event.target.value;
      setFilterValueState(query);
    };

    const applyFilter = () => {
      applyValue({ ...item, value: filterValueState });
    };

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        applyFilter();
      }
    };

    return (
      <TextField
        placeholder="tag..."
        label="Tag"
        variant="standard"
        value={filterValueState}
        onChange={handleFilterChange}
        onKeyPress={handleKeyPress}
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    );
  }

  const tagsOperators = [
    {
      value: "contains",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem || !filterItem.value) {
          return;
        }

        return (params) => {
          const formattedValue = params.formattedValue.toLowerCase();
          const query = filterItem.value.toLowerCase();
          return formattedValue.includes(query);
        };
      },
      InputComponent: TagsFilterInput,
    },
  ];

  const headers = [
    {
      headerName: "Invoice #",
      field: "invoiceNumber",
      width: 100,
      type: "number",
      defaultSortOrder: "descend",
      valueGetter: (params) => params.value,
      renderCell: (params) => <Link to={`${ROUTE_EDIT_INVOICE}${params.row.id}`}>{params.value}</Link>,
    },
    {
      headerName: i18n("invoices.type"),
      field: "type",
      type: "string",
      width: 100,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Tags",
      field: "tagsQueryHook",
      type: "string",
      width: 100,
      filterOperators: tagsOperators,
      valueFormatter: (params) => {
        const tagNames = params.value.map((tagId) => {
          const tag = orgData.tags.find((tag) => tag.id === tagId);
          return tag ? tag.name : "";
        });
        return tagNames.join(", ");
      },
      renderCell: (params) => {
        return (
          <div style={{ width: "100%", overflowX: "auto" }}>
            {params.value.map((tagId) => {
              if (orgData) {
                const tag = orgData.tags.find((tag) => tag.id === tagId);
                if (tag) {
                  return (
                    <Tag color={tag.color} key={tag.id}>
                      {tag.name}
                    </Tag>
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>
        );
      },
    },

    {
      headerName: i18n("invoices.customer"),
      field: "customer",
      type: "string",
      width: 300,
      valueGetter: (params) => params.row.customerDisplayName && params.row.customerDisplayName && params.row.customerDisplayName,
    },
    {
      headerName: "Sales Rep",
      field: "salesRepName",
      type: "string",
      width: 200,
      valueGetter: (params) => {
        return (params.row.salesRepName && params.row.salesRepName) ?? "--";
      },
    },
    {
      headerName: i18n("invoices.lastUpdated"),
      field: "updatedOn",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => params.row.updatedOn?.toDate(),
      renderCell: (params) => {
        return <Space>{convertTimeToDayString(params.row.updatedOn, "dash")}</Space>;
      },
    },
    {
      headerName: i18n("invoices.eventDate"),
      field: "eventDate",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => params.row.eventDate?.toDate(),
      renderCell: (params) => {
        return <Space>{convertTimeToDayString(params.row.eventDate, "dash")}</Space>;
      },
    },
    {
      headerName: "Created On",
      field: "createdOn",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => params.row.createdOn?.toDate(),
      renderCell: (params) => {
        return <Space>{convertTimeToDayString(params.row.createdOn, "dash")}</Space>;
      },
    },
    {
      headerName: i18n("invoices.rentalStart"),
      field: "rentalDateStart",
      type: "dateTime",
      width: 200,
      valueGetter: (params) => params.row.rentalDateStart?.toDate(),
      renderCell: (params) => {
        return (
          <Space>
            {convertTimeToDayString(params.row.rentalDateStart)}
            {params.row.specifiedReceiveTime && (
              <Tooltip title={convertToTime(params.row.specifiedReceiveTime)}>
                <ClockCircleOutlined />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      headerName: i18n("invoices.rentalEnd"),
      field: "rentalDateEnd",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => params.row.rentalDateEnd?.toDate(),
      renderCell: (params) => {
        return (
          <Space>
            {convertTimeToDayString(params.row.rentalDateEnd)}
            {params.row.specifiedReturnTime && (
              <Tooltip title={convertToTime(params.row.rentalDateEnd)}>
                <ClockCircleOutlined />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      headerName: i18n("invoices.days"),
      type: "number",
      field: "rentalRange",
    },
    {
      headerName: "Internal Notes",
      field: "id",
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={
              !checkSubscriptionLevel("internalNotes", orgData)
                ? "You do not have the required plan for internal notes. Requires Plus Plan, you can change this in Admin/Billing"
                : ""
            }
          >
            <span>
              <CustomIconButton
                customcolor={COLOR_BLUE_0}
                onClick={() => handleChatClick(params.row)}
                disabled={!checkSubscriptionLevel("internalNotes", orgData)}
              >
                <MessageIcon customcolor={COLOR_BLUE_0} />
              </CustomIconButton>
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      headerName: i18n("invoices.email"),
      resizable: true,
      minWidth: 50,
      width: 200,
      field: "email",
      valueGetter: (params) => params.row.email && params.row.email,
    },
    {
      headerName: "Phone",
      resizable: true,
      minWidth: 50,
      width: 200,
      field: "contactPhoneNumber",
      valueGetter: (params) => params.row.contactPhoneNumber && params.row.contactPhoneNumber,
    },
    {
      headerName: i18n("invoices.total"),
      field: "total",
      type: "number",
      width: 150,
      valueGetter: (params) => params.value / 100,
      renderCell: (params) => FormatToLocalCurrency(params.value, orgData.countryCode, orgData.languageCode, orgData.currencyCode),
    },
    {
      headerName: "Items",
      field: "selectedItems",
      width: 150,
      renderCell: (params) => {
        if (params.value.count < 3) {
          return (
            <div key={firelikeId()}>
              {params.value.items.map((item, index) => (
                <div key={firelikeId()}>
                  {item.name}: {item.selectedQty}
                </div>
              ))}
            </div>
          );
        } else {
          return (
            <Tooltip
              title={
                <div>
                  {params.value.items.map((item, index) => (
                    <div key={firelikeId()}>
                    <Typography >
                      {item.name}: {item.selectedQty}
                    </Typography>
                    </div>
                  ))}
                </div>
              }
            >
              <div>{params.value.count} items</div>
            </Tooltip>
          );
        }
      },
    },
    {
      headerName: i18n("invoices.balance"),
      field: "balanceRemaining",
      type: "number",
      width: 150,
      valueGetter: (params) => params.value / 100,
      renderCell: (params) => (
        <div>
          <Space direction="horizontal" style={{ alignItems: "center" }}>
            {FormatToLocalCurrency(params.value, orgData.countryCode, orgData.languageCode, orgData.currencyCode)}
            {params.row.balanceRemaining <= 0 && <CheckCircleFilled style={{ color: "#048446" }} />}
          </Space>
        </div>
      ),
    },
    {
      headerName: "Signature",
      field: "estimateSigned",
      type: "boolean",
      width: 100,
      renderCell: (params) => <div>{params.row.estimateSigned ? <CheckCircleFilled style={{ color: "#048446" }} /> : <div>--</div>}</div>,
    },
    {
      headerName: "Email Tracking",
      field: "hasSendRecords",
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.hasSendRecords ? (
            <CustomIconButton customcolor={params.row.emailDeliveryFailed ? COLOR_RED_0 : "#3b5999"} onClick={() => handleEmailsClick(params.row)}>
              <EmailIcon />
            </CustomIconButton>
          ) : (
            <div>--</div>
          )}
        </div>
      ),
    },
    {
      headerName: i18n("invoices.status"),
      field: "invoiceStatus",
      width: 300,
      // sorter: (a, b) => a?.balanceRemaining - b?.balanceRemaining,
      renderCell: (params) => (
        <div>
          <Space size={[0, 8]} wrap>
            {/* <Space direction="horizontal" style={{ alignItems: "center" }}> */}
            <Tag color={params.row.isLoaded ? "#3b5999" : "default"}>Loaded</Tag>
            <Tag color={params.row.receivedByDelivery ? "#3b5999" : "default"}>Received</Tag>
            <Tag color={params.row.returnedByPickup ? "#3b5999" : "default"}>Returned</Tag>
          </Space>
        </div>
      ),
    },
  ];

  if (orgData && orgData.qboRealmId && orgData.qboRealmId !== "") {
    headers.push({
      headerName: "QuickBooks Sync",
      field: "qbId",
      type: "boolean",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.type === "invoice" ? (
            params.row.qbId !== null ? (
              <SyncButton status={params.row.qbId} row={params.row} />
            ) : (
              <div>
                <CustomIconButton
                  customcolor={COLOR_RED_0}
                // onClick={syncClicked}
                >
                  <SyncIcon customcolor={COLOR_RED_0} />
                </CustomIconButton>
              </div>
            )
          ) : (
            <div>
              <Tooltip title="Only invoices are synced to QuickBooks at this time.">
                --
              </Tooltip>
            </div>
          )}
        </div>
      ),
    });
  }

  const weightColumn = {
    headerName: "Weight",
    field: "totalWeight",
    width: 150,
  };
  if (orgData && orgData.trackInventoryWeight) {
    headers.push(weightColumn);
  }

  return headers;
};