import { useMediaQuery, useTheme } from "@mui/material";


export enum Breakpoints {
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
  UNKNOWN = "UNKNOWN",
}


export function useResponsiveFontSize() {
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.only('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.only('md'));
    const matchesLG = useMediaQuery(theme.breakpoints.only('lg'));
    const matchesXL = useMediaQuery(theme.breakpoints.only('xl'));
  
    if (matchesXL) return 16;  // Larger font size for xl screens
    if (matchesLG) return 14;  // Large font size for lg screens
    if (matchesMD) return 14;  // Medium font size for md screens
    if (matchesSM) return 14;  // Small font size for sm screens
    return 12;  // Default font size for xs screens
  }
  

  
  // breakpoints.ts
  
  export const useCurrentBreakpoint = () => {
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.only("xs"));
    const matchesSM = useMediaQuery(theme.breakpoints.only("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.only("md"));
    const matchesLG = useMediaQuery(theme.breakpoints.only("lg"));
    const matchesXL = useMediaQuery(theme.breakpoints.only("xl"));
  
    let currentBreakpoint = Breakpoints.UNKNOWN;
    if (matchesXL) currentBreakpoint = Breakpoints.XL;
    else if (matchesLG) currentBreakpoint = Breakpoints.LG;
    else if (matchesMD) currentBreakpoint = Breakpoints.MD;
    else if (matchesSM) currentBreakpoint = Breakpoints.SM;
    else if (matchesXS) currentBreakpoint = Breakpoints.XS;
  
    const isXSAndUp = matchesXS || matchesSM || matchesMD || matchesLG || matchesXL;
    const isSMAndUp = matchesSM || matchesMD || matchesLG || matchesXL;
    const isMDAndUp = matchesMD || matchesLG || matchesXL;
    const isLGAndUp = matchesLG || matchesXL;
    const isXLAndUp = matchesXL;
  
    const isXSAndDown = matchesXS;
    const isSMAndDown = matchesXS || matchesSM;
    const isMDAndDown = matchesXS || matchesSM || matchesMD;
    const isLGAndDown = matchesXS || matchesSM || matchesMD || matchesLG;
  
    return {
      currentBreakpoint,
      isXSAndUp,
      isSMAndUp,
      isMDAndUp,
      isLGAndUp,
      isXLAndUp,
      isXSAndDown,
      isSMAndDown,
      isMDAndDown,
      isLGAndDown,
    };
  };
  
