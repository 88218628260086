export const checkCategory = (snap) => {
  const data = snap.data();
  return {
    id: snap.id,
    createdBy: data.createdBy ? data.createdBy : "",
    createdOn: data.createdOn ? data.createdOn : 0,
    description: data.description ? data.description : "",
    name: data.name ? data.name : "",
    orgId: data.orgId ? data.orgId : "",
    updatedBy: data.updatedBy ? data.updatedBy : "",
    updatedOn: data.updatedOn ? data.updatedOn : 0,
  };
};
