import { AddDaysToTimestamp } from "./addDaysToTimestamp";
import {
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_TURNAROUND,
} from "../../../utils/models/modelConstants/modelConstants";

export const DetermineApplyBundleTurnaround = ({
  useTurnaround,
  invoice,
  bundleItem,
  queryRentalStartDate,
  inheritParentTurnaround,
  parent,
}) => {
  const invoiceEndDate = invoice.rentalDateEnd;
  const itemTurnaround = () => {
    if (!useTurnaround) return 0;
    let turnaround = 0;
    if (inheritParentTurnaround) {
      turnaround = parent.turnaround ? parent.turnaround : 0;
    } else {
      turnaround = bundleItem[INV_BUNDLE_ITEM_TURNAROUND]
        ? bundleItem[INV_BUNDLE_ITEM_TURNAROUND]
        : 0;
    }
    return turnaround;
  };
  const newCutoff = AddDaysToTimestamp(itemTurnaround, invoiceEndDate);
  return newCutoff >= queryRentalStartDate;
};
