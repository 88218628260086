import React from "react";
import { Alert, Typography } from "antd";
const { Paragraph, Text } = Typography;

const InvoiceNotes = ({ notes }) => {
  return notes !== "" ? (
    <Alert message={notes} type="warning" style={{ margin: "8px 0 0 0" }} />
  ) : null;
};

export default InvoiceNotes;
