import { useCallback, useState } from "react";
import { checkCustomer } from "../../../../utils/models/checkers/checkCustomer";
import { throttle } from "throttle-debounce";
import { CUSTOMER_COLLECTION } from "../../../../utils/models/collections/collectionConstants";
import { useFirestore } from "react-redux-firebase";
import { authSelector } from "../../../../../domains/auth/authSlice";
import { useSelector } from "react-redux";

export function useCustomerSearchArray() {
  const { fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();

  const [busy, setBusy] = useState(false);
  const [data, setData] = useState([]);

  const setStrWithThrottle = useCallback(
    throttle(500, async (str) => {
      setBusy(true);
      fetchByMatchingStr({
        firestore,
        collection: `${fsOrgPrefix}${CUSTOMER_COLLECTION}`,
        searchStr: str,
      }).then((res) => {
        setBusy(false);
        setData([...res]);
      });
    }),
    []
  );

  return {
    state: { busy, data },
    actions: { setStrWithThrottle },
  };
}

const fetchByMatchingStr = async ({ firestore, collection, searchStr }) => {
  const ref = firestore
    .collection(collection)
    .where("searchArray", "array-contains", searchStr);

  try {
    const snap = await ref.get();
    let data = [];
    snap.forEach((s) => {
      data.push(checkCustomer(s));
    });
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
