import React from "react";
import { ScheduledEmail } from "../../app/interfaces/automation";
import { Avatar, Box, Button, Chip, List, ListItem, ListItemAvatar, Stack, Typography } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { functions } from "../../app/config/firebase";
import { LoadingButton } from "@mui/lab";


const ScheduledEmailsView = ({ emails, userData, reloadScheduledEmails }: { emails: ScheduledEmail[], userData: any, reloadScheduledEmails: () => {} }) => {
  const [ignoreEmails, setIgnoreEmails] = React.useState<string[]>([]);
  const [canSend, setCanSend] = React.useState<boolean>(false);
  const [sending, setSending] = React.useState<boolean>(false);

  // check if there are any emails to send by looking at the status of the emails, can only send if there are any with status of pending
  React.useEffect(() => {
    if (emails.length > 0) {
      const canSend = emails.some((email) => email.status === "pending");
      setCanSend(canSend);
    }
  }, [emails]);

  // call cloud function and send scheduled emails and ignore email ids
  const sendScheduledEmails = async () => {
    // call cloud function
    setSending(true);
    const call = functions.httpsCallable('sendScheduledEmails');
    console.log('scheduled emails', emails);

    call({ 
      scheduledEmails: emails, 
      canceledEmailIds: ignoreEmails,
      userId: userData.id,
      orgId: userData.orgId, 
      manual: true,
    })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSending(false);
        reloadScheduledEmails();
      }); 
  }

  return (
    <Stack
      justifyContent="space-between"
      sx={{ height: "100vh", overflow: "hidden" }} // Ensure the stack takes full height
      direction="column"
    >
      <List sx={{ overflow: "auto", flexGrow: 1 }}>
        {emails.map((email) => (
          <ListItem
            key={email.id}
            alignItems="flex-start"
            secondaryAction={
              email.status === "pending" && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (ignoreEmails.includes(email.id)) {
                      setIgnoreEmails(
                        ignoreEmails.filter((id) => id !== email.id)
                      );
                    } else {
                      setIgnoreEmails([...ignoreEmails, email.id]);
                    }
                  }}
                >
                  {ignoreEmails.includes(email.id) ? "Uncancel" : "Cancel"}
                </Button>
              )
            }
            sx={{ display: "flex", width: "100%", alignItems: "center" }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor:
                    ignoreEmails.includes(email.id) ||
                    email.status === "failed" ||
                    email.sendFailed ||
                    email.status === "canceled"
                      ? "error.main"
                      : "success.main",
                }}
              >
                <MailOutlineIcon />
              </Avatar>
            </ListItemAvatar>

            <Box sx={{ flex: 1, minWidth: 0 }}>
              {" "}
              {/* Ensure text takes up remaining space and allows wrapping */}
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Typography variant="body2" gutterBottom fontWeight={"bold"}>
                    Automation: {email.automation.name}
                  </Typography>

                  <Typography variant="body1">
                    {`Invoice #${email.invoiceNumber} - ${email.customerName}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {email.customerEmail}
                  </Typography>
                </Stack>

                {email.status !== "pending" && (
                  <Chip
                    variant="filled"
                    size="small"
                    sx={{
                      marginTop: 1,
                      backgroundColor:
                        email.status === "complete"
                          ? "#4caf50" 
                          : email.status === "canceled"
                          ? "#ff9800" 
                          : email.status === "failed"
                          ? "#f44336" 
                          : "default", 
                      color: "#fff",
                    }}
                    label={
                      email.status.charAt(0).toUpperCase() +
                      email.status.slice(1)
                    } 
                  />
                )}
              </Stack>
            </Box>
          </ListItem>
        ))}
      </List>

      <Box
        p={2}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
        }}
      >
        <LoadingButton
          onClick={sendScheduledEmails}
          loading={sending}
          disabled={!canSend}
          variant="contained"
          fullWidth
        >{`Send ${emails.length - ignoreEmails.length} 
        ${emails.length - ignoreEmails.length === 1 ? "Email" : "Emails"} 
        ${
          ignoreEmails.length > 0
            ? ` - Cancel ${ignoreEmails.length} 
        ${ignoreEmails.length === 1 ? "Email" : "Emails"}`
            : ""
        }
       `}</LoadingButton>
      </Box>
    </Stack>
  );
};

export default ScheduledEmailsView;
