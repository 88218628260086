import React from "react";
import { sortableElement } from "react-sortable-hoc";
import EditableRow from "../../../../invoices/components/invoiceTable/rows/EditableRow";

const DraggableRouteRow = ({ data, restProps }) => {
  const SortableItem = sortableElement((props) => <EditableRow {...props} />);
  const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
  return <SortableItem index={index} {...restProps} />;
};

export default DraggableRouteRow;
