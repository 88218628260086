export const FetchRouteDirections = async ({ firebase }, payload) => {
  const promises = payload?.map((p) => {
    const { origin, destination } = p;
    const call = firebase.functions().httpsCallable("getRouteDirections");
    return call({
      origin,
      destination,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return await Promise.all(promises);
};
