import React, { useContext } from "react";
import { RouteContext } from "../../../NewRoute";
import { Button, Card, Col, Divider, Row, Space } from "antd";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import ReceiveReturnMethodTag from "../shared/ReceiveReturnMethodTag";
import { TimeTag } from "../../../../../app/system-components/tags/RoutesTags";
import { WarningFilled } from "@ant-design/icons";
import {
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_YELLOW_0,
} from "../../../../../app/ui/colorConstants";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import { useStopCell } from "../../../../../app/services/hooks/useStopCell";
import { ItemTag } from "../../../../../app/system-components/tags/Tags";
import EmptyTable from "../../../../invoices/components/invoiceTable/modules/EmptyTable";
import { STOP_TYPE_WAYPOINT } from "../../../../../app/utils/models/modelConstants/modelConstants";
import InvoiceItemsCard from "./InvoiceItemsCard";
import WaypointCard from "./WaypointCard";

const SelectedStopInvoice = () => {
  const { state, actions } = useContext(RouteContext);
  const { items, typeLabel, timeWindow } = useStopCell({
    stop: state.selectedStop?.stop,
    routes: state.routes,
    currentRouteId: state.currentRouteId,
    selectedStop: state.selectedStop,
  });

  const selectedStopIsInRoute = ({ routeState }) => {
    // const inRoute = state.currentRoute?.invoicesQueryHook?.includes(
    //   state.selectedStop.invoiceId
    // );
    const stopExistsInRoute = routeState.currentRoute?.stops?.find((s) => {
      return (
        s?.invoiceId === routeState.selectedStop?.invoiceId &&
        s?.type === routeState.selectedStop?.stop?.type
      );
    });
    return !!stopExistsInRoute;
  };
  const isAssigned = selectedStopIsInRoute({ routeState: state });

  return (
    <Card size={"small"} bordered={false}>
      {state.selectedStop.invoiceId ? (
        <div>
          <Row>
            <Col span={12} style={{ height: "170px", overflowY: "auto" }}>
              <Space direction={"vertical"} size={0}>
                <Space direction={"horizontal"} size={4}>
                  {state.selectedStop?.stop?.type !== STOP_TYPE_WAYPOINT ? (
                    <TextBody
                      text={`#${
                        state.selectedStop?.stop?.invoice?.invoiceNumber ??
                        "Unknown"
                      }`}
                    />
                  ) : null}
                  {typeLabel && (
                    <ReceiveReturnMethodTag
                      hideEdit={true}
                      typeLabel={typeLabel}
                      stop={state.selectedStop?.stop}
                    />
                  )}
                  {timeWindow.hasWindow && <TimeTag text={timeWindow?.text} />}
                </Space>
                <TextBody text={state.selectedStop?.stop?.displayName ?? ""} />
                <Space>
                  {state.selectedStop?.stop?.addrValidated ? null : (
                    <WarningFilled style={{ color: COLOR_YELLOW_0 }} />
                  )}
                  <TextSubtitle text={state.selectedStop?.stop?.address} />
                </Space>
              </Space>
              <br />
              <Button
                type={isAssigned ? "danger" : "primary"}
                onClick={() => {
                  if (isAssigned) {
                    actions.removeStop({
                      stop: state.selectedStop?.stop,
                      routeId: state.currentRouteId,
                    });
                  } else {
                    actions.addStop({
                      stop: state.selectedStop?.stop,
                      routeId: state.currentRouteId,
                    });
                  }
                }}
                style={{
                  margin: "8px 0",
                  borderColor: "transparent",
                  color: "#FFF",
                  backgroundColor: !isAssigned && state.currentRoute?.color,
                }}
              >
                {isAssigned
                  ? `Remove from: ${state?.currentRoute.name}`
                  : `Add to route: ${state?.currentRoute?.name}`}
              </Button>
              <br />

              {state.selectedStop?.assignedInDeselectedRoutes.length !== 0 && (
                <Space direction={"vertical"} size={2}>
                  <TextSubtitle text={"ALSO ASSIGNED IN:"} />
                  {state.selectedStop?.assignedInDeselectedRoutes?.map((r) => {
                    return (
                      <Space direction={"horizontal"}>
                        {r?.name}
                        <Button
                          size={"small"}
                          style={{ color: COLOR_RED_0 }}
                          onClick={() => {
                            actions.removeStop({
                              stop: state.selectedStop?.stop,
                              routeId: r?.routeId,
                            });
                          }}
                          type={"link"}
                        >
                          Remove
                        </Button>
                      </Space>
                    );
                  })}
                </Space>
              )}
            </Col>
            <Col span={12}>
              {state.selectedStop?.stop &&
              state.selectedStop?.stop?.type === STOP_TYPE_WAYPOINT ? (
                <WaypointCard data={state.selectedStop?.stop?.waypointMeta} />
              ) : (
                <InvoiceItemsCard items={items} />
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "32px 32px 32px 57px",
          }}
        >
          <TextBody
            style={{ color: COLOR_TEXT_GRAY_1 }}
            text={"No stop selected"}
          />
        </div>
      )}
    </Card>
  );
};

export default SelectedStopInvoice;
