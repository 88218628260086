import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';

import detectBrowserLanguage from 'get-user-locale';

let currentLanguageCode = 'es';

const languages: {
  [key: string]: {
    id: string;
    label: string;
    flag: string;
    antd: any;
    dictionary: any;
  };
} = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/United-States.png',
    antd: null,
    dictionary: null,
  },
  es: {
    id: 'es',
    label: 'Español',
    flag: '/images/flags/Spain.png',
    antd: null,
    dictionary: null,
  },
  'pt-BR': {
    id: 'pt-BR',
    label: 'Português',
    flag: '/images/flags/Brazil.png',
    antd: null,
    dictionary: null,
  },
};

export async function init() {

  const languageCode = detectBrowserLanguage()
  const languageComponents = languageCode?.split('-');
  let language = 'en';
  if (languageComponents && languageComponents.length > 1) {
    language = languageComponents[0];
  } else {
    language = languageCode ?? 'en'
  }

  console.log("LANGUAGE", language);
  currentLanguageCode = language;
  setLanguageCode(currentLanguageCode);

  if (currentLanguageCode === 'en') {
    await initEn();
  }

  if (currentLanguageCode === 'pt-BR') {
    await initPtBr();
  }

  if (currentLanguageCode === 'es') {
    await initEs();
  }
}

async function initPtBr() {
  const language = languages['pt-BR'];

  // @ts-ignore
  const momentLocale = (await import('moment/locale/pt-br'))
    .default;

  language.antd = (
    await import('antd/lib/locale-provider/pt_BR')
  ).default;

  language.dictionary = (
    await import('./pt-BR')
  ).default;

  moment.locale('pt-BR', momentLocale);

  return language;
}

async function initEs() {
  const language = languages['es'];

  // @ts-ignore
  const momentLocale = (await import('moment/locale/es'))
    .default;

  language.antd = (
    await import('antd/lib/locale-provider/es_ES')
  ).default;

  language.dictionary = (
    await import('./es')
  ).default;

  moment.locale('es', momentLocale);

  return language;
}

async function initEn() {
  const language = languages['en'];

  language.dictionary = (
    await import('./en')
  ).default;

  return language;
}

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message: string, args: any[]) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (
      match: any,
      number: number,
    ) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getAntdLanguage() {
  return getLanguage().antd;
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg: string) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  localStorage.setItem('language', arg);
}

export function i18nExists(key: any) {
  if (!getLanguage()) {
    return false;
  }

  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key: any, ...args: any[]) {
  if (!getLanguage()) {
    return key;
  }

  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function i18nHtml(key: any, ...args: any[]) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  );
}





// import detectBrowserLanguage from 'get-user-locale';
// import _get from 'lodash/get';

// let currentLanguageCode = '';

// export async function i18nString(key: string) {
//   const languageCode = detectBrowserLanguage()
//   const languageComponents = languageCode?.split('-');
//   let language = 'en';
//   if (languageComponents && languageComponents.length > 1) {
//     language = languageComponents[0];
//   } else {
//     language = languageCode ?? 'en'
//   }
  
//   const languageDictionary: any = await init(language)
//   const msg = _get(languageDictionary.dictionary, key)
//   return msg
// }

// const languages: {
//   [key: string]: {
//     id: string;
//     label: string;
//     flag: string;
//     antd: any;
//     dictionary: any;
//   };
// } = {
//   en: {
//     id: 'en',
//     label: 'English',
//     flag: '/images/flags/United-States.png',
//     antd: null,
//     dictionary: null,
//   },
//   es: {
//     id: 'es',
//     label: 'Español',
//     flag: '/images/flags/Spain.png',
//     antd: null,
//     dictionary: null,
//   },
//   'pt-BR': {
//     id: 'pt-BR',
//     label: 'Português',
//     flag: '/images/flags/Brazil.png',
//     antd: null,
//     dictionary: null,
//   },
// };

// export async function init(language: string) {
//   currentLanguageCode = language

//   if (currentLanguageCode === 'en') {
//     return await initEn();
//   }

//   if (currentLanguageCode === 'es') {
//     return await initEs();
//   }
// }

// async function initEs() {
//   const language = languages['es'];

//   // @ts-ignore
//   const momentLocale = (await import('moment/locale/es'))
//     .default;

//   language.antd = (
//     await import('antd/lib/locale-provider/es_ES')
//   ).default;

//   language.dictionary = (
//     await import('./es')
//   ).default;

//   return language;
// }

// async function initEn() {
//   const language = languages['en'];

//   language.dictionary = (
//     await import('./en')
//   ).default;

//   console.log(language.dictionary)
//   return language;
// }

// function getLanguage() {
//   return languages[getLanguageCode()];
// }

// function format(message: string, args: any) {
//   if (!message) {
//     return null;
//   }

//   try {
//     return message.replace(/{(\d+)}/g, function (
//       match,
//       number,
//     ) {
//       return typeof args[number] != 'undefined'
//         ? args[number]
//         : match;
//     });
//   } catch (error) {
//     console.error(message, error);
//     throw error;
//   }
// }

// export function getLanguages() {
//   return Object.keys(languages).map((language) => {
//     return languages[language];
//   });
// }

// export function getAntdLanguage() {
//   return getLanguage().antd;
// }

// export function getLanguageCode() {
//   return currentLanguageCode;
// }

// export function setLanguageCode(arg: string) {
//   if (!languages[arg]) {
//     throw new Error(`Invalid language ${arg}.`);
//   }

//   localStorage.setItem('language', arg);
// }

// // export function i18nExists(key: any) {
// //   if (!getLanguage()) {
// //     return false;
// //   }

// //   const message = _get(getLanguage().dictionary, key);
// //   return Boolean(message);
// // }

// // export function i18n(key, ...args) {
// //   if (!getLanguage()) {
// //     return key;
// //   }

// //   const message = _get(getLanguage().dictionary, key);

// //   if (!message) {
// //     return key;
// //   }

// //   return format(message, args);
// // }

// // export function i18nHtml(key, ...args) {
// //   return (
// //     <span
// //       dangerouslySetInnerHTML={{
// //         __html: i18n(key, ...args),
// //       }}
// //     />
// //   );
// // }
