import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";

const AddCcModal = ({ visible, onCancel, onAddClicked }) => {
  const [email, setEmail] = useState(null);

  return (
    <Modal
      destroyOnClose
      width={400}
      open={visible}
      closeIcon={<></>}
      title="Send copy of invoice"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setEmail(null);
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onAddClicked(email);
          }}
        >
          Add User
        </Button>,
      ]}
    >
      <div>
        <Input
          placeholder="Enter email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default AddCcModal;
