import moment from "moment";
import firebase from "firebase/app";
export const TimeMachine = (anytime) => {
  if (!anytime)
    return {
      moment: null,
      date: null,
      firestoreDate: null,
      hoursText: "",
    };
  // check if moment
  const tryMoment = new moment(anytime);
  const isMoment = tryMoment.isValid();
  // check if firestore ts
  const isFsTimestamp = !!anytime?.seconds;
  // check if date
  const isDate = anytime instanceof Date;
  // notes: javascript date will also register as moment = true
  if (isMoment) {
    return {
      moment: tryMoment,
      date: tryMoment.toDate(),
      firestoreDate: firebase.firestore.Timestamp.fromDate(tryMoment.toDate()),
      hoursText: tryMoment.format("hh:mm a"),
    };
  } else if (isFsTimestamp && !isMoment) {
    const seconds = anytime?.seconds ?? 0;
    const firestoreToMoment = new moment(seconds * 1000);
    return {
      moment: firestoreToMoment,
      date: firestoreToMoment.toDate(),
      firestoreDate: firebase.firestore.Timestamp.fromDate(
        firestoreToMoment.toDate()
      ),
      hoursText: firestoreToMoment.format("hh:mm a"),
    };
  } else {
    return {
      moment: null,
      date: null,
      firestoreDate: null,
      hoursText: "",
    };
  }
};
