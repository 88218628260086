import { Box, Typography, Stack, Chip, Grid } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { yellow } from '@mui/material/colors';

import { firelikeId } from '../../../../../app/utils/models/checkers/firelikeid';
import convertTimeToDayString from '../../../../../app/utils/time/convertTimeToDayString';
import { useResponsiveFontSize } from '../../../../../app/hooks/use-responsive-font';
import ReceiveReturnMethodTag from './ReceiveReturnMethodTag';
import { TimeTag } from '../../../../../app/system-components/tags/RoutesTags';

const STOP_TYPE_WAYPOINT = 'waypoint'; // Assuming this constant is defined elsewhere

const StopCellHeader = ({
  hideEdit,
  hideDots,
  stop,
  invoiceNumber,
  inRoutes,
  timeWindow,
  typeLabel,
}) => {

  const responsiveFontSize = useResponsiveFontSize();

  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap" id={invoiceNumber} key={firelikeId()}>
      <Grid item xs>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            {stop?.type !== STOP_TYPE_WAYPOINT && (
              <Typography fontSize={responsiveFontSize}>{`#${stop?.invoice?.invoiceNumber ?? "Unknown"}`}</Typography>
            )}
            {typeLabel && <ReceiveReturnMethodTag typeLabel={typeLabel} stop={stop} hideEdit={hideEdit} />}
            {timeWindow.hasWindow && <TimeTag text={timeWindow?.text} />}
          </Stack>

          <Stack direction="column" spacing={4} alignItems="left">
            <Typography fontSize={responsiveFontSize}>{stop?.displayName ?? ""}</Typography>
          </Stack>

          {stop?.invoice?.eventDate && (
            <Stack direction="column" spacing={0} alignItems="left">
              <Typography fontSize={"10px"} fontWeight={"bold"}>
                {"Event"}
                </Typography> 
              <Typography fontSize={responsiveFontSize - 1}>{`${convertTimeToDayString(stop?.invoice?.eventDate)}`}</Typography>
            </Stack>
          )}

          <Stack direction="row" spacing={1} alignItems="center">
            {!stop?.addrValidated && <WarningIcon sx={{ color: yellow[500] }} />}

            <Typography fontSize={responsiveFontSize - 2} fontWeight={"bold"}>
              {stop.address}
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item>
        <Box sx={{ textAlign: "right" }}>
          {!hideDots && (
            <Stack direction="row" spacing={0.25}>
              {inRoutes?.map((route, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: route.color,
                  }}
                />
              ))}
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default StopCellHeader;


// import React from "react";
// import TextBody from "../../../../../app/system-components/typography/text/TextBody";
// import { Badge, Col, Row, Space } from "antd";
// import { WarningFilled } from "@ant-design/icons";
// import { GenericTag } from "../../../../../app/system-components/tags/RoutesTags";

// import {
//   COLOR_GREEN_0,
//   COLOR_YELLOW_0,
// } from "../../../../../app/ui/colorConstants";
// import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
// import { TimeTag } from "../../../../../app/system-components/tags/RoutesTags";
// import ReceiveReturnMethodTag from "./ReceiveReturnMethodTag";
// import { STOP_TYPE_WAYPOINT } from "../../../../../app/utils/models/modelConstants/modelConstants";
// import convertTimeToDayString from "../../../../../app/utils/time/convertTimeToDayString";
// import { i18n } from "../../../../../i18n";
// import { firelikeId } from "../../../../../app/utils/models/checkers/firelikeid";


// const StopCellHeader = ({
//   hideEdit,
//   hideDots,
//   stop,
//   invoiceNumber,
//   inRoutes,
//   timeWindow,
//   typeLabel,
// }) => {
//   return (
//     <Row
//       style={{ display: "inline-flex", width: "100%" }}
//       wrap={false}
//       id={invoiceNumber}
//       key={firelikeId()}
//       justify={"space-between"}
//       align={"top"}
//     >
//       <Col>
//         <Space direction={"vertical"} size={0}>
//           <Space direction={"horizontal"} size={4}>
//             {stop?.type !== STOP_TYPE_WAYPOINT ? (
//               <TextBody
//                 text={`#${stop?.invoice?.invoiceNumber ?? "Unknown"}`}
//               />
//             ) : null}
//             {typeLabel && (
//               <ReceiveReturnMethodTag
//                 typeLabel={typeLabel}
//                 stop={stop}
//                 hideEdit={hideEdit}
//               />
//             )}
//             {timeWindow.hasWindow && <TimeTag text={timeWindow?.text} />}
//           </Space>

//           <Space size={30}>
//             <TextBody text={stop?.displayName ?? ""} />

//             {stop?.invoice?.eventDate && (
//               <Space size={0}>
//                 <GenericTag text={i18n('invoices.eventDate')} />{" "}
//                 <TextBody
//                   text={`${convertTimeToDayString(stop?.invoice?.eventDate)}`}
//                 />
//               </Space>
//             )}
//           </Space>

//           <Space>
//             {stop?.addrValidated ? null : (
//               <WarningFilled style={{ color: COLOR_YELLOW_0 }} />
//             )}
//             <TextSubtitle text={stop.address} />
//           </Space>
//         </Space>
//       </Col>
//       <Col style={{ float: "right" }}>
//         <Space
//           direction={"vertical"}
//           style={{ float: "right", textAlign: "right", display: "block" }}
//         >
//           {!hideDots && (
//             <div
//               style={{
//                 float: "right",
//                 display: "inline-block",
//                 height: "12px",
//               }}
//             >
//               <Space direction={"horizontal"} size={0}>
//                 <div>
//                   {inRoutes?.map((i) => (
//                      <div 
//                      style={{ 
//                       margin: '1px',
//                       width: '12px',
//                       height: '12px',
//                       borderRadius: '50%',
//                       backgroundColor: i.color }}
//                    />
//                   ))}
//                 </div>
//               </Space>
//             </div>
//           )}
//         </Space>
//       </Col>
//     </Row>
//   );
// };

// export default StopCellHeader;
