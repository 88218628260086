import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Card,
  Row,
  Typography,
  Col,
  Form,
  InputNumber,
  Space,
  Divider,
  Button
} from "antd";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { COLOR_GREEN_0, COLOR_PURPLE_0 } from "../../../app/ui/colorConstants";
import onlyUnique from "../../../app/utils/filters/filterOnlyUnique";
import InventoryInvoiceGroup from "./InventoryInvoiceGroup";
import { useBulkUpdateQrItem } from "../../../app/services/hooks/useBulkUpdateQrItem";

const { Title, Text } = Typography;

const InventoryView = React.forwardRef((props, ref) => {
  const selectedInventory = props.selectedInventory;
  const invoices = props.invoices;
  const refreshInvoiceData = props.refreshInvoiceData;
  const refreshInvoices = props.refreshInvoices;
  const dailyAvailable = props.dailyAvailable;

  const [progressDetails, setProgressDetails] = useState({
    total: 0,
    completed: 0,
    isComplete: false,
  });
  const [form] = Form.useForm();
  const inputRef = React.useRef();
  const { updateDayInventoryItem, dayInventoryCompleted } = useBulkUpdateQrItem(
    {
      dayInvoices: invoices,
      selectedInventory: selectedInventory,
      invoiceCallback: refreshInvoices,
    }
  );
  const handleCompletedCount = (c) => {
    const count = Number(c.target.value);
    const safeCount =
      count > progressDetails.total ? progressDetails.total : count;
    updateDayInventoryItem({ count: safeCount });
  };

  const handleCompletedCountEnter = () => {
    inputRef.current.blur();
  };

  useEffect(() => {
    let total = 0;
    let completed = 0;
    selectedInventory &&
      selectedInventory.itemList &&
      selectedInventory.itemList.map((i) => {
        total = total + i.qtyRented;
        completed = completed + i.completed;
      });
    selectedInventory &&
      form.setFieldsValue({ totalInvCount: selectedInventory.completed });
    setProgressDetails({
      total,
      completed,
      isComplete: completed / total === 1,
    });
  }, [selectedInventory]);

  return (
    <div ref={ref}>
      <Card
        size={"small"}
        style={{
          minHeight: "600px",
          backgroundColor: "transparent",
          borderColor: "transparent",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Row
          style={{
            padding: "0 12px",
            backgroundColor: progressDetails.isComplete
              ? COLOR_GREEN_0
              : COLOR_PURPLE_0,
            height: "70px",
            borderRadius: "4px",
            boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
          }}
          wrap={false}
          justify={"space-between"}
          align={"middle"}
        >
          <Col>
            <Space size={12}>
              <Title style={{ margin: "0px", color: "#FFF" }} level={4}>
                {selectedInventory && selectedInventory.name}
              </Title>
            </Space>
            <br />
            <Text style={{ color: "#FFF" }}>
              {selectedInventory && selectedInventory.description}
            </Text>
          </Col>
          <Col>
            <Space>
              <Form form={form}>
                <Form.Item name={"totalInvCount"} style={{ margin: 0 }}>
                  <InputNumber
                    size={"large"}
                    ref={inputRef}
                    onPressEnter={handleCompletedCountEnter}
                    min={0}
                    max={selectedInventory && selectedInventory.qtyRented}
                    key={`${
                      selectedInventory && selectedInventory.id
                    }_main_input`}
                    defaultValue={
                      selectedInventory && selectedInventory.completed
                    }
                    placeholder={"All items"}
                    onBlur={(c) => handleCompletedCount(c)}
                  />
                </Form.Item>
              </Form>
              <TextBody
                style={{ fontSize: "16px", color: "#FFF" }}
                text={`${progressDetails.total}`}
              />{" "}
            </Space>
          </Col>
        </Row>
        <br />
        <br />
        <TextBody
          text={"Invoice breakdown"}
          style={{ fontSize: "17px", marginLeft: "12px" }}
        />
        <Divider
          style={{
            margin: "16px 12px",
            minWidth: 0,
            width: "auto",
          }}
        />
        {selectedInventory &&
          selectedInventory.itemList &&
          selectedInventory.itemList
            .map((i) => i.invoiceId)
            .filter(onlyUnique)
            .map((uniqueId) => {
              return (
                <InventoryInvoiceGroup
                  invoiceId={uniqueId}
                  itemList={
                    selectedInventory &&
                    selectedInventory.itemList &&
                    selectedInventory.itemList
                  }
                  invoice={invoices.find((i) => i.id === uniqueId)}
                  refreshInvoiceData={(invoice) => {
                    refreshInvoiceData(invoice);
                  }}
                  invoices={invoices}
                  dailyAvailable={dailyAvailable}
                />
              );
            })}
      </Card>
    </div>
  );
});

export default InventoryView;


export const PrintQRByInventory = ({
  invoices,
  selectedInventory,
  refreshInvoiceData,
  refreshInvoices,
  dailyAvailable,
}) => {
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button>Print Quick Report</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <InventoryView
            dailyAvailable={dailyAvailable}
            selectedInventory={selectedInventory}
            invoices={invoices}
            refreshInvoiceData={(invoice) => {
              refreshInvoiceData(invoice);
            }}
            refreshInvoices={({ selectedInventory: newInv, invoices }) => {
              refreshInvoices({ invoices });
            }}
            ref={(el) => (componentRef = el)}
          />
        </div>
      </div>
    </>
  );
};
