import { Divider, Space, Tag } from "antd";
import {
  COLOR_RED_0,
} from "../../../../app/ui/colorConstants";
import { WarningFilled } from "@ant-design/icons";
import { checkUndefined } from "../../../../app/utils/models/checkers/checkUndefined";

const StockCounter = ({ item, dailyAvailable }) => {

  const available = checkUndefined(
    dailyAvailable && dailyAvailable.stockList.find((i) => i.id === item.id),
    null
  );
  if (!available) return null;
  
  const total = available.stock ? available.stock : 0;
  const availableStock =
    available.availableStock && available.availableStock > 0
      ? available.availableStock
      : 0;
  const overbookedAmt = available.unavailableStock
    ? total - available.unavailableStock
    : total;

  return (
    <Space>
      {overbookedAmt < 0 ? (
        <Tag
          style={{
            backgroundColor: "transparent",
            fontWeight: "600",
            color: COLOR_RED_0,
            borderColor: "transparent",
            lineHeight: "30px",
            fontSize: "14px",
          }}
        >
          <WarningFilled style={{ color: "#FFD54F", marginRight: "4px" }} />
          Overbooked: {overbookedAmt}
        </Tag>
      ) : null}
      <Tag
        style={{
          borderColor: "transparent",
          lineHeight: "30px",
          fontSize: "14px",
        }}
      >
        {available.stock
          ? `Available: ${availableStock}/${total}`
          : "No stock info"}
      </Tag>
      <Divider type={"vertical"} />{" "}
    </Space>
  );
};

export default StockCounter;
