export const routeColors = [
  "#6138E9",
  "#E01E5A",
  "#E458E9",
  "#FFD54F",
  "#3BB37C",
  "#E99938",
  "#5898E9",
  "#54CFC7",
  "#ADD7FF",
  "#2B9ABC",
];
