import React, { useContext } from "react";
import { Card, Col, List } from "antd";
import {
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import { RouteAssignmentsContext } from "../RouteAssignments";
import { CheckCircleFilled } from "@ant-design/icons";

const AssignedRouteList = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  return (
    <Col flex={"260px"} className="route-assignment-list">
      <Card size={"small"} title={"Routes"}>
        <List
          locale={{ emptyText: "No routes" }}
          style={{ height: "calc(100vh - 124px)", overflowY: "auto" }}
          size="small"
          dataSource={state.routes}
          renderItem={(item) => {
            const isSelected = state.selectedRoute?.id === item?.id;
            return (
              <List.Item
                onClick={() =>
                  actions.onSelectedRoute({
                    id: item?.id,
                  })
                }
                style={{
                  cursor: "pointer",
                  fontWeight: isSelected ? "bold" : "normal",
                  borderRadius: "4px",
                  background: isSelected ? COLOR_LIGHT_GRAY : "#FFF",
                }}
              >
                {item?.isComplete && (
                  <CheckCircleFilled
                    style={{ color: COLOR_GREEN_0, marginRight: "4px" }}
                  />
                )}{" "}
                {item.name}
              </List.Item>
            );
          }}
        />
      </Card>
    </Col>
  );
};

export default AssignedRouteList;
