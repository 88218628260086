import { Affix, Modal, PageHeader, Space, Tag } from "antd";
import React, { FC, useState } from "react";
import InvoiceHeaderBtns from "./InvoiceHeaderBtns";
import { Customer } from "../../../app/interfaces/customer";
import { useHistory } from "react-router-dom";
import { ROUTE_INVOICES } from "../../../app/routes";
import { INVOICES_COLLECTION, TABLE_INVOICES_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";
import { Invoice } from "../../../app/interfaces/invoice";
import { useInvoiceHeader } from "../../auth/useInvoiceHeader";
import { Box, Button, Card, Dialog, Stack, TextField, Typography, alpha } from "@mui/material";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { functions } from "../../../app/config/firebase";
import { LoadingButton } from "@mui/lab";

export const InvoiceHeader: FC<{
  tags: any[];
  selectedTags: any[];
  handleBackClicked: () => void;
  id: string;
  customer: Customer;
  currentInvoice: any;
  handleComplete: (params: { type: any; redirect: any }) => Promise<any>;
  setCurrentInvoice: (params: { invoice: Invoice }) => void;
  handleInvoiceNumberChange: any;
}> = ({
  tags,
  selectedTags,
  handleBackClicked,
  id,
  customer,
  currentInvoice,
  handleComplete,
  setCurrentInvoice,
  handleInvoiceNumberChange,
}) => {
  const history = useHistory();

  const { title, invoiceNumbers } = useInvoiceHeader(currentInvoice);
  const { orgData } = useSelector(authSelector);
  const [newInvoiceNumber, setNewInvoiceNumber] = useState<number | null>(null);
  const [changingInvoice, setChangingInvoice] = useState(false);
  const [invoiceChangeResult, setInvoiceChangeResult]: any = useState(null)

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  }
  
  const handleClose = () => {
    setOpen(false);
  }

  // Convert to number
  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setNewInvoiceNumber(value);
  }

  const { deleteDocument } = useFirestoreWrite();

  const handleConfirmInvoiceChange = async () => {

    if(!Number.isInteger(newInvoiceNumber)) {
      alert('Invalid number');
      return;
    }

    const call = functions.httpsCallable("changeInvoiceNumber");

    setChangingInvoice(true);
    setInvoiceChangeResult(null)

    try {
      const res = await call({
        orgId: orgData.id,
        invoiceId: id,
        invoiceNumber: newInvoiceNumber,
      });

      const status = res.data.status;

      if (status === 200) {

      }

      setInvoiceChangeResult(res.data)
    } finally {
      setChangingInvoice(false);
    }
  };

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "Delete Invoice?",
      content:
        "This cannot be undone! Please note, deleting an invoice will remove it from your account completely and it cannot be recovered. It will immediately affect all associated reporting tied to this transaction (ie. QuickReport, Available Inentory, Routes...etc.) All linked payments will remain and it may affect your books. Be sure to compare transactions in QuickBooks to ensure proper account balancing.",
      okText: `Delete`,
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        
        await deleteDocument({
          data: {
            collection: TABLE_INVOICES_COLLECTION,
            id: id,
          },
        })

        deleteDocument({
          data: {
            collection: INVOICES_COLLECTION,
            id: id,
          },
        }).then(() => {
          history.push(ROUTE_INVOICES);
        });
      },
      onCancel: () => {},
    });
  };

  return (
    <Affix>
      <PageHeader
        className="PageHeader"
        title={
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <>
              <Typography component="a" onClick={handleClick}>
                {title}{" "}
              </Typography>

              <Dialog open={open} onClose={handleClose}>
                <Card sx={{ p: 3 }}>
                  <Stack gap={1} direction={"column"}>
                    <Typography gutterBottom>Change order number?</Typography>

                    <TextField
                      onChange={handleNumberChange}
                      id="standard-basic"
                      label="Enter new order number"
                    />

                    <LoadingButton
                      loading={changingInvoice}
                      onClick={handleConfirmInvoiceChange}
                    >
                      Submit
                    </LoadingButton>

                    <Box
                      sx={{
                        p: 2,
                        width: "100%",
                        borderRadius: 2,
                        bgcolor: (theme) =>
                          alpha(theme.palette.warning.light, 0.25),
                        border: (theme) =>
                          `dashed 1px ${theme.palette.divider}`,
                      }}
                    >
                      <Typography
                        sx={{ typography: { xs: "body2", xl: "body1" } }}
                        variant="body1"
                        gutterBottom
                      >
                        {`Invoice numbers must be unique across Adelie${
                          orgData.qboRealmId && orgData.qboRealmId !== ""
                            ? " and QuickBooks"
                            : ""
                        }. Upon saving, this invoice number will be checked against existing invoices.`}
                      </Typography>
                    </Box>

                    {invoiceChangeResult && (
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          width: "100%", // Make the box fill horizontal space
                          borderRadius: 2,
                          bgcolor: (theme) =>
                            invoiceChangeResult.status === 200
                              ? alpha(theme.palette.success.light, 0.25)
                              : alpha(theme.palette.error.light, 0.25),
                          border: (theme) =>
                            `dashed 1px ${theme.palette.divider}`,
                        }}
                      >
                        <Typography
                          sx={{ typography: { xs: "body2", xl: "body1" } }}
                          variant="body1"
                          gutterBottom
                        >
                          {invoiceChangeResult.msg}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Card>
              </Dialog>
            </>

            {invoiceNumbers.hasError && (
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                <Stack direction={"row"} alignItems={"center"}>
                  {invoiceNumbers.errorLabel}
                </Stack>
              </div>
            )}

            {selectedTags &&
              selectedTags.length > 0 &&
              selectedTags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                if (tag) {
                  return (
                    <Tag color={tag.color} key={tag.id}>
                      {tag.name}
                    </Tag>
                  );
                } else {
                  return null;
                }
              })}
          </Stack>
        }
        onBack={handleBackClicked}
        extra={[
          <div>
            <InvoiceHeaderBtns
              id={id}
              customerId={customer && customer.id}
              customer={customer}
              currentInvoice={currentInvoice}
              handleDelete={handleDelete}
              history={history}
              handleComplete={handleComplete}
              setCurrentInvoice={setCurrentInvoice}
            />
          </div>,
        ]}
      />
    </Affix>
  );
};
