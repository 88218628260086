import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { categoryWrites } from "../../../app/services/firestore/writes/categoryWrites";
import { Button, Form, Input, Modal } from "antd";
import { configureUpdateCategory } from "../../../app/utils/models/configure/configureUpdateCategory";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";

const EditCategoryModal = ({ onComplete, visible, onCancel, category }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const firestore = useFirestore();
  const [form] = Form.useForm();

  useEffect(() => {
    category &&
      form.setFieldsValue({
        name: category.name,
        description: category.description,
      });
  }, [category]);
  const handleFinish = () => {
    form.validateFields().then(() => {
      if (!userData || !orgData)
        return notificationError("Something went wrong", "");
      setSaving(true);
      const values = form.getFieldsValue();
      const payload = configureUpdateCategory(values, userData.id, orgData.id);
      categoryWrites({ firestore }, fsOrgPrefix)
        .updateCategory(category.id, payload)
        .then(() => {
          setSaving(false);
          form.resetFields();
          notificationConfirm("Category updated", "");
          onComplete();
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    });
  };

  const handleDelete = () => {
    setDeleting(true);
    categoryWrites({ firestore }, fsOrgPrefix)
      .deleteCategory(category.id)
      .then(() => {
        setDeleting(false);
        onComplete();
        notificationConfirm("Category deleted", "");
      })
      .catch((err) => {
        console.log(err);
        setDeleting(false);
      });
  };

  return (
    <Modal
      destroyOnClose
      width={400}
       open={visible}
      closeIcon={<></>}
      title="Edit Category"
      footer={[
        <Button
          key="delete"
          type="danger"
          style={{ float: "left" }}
          onClick={() => {
            handleDelete();
          }}
          loading={deleting}
          disabled={saving || deleting}
        >
          Delete
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
          loading={saving}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          style={{ marginBottom: "12px" }}
          rules={[
            {
              required: true,
              message: "Name required",
            },
          ]}
        >
          <Input placeholder="Category name" />
        </Form.Item>
        <Form.Item
          name="description"
          style={{ marginBottom: "0px" }}
          rules={[
            {
              required: true,
              message: "Description required",
            },
          ]}
        >
          <Input placeholder="Category description" maxLength={80} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
