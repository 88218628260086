import { Box, Button, Chip, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Stack, Typography, capitalize, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Invoice } from "../../../app/interfaces/invoice";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { checkInvoices } from "../../../app/utils/models/checkers/checkInvoices";
import { ConfirmDialog } from "../../file-manager/custom-dialog";
import { useBoolean } from "../../../app/hooks/use-boolean";
import { Diff, compareInvoices } from "../logic/MakeInvoiceDiff";
import InvoiceDiff from "../components/InvoiceDiff";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { enqueueSnackbar } from "notistack";


interface IHistoryDrawer {
  currenInvoice: Invoice;
  orgData: OrganizationData;
  users: any[];
  handleReplaceInvoice: (invoice: Invoice) => void;
  demoMode: boolean;
}

const InvoiceHistoryTab = ({ currenInvoice, orgData, users, handleReplaceInvoice, demoMode }: IHistoryDrawer) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const theme = useTheme();

  const confirmRestore = useBoolean();
  const [invoiceToRestore, setInvoiceToRestore] = useState<any>(null);
  const [historicalInvoices, setHistoricalInvoices] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      firebase
        .firestore()
        .collection("orgs")
        .doc(currenInvoice.orgId)
        .collection("invoices")
        .doc(currenInvoice.id)
        .collection("history")
        .orderBy("updatedAt", "desc")
        .get()
        .then((snapshot) => {
          const invoices = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              historicalId: doc.id,
            };
          });

          setHistoricalInvoices(invoices);
        });
    };

    fetchData();
  }, []); // dont remove dependency array.

  const handleRestore = async () => {

    if (demoMode) {
      enqueueSnackbar("This feature is disabled in demo mode.", { variant: "info" });
      return;
    }
    // overwrite the current invoice with the selected invoice
    await firestore.collection("orgs").doc(currenInvoice.orgId).collection("invoices").doc(currenInvoice.id).set(invoiceToRestore);
    await firestore
      .collection("orgs")
      .doc(currenInvoice.orgId)
      .collection("invoices")
      .doc(currenInvoice.id)
      .collection("history")
      .doc(invoiceToRestore.historicalId)
      .update({
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

    handleReplaceInvoice(invoiceToRestore);
  };

  return (
    <Box
      sx={{
        p: 0,
      }}
    >
      <Typography variant="h6">History</Typography>
      <Typography gutterBottom variant="body2">
        We store the last seven saved versions. Select a previous version of this invoice to restore it.
      </Typography>
      <Divider sx={{ mt: 1 }} />

      {historicalInvoices.map((invoice, index) => {
        const user = users.find((user) => user.id === invoice.updatedBy);
        const userName = user ? user.name : invoice.updatedBy;
        const updatedAt = invoice.updatedAt.toDate().toLocaleString();

        const previousInvoice = historicalInvoices[index + 1];
        let diffs: Diff[] = [];
        if (previousInvoice) {
          diffs = compareInvoices(invoice, previousInvoice);
        }

        return (
          <Box
            sx={{
              border: "1px solid",
              borderColor: theme.palette.grey[300],
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              mt: 2,
              p: 1,
            }}
            key={invoice.historicalId}
          >
            <Box sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                  <Typography variant="body2" fontWeight="bold">{`Last Saved: ${updatedAt}`}</Typography>
                  <Typography variant="caption">{`By: ${userName}`}</Typography>
                  {/* <Chip size="small" sx={{ width: "100px" }} label={capitalize(invoice.type)} /> */}
                </Stack>

                <Button
                  onClick={() => {
                    setInvoiceToRestore(invoice);
                    confirmRestore.onTrue();
                  }}
                  variant="contained"
                  sx={{ mr: 2 }}
                  aria-label="comment"
                >
                  Restore
                </Button>
              </Stack>
            </Box>

            <Divider
              sx={{
                mt: 1,
                mb: 1,
              }}
            />

            <Box>{diffs.length > 0 ? <InvoiceDiff diffs={diffs} orgData={orgData} /> : <Typography>No differences found between the orders.</Typography>}</Box>
          </Box>
        );
      })}

      {invoiceToRestore && (
        <ConfirmDialog
          open={confirmRestore.value}
          onClose={confirmRestore.onFalse}
          title="Restore Invoice?"
          content={`Are you sure you want to restore this invoice? This will replace the current invoice with the selected version that was saved on ${invoiceToRestore?.updatedAt
            .toDate()
            .toLocaleString()}`}
          action={
            <Button variant="contained" color="success" onClick={handleRestore}>
              Restore
            </Button>
          }
        />
      )}
    </Box>
  );
};

export default InvoiceHistoryTab;
