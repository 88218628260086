import { centsToDollars } from "./checkCurrencyToDollars";
import { checkUndefined, checkUndefinedOrNull } from "./checkUndefined";
import {
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
  INV_BUNDLE_ITEM_TURNAROUND,
} from "../modelConstants/modelConstants";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import { InventoryItem } from "../../../interfaces/inventoryItem";

export const checkInventory = (
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>,
  alreadyConverted?: boolean
) => {

  const convertRates = (
    rates: {
      rate: number;
      rateName: string;
      rateRange: string;
      id: string;
    }[]
  ) => {
    if (!rates || typeof rates === "undefined") return [];
    return rates.map((r) => {
      const { rate, ...rest } = r;
      return {
        rate: centsToDollars(rate),
        ...rest,
      };
    });
  };

  const checkBundleItems = (items: any[]) => {
    return (
      items &&
      items.map((i) => {
        return {
          [INV_BUNDLE_ITEM_ID]: checkUndefinedOrNull(i[INV_BUNDLE_ITEM_ID], ""),
          [INV_BUNDLE_ITEM_DESC]: checkUndefinedOrNull(
            i[INV_BUNDLE_ITEM_DESC],
            ""
          ),
          [INV_BUNDLE_ITEM_NAME]: checkUndefinedOrNull(
            i[INV_BUNDLE_ITEM_NAME],
            ""
          ),
          [INV_BUNDLE_ITEM_QTY]: checkUndefinedOrNull(
            i[INV_BUNDLE_ITEM_QTY],
            0
          ),
          [INV_BUNDLE_ITEM_TURNAROUND]: checkUndefinedOrNull(
            i[INV_BUNDLE_ITEM_TURNAROUND],
            0
          ),
        };
      })
    );
  };

  const data: Partial<InventoryItem> = alreadyConverted
    ? snap
    : snap.data() ?? {};
  const item: InventoryItem = {
    id: data && data.id ? data.id : "",
    qbId: checkUndefined(data.qbId, null),
    syncToken: checkUndefined(data.syncToken, null),
    orgId: checkUndefined(data.orgId, ""),
    type: checkUndefined(data.type, "rental"),
    name: checkUndefined(data.name, ""),
    description: checkUndefined(data.description, ""),
    rtfDescription: checkUndefined(data.rtfDescription, ""),
    stock: checkUndefined(data.stock, null),
    availableStock: checkUndefined(data.stock, null),
    turnaround: checkUndefined(data.turnaround, null),
    categories: checkUndefined(data.categories, []),
    categoriesQueryHook: checkUndefined(data.categoriesQueryHook, []),
    rates: checkUndefined(convertRates(data.rates ?? []), []),
    showInShop: data.showInShop ?? false,
    embeddedShopRateId: data.embeddedShopRateId ?? null,
    embeddedShopRateWeekId: data.embeddedShopRateWeekId ?? null,
    embeddedShopRateMonthId: data.embeddedShopRateMonthId ?? null,

    depreciation: data.depreciation
      ? {
        initialValue: data.depreciation.initialValue
          ? data.depreciation.initialValue
          : null,
        initialDate: data.depreciation.initialDate
          ? data.depreciation.initialDate
          : null,
        depreciationYears: data.depreciation.depreciationYears
          ? data.depreciation.depreciationYears
          : null,
      }
      : null,
    notes: checkUndefined(data.notes, ""),
    image: data.image
      ? {
        downloadURL: data.image.downloadURL ? data.image.downloadURL : "",
        fileName: data.image.fileName ? data.image.fileName : "",
      }
      : null,
    isAvailable: data.isAvailable ? data.isAvailable : false,
    isActive: data.isActive ? data.isActive : false,
    isTaxable: data.isTaxable ? data.isTaxable : false,
    bundleItems: data.bundleItems ? checkBundleItems(data.bundleItems) : [],
    bundleItemsQueryHook: data.bundleItemsQueryHook
      ? data.bundleItemsQueryHook
      : [],
    autoCalculateStock: data.autoCalculateStock ? data.autoCalculateStock : false,
    inheritParentTurnaround: data.inheritParentTurnaround
      ? data.inheritParentTurnaround
      : false,
    createdOn: data.createdOn ? data.createdOn : null,
    createdBy: data.createdBy ? data.createdBy : "",
    updatedOn: data.updatedOn ? data.updatedOn : null,
    updatedBy: data.updatedBy ? data.updatedBy : "",
    createdOnPlatform: checkUndefined(data.createdOnPlatform, null),
    updateSource: "adelie",
    hasMaintenanceDetails: data.hasMaintenanceDetails ? data.hasMaintenanceDetails : false,
    maintenanceDetails: data.maintenanceDetails ? data.maintenanceDetails : null,
    lastMaintenanceRecord: data.lastMaintenanceRecord ? data.lastMaintenanceRecord : null,
    
    weight: data.weight ? data.weight : null,
    volume: data.volume ? data.volume : null, // cubic 
    dimensions: data.dimensions ? data.dimensions : null,
    stackable: data.stackable ? data.stackable : false,
    routingConstraints: data.routingConstraints ? data.routingConstraints : null,
    requiresTwoPeople: data.requiresTwoPeople ? data.requiresTwoPeople : false,
    informationURL: data.informationURL ? data.informationURL : null,

    startingProfit: data.startingProfit ? data.startingProfit : null,
    expenseAccount: data.expenseAccount ? data.expenseAccount : null,
    incomeAccount: data.incomeAccount ? data.incomeAccount : null,
    assetAccount: data.assetAccount ? data.assetAccount : null,
  };
  return item;
};

export const checkInventoryForInvoice = () => {};
