export const UpdateParentCompletedFromChild = (parent, qrItems) => {
  let updatedItems = [];
  if (parent) {
    // if bundle child, determine if parent should not be completed + configure the count
    // map and get ratio of all items
    // apply lowest ratio to parent bundle and round to nearest int
    // update parent int
    let ratios = [];
    const children = qrItems.filter(
      (i) => parent.bundleChildrenIds.includes(i.id) && i.isBundleChild
    );
    children &&
      children.map((i) => {
        const ratio = i.completed / i.qtyRented;
        ratios.push(ratio);
      });
    const lowest = Math.min(...ratios);
    qrItems.map((i) => {
      if (
        !i.isBundleChild &&
        i.id === parent.id &&
        i.bundleChildrenIds.length !== 0
      ) {
        const { completed, ...rest } = i;
        updatedItems.push({
          completed: Math.floor(i.qtyRented * lowest),
          ...rest,
        });
      } else {
        updatedItems.push(i);
      }
    });
  }
  return updatedItems;
};
