export const USERS_COLLECTION = "users";
export const ORG_COLLECTION = "orgs";
export const TAX_RATES_COLLECTION = "taxCodes";
export const CATEGORIES_COLLECTION = "categories";
export const INVENTORY_COLLECTION = "inventory";
export const PARTS_COLLECTION = "parts";
export const PO_COLLECTION = "purchaseOrders";
export const SERVICE_ITEMS_COLLECTION = "serviceItems";
export const INVOICES_COLLECTION = "invoices";
export const TABLE_INVOICES_COLLECTION = "tableRowInvoices";

export const CUSTOMER_COLLECTION = "customers";
export const VENDOR_COLLECTION = "vendors";
export const VENUE_COLLECTION = "venues";
export const PAYMENT_COLLECTION = "payments";
export const ROUTES_COLLECTION = "routes";
export const TRUCKS_COLLECTION = "trucks";
export const SEND_RECORDS = "sendRecords";
