import { useEffect, useState } from "react";

export function useModalMgr() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  // actions
  const ok = async (fn) => {
    setLoading(true);
    fn && (await fn());
    hide();
    setLoading(false);
  };
  const cancel = async (fn) => {
    setLoading(true);
    fn && (await fn());
    hide();
    setLoading(false);
  };
  const loadData = async (values) => {
    setLoading(true);
    setData(values);
    setLoading(false);
  };
  const show = () => {
    setVisible(true);
  };
  const hide = () => {
    setData(null);
    setVisible(false);
  };

  return { visible, data, loading, ok, cancel, loadData, show, hide };
}
