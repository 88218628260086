import notificationWarning from "../../../app/system-components/toasters/notificationWarning";
import {
  INVOICE_DRAFT,
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  ROW_TYPE_SUBTOTAL,
} from "../../../app/utils/models/modelConstants/modelConstants";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { message, Modal } from "antd";

export const validateInvoiceType = async ({
  type,
  form,
  customer,
  rentalRange,
  selectedItems,
  inventory,
  isEdit,
  previousSavedInvoice,
  setCustomerAddressChecked,
  customerAddressChecked,
  showOverbookedError,
}) => {
  if (type === INVOICE_DRAFT) {
    return new Promise((res, rej) => {
      if (!customer)
        return notificationWarning("Unable to save", "Customer required");
      form
        .validateFields()
        .then(() => {
          res({ type: type, isEdit: isEdit, hasError: false });
        })
        .catch((err) => {
          console.log(err);
          res({ type: null, isEdit: null, hasError: true });
        });
    });
  } else {
    return new Promise((res, rej) => {
      form
        .validateFields()
        .then((fields) => {
          // validation
          if (!customer || rentalRange === 0 || !fields.invoicePaymentDueDate)
            return notificationWarning(
              "Unable to save",
              "Customer, rental range, and invoice due date required"
            );
          const isDeliveryReceive =
            fields?.receiveMethod === RECEIVE_METHOD_DELIVERY &&
            customerAddressChecked.receive;
          const isOnSiteReturn =
            fields?.returnMethod === RETURN_METHOD_ONSITE &&
            customerAddressChecked.return;
          if (customer?.customerShippingAddressStr === "") {
            console.log("No customer shipping address string");
            if (isDeliveryReceive || isOnSiteReturn) {
              setCustomerAddressChecked({
                receive:
                  isDeliveryReceive === true
                    ? false
                    : customerAddressChecked.receive,
                return:
                  isOnSiteReturn === true
                    ? false
                    : customerAddressChecked.return,
              });
              return notificationWarning(
                "Unable to save",
                "Customer must have a valid address"
              );
            }
          }

          let hasBlankItems = false;
          // let isUnderstocked = false;
          // let overbookedItem = "";
          let reduceStock = [];
          selectedItems.items.forEach((item) => {
            if (item.rowType === ROW_TYPE_SUBTOTAL) return;
            const exists = reduceStock.find((i) => i.id === item.id);
            const locateInPrevious =
              isEdit &&
              previousSavedInvoice.selectedItems.items.find(
                (i) => i.id === item.id
              );
            if (exists) {
              const obj = {
                id: exists.id,
                qty:
                  isEdit && locateInPrevious
                    ? exists.qty +
                    item.selectedQty -
                    locateInPrevious.selectedQty
                    : exists.qty + item.selectedQty,
              };
              reduceStock = [...reduceStock.filter((i) => i.id !== item.id)];
              reduceStock.push(obj);
            } else {
              reduceStock.push({
                id: item.id,
                qty:
                  isEdit && locateInPrevious
                    ? item.selectedQty - locateInPrevious.selectedQty
                    : item.selectedQty,
              });
            }
            reduceStock.map((i) => {
              const invItem = inventory.find((inv) => inv.id === i.id);
              if (invItem) {
                // console.log(invItem.availableStock, i.qty);
                // if (
                //   invItem.availableStock !== null &&
                //   invItem.availableStock < i.qty
                // ) {
                //   isUnderstocked = true;
                //   overbookedItem = invItem.name;
                // }
              } else {
                console.log(
                  "Could not locate all selected Items. Item not found: ",
                  i
                );
              }
            });
            if (
              (item.id && item.selectedRateMeta) ||
              item.rowType === ROW_TYPE_SUBTOTAL
            )
              return;
            hasBlankItems = true;
          });
          if (hasBlankItems)
            return notificationWarning(
              "Unable to save",
              "Invoice has incomplete or empty items"
            );
          if (showOverbookedError) {

            Modal.confirm({
              title: "Available limit exceeded",
              content: "One or more items are overbooked",
              okText: isEdit ? `Save ${type}` : `Create ${type}`,
              cancelText: "Cancel",
              onOk: () => {
                res({ type: type, isEdit: isEdit, hasError: false });
              },
              onCancel: () => { },
            });
          } else {
            res({ type: type, isEdit: isEdit, hasError: false });
          }
        })
        .catch((err) => {
          console.log(err);
          res({ type: null, isEdit: null, hasError: true });
        });
    });
  }
};
