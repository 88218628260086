import { getGeocode } from "use-places-autocomplete";

export const Geocoder = async (address) => {
  const res = await getGeocode({
    address: address,
  });
  const data = res?.[0]?.geometry?.location;
  return {
    lat: data?.lat(),
    lng: data?.lng(),
  };
};
