import { LoadingButton } from '@mui/lab';
import { alpha, Box, Button, Card, Checkbox, Dialog, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import { FormatToLocalCurrency } from '../../../app/utils/currency/formatToLocalCurrency';
import { OrganizationData } from '../../../app/interfaces/organizationData';
import { FullReceiveReturnDetailsProps } from '../types/ReceiveReturnDetails';

interface ShippingCalculatorProps {
    
    showDeliveryFeeCalculator: boolean;
    setShowDeliveryFeeCalculator: (value: boolean) => void;
    orgData: OrganizationData;
    receiveReturnDetails?: FullReceiveReturnDetailsProps;
    getShippingFee: any;
    setDeliveryFee: (fee: number) => void;
    invoiceChangeResult?: {
      status: number;
      msg: string;
    };
    responsiveFontSize: number;
    receiveFeeCalculated: { cost: number; distance: number } | null;
    setReceiveFeeCalculated: (value: { cost: number; distance: number } | null) => void;
    returnFeeCalculated: { cost: number; distance: number } | null;
    setReturnFeeCalculated: (value: { cost: number; distance: number } | null) => void;
    loadingReceiveFee: boolean;
    setLoadingReceiveFee: (value: boolean) => void;
    loadingReturnFee: boolean;
    setLoadingReturnFee: (value: boolean) => void;
    applyRoundTrip: boolean;
    setApplyRoundTrip: (value: boolean) => void;
  }
  

  const ShippingCalculator: React.FC<ShippingCalculatorProps> = ({
    showDeliveryFeeCalculator,
    setShowDeliveryFeeCalculator,
    orgData,
    receiveReturnDetails,
    getShippingFee,
    setDeliveryFee,
    invoiceChangeResult,
    responsiveFontSize,
    receiveFeeCalculated,
    setReceiveFeeCalculated,
    returnFeeCalculated,
    setReturnFeeCalculated,
    loadingReceiveFee,
    setLoadingReceiveFee,
    loadingReturnFee,
    setLoadingReturnFee,
    applyRoundTrip,
    setApplyRoundTrip,
  }) => {

  return (
    <Dialog
    open={showDeliveryFeeCalculator}
    onClose={() => {
      setShowDeliveryFeeCalculator(false);
    }}
  >
    <Card sx={{ p: 3 }}>
      <Stack gap={1} direction={"column"}>
        <Typography variant={"h6"} gutterBottom>
          Calculate Delivery Fee
        </Typography>

        <Typography gutterBottom>
          {orgData.baseRate ? `Base Delivery Fee: ${FormatToLocalCurrency(orgData.baseRate / 100, orgData)}` : "No delivery fee set"}
        </Typography>

        <Typography gutterBottom>
          {orgData.rangeRate ? `Range Rate: ${FormatToLocalCurrency(orgData.rangeRate / 100, orgData)} per mile | km` : "No delivery fee set"}
        </Typography>

        <Divider />

        {receiveReturnDetails?.receiveBy === "delivery" && (
          <Typography gutterBottom>{`Delivering To: ${receiveReturnDetails?.manualReceiveAddress}`}</Typography>
        )}

        {receiveReturnDetails?.returnBy === "onSite" && (
          <Typography gutterBottom>{`Picking Up At: ${receiveReturnDetails?.manualReturnAddress}`}</Typography>
        )}

        <Divider />

        {loadingReceiveFee && <Typography>Loading...</Typography>}

        {loadingReturnFee && <Typography>Loading...</Typography>}

        {receiveFeeCalculated && (
          <Typography>{`Delivery Fee: ${FormatToLocalCurrency(receiveFeeCalculated.cost / 100, orgData)} for ${Math.round(
            receiveFeeCalculated.distance
          )} miles`}</Typography>
        )}

        {returnFeeCalculated && (
          <Typography>{`Pick Up Fee: ${FormatToLocalCurrency(returnFeeCalculated.cost / 100, orgData)} for ${Math.round(
            returnFeeCalculated.distance
          )} miles`}</Typography>
        )}

        {returnFeeCalculated || receiveFeeCalculated ? (
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={applyRoundTrip}
                onChange={(e) => {
                  setApplyRoundTrip(e.target.checked);
                }}
              />
            }
            label={<Typography fontSize={responsiveFontSize}>Charge Round Trip</Typography>}
          />
        ) : null}

        {!receiveFeeCalculated && !returnFeeCalculated && (
          <LoadingButton
            variant="outlined"
            loading={loadingReceiveFee || loadingReturnFee}
            onClick={async () => {
              if (receiveReturnDetails?.receiveBy === "delivery") {
                const fee = await getShippingFee("delivery");
                console.log(fee);
              }
              if (receiveReturnDetails?.returnBy === "onSite") {
                const fee = await getShippingFee("onSite");
                console.log(fee);
              }
            }}
          >
            Calculate
          </LoadingButton>
        )}

        {(receiveFeeCalculated || returnFeeCalculated) && (
          <Button
            variant="outlined"
            onClick={() => {
              setDeliveryFee((((receiveFeeCalculated?.cost || 0) + (returnFeeCalculated?.cost || 0)) / 100) * (applyRoundTrip ? 2 : 1));
              setShowDeliveryFeeCalculator(false);
            }}
          >
            {`Apply Delivery Fee: ${FormatToLocalCurrency(
              (((receiveFeeCalculated?.cost || 0) + (returnFeeCalculated?.cost || 0)) / 100) * (applyRoundTrip ? 2 : 1),
              orgData
            )}`}
          </Button>
        )}

        <Box
          sx={{
            p: 2,
            width: "100%",
            borderRadius: 2,
            bgcolor: (theme) => alpha(theme.palette.warning.light, 0.25),
            border: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          <Typography sx={{ typography: { xs: "body2", xl: "body1" } }} variant="body1" gutterBottom>
            Delivery and Pickup Fees are calculated based on the distance between the warehouse and the delivery/pickup location. The base rate is the
            minimum fee charged for delivery. The range rate is the fee charged per mile or kilometer.
            <br />
            <br />
            These are one way calculations. If you want to calculate the fee for both ways, select the round trip checkbox to double the fee.
            <br />
            <br />
            Calculations will only be made for applicable methods: Delivery or On Site Pickup.
          </Typography>
        </Box>

        {invoiceChangeResult && (
          <Box
            sx={{
              mt: 2,
              p: 2,
              width: "100%", // Make the box fill horizontal space
              borderRadius: 2,
              bgcolor: (theme) =>
                invoiceChangeResult.status === 200 ? alpha(theme.palette.success.light, 0.25) : alpha(theme.palette.error.light, 0.25),
              border: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
          >
            <Typography sx={{ typography: { xs: "body2", xl: "body1" } }} variant="body1" gutterBottom>
              {invoiceChangeResult.msg}
            </Typography>
          </Box>
        )}
      </Stack>
    </Card>
  </Dialog>
  )
}

export default ShippingCalculator