import { useEffect, useState } from "react";
import InventoryCompletedTracker from "./InventoryCompletedTracker";
import { Card, Space } from "antd";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import {
  COLOR_TEXT_GRAY_1,
} from "../../../app/ui/colorConstants";

const InventoryInvoiceGroup = ({
  invoiceId,
  itemList,
  invoice,
  refreshInvoiceData,
  invoices,
  ...props
}) => {

  const [details, setDetails] = useState({
    invoiceNumber: "",
    customer: "",
  });

  useEffect(() => {
    const info = itemList && itemList.find((i) => i.invoiceId === invoiceId);
    setDetails({
      invoiceNumber: info.invoiceNumber,
      customer: info.customer,
    });
  }, [invoiceId]);
  
  return (
    <div style={{ marginBottom: "24px" }}>
      <div
        style={{
          margin: "0 12px",
          color: COLOR_TEXT_GRAY_1,
        }}
      >
        <Space size={4}>
          From invoice:{" "}
          <TextBody
            text={`#${details.invoiceNumber}: ${details.customer}`}
            style={{
              fontWeight: "bold",
              color: COLOR_TEXT_GRAY_1,
            }}
          />
        </Space>
      </div>
      
      <Card
        size={"small"}
        style={{ margin: "6px 12px 12px 12px", borderColor: "transparent" }}
        bodyStyle={{ paddingBottom: 0 }}
      >
        {itemList && itemList.filter((i) => i.invoiceId === invoiceId)
            .map((item) => (
              <InventoryCompletedTracker
                item={item}
                invoice={invoice}
                refreshInvoiceData={refreshInvoiceData}
                {...props}
              />
            ))}
      </Card>
    </div>
  );
};

export default InventoryInvoiceGroup;
