import "../../../App.less";
import { Menu, Dropdown, Modal } from "antd";
import { BORDER_RADIUS } from "../../../app/ui/styleConstants";
import { COLOR_DARK_BG_0 } from "../../../app/ui/colorConstants";
import { useFirebase } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, logout, setLogoutState } from "../../auth/authSlice";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { ROUTE_LOGIN } from "../../../app/routes";
import { resetPasswordEmail } from "../../auth/resetPasswordEmail";
import notificationError from "../../../app/system-components/toasters/notificationError";

const { confirm } = Modal;
const ProfileDropdown = ({ history }) => {
  const { userData } = useSelector(authSelector);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const handleLogout = () => {
    Modal.confirm({
      title: "Logout?",
      icon: <></>,
      maskClosable: true,
      onOk: async () => {
        await dispatch(setLogoutState()).then(() => {
          dispatch(logout({ firebase }, history)).then(() => {
            history.push(ROUTE_LOGIN);
            window.location.href = window.location.href;
          });
        });
      },
      onCancel() { },
    });
  };
  const handleResetPassword = () => {
    if (!userData.email) return notificationError("No user email exists");
    confirm({
      title: "Reset Password?",
      icon: <></>,
      content: `This will send you an email (${userData.email}) with instructions to complete the reset password process.`,
      okText: "Reset Password",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, _reject) => {
          resetPasswordEmail(
            { firebase },
            userData.email,
            "Please check your email to complete your password reset"
          ).then(() => {
            resolve();
          });
        }).catch((err) => {
          notificationError("Something went wrong", "Please contact support");
          console.log("Password Reset Email error", err);
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const menu = (
    <Menu>
      <Menu.Item key={"reset_password"} onClick={handleResetPassword}>Reset Password</Menu.Item>
      <Menu.Item key={"logout"} danger onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger="click" placement="bottomLeft">
      <div
        style={{
          borderRadius: BORDER_RADIUS,
          background: COLOR_DARK_BG_0,
          padding: "0 8px",
          marginRight: "8px",
        }}
      >
        <TextBody
          style={{ color: "#FFF" }}
          text={`${userData?.email !== "" ? userData?.email : "Adelie user"}`}
        />
      </div>
    </Dropdown>
  );
};

export default ProfileDropdown;
