/**
 * Work in progress. Start using this to extract function / logic to pass in to global state.
 * Long term goal is to move all logic here instead of cluttering up components
 */
import { useState } from "react";

export function useInvoiceEditor() {
  const [taxSettings, setTaxSettings] = useState({
    customerIsTaxExempt: false,
  });

  const setTaxableSettings = (customer) => {
    const isExempt = customer?.isTaxExempt ?? false;
    setTaxSettings({
      ...taxSettings,
      customerIsTaxExempt: isExempt,
    });
  };
  return {
    state: { taxSettings },
    actions: { setTaxableSettings },
  };
}
