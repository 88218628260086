/**
 * If useTurnaround is selected, verify that
 * calculate turnaround of item as a date from invoice end
 * if calc'd date > invoice start, continue reduce, else skip
 * returns true if should still apply the reduction
 */
import { AddDaysToTimestamp } from "./addDaysToTimestamp";

export const DetermineApplyTurnaround = ({
  useTurnaround,
  invoice,
  item,
  queryRentalStartDate,
}) => {
  const invoiceEndDate = invoice.rentalDateEnd;
  const itemTurnaround = () => {
    if (!useTurnaround) return 0;
    return item.turnaround ? item.turnaround : 0;
  };
  const newCutoff = AddDaysToTimestamp(itemTurnaround, invoiceEndDate);
  return newCutoff >= queryRentalStartDate;
};
