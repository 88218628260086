import TextBody from "../../../app/system-components/typography/text/TextBody";
import { Input, Space, Switch } from "antd";

const { TextArea } = Input;
const ExpandedInvoiceItem = ({ item, selectedInvoice }) => {
  console.log(item, selectedInvoice);
  const handleIssueFlagged = (e) => {
    console.log(e);
    // firestore call + update
  };
  const renderChecked = () => {
    const isFlagged =
      selectedInvoice &&
      selectedInvoice.qrIssueItemIds.some((id) => id === item.id);
    console.log(isFlagged);
  };
  return (
    <div style={{ margin: "12px" }}>
      <TextBody text={"Notes:"} style={{ marginBottom: "8px" }} />
      <br />
      <TextArea />
      <br />
      <br />
      <Space>
        <Switch
          size={"small"}
          onChange={handleIssueFlagged}
          checked={renderChecked}
        />
        Flag Issue
      </Space>
      <br />
      <Input style={{ marginTop: "8px" }} />
    </div>
  );
};

export default ExpandedInvoiceItem;
