import React from "react";
import { List } from "antd";
import {
  renderOverbookedIcon,
  renderStatusIcon,
} from "../helpers/renderStatusIcon";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { COLOR_PURPLE_0 } from "../../../app/ui/colorConstants";
import { log } from "@craco/craco/lib/logger";

const InvoiceList = ({
  data,
  selectedInvoice,
  setSelectedInvoice,
  dailyAvailable,
}) => {
  return (
    <List
      style={{ height: "calc(74vh)", overflowY: "auto" }}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          onClick={() => setSelectedInvoice(item)}
          className={
            selectedInvoice && selectedInvoice.id === item.id
              ? "qr-selected-invoice"
              : "qr-invoice"
          }
          actions={[
            renderOverbookedIcon(item, dailyAvailable),
            renderStatusIcon(item.qrStatus),
          ]}
        >
          <List.Item.Meta
            title={
              <TextBody
                style={{
                  color:
                    selectedInvoice && selectedInvoice.id === item.id
                      ? COLOR_PURPLE_0
                      : null,
                }}
                text={`#${item.invoiceNumber} - ${item.customer.customerDisplayName}`}
              />
            }
            description={
              <TextBody
                style={{
                  color:
                    selectedInvoice && selectedInvoice.id === item.id
                      ? COLOR_PURPLE_0
                      : null,
                }}
                text={item.displayName}
              />
            }
          />
        </List.Item>
      )}
    />
  );
};

export default InvoiceList;
