import { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  Tooltip,
  Progress,
  List,
} from "antd";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { PictureFilled } from "@ant-design/icons";
import {
  COLOR_DARK_BG_0,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
  COLOR_YELLOW_0,
} from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import QuickAdd from "./components/QuickAdd";
import InvMetaDataList from "./components/InvMetaDataList";
import TagTypeWithTooltip from "./components/TagTypeWithTooltip";
import { i18n } from "../../i18n";
import { useAvailableInventoryPaginated } from "../../app/services/hooks/useAvailableInventoryPaginated";

const AvailableInventory = ({
  rentalDates,
  inventory,
  searchStr,
  cart,
  setCart,
  setEmptyState,
  selectedTab,
}) => {

  useEffect(() => {
    let unmounted = false;
    !unmounted && refreshInvoices();
    return () => {
      unmounted = true;
    };
  }, [rentalDates, searchStr, inventory]);

  const {
    availableInventory,
    fetchingInvoices,
    refreshInvoices,
    fullInventory,
  } = useAvailableInventoryPaginated({
    startEndOpts: { useTurnaround: true, ...rentalDates },
    filterInventoryIds: inventory?.map((i) => i.id),
    invoiceItems: [],
    ignoreInvoicesForAvailability: [],
  });

  const configurePercent = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    return i.stock ? Math.max(0, (availability / i.stock) * 100) : 0;
  };
  
  const configureSuccessPercent = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    const pendingStock = i.unavailableEstStock || 0;
    return i.stock ? Math.max(0, ((availability - pendingStock) / i.stock) * 100) : 0;
  };
  const handleEmptyState = () => {
    setEmptyState(!hasRentalDates());
    return (
      <TextBody
        style={{ color: COLOR_TEXT_GRAY_1 }}
        text={i18n("availabilityPage.selectDates")}
      />
    );
  };

  const hasRentalDates = () => {
    return (
      rentalDates.rentalDateStart !== null && rentalDates.rentalDateEnd !== null
    );
  };

  const progressBarTextColor = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    if (availability < 0) {
      return COLOR_RED_0;
    } else if (availability === 0) {
      return COLOR_YELLOW_0;
    } else {
      return COLOR_DARK_BG_0;
    }
  };

  const progressBarAvailabilityNumber = (i) => {
    // console.log("Item data:", i);

    // If there are no invoices or unavailable stock, use the total stock
    if (!i.unavailableStock) {
      // console.log(`${i.name} - Using total stock: ${i.stock}`);
      return i.stock;
    }
    
    if (i.type === "bundle" && i.bundleItems) {
      // Calculate bundle availability based on its own stock and assignments
      const bundleAvailability = i.stock - i.unavailableStock;
      
      // Calculate availability based on subcomponents
      const subComponentAvailabilities = i.bundleItems.map(subItem => {
        const subItemInInventory = fullInventory.find(invItem => invItem.id === subItem.bundleItemId);
        if (subItemInInventory) {
          const subItemAvailability = subItemInInventory.stock - subItemInInventory.unavailableStock;
          return Math.floor(subItemAvailability / subItem.bundleItemQty);
        }
        return 0;
      });
      
      // Find the limiting factor (minimum availability among bundle and subcomponents)
      let limitingAvailability = Math.min(bundleAvailability, ...subComponentAvailabilities);
      if (!i.autoCalculateStock) {
        limitingAvailability = bundleAvailability;
      }
      // console.log(`${i.name} - Bundle Availability: ${bundleAvailability}, Subcomponent Availabilities: ${subComponentAvailabilities}, Limiting Availability: ${limitingAvailability}`);
      
      return limitingAvailability;
    } else {
      const availability = i.stock - i.unavailableStock;
      // console.log(`${i.name} - Stock: ${i.stock}, Unavailable: ${i.unavailableStock}, Calculated Availability: ${availability}`);
      return availability;
    }
  };
  
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 217px)",
        overflowY: "auto",
      }}
    >
      {hasRentalDates() ? (
        <List
          locale={{ emptyText: fetchingInvoices || !availableInventory ? "Loading..." : "No items" }}
          itemLayout="horizontal"
          dataSource={availableInventory}
          renderItem={(i) => (
            <List.Item>
              <Card style={{ width: "100%" }} size="small" key={i.id}>
                <Row align="middle" gutter={12} justify={"space-between"}>
                  <Col style={{ marginRight: "24px" }}>
                    <Space>
                      <div>
                        {i.image ? (
                          <img
                            src={i.image.downloadURL}
                            style={{
                              height: "76px",
                              width: "76px",
                              objectFit: "contain",
                            }}
                            alt={i.image.fileName}
                            onError={(e) => {
                              e.target.onerror = null; // Prevents looping
                              e.target.style.display = "none"; // Hide the img element
                              e.target.nextSibling.style.display = "inline-block"; // Show the PictureFilled
                            }}
                          />
                        ) : null}
                        <PictureFilled
                          style={{
                            color: COLOR_TEXT_GRAY_2,
                            fontSize: "76px",
                            display: i.image ? "none" : "inline-block",
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Space>
                          <TextBody style={{ fontSize: "16px", fontWeight: "bold" }} text={i.name} />
                          <TextSubtitle style={{ fontSize: "14px" }} text={i.description} />
                          <TagTypeWithTooltip data={i} availableInventory={fullInventory} />
                        </Space>
                        <div style={{ width: "200px", marginTop: "4px" }}>
                          {i.stock ? (
                            <Tooltip
                              title={`Available: ${progressBarAvailabilityNumber(i)} | Pending: ${i.unavailableEstStock} | Total: ${i.stock ? i.stock : "NA"}`}
                            >
                              <Progress
                                strokeColor={"#BAE7CB"}
                                format={(p) => (
                                  <TextSubtitle
                                    text={`${Math.abs(progressBarAvailabilityNumber(i))} ${progressBarAvailabilityNumber(i) < 0 ? "Overbooked" : "Left"}`}
                                    color={progressBarTextColor(i)}
                                  />
                                )}
                                percent={configurePercent(i)}
                                success={{
                                  percent: configureSuccessPercent(i),
                                  strokeColor: "#38BB69",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <TextSubtitle text={`No stock info available`} />
                          )}
                        </div>
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    <QuickAdd itemMax={i.availableStock} setCart={setCart} cart={cart} itemId={i.id} item={i} />
                  </Col>
                </Row>
                {i.invoices && <InvMetaDataList key={i.selectedItemId} invoices={i.invoices} itemId={i.id} />}
              </Card>
            </List.Item>
          )}
        />
      ) : (
        handleEmptyState()
      )}
    </div>
  );
};

export default AvailableInventory;

export const useAvailableInventoryDbWrapper = () => {};
