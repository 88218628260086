import React, { useEffect, useState } from "react";
import { Alert, Tooltip, Row, Col } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { COLOR_LIGHT_BLUE } from "../../../app/ui/colorConstants";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { ROUTE_EDIT_INVOICE } from "../../../app/routes";

const InvMetaDataAlert = ({ invoice, itemId }) => {

  const [data, setData] = useState({
    qty: 0,
    eventDate: null,
    rentalDateStart: null,
    rentalDateStartTime: "11:59 PM",
    rentalDateEnd: null,
    rentalDateEndTime: "11:59 PM",
  });

  useEffect(() => {
    // configure items and dates
    if (!invoice || !itemId) return;

    let qty = 0;
    const { qrItems } = invoice;
    if (!qrItems) return;
    qrItems
      .filter((i) => i.id === itemId)
      .map((item) => {
        const amt = item.qtyRented ? item.qtyRented : 0;
        qty = qty + amt;
      });
      
    setData({
      qty: qty,
      // eventDate: data.eventDate ? moment(invoice.eventDate.seconds * 1000).format("MMM Do YYYY") : null,
      eventDate: invoice.eventDate ? moment(invoice.eventDate.seconds * 1000).format("MMM Do YYYY") : null,

      rentalDateStart: moment(invoice.rentalDateStart.seconds * 1000).format(
        "MMMM Do YYYY"
      ),
      rentalDateStartTime: moment(
        invoice.rentalDateStart.seconds * 1000
      ).format("h:mm A"),
      rentalDateEnd: moment(invoice.rentalDateEnd.seconds * 1000).format(
        "MMMM Do YYYY"
      ),
      rentalDateEndTime: moment(invoice.rentalDateEnd.seconds * 1000).format(
        "h:mm A"
      ),
    });
  }, [invoice]);

  return (
    <div>
      {data && data.qty !== 0 && (
        <Alert
          key={invoice && invoice.id}
          style={{
            padding: "0 8px  ",
            backgroundColor: COLOR_LIGHT_BLUE,
            borderRadius: "4px",
            marginTop: "8px",
          }}
          icon={<></>}
          banner
          description={
            data.qty &&
            invoice && (
              <Row>
                <Col span={6}>
                  {
                    <span style={{ fontWeight: "bold" }}>
                      <NumberFormat
                        value={data.qty}
                        displayType={"text"}
                        thousandSeparator
                      />
                    </span>
                  }{" "}
                  assigned to{" "}
                  <Tooltip title={"Open in new tab"} placement={"right"}>
                    <Link
                      to={`${ROUTE_EDIT_INVOICE}${invoice.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {invoice.customer && invoice.customer.customerDisplayName}{" "}
                      (#{invoice.invoiceNumber})
                    </Link>
                  </Tooltip>{" "}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>{"Event Date: "}</span>{" "}
                  {data.eventDate || '--'}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>{"Leaving: "}</span>
                  {data.rentalDateStart} at {data.rentalDateStartTime}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>{"Returning: "}</span>
                  {data.rentalDateEnd} at {data.rentalDateEndTime}
                </Col>
              </Row>
            )
          }
        />
      )}
    </div>
  );
};

export default InvMetaDataAlert;
