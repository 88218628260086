import React, { useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import { configureCategory } from "../../../app/utils/models/configure/configureCategory";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { categoryWrites } from "../../../app/services/firestore/writes/categoryWrites";
import { useFirestore } from "react-redux-firebase";

const CreateCategoryModal = ({ visible, onCancel, onComplete }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);

  const firestore = useFirestore();

  const handleFinish = () => {
    form.validateFields().then(() => {
      if (!userData || !orgData)
        return notificationError("Something went wrong", "");
      setSaving(true);
      const values = form.getFieldsValue();
      const payload = configureCategory(values, userData.id, orgData.id);
      categoryWrites({ firestore }, fsOrgPrefix)
        .createCategory(payload)
        .then(() => {
          setSaving(false);
          form.resetFields();
          onComplete();
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    });
  };

  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose
      width={400}
      open={visible}
      closeIcon={<></>}
      title="New Category"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
        >
          Create Category
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          style={{ marginBottom: "12px" }}
          rules={[
            {
              required: true,
              message: "Name required",
            },
          ]}
        >
          <Input placeholder="Category name" />
        </Form.Item>
        <Form.Item
          name="description"
          style={{ marginBottom: "0px" }}
          rules={[
            {
              required: false,
              message: "Description required",
            },
          ]}
        >
          <Input placeholder="Category description" maxLength={80} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCategoryModal;
