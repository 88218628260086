import React from "react";
import { DatePicker, Form, Space } from "antd";
import { i18n } from "../../../i18n";

const EventDateField = ({ isSalesInvoice }) => {
  return (
    <div style={{ marginBottom: "-6px" }}>
      <Space>
        {isSalesInvoice ? 'Date:' : `${i18n('invoices.eventDate')}:`}{" "}
        <Form.Item name={"eventDate"} style={{ margin: "6px 0" }}>
          <DatePicker
            placeholder={i18n('invoices.eventDate')}
            style={{ width: "150px" }}
            format={(d) => d.format("MMM Do YYYY")}
          />
        </Form.Item>
      </Space>
    </div>
  );
};

export default EventDateField;
