import notificationError from "../../../system-components/toasters/notificationError";

export async function fsMultiArrayQuery({ firestore }, idArray, fn, checker) {
  return new Promise((resolve, reject) => {
    if (idArray.length === 0) return;

    let queryGroups = [];
    const groups = idArray.length <= 10 ? 1 : Math.ceil(idArray.length / 10);

    for (var i = 1; i < groups + 1; i++) {
      const indexRange = (arr, start, end) => {
        return arr.slice(start, end);
      };
      const startArr = (i) => {
        if (i === 1) return 0;
        return i * 10 - 10;
      };
      let groupArr = indexRange(idArray, startArr(i), i * 10);
      queryGroups.push(groupArr);
    }

    const functionPromise = (arr) => {
      return new Promise(async (resolve, reject) => {
        let list = [];
        const listSnap = await fn({ firestore }, arr);
        listSnap.forEach((s) => {
          list.push(checker(s));
        });
        resolve(list);
      });
    };

    let promiseQueries = queryGroups.map((g) => functionPromise(g));
    Promise.all(promiseQueries)
      .then((res) => {
        let promiseResponse = [].concat.apply([], res);
        resolve(promiseResponse);
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "");
      });
  });
}
