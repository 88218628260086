import { getRowTotal } from "../../../helpers/getRowTotal";

/**
 * Takes selected items and duration, outputs updated selected items with row total and duration
 * @constructor
 */
export const UpdateDurationRowTotal = (selectedItems, duration) => {
  return selectedItems.items.map((i) => {
    const { selectedDuration, ...rest } = i;
    const rangeType = i.selectedRateMeta ? i.selectedRateMeta.rateRange : null;
    if (rangeType) {
      const newItem = {
        selectedDuration: duration,
        ...rest,
      };
      return {
        ...newItem,
        rowTotal: getRowTotal(newItem).newRowTotal,
      };
    } else {
      return i;
    }
  });
};
