import {
  PAYMENT_COLLECTION,
} from "../../../utils/models/collections/collectionConstants";
import { CheckPayment } from "../../../utils/models/checkers/checkPayment";

export const PaymentQueries = (orgTimezone) => {
  async function fetchPaymentsByCustomer({ firestore }, orgPrefix, customerId) {
    const ref = firestore
      .collection(`${orgPrefix}${PAYMENT_COLLECTION}`)
      .where("customerQueryHook", "==", customerId)
      .orderBy("paymentDate", "desc");
    let data = [];
    const snap = await ref.get();
    snap.forEach((s) => {
      data.push(CheckPayment(s, orgTimezone));
    });
    return data;
  }
  async function fetchPaymentsByInvoiceId({ firestore }, orgPrefix, invoiceId) {
    const ref = firestore
      .collection(`${orgPrefix}${PAYMENT_COLLECTION}`)
      .where("invoicesQueryHook", "array-contains", invoiceId)
      .orderBy("paymentDate", "desc");
    let data = [];
    const snap = await ref.get();
    snap.forEach((s) => {
      data.push(CheckPayment(s, orgTimezone));
    });
    return data;
  }
  return {
    fetchPaymentsByCustomer,
    fetchPaymentsByInvoiceId,
  };
};
