import React from 'react';
import { Image } from 'antd';
import { Media, Message } from '../../../app/interfaces/invoice';

interface MessageItemProps {
  message: Message;
}

const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  return (
    <li style={{ listStyleType: 'none', paddingBottom: '10px' }}>
      <span style={{ fontSize: '10px' }}>{message.sentByName}</span>
      <br />
      <div
        style={{
          backgroundColor: '#ECECEC',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        {message.body}

        {message.media && message.media.length > 0 && (
          <div>
            {message.media.map((media: Media, index: number) => (
              <Image key={index} src={media.mediaUrl} alt='Media' width={200} style={{ marginTop: '10px', marginBottom: '10px' }} />
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

export default MessageItem;
