import { useEffect, useState } from "react";
import { Button, Col, Input, List, Popconfirm, Row, Space } from "antd";
import { useFirestore } from "react-redux-firebase";
import _ from 'lodash';

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { DeleteOutlined } from "@ant-design/icons";
import { firelikeId } from "../../../../app/utils/models/checkers/firelikeid";
import { authSelector } from "../../../auth/authSlice";
import { useSelector } from "react-redux";

const AdditionalContacts = ({ customer, updateCustomer }) => {
  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [editingContactId, setEditingContactId] = useState(null);
  const [editingName, setEditingName] = useState("");
  const [editingEmail, setEditingEmail] = useState("");
  const [editingPhone, setEditingPhone] = useState("");


  const [contacts, setContacts] = useState([]);

  const editContact = (contact) => {
    setEditingContactId(contact.id);
    setEditingName(contact.name);
    setEditingEmail(contact.email);
    setEditingPhone(contact.phone);
  };

  const saveContact = async () => {
    const updatedContacts = contacts.map((contact) =>
      contact.id === editingContactId
        ? { ...contact, name: editingName, email: editingEmail, phone: editingPhone }
        : contact
    );
    setContacts(updatedContacts);
    const clone = _.cloneDeep(customer);
    clone.additionalContacts = updatedContacts;
    updateCustomer(clone);
  
    await firestore.collection(`orgs/${orgData.id}/customers/`).doc(customer.id).update({ additionalContacts: updatedContacts });
  
    // Reset editing state
    setEditingContactId(null);
    setEditingName("");
    setEditingEmail("");
    setEditingPhone("");
  };

  useEffect(() => {
    setContacts(customer.additionalContacts);
  }, []);

  const addContact = async () => {
    const newContact = {
      name,
      email,
      phone,
      id: firelikeId()
    };
    const newContacts = [...contacts, newContact]
    setContacts(newContacts);
    const clone = _.cloneDeep(customer);
    clone.additionalContacts = newContacts
    updateCustomer(clone)

    setEmail("");
    setName("")
    setPhone("")

    await firestore.collection(`orgs/${orgData.id}/customers/`).doc(customer.id).update({ additionalContacts: newContacts });

  };

  const deleteContact = async (contact) => {
    const newContacts = contacts.filter((c) => c.email !== contact.email)
    setContacts(newContacts);
    const clone = _.cloneDeep(customer);
    clone.additionalContacts = newContacts;
    await firestore.collection(`orgs/${orgData.id}/customers/`).doc(customer.id).update({ additionalContacts: newContacts });
    updateCustomer(clone)
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"ADD CONTACT"} />
          </div>
          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                value={name}
                placeholder="contact name"
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                value={email}
                placeholder="contact email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Input
                value={phone}
                placeholder="contact phone"
                onChange={(e) => setPhone(e.target.value)}
              />

              <Button type="primary" onClick={addContact}>
                Add
              </Button>
            </Space>
            <br />
            <br />
          </div>
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"ADDITIONAL CONTACTS"} />
          </div>
          <div>
            {contacts.length > 0 && (
              <List
                bordered
                dataSource={contacts}
                size="small"
                renderItem={(item) => (
                  item.id === editingContactId ? (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={saveContact}>Save</Button>,
                      ]}
                    >
                      <Col>
                        <Space size={2} direction="vertical">
                          <Input value={editingName} onChange={(e) => setEditingName(e.target.value)} />
                          <Input value={editingEmail} onChange={(e) => setEditingEmail(e.target.value)} />
                          <Input value={editingPhone} onChange={(e) => setEditingPhone(e.target.value)} />
                        </Space>
                      </Col>
                    </List.Item>
                  ) : (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => editContact(item)}>Edit</Button>,
                        <Popconfirm
                          title="Are you sure to delete this contact?"
                          onConfirm={() => deleteContact(item)}
                          onCancel={null}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined />
                        </Popconfirm>,
                      ]}
                    >
                      <Col>
                        <Space size={2} direction="vertical">
                          <span>Name: {item.name}</span>
                          <span>Email: {item.email}</span>
                          <span>Phone: {item.phone}</span>
                        </Space>
                      </Col>
                    </List.Item>
                  )
                )}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdditionalContacts;
