export const convertWebPToJpegAndBase64 = async (webpImageUrl) => {
    try {
      const response = await fetch(webpImageUrl);
      const blob = await response.blob();
  
      // Create a new HTML canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Create an image element to load the WebP image
      const image = new Image();
      image.src = URL.createObjectURL(blob);
  
      // Wait for the image to load
      await new Promise((resolve) => (image.onload = resolve));
  
      // Set the canvas dimensions to match the image
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Set the background to white
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw the WebP image onto the canvas
      ctx.drawImage(image, 0, 0);
  
      // Convert the canvas content to JPEG as base64 data URI
      const jpegBase64 = canvas.toDataURL('image/jpeg');
  
      return jpegBase64;
    } catch (error) {
      console.error('Error converting WebP to JPEG and Base64:', error);
      throw error;
    }
  };

  export const convertPngToJpeg = async (imageUrl) => {

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
  
      // Create a new HTML canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
  
      // Create an image element to load the PNG image
      const img = new Image();
      img.src = URL.createObjectURL(blob);
  
      // Wait for the image to load
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
  
      // Set the canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;
  
      // Set the background to white
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
  
      // Draw the image on top of the white background
      ctx.drawImage(img, 0, 0);
  
      // Convert the canvas content to JPEG as base64 data URI
      const jpegUrl = canvas.toDataURL('image/jpeg');
  
      return jpegUrl;
    } catch (error) {
      console.error('Error converting PNG to JPEG:', error);
      throw error;
    }
  };
  
  export const convertImageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to Base64:', error);
      throw error;
    }
  };
  