import { TimeMachine } from "../../../time/timeMachine";
import moment from "moment-timezone";

/**
 * Use to wrap around dates for queries.
 * takes dates and org offset and returns new dates
 */
export const invoiceUtcOffsetQuery = () => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const forDates = ({ startDate, endDate, orgTimezone }) => {
    const newStart = startDate ?? null;
    const newEnd = endDate ?? null;

    if (!newStart)
      return {
        start: null,
        end: null,
      };

    const newMomentStart = TimeMachine(newStart).moment;
    const newMomentEnd = TimeMachine(newEnd).moment;

    const orgOffset = moment.tz.zone(orgTimezone).utcOffset(newMomentStart);
    const localOffset = moment.tz.zone(localTimezone).utcOffset(newMomentStart);
    const offset = orgOffset - localOffset;

    const adjustedStart = TimeMachine(
      moment(newMomentStart).add(offset, "minutes")
    ).date;
    const adjustedEnd = TimeMachine(
      moment(newMomentEnd).add(offset, "minutes")
    ).date;

    return {
      start: adjustedStart,
      end: adjustedEnd,
    };
  };

  const forDate = ({ date, orgTimezone }) => {
    const newStart = date ?? null;

    if (!newStart) return null;

    const newMomentStart = TimeMachine(newStart).moment;

    const orgOffset = moment.tz.zone(orgTimezone).utcOffset(newMomentStart);
    const localOffset = moment.tz.zone(localTimezone).utcOffset(newMomentStart);
    const offset = orgOffset - localOffset;

    return TimeMachine(moment(newMomentStart).add(offset, "minutes")).date;
  };

  return { forDate, forDates };
};
