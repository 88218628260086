import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Space,
  Tooltip,
  Spin,
  List,
  Tag,
  Typography,
} from "antd";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { COLOR_GREEN_0, COLOR_RED_0 } from "../../../app/ui/colorConstants";

import { COLOR_LIGHT_GRAY } from "../../../app/ui/colorConstants";
import { centsToDollars } from "../../../app/utils/models/checkers/checkCurrencyToDollars";
import NumberFormat from "react-number-format";
import { useFirebase } from "react-redux-firebase";
import { CheckPayment } from "../../../app/utils/models/checkers/checkPayment";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";

const SyncQuickBooksModal = ({
  visible,
  onCancel,
  onComplete,
  invoice,
  orgData,
  handleComplete,
}) => {
  const firebase = useFirebase();

  const [saving, setSaving] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      firebase
        .firestore()
        .collection("orgs")
        .doc(orgData.id)
        .collection("payments")
        .where("invoicesQueryHook", "array-contains", invoice.id)
        .orderBy("paymentDate", "desc")
        .get()
        .then((snapshot) => {
          let data = [];
          snapshot.docs.forEach((s) => {
            data.push(CheckPayment(s, orgData.orgTimezone));
          });
          setPayments(data);
        });
    };

    fetchData();
  }, []); // dont remove dependency array.

  const handleFinish = async () => {
    const response = await handleComplete({
      type: invoice.type,
      redirect: null,
    });

    setSaving(true);

    const call = firebase.functions().httpsCallable("manualInvoiceSync");

    return call({
      orgId: orgData.id,
      invoiceId: invoice.id,
    })
      .then(async (res) => {
        setSaving(false);
        onComplete(true);
      })
      .catch((err) => {
        console.log(err);
        onComplete(false);
        setSaving(false);
      });
  };

  const syncStatusIcon = (status) => {
    if (Object.is(status, undefined) || Object.is(status, null)) {
      return "N/A";
    }
    if (status) {
      return <CheckCircleFilled style={{ color: COLOR_GREEN_0 }} />;
    } else {
      return <CloseCircleFilled style={{ color: COLOR_RED_0 }} />;
    }
  };

  return (
    <Modal
      destroyOnClose
      width={500}
      open={visible}
      closeIcon={<></>}
      title={
        <Tooltip title="Review the transactions below and verify they either do not match or do not exist in QuickBooks. Then, click the sync button below to update/sync all transactions to QuickBooks Online">
          QuickBooks Sync Status
        </Tooltip>
      }
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          style={{ backgroundColor: "green", border: "1px solid green" }}
          type="primary"
          onClick={() => handleFinish()}
          disabled={0}
          loading={saving}
        >
          Sync Transactions
        </Button>,
      ]}
    >
      <Spin spinning={saving}>
        <div>
          <Row>
            <Col>
              <div>
                <strong>Invoice #:</strong> {invoice.invoiceNumber} | Synced QB
                <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                  Invoice #:
                </span>
                {invoice.qboInvoiceNumber}
                <br />
                {`Last Sync: ${invoice.lastSyncDate
                  ?.toDate()
                  .toLocaleString()}`}
                <br />
                <span style={{ marginRight: "5px" }}>Last Sync Success:</span>
                {syncStatusIcon(invoice.lastSyncSuccess)}
              </div>
              {payments && (
                <List
                  locale={{ emptyText: "No payments" }}
                  size="small"
                  bordered={false}
                  dataSource={payments}
                  renderItem={(item) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={
                          <Space>
                            <strong>Payment: </strong>
                            <NumberFormat
                              style={{ fontWeight: "bold" }}
                              value={item?.total}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />

                            <Tag
                              style={{
                                borderColor: "transparent",
                                backgroundColor: COLOR_LIGHT_GRAY,
                              }}
                            >
                              {capitalizeFirstLetter(item?.paymentMethod ?? "")}
                            </Tag>
                          </Space>
                        }
                        description={
                          <Space direction={"vertical"} size={0}>
                            <div>
                              <Space>
                                Last Sync:{" "}
                                {item.lastSyncDate?.toDate().toLocaleString() ??
                                  "N/A"}
                              </Space>
                              <br />
                              <Space>
                                Last Sync Success:{" "}
                                {syncStatusIcon(item.lastSyncSuccess)}
                              </Space>
                            </div>

                            {item.refunds && item.refunds.length > 0 && (
                              <div
                                style={{ float: "right", marginLeft: "10px" }}
                              >
                                <br />
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  Refunds
                                </Typography.Title>

                                {item.refunds.map((refund, index) => {
                                  return (
                                    <div
                                      style={{
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.05)",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      Amount
                                      <NumberFormat
                                        style={{
                                          fontWeight: "bold",
                                          marginLeft: "5px",
                                        }}
                                        value={centsToDollars(
                                          refund.amount
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                      <br />
                                      {`Last Sync: ${refund.lastSyncDate
                                        ?.toDate()
                                        .toLocaleString() ?? "N/A"
                                        }`}
                                      <br />
                                      Last Sync Success:{" "}
                                      {syncStatusIcon(invoice.lastSyncSuccess)}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Space>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </Modal>
  );
};

export default SyncQuickBooksModal;
