import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../../../app/utils/models/modelConstants/modelConstants";

export const ConfigureStopTooltipTypeLabel = (type) => {
  if (!type) return "Unknown";
  if (type === RETURN_METHOD_ONSITE) return "Pick Up";
  if (type === RECEIVE_METHOD_DELIVERY) return "Delivery";
  if (type === STOP_TYPE_WAYPOINT) return "Waypoint";
  return "Unknown";
};
