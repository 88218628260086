import { Divider, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import Iconify from "../../../app/iconify";
import { keyframes } from "@mui/system";

type Props = {
  isSalesInvoice: boolean;
  showShortcuts: boolean;
  systemOS: string;
  handleAdd: any;
  noItems: boolean;
};

const radarAnimation = keyframes`
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const LineItemsHeader = ({handleAdd, isSalesInvoice, showShortcuts, systemOS, noItems}: Props) => {

  const responsiveFontSize = useResponsiveFontSize();
  const theme = useTheme();

  return (
    <>
      <Grid
        sx={{
          p: 1,
          backgroundColor: theme.palette.grey[200],
          borderRadius: 1,
        }}
        container
        // spacing={{ xs: 2, lg: 1, xl: 2 }}
        alignItems="center"
      >
        <Grid item lg={3}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Tooltip title={`Add Item${showShortcuts ? ` - ${systemOS === "mac" ? "⌘ A" : "ctrl A"}` : ""}`}>
              <IconButton
                onClick={handleAdd}
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 255, 0.3)", 
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%) scale(1)",
                    opacity: noItems ? 1 : 0, 
                    animation: noItems ? `${radarAnimation} 1.5s infinite ease-out` : "none",
                  },
                }}
              >
                <Iconify icon={"octicon:feed-plus-16"} color={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
            <Typography fontSize={responsiveFontSize}>Item Name</Typography>
          </Stack>
        </Grid>
        <Grid item lg={3.5} xl={4}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }}>
            Description
          </Typography>
        </Grid>
        <Grid item lg={1}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }}>
            Quantity
          </Typography>
        </Grid>
        <Grid item lg={1.5} xl={1}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }}>
            Rate
          </Typography>
        </Grid>

        <Grid item lg={1}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }} color={isSalesInvoice ? theme.palette.text.secondary : theme.palette.text.primary}>
            Duration
          </Typography>
        </Grid>
        <Grid item lg={1}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }}>
            Taxable
          </Typography>
        </Grid>
        <Grid item lg={1}>
          <Typography fontSize={responsiveFontSize} sx={{ pl: 2 }}>
            Total
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2, mt: 1, borderStyle: "dashed" }} />
    </>
  );
};

export default LineItemsHeader;
