import { CATEGORIES_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import notificationError from "../../../system-components/toasters/notificationError";
import { checkCategory } from "../../../utils/models/checkers/checkCategory";

export function categoryQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${CATEGORIES_COLLECTION}`);

  async function fetchCategories() {
    const snap = await ref.get();
    let categories = [];
    snap.forEach((s) => {
      categories.push(checkCategory(s));
    });
    return categories;
  }
  return {
    fetchCategories: fetchCategories, // fetch categories
  };
}
