import {
  QR_STATUS_COMPLETE,
  QR_STATUS_HAS_ISSUE,
  QR_STATUS_IN_PROGRESS,
} from "../../../../utils/models/modelConstants/modelConstants";
export const DetermineInvoiceStatus = (qrItems) => {
  let hasIssues = false;
  let totalCompleted = 0;
  let totalRented = 0;
  qrItems &&
    qrItems.map((i) => {
      if (i.hasIssue) {
        hasIssues = true;
      }
      totalCompleted = i.completed + totalCompleted;
      totalRented = i.qtyRented + totalRented;
    });
  const isComplete =
    totalCompleted === totalRented && totalCompleted + totalRented !== 0;
  if (isComplete) {
    return QR_STATUS_COMPLETE;
  } else if (hasIssues) {
    return QR_STATUS_HAS_ISSUE;
  }
  return QR_STATUS_IN_PROGRESS;
};
