export const getRowTotal = (item) => {
  const rate = item.selectedRateMeta && item.selectedRateMeta.rate;
  const duration = item.selectedDuration;
  const qty = item.selectedQty;
  if (item.type && item.type === "sale") {
    return {
      newRowTotal: rate * qty,
      hasValidatedFields: true,
    };
  }
  const hasReqFields = !!(rate && duration && qty);
  if (hasReqFields === false) {
    return {
      newRowTotal: 0,
      hasValidatedFields: false,
    };
  }
  return {
    newRowTotal: rate * duration * qty,
    hasValidatedFields: true,
  };
};
