import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import { routeColors } from "../../helpers/routeColors";
import { CheckOutlined } from "@ant-design/icons";

const SelectRouteColor = ({ route, form }) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    route && setSelected(route?.color);
  }, [route]);
  const color = route?.color;

  return (
    <Space direction={"horizontal"}>
      {routeColors.map((color) => {
        return (
          <Button
            onClick={() => {
              setSelected(color);
              form.setFieldsValue({ color: color });
            }}
            size={"small"}
            shape={"circle"}
            style={{
              backgroundColor: color,
              borderColor: color,
              opacity: selected === color ? 1 : 0.25,
            }}
          >
            {selected === color ? (
              <CheckOutlined style={{ color: "#FFF", fontSize: "12px" }} />
            ) : (
              " "
            )}
          </Button>
        );
      })}
    </Space>
  );
};

export default SelectRouteColor;
