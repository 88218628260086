import notificationError from "../../../system-components/toasters/notificationError";
import { dollarToCents } from "./configureCurrencyToCents";
import onlyUnique from "../../filters/filterOnlyUnique";
import { checkUndefinedOrNull } from "../checkers/checkUndefined";
import { INV_BUNDLE_ITEM } from "../modelConstants/modelConstants";
import {
  InventoryItem,
  InventoryItemFormData,
} from "../../../interfaces/inventoryItem";

export const configureInventory = () => {
  const convertRates = (
    rates: {
      rate: number;
      rateName: string;
      rateRange: string;
      id: string;
    }[]
  ) => {
    if (typeof rates === "undefined") return [];
    if (rates.length === 0) return [];
    return rates.map((r) => {
      const { rate, ...rest } = r;
      return {
        rate: dollarToCents(rate),
        ...rest,
      };
    });
  };

  const determineInheritTurnaround = (
    type: string | undefined,
    turnaround: any
  ) => {
    const t = checkUndefinedOrNull(type, "rental");
    const turn = checkUndefinedOrNull(turnaround, 0);
    return t === INV_BUNDLE_ITEM && turn !== 0;
  };

  function newInventoryItem(
    values: Partial<InventoryItemFormData>,
    createdById: string,
    orgData: any
  ) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not create item", "");

      const hasMaintenanceDetails = values.hasMaintenanceDetails ?? false
      let maintenanceDetails = null;
      if (hasMaintenanceDetails) {
        maintenanceDetails = {
          activeHours: values.activeHours ? values.activeHours : 0,
          hoursBetweenServices: values.hoursBetweenServices ? values.hoursBetweenServices : 0,
          timeBetweenServices: null,
          maintenanceNotes: null,
        }
      }

      const type = values.type ? values.type : "rental";

    const newItem: Partial<InventoryItem> = {
      orgId: orgData.id,
      type: type,
      autoCalculateStock: values.autoCalculateStock || true,
      name: values.name?.trim() ?? "",
      description: values.description ?? "",
      rtfDescription: values.rtfDescription ? values.rtfDescription :  "",
      stock: values.stock ? values.stock : null,
      turnaround: values.turnaround ? values.turnaround : null,
      inheritParentTurnaround: determineInheritTurnaround(
        values.type,
        values.turnaround
      ),
      categories: values.categories ? values.categories : [],
      categoriesQueryHook: values.categoriesQueryHook
        ? values.categoriesQueryHook
        : [],
      rates: convertRates(values.rates ?? []),
      showInShop: values.showInShop || null,
      embeddedShopRateId: values.embeddedShopRateId || null,
      embeddedShopRateWeekId: values.embeddedShopRateWeekId || null,
      embeddedShopRateMonthId: values.embeddedShopRateMonthId || null,
      startingProfit: values.startingProfit ? values.startingProfit : null,
      weight: values.weight ? values.weight : null,
      depreciation: {
        initialValue: values.initialValue ? values.initialValue : null,
        initialDate: values.initialDate ? values.initialDate : null,
        depreciationYears: values.depreciationYears
          ? values.depreciationYears
          : null,
      },
      notes: values.notes ? values.notes : "",
      informationURL: values.informationURL ? values.informationURL : null,
      image: values.image ? values.image : null,
      isAvailable: values.isAvailable ?? false,
      isActive: values.isActive ?? false,
      isTaxable: values.isTaxable ?? false,
      hasMaintenanceDetails,
      maintenanceDetails,
      bundleItems: values.bundleItems ? values.bundleItems : [],
      bundleItemsQueryHook: values.bundleItemsQueryHook
        ? values.bundleItemsQueryHook.filter(onlyUnique)
        : [],
      createdOn: new Date(),
      createdBy: createdById,
      updatedOn: new Date(),
      updatedBy: createdById,
      updateSource: "adelie",
      createdOnPlatform: "adelie",
      syncToken: null,
      qbId: null,
    };

    return newItem;
  }
  function editInventoryItem(
    values: InventoryItemFormData,
    editedById: string,
    orgData: any
  ): Partial<InventoryItem> | undefined {
    if (!values || !editedById || !orgData) {
      notificationError("Could not edit item", ""); // could we throw an exception here?
      return undefined;
    }

    const hasMaintenanceDetails = values.hasMaintenanceDetails ?? false
    let maintenanceDetails = null;
    if (hasMaintenanceDetails) {
      maintenanceDetails = {
        activeHours: values.activeHours ? values.activeHours : 0,
        hoursBetweenServices: values.hoursBetweenServices ? values.hoursBetweenServices : 0,
        timeBetweenServices: null,
        maintenanceNotes: null,
      }
    }

    const autoCalculateStock = values.autoCalculateStock ?? true;

    const result: Partial<InventoryItem> = {
      orgId: orgData.id,
      type: values.type ? values.type : "rental",
      name: values.name.trim(),
      description: values.description,
      rtfDescription: values.rtfDescription ? values.rtfDescription : " ",
      stock: values.stock ? values.stock : null,
      turnaround: values.turnaround ? values.turnaround : null,
      autoCalculateStock,
      categories: values.categories ? values.categories : [],
      categoriesQueryHook: values.categoriesQueryHook
        ? values.categoriesQueryHook
        : [], // for referencing categories
      rates: convertRates(values.rates),
      showInShop: values.showInShop || false,
      embeddedShopRateId: values.embeddedShopRateId || null,
      embeddedShopRateWeekId: values.embeddedShopRateWeekId || null,
      embeddedShopRateMonthId: values.embeddedShopRateMonthId || null,
      startingProfit: values.startingProfit ? values.startingProfit : null,
      weight: values.weight ? values.weight : null,
      depreciation: {
        initialValue: values.initialValue ? values.initialValue : null,
        initialDate: values.initialDate ? values.initialDate : null,
        depreciationYears: values.depreciationYears
          ? values.depreciationYears
          : null,
      },
      inheritParentTurnaround: determineInheritTurnaround(
        values.type,
        values.turnaround
      ),
      notes: values.notes ? values.notes : "",
      informationURL: values.informationURL ? values.informationURL : null,
      image: values.image ? values.image : null, // object with url and name
      isAvailable: values.isAvailable, // populates in searching available inventory
      isActive: values.isActive, // denotes temporary holds (ie servicing etc)
      isTaxable: values.isTaxable,
      hasMaintenanceDetails,
      maintenanceDetails,
      bundleItems: values.bundleItems ? values.bundleItems : [],
      bundleItemsQueryHook: values.bundleItemsQueryHook
        ? values.bundleItemsQueryHook.filter(onlyUnique)
        : [],
      updatedOn: new Date(),
      updatedBy: editedById,
      updateSource: "adelie",
    };
    return result;
  }

  function duplicateInventoryItem(
    values: any,
    createdById: string,
    orgData: any
  ) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not edit item", "");
    const {
      id,
      name,
      createdOn,
      createdBy,
      updatedOn,
      updatedBy,
      rates,

      /**
       * Remove from configuring
       */
      qbId,
      updateSource,
      createdOnPlatform,
      startingProfit,
      /**
       *
       */
      ...rest
    } = values;
    return {
      name: `${name.trim()} Copy`,
      createdOn: new Date(),
      createdBy: createdById,
      updatedOn: new Date(),
      updatedBy: createdById,
      rates: convertRates(rates),
      updateSource: "adelie",
      createdOnPlatform: "adelie",
      qbId: null,
      syncToken: null,
      ...rest,
    };
  }

  return {
    newInventoryItem, // configure new inventory item
    editInventoryItem, // edit inventory item
    duplicateInventoryItem, // duplicate inventory item
  };
};
