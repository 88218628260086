import { createContext, useContext, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import {
  useGetInvoicesForDayQuery,
  useGetRoutesForMonthQuery,
} from "../../../app/services/api";
import { useFirestore } from "react-redux-firebase";
import RouteStopList from "./components/assignedList/RouteStopList";
import StopList from "./components/stopList/StopList";
import SelectRouteHeader from "./components/routeList/SelectRouteHeader";
import Map from "./Map";
import { RouteContext } from "../NewRoute";
import SelectedStopInvoice from "./components/stopPane/SelectedStopInvoice";
import { useJsApiLoader } from "@react-google-maps/api";
import { useModalMgr } from "../../../app/services/hooks/useModalMgr";
import moment from "moment";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import {
  COLOR_GREEN_0,
} from "../../../app/ui/colorConstants";
import { Grid, Card, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
export const WaypointModalContext = createContext();

const CreateRoutes = () => {
  const ModalMgr = useModalMgr();

  const { state, actions } = useContext(RouteContext);
  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const { data: stops, isFetching } = useGetInvoicesForDayQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    moment: state.date?.moment,
    orgTimezone: orgData?.orgTimezone,
  });

  const {
    data: fetchedRoutes,
    isFetching: fetchingRoutes,
    refetch: refetchRoutes,
  } = useGetRoutesForMonthQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    monthStart: new moment(state.date?.moment),
    monthEnd: new moment(state.date?.moment),
    orgTimezone: orgData?.orgTimezone,
  });

  const apiKey = "AIzaSyCk8zR40fhVRwZjl7TrXS1XyS04q40xapw";
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
  });

  useEffect(() => {
    async function fetchData() {
      if (isFetching) return;
      actions.configureInitialStops(stops);
    }
    fetchData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stops]);

  useEffect(() => {
    async function fetchData() {
      if (fetchingRoutes) return;
      actions.configureInitialRoutes(fetchedRoutes);
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedRoutes]);

  useEffect(() => {
    refetchRoutes();
  }, []);

  useEffect(() => {
    const validCoAddr =
      state.coAddr?.startLat !== 0 && state.coAddr?.startLng !== 0;
    if (isFetching || fetchingRoutes) return;
    if (state.routes.length === 0 && validCoAddr) {
      actions.addRoute();
    } else {
      actions.setCurrentRouteId(state.routes[0]?.id);
    }
  }, [isFetching, fetchingRoutes]);
  const selectedRoute = (routes, currentRouteId) => {
    const route = routes?.find((r) => r.id === currentRouteId);
    return route ? route : null;
  };

  return (
    <div>
      <WaypointModalContext.Provider value={ModalMgr}>
        <Spin
          spinning={isFetching || state.updating || fetchingRoutes}
          delay={800}
          tip={"Updating.."}
        >
          {/* <Space className="route-elements-row"> */}
          <Grid container sx={{ mt: 1}}>
            <Grid item xs={12} sm={3} order={{xs: 2, sm: 1}}>
              <Box
                sx={{ height: { xs: '500px', md: "calc(96vh - 40px)"} }}
              >
                <StopList isLoaded={isLoaded} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} order={{xs: 3, sm: 2}}>
              <Box
                sx={{
                  borderLeft: "1px solid #dddddd",
                  borderRight: "1px solid #dddddd",
                  height: "calc(96vh - 40px)", 
                  position: "relative",
                }}
              >
                <Map isLoaded={isLoaded} />

                {state.selectedStop?.invoiceId || state.selectedStop?.stop ? (
                  <Card
                    className={"animated animatedFadeInUp fadeInUp"}
                    sx={{
                      position: "absolute", // Position the Card absolutely within its relative parent
                      margin: "0 16px 16px",
                      height: "224px",
                      bottom: "0px",
                      zIndex: 100,
                      width: "90%",
                      boxShadow: "0px 2px 12px rgba(26, 26, 53, 0.1)",
                      padding: "10px",
                    }}
                  >
                    <Row>
                      <Col flex={"auto"}>
                        <SelectedStopInvoice />
                      </Col>
                      <Col flex={"24px"}>
                        <Button
                          size={"small"}
                          onClick={() => {
                            actions.clearSelectedStop();
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ) : null}
              </Box>
            </Grid>

            <Grid item xs={12} sm={3} order={{xs: 1, sm: 3}}>
              <Box
                sx={{ height: { xs: '400px', md: "calc(96vh - 40px)"} }}
              >
                <div
                  style={{
                    height: "calc(40vh - 30px)",
                    whiteSpace: "nowrap",
                    overflowY: "auto",
                    backgroundColor: "#FFF",
                  }}
                >
                  <SelectRouteHeader />
                </div>
                <div
                  style={{
                    height: "calc(60vh - 30px)",
                  }}
                >
                  {selectedRoute(state.routes, state.currentRouteId) && (
                    <RouteStopList
                      data={selectedRoute(state.routes, state.currentRouteId)}
                    />
                  )}
                </div>

                <Button
            onClick={() => actions.setStep(2)}
            style={{
              boxShadow: "8px 12px 8px rgb(0,0,0, .15)",
              bottom: "50px",
              float: "right",
              marginRight: "16px",
              zIndex: 1000,
              width: "auto",
              borderColor: "transparent",
              backgroundColor: COLOR_GREEN_0,
              color: "#FFF",
              fontWeight: "bold",
            }}
          >
            Continue to Assignments <ArrowRightOutlined />
          </Button>
              </Box>
            </Grid>
          </Grid>
          {/* </Space> */}
    
        </Spin>
      </WaypointModalContext.Provider>
    </div>
  );
};

export default CreateRoutes;
