export const FormatToLocalCurrency = (number, orgData = {}) => {
  
  const { countryCode = 'US', languageCode = 'en', currencyCode = 'USD'} = orgData;
  
  let formatter = new Intl.NumberFormat(`${languageCode}-${countryCode}`, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2, 
  });
  return formatter.format(number);
};
