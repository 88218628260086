import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { Input } from "antd";
import { i18n } from "../../../i18n";

const { Search } = Input;
const CustomAlgoliaSearchbox = connectSearchBox(
  ({ currentRefinement, refine, onSearch, size }) => {
    return (
      <Search
        placeholder={ i18n('inventory.search') }
        value={currentRefinement}
        onChange={(e) => {
          refine(e.target.value);
        }}
        // onSearch={onSearch}
        style={{ width: 400 }}
        size={size ? size : "default"}
      />
    );
  }
);

export default CustomAlgoliaSearchbox;
