// inventory
export const INV_ITEM_RATE = "rate";
export const INV_ITEM_RATE_RANGE = "rateRange";
export const INV_ITEM_RATE_NAME = "rateName";
export const INV_ITEM_SHOW_IN_SHOP = "showInShop";
export const INV_ITEM_EMBEDDED_SHOP_RATE_ID = "embeddedShopRateId";
export const INV_ITEM_EMBEDDED_SHOP_RATE_WEEK_ID = "embeddedShopRateWeekId";
export const INV_ITEM_EMBEDDED_SHOP_RATE_MONTH_ID = "embeddedShopRateMonthId";

export const INV_BUNDLE_ITEM_ID = "bundleItemId";
export const INV_BUNDLE_ITEM_QTY = "bundleItemQty";
export const INV_BUNDLE_ITEM_NAME = "bundleItemName";
export const INV_BUNDLE_ITEM_DESC = "bundleItemDesc";
export const INV_BUNDLE_ITEM_TURNAROUND = "bundleItemTurnaround";
export const INV_BUNDLE_ITEM = "bundle";
export const INV_SALE_ITEM = "sale";
export const RENTAL_ITEM = "rental";

// invoice form
export const INVOICE_INV_ITEM = "inventoryItemId";
export const INVOICE_INV_RATE = "inventoryItemRate";
export const INVOICE_INV_DURATION = "inventoryItemDuration";
export const INVOICE_INV_QUANTITY = "inventoryItemQuantity";
export const INVOICE_CUSTOM_ITEM = "customInvoiceItem";
export const INVOICE_CUSTOM_RATE = "customInvoiceRate";
export const INVOICE_ESTIMATE = "estimate";
export const INVOICE = "invoice";
export const INVOICE_DRAFT = "draft";
export const INVOICE_INV_ITEM_PRICE = "itemInvoicePrice";
export const RECEIVE_METHOD_DELIVERY = "delivery";
export const RECEIVE_METHOD_PICKUP = "pickup";
export const RETURN_METHOD_ONSITE = "onSite";
export const RETURN_METHOD_DROPOFF = "dropOff";
export const ROW_TYPE_SINGLE_ITEM = "singleItem";
export const ROW_TYPE_SALE_ITEM = "saleItem";
export const ROW_TYPE_BUNDLE_ITEM = "bundleItem";
export const ROW_TYPE_SUBTOTAL = "subtotal";

// customer form names (invoice & customer)
export const CUSTOMER_CONTACT_NAME = "customerContactName";
export const CUSTOMER_NAME = "customerName";
export const CUSTOMER_ADDRESS = "customerAddress";
export const CUSTOMER_ADDRESS_SECONDARY = "customerAddressSecondary";
export const CUSTOMER_CITY = "customerCity";
export const CUSTOMER_STATE = "customerState";
export const CUSTOMER_ZIP = "customerZip";
export const CUSTOMER_FULL_ADDRESS_STR = "customerAddressStr";

export const CUSTOMER_DISPLAY_NAME = "customerDisplayName";
export const CUSTOMER_COMPANY_NAME = "customerCompanyName";

export const CUSTOMER_BILLING_ADDRESS = "customerBillingAddress";
export const CUSTOMER_BILLING_CITY = "customerBillingCity";
export const CUSTOMER_BILLING_STATE = "customerBillingState";
export const CUSTOMER_BILLING_ZIP = "customerBillingZip";
export const CUSTOMER_FULL_BILLING_ADDRESS_STR = "customerBillingAddressStr";

export const CUSTOMER_SHIPPING_ADDRESS = "customerShippingAddress";
export const CUSTOMER_SHIPPING_CITY = "customerShippingCity";
export const CUSTOMER_SHIPPING_STATE = "customerShippingState";
export const CUSTOMER_SHIPPING_ZIP = "customerShippingZip";
export const CUSTOMER_FULL_SHIPPING_ADDRESS_STR = "customerShippingAddressStr";

export const CUSTOMER_TAX_EXEMPT = "isTaxExempt";
export const CUSTOMER_TAX_RATE = "customerTaxRate";
export const CUSTOMER_TAX_RATE_DETAILS = "customerTaxRateDetails";
export const CUSTOMER_SHIP_TO_BILLING = "shipToBilling";

// venue form names (invoice & customer)
export const VENUE_CONTACT_NAME = "venueContactName";
export const VENUE_NAME = "venueName";
export const VENUE_ADDRESS = "venueAddress";
export const VENUE_CITY = "venueCity";
export const VENUE_STATE = "venueState";
export const VENUE_ZIP = "venueZip";
export const VENUE_FULL_ADDRESS_STR = "venueAddressStr";
export const VENUE_NOTES = "venueNotes";

// quick report statuses
export const QR_STATUS_NOT_STARTED = "notStarted";
export const QR_STATUS_IN_PROGRESS = "inProgress";
export const QR_STATUS_HAS_ISSUE = "hasIssue";
export const QR_STATUS_COMPLETE = "completed";

// waypoint
export const STOP_TYPE_WAYPOINT = "waypoint";

// payment types
export const PAYMENT_METHOD_STRIPE = "stripe";
export const PAYMENT_METHOD_JUSTIFI = "justifi";
export const PAYMENT_METHOD_CASH = "cash";
export const PAYMENT_METHOD_CHECK = "check";
export const PAYMENT_METHOD_EXTERNAL = "external";
