import React, { useContext } from "react";
import { sortableContainer } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { RouteContext } from "../../../NewRoute";

const DraggableRouteContainer = ({ props, data, routeId }) => {
  const { actions } = useContext(RouteContext);
  const SortableBody = sortableContainer((props) => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      actions.sortStops({
        routeId: routeId,
        stops: newData,
      });
    }
  };
  return (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
};

export default DraggableRouteContainer;
