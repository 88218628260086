export default function convertTimeToDayString(createdOn: any, na = 'unknown') {
  if (createdOn) {
    return new Date(createdOn.seconds * 1000).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      // hour: "2-digit",
      // minute: "2-digit",
    });
  } else {
    if (na === 'unknown') {
      return "Unknown time";
    } else if (na === 'dash'){
      return "--"
    } else {
      return " "
    }
  }
}

export function formatDateToMonthDay(date: Date) {

  if (!date) {
    return "Unknown"
  }

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${month} ${day}, ${hour}:${minute}`;
}
