import React, { createContext, useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import { unassignedColumns } from "./unassignedColumns";
import { StopsHelper } from "../../../../../app/services/hooks/helpers/route/stopsHelper";
import { StopPayload } from "../../helpers/stopPayload";
import { GetStopInRoute } from "../../helpers/getStopInRoute";
import {
  DeliveryTag,
  PickupTag,
} from "../../../../../app/system-components/tags/RoutesTags";
import { RouteContext } from "../../../NewRoute";
import { COLOR_LIGHT_GRAY } from "../../../../../app/ui/colorConstants";
import StopListSearch from "./StopListSearch";
import WaypointModal, { WaypointScriptModal } from "./WaypointModal";
import { useJsApiLoader } from "@react-google-maps/api";
import { useModalMgr } from "../../../../../app/services/hooks/useModalMgr";
import { WaypointModalContext } from "../../CreateRoutes";
import { firelikeId } from "../../../../../app/utils/models/checkers/firelikeid";
import { Box, IconButton, Typography } from "@mui/material";
import Iconify from "../../../../../app/iconify";
import { useCopyToClipboard } from "../../../../../app/hooks/use-copy-to-clipboard";
import { enqueueSnackbar } from "notistack";


const AI_PROMPT = `

You are an AI assistant specializing in logistics and route optimization for an event rental company. Your task is to analyze the given set of delivery and pickup locations and create the most efficient routes possible, taking into account various constraints and priorities.

## Input Variables
- numberOfRoutes: 4

## Input Data Structure
You will receive an array of objects, each representing a stop on the route. Each object contains the following properties:
- "address": String, the full address of the stop
- "customer": String, the customer's name
- "invoiceNumber": Number, a unique identifier for the invoice
- "invoiceId": String, another unique identifier for the invoice
- "latitude": Number, the latitude coordinate of the stop
- "longitude": Number, the longitude coordinate of the stop
- "startTime": Null or DateTime, the scheduled start time for the stop (if applicable)
- "endTime": Null or DateTime, the scheduled end time for the stop (if applicable)
- "type": String, can be 'delivery', 'pickup', or 'waypoint' (ignore 'waypoint' for now)

## Task
1. Create the specified number of optimized routes based on the "numberOfRoutes" input.
2. Analyze the given locations, their geographical distribution, and their types (delivery or pickup).
3. Prioritize stops based on the following criteria:
   a. Specified time windows (startTime and endTime) have the highest priority.
   b. In the absence of time windows, generally schedule deliveries earlier and pickups later in each route.
4. Create optimized routes that minimize total travel distance and time while adhering to the above priorities.
5. If multiple stops are at the same address, group them together in the route.
6. Provide the optimized routes as ordered lists of stops for each route.
7. Include the total estimated distance and time for each route and for all routes combined.
8. Highlight any potential issues or considerations (e.g., long distances between stops, time zone changes, uneven distribution of stops among routes).

## Output
Your response should include:
1. The optimized routes as ordered lists of stops for each route, including:
   - Customer names
   - Addresses
   - Type of stop (delivery or pickup)
   - Any specified time windows
2. Total estimated distance for each route and all routes combined.
3. Total estimated time for each route and all routes combined.
4. Distribution of deliveries and pickups across the routes.
5. Recommendations or considerations for improving route efficiency.

Please analyze the provided data and generate optimized routes based on the given criteria, number of routes, and type priorities.`

const StopList = ({ isLoaded }) => {
  const { actions, state } = useContext(RouteContext);
  const modal = useContext(WaypointModalContext);
  const [keywords, setKeywords] = useState("");
  const [stopList, setStopList] = useState([]);

  const { copy } = useCopyToClipboard();

  useEffect(() => {
    const list = state.initialStops.filter((s) => {
      const str = `#${s?.invoice?.invoiceNumber} ${s?.displayName}`;
      if (!keywords) return true;
      return str.toLowerCase().includes(keywords);
    });
    setStopList(list);
  }, [keywords]);

  return (
    <div>
      <div
        style={{
          padding: "8px",
          height: "auto",
          backgroundColor: "#FFF",
          borderBottom: "1px #dddddd solid",
        }}
      >
        <StopListSearch setKeywords={setKeywords} />
        <br />
        <Row
          wrap={false}
          align={"middle"}
          justify={"space-between"}
          style={{ width: "100%" }}
        >
          <Col>
            <Space direction={"horizontal"} size={8}>
              <Space direction={"horizontal"}>
                <Typography fontSize={"13px"} fontWeight={"bold"}>

                Deliveries:
                </Typography>
                <DeliveryTag text={`${state.stopBreakout?.deliveryCount}`} />
              </Space>
              <Space direction={"horizontal"}>
                
                <Typography fontSize={"13px"} fontWeight={"bold"}>
                Pick-ups:
                </Typography>
                <PickupTag text={`${state.stopBreakout?.pickupCount}`} />
              </Space>
            </Space>
          </Col>
          <Col>
            <Button
              onClick={() => {
                modal.show();
              }}
              style={{
                float: "right",
                borderColor: "transparent",
                backgroundColor: COLOR_LIGHT_GRAY,
              }}
              size={"small"}
            >
              + Waypoint
            </Button>
              </Col>
              <Col>
            <IconButton onClick={() => {
              console.log(state.initialStops)
              const stopsData = []
              state.initialStops.forEach((s) => {
                const d = {
                  address: s.address,
                  customer: s.displayName,
                  invoiceNumber: s.invoice?.invoiceNumber ?? "",
                  invoiceId: s.invoiceId ?? "",
                  latitude: s.lat,
                  longitude: s.lng,
                  startTime: s.timeWindowStart ?? null,
                  endTime: s.timeWindowEnd ?? null,
                  type: s.type, // delivery or pickup
                }

                stopsData.push(d);

                const prompt = AI_PROMPT + `\n\n` + JSON.stringify(stopsData);
                enqueueSnackbar('Route Data Copied');
                copy(prompt);
              })
            }}>
              <Iconify icon={"mdi:export"} />
              </IconButton>
          </Col>
        </Row>
      </div>
      <Box 
        sx={{ height: { xs: '400px', md: "calc(96vh - 90px)"} }}
      >
        <Table
          locale={{ emptyText: "No unassigned stops" }}
          style={{
            height: "100%",
            overflowY: "auto",
          }}
          showHeader={false}
          size={"small"}
          pagination={false}
          dataSource={keywords ? stopList : state.initialStops}
          columns={unassignedColumns(state.selectedStop)}
          rowKey={firelikeId()}
          rowClassName={"UnassignedRouteRow"}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                actions.updateSelectedStop({
                  invoiceId: record?.invoiceId ?? null,
                  type: record?.type ?? null,
                });
              },
            }; // click row
          }}
        />
      </Box>

      <WaypointModal visible={modal.visible} isLoaded={isLoaded} />

    </div>
  );
};

export default StopList;
