import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 

import { Box, Button, Checkbox, Chip, Container, Dialog, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { fileData } from "../../file-manager/file-thumbnail";

import { functions } from "../../../app/config/firebase";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Rowing } from "@mui/icons-material";
import FileManagerNewFileBox from "../../file-manager/file-manager-new-file-box";
import { CheckFile } from "../../file-manager/file";

const shortCodes = [
  { label: "Invoice Number", code: "{{INVOICE_NUMBER}}" },
  { label: "Company Name", code: "{{COMPANY_NAME}}" },
  { label: "Customer First Name", code: "{{CUSTOMER_FIRST_NAME}}" },
];

const EmailTemplateMaker = ({ handleClose, save, template, orgData }) => {
  const db = useFirestore();
  const firebase = useFirebase();

  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [loadingAIResponse, setLoadingAIResponse] = useState(false);

  const [requiresInvoice, setRequiresInvoice] = useState(true);
  const [includePaymentLink, setIncludePaymentLink] = useState(false);
  const [includeInvoicePDF, setIncludeInvoicePDF] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  // Attachments
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newAttachments, setNewAttachments] = useState([]);

  useEffect(() => {
    if (template) {
      setBody(template.body ?? "");
      setSubject(template.subject ?? "");
      setTemplateName(template.templateName ?? "New Template");
      setRequiresInvoice(template.requiresInvoice ?? true);
      setIncludePaymentLink(template.includePaymentLink ?? false);
      setIncludeInvoicePDF(template.includeInvoicePDF ?? true);
      setSelectedFiles(template?.attachments ?? []);
    }
  }, [template]);

  async function fetchFiles() {
    if (!orgData) return;
    const querySnapshot = await db
      .collection("orgs")
      .doc(orgData.id)
      .collection("fileManager")
      .get();
    const files = querySnapshot.docs.map((doc) => CheckFile(doc));
    setFiles(files);
  }

  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle changes
  const handleChange = (event) => {
    switch (event.target.name) {
      case "requiresInvoice":
        setRequiresInvoice(event.target.checked);
        break;
      case "includePaymentLink":
        setIncludePaymentLink(event.target.checked);
        break;
      case "includeInvoicePDF":
        setIncludeInvoicePDF(event.target.checked);
        break;
      default:
        break;
    }
  };

  const quillRef = useRef(null);

  const insertShortCode = (shortCode) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection(true);
    if (range) {
      quill.insertText(range.index, shortCode);
      quill.setSelection(range.index + shortCode.length);
    }
  };

  const makeBodyWithSkipper = async () => {
    if (templateName === "") {
      enqueueSnackbar("Please enter a template name", { variant: "error" });
      return;
    }

    const callData = {
      orgData: orgData,
      templateName: templateName,
      subjectLine: subject,
      body: body,
    };

    setLoadingAIResponse(true);
    setBody("");

    const call = functions.httpsCallable("generateEmailTemplate");
    const result = await call(callData);
    const bodyJson = JSON.parse(result.data);

    setBody(bodyJson.body.replaceAll("\n", "<br>"));
    setLoadingAIResponse(false);
  };

  const handleAddAttachment = (value) => {
    if (!value) {
      return;
    }
    if (selectedFiles.includes(value)) {
      return;
    }
    const newFiles = [...selectedFiles, value];
    setSelectedFiles(newFiles);
    setSelectedOption(null);
  };
  const handleRemoveAttachment = (removedFileId) => {
    const newFiles = selectedFiles.filter((tagId) => tagId !== removedFileId);
    setSelectedFiles(newFiles);
  };

  const handleUpload = async () => {
    const storageRef = firebase.storage().ref();
    const firestoreRefs = []; // Array to store Firestore document references

    const uploadBlob = async (file) => {
      const { key, name = "", size = 0, type = "doc" } = fileData(file);

      const response = await fetch(file.preview);
      const blob = await response.blob();
      const fileRef = storageRef.child(
        `filemanager/${orgData.id}/${file.name}`
      );
      const snapshot = await fileRef.put(blob);
      const downloadURL = await snapshot.ref.getDownloadURL();

      // Create a Firestore document for each file after it's uploaded
      const firestoreRef = db
        .collection("orgs")
        .doc(orgData.id)
        .collection("fileManager")
        .doc();

      const newDoc = {
        id: firestoreRef.id,
        name: name,
        size,
        type,
        url: downloadURL,
        tags: [],
        isFavorited: false,
        shared: null,
        createdAt: new Date(),
        modifiedAt: new Date(),
      };

      await firestoreRef.set(newDoc);

      firestoreRefs.push(firestoreRef.id); // Store the reference
    };

    await Promise.all(
      newAttachments.map((fileObj) => {
        if (!(fileObj instanceof File)) return null;
        return uploadBlob(fileObj);
      })
    )
      .then(() => {
        console.info("All files uploaded and documents created");
      })
      .catch((error) => {
        console.error("Error uploading files or adding document: ", error);
      });

    return firestoreRefs; // Return the array of Firestore document references
  };

  const saveTemplate = async () => {
    let ref = db
      .collection("orgs")
      .doc(orgData.id)
      .collection("templates")
      .doc("email")
      .collection("email");
    if (template) {
      ref = ref.doc(template.id);
    } else {
      ref = ref.doc();
    }

    let attachmentIds = selectedFiles;

    // First check if there are attachments that need to be uploaded.
    if (newAttachments.length > 0) {
      // If there are, upload them.
      const newAttachmentIds = await handleUpload();
      attachmentIds = [...attachmentIds, ...newAttachmentIds];
    }

    const templateData = {
      id: template ? template.id : ref.id,
      templateName: templateName,
      subject: subject,
      body: body,
      requiresInvoice: requiresInvoice,
      includePaymentLink: includePaymentLink,
      includeInvoicePDF: includeInvoicePDF,
      attachments: attachmentIds,
    };

    if (template) {
      await ref.update(templateData);
    } else {
      await ref.set(templateData);
    }

    enqueueSnackbar("Template Saved", { variant: "success" });
  };

  const deleteTemplate = async () => {
    let ref = db
      .collection("orgs")
      .doc(orgData.id)
      .collection("templates")
      .doc("email")
      .collection("email");
    if (template) {
      ref = ref.doc(template.id);
    }

    await ref.delete();

    enqueueSnackbar("Template Deleted", { variant: "success" });

    handleClose();
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {templateName ? templateName : "New Template"}
          </Typography>
          <Button autoFocus color="inherit" onClick={saveTemplate}>
            Save
          </Button>
          {template && (
            <Button
              autoFocus
              variant="contained"
              color="error"
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} sx={{ p: 2 }}>
        <Grid
          container
          direction="row"
          gap={2}
          justifyContent="center"
          alignContent="center"
        >
          <Grid container item direction={"column"} xs={12} gap={2} md={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    disabled={true}
                    onChange={handleChange}
                    name="requiresInvoice"
                  />
                }
                label="This Template Requires An Invoice"
              />

              {requiresInvoice && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includePaymentLink}
                        onChange={handleChange}
                        name="includePaymentLink"
                      />
                    }
                    label="Include Payment Link"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeInvoicePDF}
                        onChange={handleChange}
                        name="includeInvoicePDF"
                      />
                    }
                    label="Include Invoice PDF"
                  />
                </>
              )}
            </FormGroup>

            <div>
              <br />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <Tooltip
                    title={`Emails have a 10MB attachment limit. Priority is given
            to the Invoice PDF, then attachments. If the total size of the
            attachments exceeds 10MB, the remaining attachments will be
            added to the email as links that can be viewed and downloaded
            from the browser by the customer. Do not add any attachment that
            you would not want generally available to the public`}
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon sx={{ color: "text.secondary" }} />
                    </IconButton>
                  </Tooltip>{" "}
                  You can add attachments to this email from your saved files in
                  the File Manager, or add a new file by clicking the "Upload"
                  button.
                </Typography>
              </Box>

              <br />

              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Attachments...
                </InputLabel>

                <Select
                  value={selectedOption}
                  onChange={(event) => handleAddAttachment(event.target.value)}
                  label="Add attachment..."
                >
                  {files &&
                    files
                      .filter((t) => !selectedFiles.includes(t.id))
                      .map((tag) => (
                        <MenuItem value={tag.id} key={tag.id}>
                          {tag.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>

              <Box
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selectedFiles &&
                  selectedFiles.length > 0 &&
                  selectedFiles.map((fileId) => {
                    const file = files.find((file) => file.id === fileId);
                    return file ? (
                      <Chip
                        label={file.name}
                        key={file.id}
                        onDelete={() => handleRemoveAttachment(fileId)}
                        color="primary"
                        sx={{
                          maxWidth: 200, 
                          overflow: "hidden", 
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap", 
                        }}
                      />
                    ) : null;
                  })}
              </Box>

              <FileManagerNewFileBox onNewAttachments={setNewAttachments} />
            </div>
          </Grid>

          <Grid container direction={"column"} item gap={1} xs={12} md={8}>
            <Grid item xs={12}>
              <Stack gap={1}>
                <TextField
                  id="outlined-basic"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  label="Template Name"
                  variant="outlined"
                  fullWidth
                />

                <TextField
                  id="outlined-basic"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  label="Subject"
                  variant="outlined"
                  fullWidth
                />

                <ReactQuill
                  theme="snow"
                  value={body}
                  onChange={setBody}
                  ref={quillRef}
                  modules={{
                    toolbar: [["bold", "italic", "underline"], ["link"]],
                  }}
                  placeholder="Email Body"
                  style={{
                    height: "300px",
                    marginBottom: "50px",
                  }}
                />
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Stack direction="row" gap={1}>
                    {shortCodes.map((item, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        onClick={() => insertShortCode(item.code)}
                        disabled={
                          item.code === "{{INVOICE_NUMBER}}" && !requiresInvoice
                        }
                      >
                        {item.label}
                      </Button>
                    ))}
                  </Stack>
                  <LoadingButton
                    loading={loadingAIResponse}
                    variant="contained"
                    onClick={makeBodyWithSkipper}
                  >
                    ✨ Generate With AI ✨
                  </LoadingButton>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Dialog open={confirmDeleteDialog}>
          <Box sx={{ p: 2 }}>
            <Typography gutterBottom sx={{mb: 2}} fontWeight={"bold"}>Confirm Delete</Typography>
            <Stack direction="row" gap={2}>
            <Button sx={{backgroundColor: "gray"}} variant="contained" onClick={() => setConfirmDeleteDialog(false)}>
              Cancel
            </Button >
            <Button variant="contained" onClick={deleteTemplate}>Delete</Button>
            </Stack>
          </Box>
        </Dialog>
      </Container>
    </>
  );
};

export default EmailTemplateMaker;
