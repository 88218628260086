
import React from 'react';

import { Box, Card, Typography, CardContent, Grid } from '@mui/material';

import { capitalize } from 'lodash';
import { Stack } from '@mui/system';
import { WeatherDataFiveDay } from '../../../app/interfaces/weather-forecast-five';

import { OrganizationData } from '../../../app/interfaces/organizationData';
import { fDate } from '../../../app/utils/format-time';

interface WeatherWidgetProps {
  data: WeatherDataFiveDay;
  orgData: OrganizationData
}

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ data, orgData }) => {

  const { city, list } = data;
  const currentWeather = list[0];

  const icon = () => {
    const iconId = currentWeather.weather[0].icon;
    const url = `https://openweathermap.org/img/wn/${iconId}@2x.png`;
    
    return <img src={url} alt="weather icon" />;
  }

  return (
    <Card sx={{height: "100%"}}>
      <CardContent>
        <Grid container gap={4}>
          <Stack>
            <Typography variant="h6">{fDate(new Date())}</Typography>
            <Typography variant="h2" component="div">
              {Math.round(currentWeather.main.temp)}°F
            </Typography>
          </Stack>

          <Stack>
          {orgData && orgData.id === "nTix6zfTnaKkmt1qAwMl" ? (
              <Typography variant="h5" component="div">
                {'Gardena'}, {city.country}
              </Typography>
            ) : (
              <Typography variant="h5" component="div">
                {city.name}, {city.country}
              </Typography>
            )}

            <Box display="flex" alignItems="center">
              {icon()}
              <Typography variant="h6" color="text.primary">
                {capitalize(currentWeather.weather[0].description)}
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Box>
          <Typography sx={{ fontSize: 14 }} color="text.primary" >
            Feels like {Math.round(currentWeather.main.feels_like)}°F
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.primary" >
            Wind {currentWeather.wind.speed} m/h
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.primary" >
            Humidity {currentWeather.main.humidity}%
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.primary" >
            Precip {Math.round(currentWeather.pop * 100)}%
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default WeatherWidget;
