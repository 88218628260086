export default function convertToTime(createdOn) {
  if (createdOn) {
    return new Date(createdOn.seconds * 1000).toLocaleTimeString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return "Unknown time";
  }
}
