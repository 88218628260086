import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import { useFirestore } from "react-redux-firebase";
import { configureCustomer } from "../../../../app/utils/models/configure/configureCustomer";
import { customerWrites } from "../../../../app/services/firestore/writes/customerWrites";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import {
  CUSTOMER_BILLING_ADDRESS,
  CUSTOMER_BILLING_CITY,
  CUSTOMER_BILLING_STATE,
  CUSTOMER_BILLING_ZIP,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SHIP_TO_BILLING,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  CUSTOMER_TAX_EXEMPT,
  CUSTOMER_TAX_RATE,
  CUSTOMER_TAX_RATE_DETAILS,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import Checkbox from "antd/es/checkbox/Checkbox";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_GREEN_0, COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import { useFirestoreFetch } from "../../../../app/services/hooks/fetch/useFirestoreFetch";
import { taxRateQueries } from "../../../../app/services/firestore/queries/taxRateQueries";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import PaymentHistoryModal from "./PaymentHistoryModal";
import { useHistory } from "react-router-dom";
import {
  ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT,
} from "../../../../app/routes";
import DisplayCustomerBalance from "../../../sharedComponents/DisplayCustomerBalance";
import CustomerInvoiceHistory from "./CustomerInvoiceHistory";
import AdditionalContacts from "./AdditionalContacts";
import { EditCustomerQboSync } from "./EditCustomerQboSync";
import Iconify from "../../../../app/iconify";
import { useCopyToClipboard } from "../../../../app/hooks/use-copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";

const EditCustomerModal = ({
  onCancel,
  onComplete,
  onContactAdded,
  visible,
  selected,
  hideDeactivate,
  showPaymentOptions,
}) => {

  const { copy } = useCopyToClipboard();

  const handleCopy = useCallback((text) => {
    enqueueSnackbar('Customer ID Copied');
    copy(text);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copy, enqueueSnackbar]);

  const [selectedCustomer, setSelectedCustomer] = useState(selected)
  useEffect(() => {
    setSelectedCustomer(selected)
  }, [selected])
  

  const firestore = useFirestore();
  const history = useHistory();

  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const { fetching, data: taxRates } = useFirestoreFetch(
    taxRateQueries().fetchActiveTaxRates
  );

  const [tabType, setTabType] = useState('customer');  // customer , invoices , payments , contacts
  const [billingShippingSame, setBillingShippingSame] = useState(false);
  const [saving, setSaving] = useState(false);
  const [defaultDisplayEnabled, setDefaultDisplayEnabled] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
      const selectedTr = taxRates.find(
        (r) => r.id === values[CUSTOMER_TAX_RATE]
      );
      const taxDetails = selectedTr ? selectedTr : null;
      const taxRate = selectedTr ? selectedTr.rate : null;
      const data = {
        ...values,
        taxDetails: taxDetails,
        customerTaxRate: taxRate,
      };
      const payload = configureCustomer().editCustomer(
        data,
        userData.id,
        orgData
      );
      customerWrites({ firestore }, fsOrgPrefix)
        .editCustomer(selected.id, payload)
        .then(() => {
          clearBeforeClose();
          onComplete();
          notificationConfirm("Customer saved", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);
          notificationError(
            "Something went wrong",
            "Customer could not be saved"
          );
        });
    });
  };

  const clearBeforeClose = () => {
    setDefaultDisplayEnabled(false);
    setSaving(false);
    setBillingShippingSame(false);
    form.resetFields();
  };

  useEffect(() => {
    if (fetching || !visible || !selectedCustomer) return;
    const taxRate =
    selectedCustomer && selectedCustomer[CUSTOMER_TAX_RATE_DETAILS]
        ? selectedCustomer[CUSTOMER_TAX_RATE_DETAILS].id
        : null;

    const { customerTaxRate, ...rest } = selectedCustomer;
    form.setFieldsValue({
      [CUSTOMER_TAX_RATE]: taxRate,
      ...rest,
    });

    setBillingShippingSame(selectedCustomer && selectedCustomer[CUSTOMER_SHIP_TO_BILLING]);

  }, [visible, fetching, selectedCustomer]);

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      [CUSTOMER_SHIPPING_ADDRESS]: values[CUSTOMER_BILLING_ADDRESS],
      [CUSTOMER_SHIPPING_CITY]: values[CUSTOMER_BILLING_CITY],
      [CUSTOMER_SHIPPING_STATE]: values[CUSTOMER_BILLING_STATE],
      [CUSTOMER_SHIPPING_ZIP]: values[CUSTOMER_BILLING_ZIP],
    });
  }, [billingShippingSame]);

  const duplicateBilling = (formSuffix, target) => {
    if (!billingShippingSame) return;
    form.setFieldsValue({
      [`customerShipping${formSuffix}`]: target.value,
    });
  };

  const handleDeactivate = () => {
    Modal.confirm({
      title: "Make Inactive?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "If you are integrated with Quickbooks, this customer will also be made inactive in your Quickbooks account.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          customerWrites({ firestore }, fsOrgPrefix)
            .editCustomer(selectedCustomer && selectedCustomer.id, { isActive: false })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Customer made inactive", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Customer could not be made inactive"
              );
            });
        });
      },
      onCancel() { },
    });
  };

  // MARK: Reactivate
  const handleReactivate = () => {
    Modal.confirm({
      title: "Make Active?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "If you are integrated with Quickbooks, this customer will also be made active in your Quickbooks account.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          customerWrites({ firestore }, fsOrgPrefix)
            .editCustomer(selectedCustomer && selectedCustomer.id, { isActive: true })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Customer made active", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Customer could not be made active"
              );
            });
        });
      },
      onCancel() { },
    });
  };

  const handleValuesChanged = (e, all) => {
    const lastName = all.lastName !== "" ? ` ${all.lastName}` : "";
    const co =
      all[CUSTOMER_COMPANY_NAME] !== "" &&
        `${all.firstName}${all.lastName}` !== ""
        ? ` - ${all[CUSTOMER_COMPANY_NAME]}`
        : all[CUSTOMER_COMPANY_NAME] !== "" &&
          `${all.firstName}${all.lastName}` === ""
          ? `${all[CUSTOMER_COMPANY_NAME]}`
          : "";
    const defaultDisplayName = `${all.firstName}${lastName}${co}`.trim();
    const currDisplay = all[CUSTOMER_DISPLAY_NAME];
    if (currDisplay !== defaultDisplayName && defaultDisplayName !== "") {
      setDefaultDisplayEnabled(true);
    } else {
      setDefaultDisplayEnabled(false);
    }
  };

  // MARK: Display Name
  const generateDisplayName = () => {
    const values = form.getFieldsValue();
    const firstName = values.firstName;
    const lastName = ` ${values.lastName}`;
    const co =
      values[CUSTOMER_COMPANY_NAME] !== "" &&
        `${firstName}${lastName.trim()}` !== ""
        ? ` - ${values[CUSTOMER_COMPANY_NAME]}`
        : values[CUSTOMER_COMPANY_NAME] !== "" &&
          `${firstName}${lastName.trim()}` === ""
          ? `${values[CUSTOMER_COMPANY_NAME]}`
          : "";
    const defaultDisplayName = `${firstName}${lastName}${co}`.trim();
    form.setFieldsValue({
      [CUSTOMER_DISPLAY_NAME]: defaultDisplayName,
    });
    setDefaultDisplayEnabled(false);
  };

  const renderOption = (t) => {
    return `${t.name} (${(t.rate * 100).toFixed(2)}%)`;
  };

  const configureDeactivateBtns = (selected) => {
    if (!selected) return null;
    if (hideDeactivate) return null;
    return (
      <Button
        style={{ float: "left" }}
        type={selected.isActive ? "danger" : ""}
        key="inactive"
        onClick={selected.isActive ? handleDeactivate : handleReactivate}
        disabled={saving}
      >
        {selected.isActive ? "Deactivate" : "Reactivate"}
      </Button>
    );
  };

  const configureDisabled = (selected) => {
    if (!selected) return false;
    return !selected.isActive;
  };

  const handleReceivePayment = () => {
    selectedCustomer.id &&
      history.push(`${ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT}${selectedCustomer.id}`);
  };


  return (
    <Modal
      destroyOnClose
      width={1000}
      open={visible}
      closeIcon={
        <Iconify icon={"mdi:close"} />
      }

      onCancel={() => {
        onCancel();
      }
      }
      title={
        <div>
          <Space align="center">
            <IconButton onClick={() => handleCopy(selectedCustomer.id)}>
              <Iconify icon={"tabler:id"} />
            </IconButton>

            Edit Customer
            {selectedCustomer && !selectedCustomer.isActive && (
              <CategoryTag text={"Deactivated"} />
            )}
          </Space>
          {showPaymentOptions && (
            <Space style={{ float: "right", paddingRight: "15px" }}>
              <div style={{ fontSize: "14px", paddingBottom: "5px" }}>
                <DisplayCustomerBalance customer={selectedCustomer} />
              </div>

              <Button
                type={"primary"}
                style={{
                  marginTop: "-12px",
                  backgroundColor: COLOR_GREEN_0,
                  borderColor: COLOR_GREEN_0,
                }}
                onClick={handleReceivePayment}
              >
                Receive Payment
              </Button>
            </Space>
          )}
        </div>
      }
      footer={
        tabType === "customer" ? (
          [
            selectedCustomer && configureDeactivateBtns(selectedCustomer),
            <Button
              key="cancel"
              onClick={() => {
                form.resetFields();
                onCancel();
              }}
              disabled={saving}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleFinish()}
              disabled={saving || configureDisabled(selectedCustomer)}
              loading={saving}
            >
              Save
            </Button>,
          ]
        ) : (
          <Button
            key="close"
            onClick={() => {
              if (onContactAdded === null) {
                onCancel();
              }
              else {
                onContactAdded();
              }
            }}
            disabled={saving}
          >
            Close
          </Button>
        )
      }
    >
      <div style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey="customer"
          activeKey={tabType}
          onChange={setTabType}
        >
          <TabPane tab="Customer" key="customer" />
          <TabPane tab="Invoices" key="invoices" />
          <TabPane tab="Payments" key="payments" />
          <TabPane tab="Additional Contacts" key="contacts" />

          {orgData && orgData.qboRealmId && orgData.qboRealmId !== "" && (
            <TabPane tab={"QuickBooks Sync"} key="quickbooks" />
          )}
        </Tabs>
      </div>

      {tabType === "customer" && (
        <div>
          <Form form={form} onValuesChange={handleValuesChanged}>
            <div style={{ marginBottom: "12px" }}>
              <TextSubtitle text={"CUSTOMER INFO"} />
            </div>
            <Row gutter={24} wrap={false}>
              <Col span={12}>
                <Row wrap={false} gutter={8}>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="title"
                      style={{ marginBottom: "12px", width: "60px" }}
                    >
                      <Input
                        placeholder="Title"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="firstName"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="First Name"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="middleName"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Middle"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="lastName"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Last Name"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="suffix"
                      style={{ marginBottom: "12px", width: "60px" }}
                    >
                      <Input
                        placeholder="Suffix"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name={CUSTOMER_COMPANY_NAME}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    placeholder="Company name"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
                <Space
                  style={{ width: "100%", display: "inline-block" }}
                  direction={"vertical"}
                  align={"start"}
                >
                  <Form.Item
                    name={CUSTOMER_DISPLAY_NAME}
                    style={{ marginBottom: "12px", width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Display name required",
                      },
                    ]}
                  >
                    <Input
                      disabled={configureDisabled(selected)}
                      placeholder="Display name"
                      className="requiredDisplayName"
                    />
                  </Form.Item>
                  {defaultDisplayEnabled ? (
                    <Button
                      disabled={configureDisabled(selected)}
                      size={"small"}
                      type="primary"
                      style={{
                        height: "22px",
                        lineHeight: "14px",
                        fontSize: "14px",
                      }}
                      onClick={generateDisplayName}
                    >
                      Generate Display Name
                    </Button>
                  ) : null}
                </Space>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue=""
                  name="email"
                  style={{ marginBottom: "12px" }}
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
                <Row wrap={false} gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      initialValue=""
                      name="phone"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Phone"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      initialValue=""
                      name="mobile"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Mobile"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  initialValue=""
                  name="otherContact"
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    placeholder="Other"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row wrap={false} justify="space-between" gutter={24}>
              <Col>
                <div style={{ marginBottom: "12px" }}>
                  <TextSubtitle text={"BILLING ADDRESS"} />
                </div>
                <Form.Item
                  initialValue=""
                  name={CUSTOMER_BILLING_ADDRESS}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    disabled={configureDisabled(selected)}
                    placeholder="Street"
                    onChange={(e) => duplicateBilling("Address", e.target)}
                  />
                </Form.Item>
                <Row wrap={false} justify="space-between">
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_BILLING_CITY}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="City"
                        onChange={(e) => duplicateBilling("City", e.target)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_BILLING_STATE}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="State"
                        onChange={(e) => duplicateBilling("State", e.target)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_BILLING_ZIP}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="ZIP"
                        onChange={(e) => duplicateBilling("Zip", e.target)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify={"space-between"} style={{ marginBottom: "2px" }}>
                  <Col>
                    <TextSubtitle text={"SHIPPING ADDRESS"} />
                  </Col>
                  <Col>
                    <Form.Item
                      name={CUSTOMER_SHIP_TO_BILLING}
                      style={{ marginBottom: 0 }}
                      valuePropName={"checked"}
                      initialValue={false}
                    >
                      <Checkbox
                        disabled={configureDisabled(selected)}
                        onChange={(e) =>
                          setBillingShippingSame(e.target.checked)
                        }
                      >
                        Use Billing
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  initialValue=""
                  name={CUSTOMER_SHIPPING_ADDRESS}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    placeholder="Street"
                    disabled={
                      billingShippingSame || configureDisabled(selected)
                    }
                  />
                </Form.Item>
                <Row wrap={false} justify="space-between">
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_SHIPPING_CITY}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        placeholder="City"
                        disabled={
                          billingShippingSame || configureDisabled(selected)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_SHIPPING_STATE}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        placeholder="State"
                        disabled={
                          billingShippingSame || configureDisabled(selected)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      initialValue=""
                      name={CUSTOMER_SHIPPING_ZIP}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        placeholder="ZIP"
                        disabled={
                          billingShippingSame || configureDisabled(selected)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row wrap={false} justify="space-between" gutter={24}>
              <Col span={12}>
                <div style={{ marginBottom: "12px" }}>
                  <TextSubtitle text={"TAX INFO"} />
                </div>
                <Form.Item
                  valuePropName="checked"
                  initialValue={false}
                  name={CUSTOMER_TAX_EXEMPT}
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox disabled={configureDisabled(selected)}>
                    Tax exempt
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={CUSTOMER_TAX_RATE}
                  style={{ marginBottom: "12px" }}
                >
                  <Select
                    disabled={fetching || configureDisabled(selected)}
                    placeholder={"Select Tax Rate"}
                  >
                    {taxRates.map((t) => (
                      <Select.Option key={t.id} value={t.id}>
                        {t && renderOption(t)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <div style={{ marginBottom: "12px" }}>
                  <TextSubtitle text={"ADDITIONAL NOTES"} />
                </div>

                <Form.Item
                  name={"notes"}
                  style={{ marginBottom: "12px", width: "100%" }}
                >
                  <Input.TextArea
                    disabled={configureDisabled(selected)}
                    placeholder="Notes"
                    className="requiredDisplayName"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>

                <Form.Item
                  valuePropName="checked"
                  initialValue={false}
                  name={"checkNotesReminder"}
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox disabled={configureDisabled(selected)}>
                    Remind me to review these notes when added to an order.
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {tabType === "invoices" && (
        <CustomerInvoiceHistory
          customerId={selectedCustomer && selectedCustomer.id}
        />
      )}

      {tabType === "payments" && (
        <div>
          <PaymentHistoryModal
            customer={selectedCustomer && selectedCustomer}
          />
        </div>
      )}

      {tabType === "contacts" && (
        <div>
          <AdditionalContacts
            customer={selectedCustomer && selectedCustomer}
            updateCustomer={setSelectedCustomer}
          />
        </div>
      )}

      {tabType === "quickbooks" && (
        <div>
          <EditCustomerQboSync
            customer={selectedCustomer && selectedCustomer}
            orgId={orgData.id}
          />
        </div>
      )}
    </Modal>
  );
};

export default EditCustomerModal;
