import React, { useEffect, useState } from "react";
import { Button, Table, Row, Col, Space, Select, Tooltip } from "antd";
import { invoiceTableColumns } from "./invoiceTable/InvoiceTableColumns";
import { ROW_TYPE_SUBTOTAL } from "../../../app/utils/models/modelConstants/modelConstants";
import EditableCell from "./invoiceTable/cells/EditableCell";
import DraggableContainer from "./invoiceTable/rows/DraggableContainer";
import DraggableBodyRow from "./invoiceTable/rows/DraggableBodyRow";
import { EmptyTable } from "./invoiceTable/modules/EmptyTable";
import { getRowTotal } from "../helpers/getRowTotal";
import { configureNewSingleItem } from "../../../app/utils/models/configure/invoiceTable/configureNewSingleItem";
import InvoiceTableSummary from "./invoiceTable/summary/InvoiceTableSummary";
import { UpdateDurationRowTotal } from "./invoiceTable/helpers/updateDurationRowTotal";
import { LoadingButton } from "@mui/lab";

const InvoiceItems = ({
  isSalesInvoice,
  availableItems,
  selectedItems,
  setSelectedItems,
  invoiceTotal,
  setInvoiceTotal,
  setContextMenu,
  contextMenu,
  rentalRange,
  setToggleNewInvModal,
  customer,
  currentInvoice,
  invoiceTaxRate,
  setInvoiceTaxRate,
  setDeliveryFee,
  deliveryFee,
  setDeliveryFeeTaxable,
  deliveryFeeTaxable,
  orgData,
  getShippingFee,
  editing,
  setEditing,
  loadingAllInventory
}) => {
  const handleTaxableChange = (checked, record) => {
    const { selectedTaxable, ...rest } = record;
    handleSave({ selectedTaxable: checked, ...rest });
  };

  // editable cell handlers
  const handleAdd = () => {
    setSelectedItems({
      items: [
        ...selectedItems.items,
        configureNewSingleItem(selectedItems, customer),
      ],
      count: selectedItems.count + 1,
    });
  };
  const handleAddSubtotal = () => {
    const newItem = {
      key: selectedItems.count,
      rowType: ROW_TYPE_SUBTOTAL,
      rowTotal: 0,
    };
    setSelectedItems({
      items: [...selectedItems.items, newItem],
      count: selectedItems.count + 1,
    });
  };
  const handleSave = (row) => {
    const exempt = customer?.isTaxExempt;

    const newData = [...selectedItems.items];
    const index = newData.findIndex((item) => row.key === item.key);
    let item = newData[index];
    const { rowTotal, selectedTaxable, ...rest } = item;
    const {
      rowTotal: rowTotalRow,
      selectedTaxable: selectedTaxableRow,
      ...rowRest
    } = row;
    const { newRowTotal, hasValidatedFields } = getRowTotal(row);
    const newItem = {
      ...rest,
      ...rowRest,
      rowTotal: newRowTotal, // determine total
      selectedTaxable: exempt === true ? false : selectedTaxableRow,
      hasValidatedFields: hasValidatedFields,
    };
    newData.splice(index, 1, { ...newItem });
    setSelectedItems({
      items: newData,
      count: selectedItems.items.length,
    });
    // reduce quantities
  };

  const handleRemove = (key) => {
    const items = [...selectedItems.items];
    const newItems = items
      .filter((i) => i.key !== key)
      .map((i, index) => {
        const { key, ...rest } = i;
        return {
          key: index,
          ...rest,
        };
      });
    setSelectedItems({
      items: newItems,
      count: newItems.length,
    });
  };

  // table components & columns
  const components = {
    body: {
      wrapper: (props) => (
        <DraggableContainer
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          props={props}
        />
      ),
      row: (props) => {
        const { className, style, ...restProps } = props;
        return (
          <DraggableBodyRow
            selectedItems={selectedItems}
            className={className}
            style={style}
            restProps={restProps}
          />
        );
      },
      cell: EditableCell,
    },
  };

  const updateSubtotal = (key, amt) => {
    const newItems = [...selectedItems.items];
    const { rowTotal, ...rest } = newItems[key];
    newItems.splice(key, 1, {
      rowTotal: amt,
      ...rest,
    });
    setSelectedItems({
      items: newItems,
      count: selectedItems.items.length,
    });
  };
  const newColumns = invoiceTableColumns(
    {
      handleTaxableChange: handleTaxableChange,
      handleRemove: handleRemove,
      selectedItems: selectedItems,
      orgData: orgData,
      isSalesInvoice: isSalesInvoice,
    },
    updateSubtotal
  ).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        selectedItems: selectedItems,
        availableItems: availableItems,
        setToggleNewInvModal: setToggleNewInvModal,
        rentalRange: rentalRange,
        editingInvoice: editing,
      }),
    };
  });
  const applyDuration = () => {
    const updated = UpdateDurationRowTotal(selectedItems, rentalRange);
    setSelectedItems({
      items: updated,
      count: updated.length,
    });
  };
  const renderTitle = () => {
    return (
      <Row justify="space-between" style={{ marginBottom: "12px" }}>
        <Col>
          {editing ? (
            <Space>
              <Button onClick={handleAdd}>Add Item</Button>
              <Button onClick={handleAddSubtotal}>Add Subtotal</Button>
            </Space>
          ) : (
            // <Button onClick={() => {
            //   setEditing(true)
            // }}>Edit Line Items</Button>

            <LoadingButton
            variant="outlined"
              loading={loadingAllInventory}
              onClick={() => {
                setEditing(true);
              }}
            >
              Edit Line Items
            </LoadingButton>
          )}
        </Col>
        <Col>
          {!isSalesInvoice && (
            <Button type="link" onClick={applyDuration}>
              Apply Duration ({rentalRange})
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  const configureContextMenu = (event, record, scrollTop) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!contextMenu.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenu({
          item: null,
          visible: false,
          x: 0,
          y: 0,
        });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenu({
      item: record,
      visible: true,
      x: event.clientX - 256,
      y: event.clientY + scrollTop,
    });
  };

  useEffect(() => {}, [selectedItems]);

  return (
    <div className="item-table">
      {renderTitle()}
      <Table
        pagination={false}
        locale={{ emptyText: EmptyTable }}
        size="small"
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={selectedItems.items}
        columns={newColumns}
        onRow={(record, rowIndex) => {
          return {
            onContextMenu: (event) => {
              if (setContextMenu) {
                const el =
                  document.getElementsByClassName("ant-layout-content");
                const scrollTop = el[0].scrollTop;
                configureContextMenu(event, record, scrollTop);
              }
            },
          };
        }}
        summary={() => (
          <InvoiceTableSummary
            setInvoiceTotal={setInvoiceTotal}
            invoiceTotal={invoiceTotal}
            selectedItems={selectedItems}
            customer={customer}
            currentInvoice={currentInvoice}
            invoiceTaxRate={invoiceTaxRate}
            setInvoiceTaxRate={setInvoiceTaxRate}
            setDeliveryFee={setDeliveryFee}
            deliveryFee={deliveryFee}
            setDeliveryFeeTaxable={setDeliveryFeeTaxable}
            deliveryFeeTaxable={deliveryFeeTaxable}
            getShippingFee={getShippingFee}
          />
        )}
      />
    </div>
  );
};

export default InvoiceItems;
