import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import notificationError from "../../system-components/toasters/notificationError";
import { invoiceWrites } from "../firestore/writes/invoiceWrites";
import notificationConfirm from "../../system-components/toasters/notificationConfirm";
import {
  UpdateParentFlagFromChildren,
} from "./helpers/qrItems/updateParentFlagFromChildren";
import { UpdateQrItemInArray } from "./helpers/qrItems/updateQrItemInArray";
import { QrItemPayload } from "./helpers/qrItems/qrItemPayload";
import { FindParentBundleFromItem } from "./helpers/qrItems/findParentBundleFromItem";
import { UpdateParentCompletedFromChild } from "./helpers/qrItems/updateParentCompletedFromChild";
import { UpdateChildrenIfParentComplete } from "./helpers/qrItems/updateChildrenIfParentComplete";
import { DetermineInvoiceStatus } from "./helpers/qrItems/determineInvoiceStatus";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";

export function useUpdateQrItem({ invoice, item, invoiceCallback }) {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });
  const [completed, setCompleted] = useState(0);
  const [qtyRented, setQtyRented] = useState(0);
  const [flagged, setFlagged] = useState(false);
  const [notes, setNotes] = useState(null);
  const [issueDescription, setIssueDescription] = useState("");
  const { authenticated, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();

  const hasBundleChildren =
    item.bundleChildrenIds && item.bundleChildrenIds.length !== 0;
  const callback = async (data) => {
    if (!invoiceCallback) return;
    invoiceCallback(data);
  };
  const updateToFirestore = (id, payload) => {
    invoiceWrites({ firestore }, fsOrgPrefix).updateInvoice(id, payload);
  };
  const errorNotif = () => {
    notificationError("Changes could not be saved", "Refresh the page");
  };

  const updateFlag = async ({ flag, description }) => {
    if (!item) return;
    const itemPayload = QrItemPayload().flag({ item, flag, description });
    const newQrItems = UpdateParentFlagFromChildren({
      invoice,
      item,
      itemPayload,
      flag,
    });
    const { qrItems, qrStatus, ...invoiceRest } = invoice;
    const payload = {
      qrItems: [...newQrItems],
      qrStatus: DetermineInvoiceStatus(newQrItems),
    };
    setFlagged(flag);
    setIssueDescription(itemPayload.issueDescription);
    try {
      callback({ ...invoiceRest, ...payload });
      updateToFirestore(invoice.id, payload);
    } catch (err) {
      console.log(err);
      setFlagged(!flag);
      setIssueDescription(item.issueDescription);
      errorNotif();
    }
  };

  const updateNotes = async ({ notes: note }) => {
    if (!item) return;
    const newQrItems = UpdateQrItemInArray({
      invoice,
      item,
      payload: QrItemPayload().notes({ item, note }),
    });
    const { qrItems, qrStatus, ...invoiceRest } = invoice;
    const payload = {
      qrItems: [...newQrItems],
      qrStatus: DetermineInvoiceStatus(newQrItems),
    };
    setNotes(note);
    try {
      callback({ ...invoiceRest, ...payload });
      updateToFirestore(invoice.id, payload);
    } catch (err) {
      console.log(err);
      setNotes(item.notes);
      errorNotif();
    }
  };

  const updateCompleted = async ({ count }) => {
    if (!item) return;
    const newQrItems = UpdateQrItemInArray({
      invoice,
      item,
      payload: QrItemPayload().completed({ item, count }),
    });
    const { qrItems, qrStatus, ...invoiceRest } = invoice;
    const parent = FindParentBundleFromItem(newQrItems, item);
    const updatedItems = parent
      ? UpdateParentCompletedFromChild(parent, newQrItems)
      : [...newQrItems];
    const payload = {
      qrItems: updatedItems,
      qrStatus: DetermineInvoiceStatus(updatedItems),
    };
    if (count === item.qtyRented) {
      notificationConfirm(`${item.name} completed`);
    }
    setCompleted(count);
    try {
      callback({ ...invoiceRest, ...payload });
      updateToFirestore(invoice.id, payload);
    } catch (err) {
      console.log(err);
      setCompleted(item.completed);
      errorNotif();
    }
  };

  const updateBundle = async ({ count }) => {
    if (!item) return;
    const ratio = count / item.qtyRented;
    const newQrItems = UpdateChildrenIfParentComplete({
      invoice,
      payload: QrItemPayload().bundleItem({ item, count }),
      ratio,
      item,
    });
    const { qrItems, qrStatus, ...invoiceRest } = invoice;
    if (ratio === 1) {
      notificationConfirm(`${item.name} completed`);
    }
    const payload = {
      qrItems: [...newQrItems],
      qrStatus: DetermineInvoiceStatus(newQrItems),
    };
    setCompleted({ ...count });
    try {
      callback({ ...invoiceRest, ...payload });
      updateToFirestore(invoice.id, payload);
    } catch (err) {
      console.log(err);
      setCompleted(item.completed);
      errorNotif();
    }
  };

  useEffect(() => {
    if (!authenticated) return;
    setFlagged(item.hasIssue);
    setNotes(item.notes);
    setIssueDescription(item.issueDescription);
    setQtyRented(item.qtyRented);
    setCompleted(item.completed);
  }, [authenticated, item]);

  return {
    updating: updating,
    error: error,
    flagged: flagged,
    issueDescription: issueDescription,
    notes: notes,
    hasBundleChildren: hasBundleChildren,
    completed: completed,
    qtyRented: qtyRented,
    updateCompleted: updateCompleted,
    updateBundle: updateBundle,
    updateNotes: updateNotes,
    callback: callback,
    updateFlag: updateFlag,
  };
}
