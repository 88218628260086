import {
  QR_STATUS_COMPLETE,
  QR_STATUS_HAS_ISSUE,
  QR_STATUS_IN_PROGRESS,
} from "../../../app/utils/models/modelConstants/modelConstants";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import React from "react";
import { Badge } from "antd";

export const renderStatusIcon = (status) => {
  if (status === QR_STATUS_COMPLETE)
    return <CheckCircleFilled style={{ color: COLOR_GREEN_0 }} />;
  if (status === QR_STATUS_HAS_ISSUE) return <Badge dot color={COLOR_RED_0} />;
  return <Badge dot color={COLOR_YELLOW_0} />;
};

export const renderOverbookedIcon = (invoice, dailyAvailable) => {
  if (!invoice) return;
  if (!dailyAvailable) return;
  const overbooked =
    dailyAvailable &&
    dailyAvailable.stockList.some((i) => {
      return (
        (invoice.selectedItemsQueryHook.includes(i.id) ||
          invoice.bundleItemsQueryHook.includes(i.id)) &&
        i.isOverbooked === true
      );
    });
  return overbooked ? <WarningFilled style={{ color: "#FFD54F" }} /> : null;
};

export const renderOverbookedIconInv = (inv, dailyAvailable) => {
  console.log(inv);
  if (!inv) return;
  if (!dailyAvailable) return;
  const overbooked =
    dailyAvailable &&
    dailyAvailable.stockList.find((i) => {
      const sameItem = i.id === inv.id;
      return sameItem && i.isOverbooked === true;
    });
  return overbooked ? <WarningFilled style={{ color: "#FFD54F" }} /> : null;
};

export const renderInvStatusIcon = (item) => {
  const progress = item.completed / item.qtyRented;
  const complete = progress === 1;

  if (complete) return <CheckCircleFilled style={{ color: COLOR_GREEN_0 }} />;
  if (item.hasIssue) return <Badge dot color={COLOR_RED_0} />;
  return <Badge dot color={COLOR_YELLOW_0} />;
};
