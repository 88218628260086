type SendType = 'immediate' | 'after' | 'on' | 'before'
type SendException = 'recent'
type TriggerType = 'draft_to_estimate' | 'estimate_to_invoice' | 'estimate_signed' | 'first_payment_made' | 'payment_made' | 'paid_in_full' | 'loaded' | 'delivered' | 'picked_up' | 
'rental_start_date' | 'rental_end_date' | 'event_date' | 'payment_due_date' | 'order_created' | 'order_items_updated' 

export type AutomationTrigger = {
    id: string;
    displayName: string;
    description: string;
    sendTypes: SendType[];
    exceptions: SendException[];
    trigger: TriggerType;
}

export type Automation = {
    id: string;
    name: string;
    daysAfter?: number | undefined | null;
    daysBefore?: number | undefined | null;
    sendOnSelected: boolean;
    sendAfterSelected: boolean;
    sendBeforeSelected: boolean;
    sendImmediatelySelected: boolean;
    emailTemplateIds: any[];
    object: string;
    automationTrigger: AutomationTrigger;
    triggerType: TriggerType;
    reviewFirst: boolean;
}

export type ScheduledEmail = {
    id: string;
    invoiceId: string;
    invoiceNumber: string;
    customerName: string;
    customerEmail: string;
    automation: Automation;
    triggerType: TriggerType;
    reviewFirst: boolean;
    scheduledForDate: Date;
    scheduledForISO: string;
    sentOn: Date | null;
    status: 'complete' | 'failed' | 'canceled' | 'pending';
    sendFailed: boolean;
}

export const allTriggers: AutomationTrigger[] = [
    {
        id: 'draft_to_estimate',
        displayName: 'Draft Becomes Estimate',
        description: 'When a draft is converted to an estimate',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'draft_to_estimate'
    },
    {
        id: 'estimate_to_invoice',
        displayName: 'Estimate Becomes Invoice',
        description: 'When an estimate is converted to an invoice',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'estimate_to_invoice'
    },
    {
        id: 'estimate_signed',
        displayName: 'Estimate Signed',
        description: 'When an estimate is signed',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'estimate_signed'
    },
    // {
    //     id: 'first_payment_made',
    //     displayName: 'First Payment Made',
    //     description: 'When the first payment is made on an invoice',
    //     sendTypes: ['immediate', 'after'],
    //     exceptions: ['recent'],
    //     trigger: 'first_payment_made'
    // },
    // {
    //     id: 'payment_made',
    //     displayName: 'Payment Made',
    //     description: 'When a payment is made on an invoice',
    //     sendTypes: ['immediate', 'after'],
    //     exceptions: ['recent'],
    //     trigger: 'payment_made'
    // },
    // {
    //     id: 'paid_in_full',
    //     displayName: 'Paid in Full',
    //     description: 'When an invoice is paid in full',
    //     sendTypes: ['immediate', 'after'],
    //     exceptions: ['recent'],
    //     trigger: 'paid_in_full'
    // },
    {
        id: 'loaded',
        displayName: 'Loaded',
        description: 'When an order is loaded',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'loaded'
    },
    {
        id: 'delivered',
        displayName: 'Delivered',
        description: 'When an order is delivered',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'delivered'
    },
    {
        id: 'picked_up',
        displayName: 'Picked Up',
        description: 'When an order is picked up',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'picked_up'
    },
    {
        id: 'rental_start_date',
        displayName: 'Rental Start Date',
        description: 'When the rental start date is reached',
        sendTypes: ['on', 'before', 'after'],
        exceptions: ['recent'],
        trigger: 'rental_start_date'
    },
    {
        id: 'rental_end_date',
        displayName: 'Rental End Date',
        description: 'When the rental end date is reached',
        sendTypes: ['on', 'before', 'after'],
        exceptions: ['recent'],
        trigger: 'rental_end_date'
    },
    {
        id: 'event_date',
        displayName: 'Event Date',
        description: 'When the event date is reached',
        sendTypes: ['on', 'before', 'after'],
        exceptions: ['recent'],
        trigger: 'event_date'
    },
    {
        id: 'payment_due_date',
        displayName: 'Payment Due Date',
        description: 'When the payment due date is reached',
        sendTypes: ['on', 'before', 'after'],
        exceptions: ['recent'],
        trigger: 'payment_due_date'
    },
    {
        id: 'order_created',
        displayName: 'Order Created',
        description: 'When an order is created',
        sendTypes: ['immediate', 'after'],
        exceptions: ['recent'],
        trigger: 'order_created'
    },
    // {
    //     id: 'order_items_updated',
    //     displayName: 'Order Items Updated',
    //     description: 'When the items on an order are updated',
    //     sendTypes: ['immediate', 'after'],
    //     exceptions: ['recent'],
    //     trigger: 'order_items_updated'
    // },
]