import {
    Button,
    Drawer,
    Row,
    Col,
    Space,
} from "antd";

import InvoiceSendRecords from "./InvoiceSendRecords";

const InvoiceEmailDrawer = ({
    visible,
    handleCloseDrawer,
    invoice,
}) => {

    const onClose = () => {
        handleCloseDrawer({
            visible: false,
            item: null,
        });
    };

    return (
        <Drawer
            width={600}
            title={
                <Row wrap={false} style={{ width: "100%" }} align="middle">
                    <Col flex={"auto"}>
                        Invoice: {invoice && invoice.invoiceNumber}
                    </Col>
                    <Col flex={"145px"} style={{ textAlign: "right" }}>
                        <Space>
                            <Button onClick={handleCloseDrawer}>
                                Close
                            </Button>
                        </Space>
                    </Col>
                </Row>
            }
            destroyOnClose={true}
            placement={"right"}
            closable={false}
            onClose={onClose}
            open={visible}
            key={"item"}
        >
            <InvoiceSendRecords invoiceId={invoice && invoice.id}/>

        </Drawer>
    );
};

export default InvoiceEmailDrawer;
