import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import notificationError from '../../../system-components/toasters/notificationError';
import { CUSTOMER_COLLECTION } from '../../../utils/models/collections/collectionConstants';
import { Customer } from '../../../interfaces/customer';

export function customerWrites({ firestore }: { firestore: ExtendedFirestoreInstance }, orgPrefix: string) {
  if (!orgPrefix) return notificationError('Organization error', 'Cannot determine organization');
  const ref = firestore.collection(`${orgPrefix}${CUSTOMER_COLLECTION}`);

  async function createCustomer(payload: any) {

    // First check to make sure there is not another customer with the same customer display name:
    const customerExists = await ref.where('customerDisplayName', '==', payload.customerDisplayName).get();
    if (!customerExists.empty) {
      notificationError('Customer exists', 'A customer with the same display name already exists');
      const error = new Error('Customer exists');
      throw error;
    }
    const newRef = firestore.collection(`${orgPrefix}${CUSTOMER_COLLECTION}`).doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function editCustomer(id: string, payload: Partial<Customer>) {
    delete payload.additionalContacts;
    return await ref.doc(id).set({ ...payload }, { merge: true });
  }
  async function deleteCustomer(id: string) {
    return await ref.doc(id).delete();
  }

  return {
    createCustomer: createCustomer, // create customer
    editCustomer: editCustomer, // edit customer
    deleteCustomer: deleteCustomer, // delete customer
  };
}
