import { checkUndefined, checkUndefinedOrNull } from "./checkUndefined";
import { centsToDollars } from "./checkCurrencyToDollars";
import { dollarToCents } from "../configure/configureCurrencyToCents";
import { formatDateOnRead } from "../../time/formatTimezoneOffset";

export const CheckPayment = (snap, orgTimezone) => {
  if (!snap) return;
  const data = snap.data();

  const configureFulfilled = (fulfilled) => {
    return fulfilled.map((i) => {
      const { amount, invoiceId, ...rest } = i;
      return {
        fulfilled: centsToDollars(fulfilled),
        id: invoiceId,
        amount: centsToDollars(amount),
        ...rest,
      };
    });
  };

  const refunds = data.refunds;

  return {
    orgId: checkUndefined(data.orgId, null),
    id: checkUndefined(data.id, ""),
    customer: checkUndefined(data.customer, null),
    customerQueryHook: checkUndefined(data.customerQueryHook, ""),
    invoicesQueryHook: checkUndefined(data.invoicesQueryHook, []),
    total: centsToDollars(checkUndefined(data.total, 0)),
    credit: centsToDollars(checkUndefined(data.credit, 0)),
    invoices: configureFulfilled(checkUndefined(data.invoices, [])),
    lastSyncDate: checkUndefined(data.lastSyncDate, null),
    lastSyncSuccess: checkUndefined(data.lastSyncSuccess, null),
    paymentDate: formatDateOnRead({
      date: checkUndefined(data.paymentDate, null),
      orgTimezone,
    }),
    paymentMethod: checkUndefined(data.paymentMethod, null),
    refNo: checkUndefined(data.refNo, null),
    refunds: checkUndefinedOrNull(refunds, []),
    status: checkUndefined(data.status, null),
    stripeIntentId: checkUndefined(data.stripeIntentId, null),
    stripeFees: centsToDollars(checkUndefined(data.stripeFees, null)),
    stripeNet: centsToDollars(checkUndefined(data.stripeNet, null))
  };
};
