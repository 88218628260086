import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Table, Row, Col, Space, Popconfirm } from "antd";
import { Card } from "antd";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { SketchPicker } from "react-color";

const TagsView = () => {
  const [form] = Form.useForm();
  const firebase = useFirebase();
  const firestore = useFirestore();

  const { orgData, fsOrgPrefix, userData } = useSelector(authSelector);

  const [tags, setTags] = useState([]);
  const [tagColor, setTagColor] = useState("#000000");

  const handleColorChange = (color) => setTagColor(color.hex);

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const newTag = {
      key: Date.now().toString(),
      name: values.name,
      description: values.description || '',
      color: tagColor,
    };
    // Add the new tag to the local state
    setTags((prev) => [...prev, newTag]);

    // Reset the form fields
    form.resetFields();
    setTagColor("#000000");

    // Add the new tag to Firestore
    const docRef = await firestore.collection(`orgs/${orgData.id}/tags`).add(newTag);
    newTag.id = docRef.id; // Get the id of the newly added document
  };

  const deleteTag = async (tag) => {
    // Remove the tag from Firestore
    await firestore.collection(`orgs/${orgData.id}/tags`).doc(tag.id).delete();
  
    // Remove the tag from the local state
    setTags(tags.filter(t => t.id !== tag.id));
  };
  

  useEffect(() => {
    if (orgData) {
      const fetchTags = async () => {
        const snapshot = await firestore.collection(`orgs/${orgData.id}/tags`).get();
        const fetchedTags = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(fetchedTags);
      };

      fetchTags();
    }
  }, [firestore, orgData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: '35%',  // Adjust these percentages as needed
    },
    {
      title: "Description",
      dataIndex: "description",
      width: '35%',
    },
    {
      title: "Color",
      dataIndex: "color",
      render: (_, record) => {
        return (
          <div
            style={{ backgroundColor: record.color, width: 30, height: 30 }}
          />
        );
      },
      width: '20%',
    },
    {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Popconfirm
              title="Are you sure to delete this tag?"
              onConfirm={() => deleteTag(record)}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
        width: '10%',
      }
  ];
  

  return (
    <>
      <Row>
        <Col flex="auto">
          <Card style={{ margin: "0 12px 12px" }} size="small" title="Tags">
            <Table
              columns={columns}
              dataSource={tags}
              rowKey="key"
            />
          </Card>
        </Col>
  
        <Col flex="none">
          <Card title={'Add Tag'} style={{ marginRight: "12px"}}>
            <Form form={form} onFinish={handleSave}>
              <Space direction="vertical">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Please input the tag name!" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item name="description">
                  <Input placeholder="Description" />
                </Form.Item>
                <SketchPicker color={tagColor} onChangeComplete={handleColorChange} />
                <Button type="primary" htmlType="submit">Save</Button>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TagsView;
