import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  InputNumber,
  Row,
  Modal,
  Space,
  Tag,
  Typography,
} from "antd";
import {
  FlagFilled,
  SnippetsFilled,
  ExclamationCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { useUpdateQrItem } from "../../../app/services/hooks/useUpdateQrItem";
import { configureUpdateCategory } from "../../../app/utils/models/configure/configureUpdateCategory";
import StockCounter from "./modules/stockCounter";

const { TextArea } = Input;
const ItemCompletedTracker = ({
  invoice,
  item,
  type,
  refreshInvoiceData,
  dailyAvailable,
}) => {
  const {
    updateFlag,
    flagged,
    issueDescription,
    hasBundleChildren,
    notes,
    completed,
    qtyRented,
    updateNotes,
    updateCompleted,
    updateBundle,
  } = useUpdateQrItem({
    invoice: invoice,
    item: item,
    invoiceCallback: refreshInvoiceData,
  });
  const handleIssueClicked = () => {
    updateFlag({
      flag: !flagged,
    });
  };

  const handleIssueDesc = (e) => {
    updateFlag({
      flag: flagged,
      description: e.target.value,
    });
  };

  const handleNote = (e) => {
    updateNotes({
      notes: e.target.value,
    });
  };

  const handleCompletedCount = (c) => {
    const count = Number(c.target.value);
    const safeCount = count > qtyRented ? qtyRented : count;
    if (safeCount === completed) return;
    if (!hasBundleChildren) {
      updateCompleted({
        count: safeCount,
      });
    } else {
      showPromiseConfirm(safeCount);
    }
  };

  const handleCompletedCountEnter = () => {
    inputRef.current.blur();
  };

  function showPromiseConfirm(c) {
    Modal.confirm({
      title: "Update all bundle items?",
      icon: <ExclamationCircleOutlined />,
      content: "This will re-calculate all completed bundle items",
      onOk() {
        return new Promise((resolve, reject) => {
          // hook
          updateBundle({
            count: c,
          }).then(() => {
            resolve();
          });
        }).catch((err) => console.log(err));
      },
      onCancel() {},
    });
  }

  const configureFlagCopy = () => {
    if (hasBundleChildren && flagged) return "Bundle item flagged";
    if (hasBundleChildren && !flagged) return "";
    if (!hasBundleChildren && flagged) return null;
    return "Flag Item";
  };

  const inputRef = React.useRef();
  const issueDescInput = React.useRef();

  return (
    <div style={{ padding: "4px" }}>
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{
          borderBottom: `1px ${COLOR_TEXT_GRAY_2} solid`,
        }}
      >
        <Col>
          <Space>
            <TextBody
              text={item.name}
              style={{ fontSize: "15px", fontWeight: "bold" }}
            />
            <TextBody
              text={item.description}
              style={{ color: COLOR_TEXT_GRAY_1 }}
            />
          </Space>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <div
            style={{
              padding: "6px",
            }}
          >
            <Space>
              {item && dailyAvailable && (
                <StockCounter dailyAvailable={dailyAvailable} item={item} />
              )}
              {hasBundleChildren ? (
                <Tag
                  style={{
                    lineHeight: "30px",
                    borderColor: COLOR_LIGHT_BLUE,
                    backgroundColor: COLOR_LIGHT_BLUE,
                    color: COLOR_BLUE_0,
                    fontSize: "14px",
                  }}
                >
                  Bundles
                </Tag>
              ) : null}
              <InputNumber
                ref={inputRef}
                onPressEnter={handleCompletedCountEnter}
                min={0}
                max={item.qtyRented}
                key={`${item.id}_${completed}`}
                defaultValue={completed}
                placeholder={"Items"}
                onBlur={(c) => handleCompletedCount(c)}
              />{" "}
              <TextBody
                style={{ fontSize: "14px" }}
                text={`/ ${item.qtyRented}`}
              />{" "}
              {completed === qtyRented && (
                <CheckCircleFilled
                  style={{ color: COLOR_GREEN_0, marginRight: "8px" }}
                />
              )}
            </Space>
          </div>
        </Col>
      </Row>
      <div style={{ width: "100%", marginTop: "8px" }}>
        <Space direction={"vertical"} style={{ width: "100%" }} align={"top"}>
          {!hasBundleChildren && (
            <Row style={{ width: "100%", marginBottom: "8px" }} wrap={false}>
              <Col flex={"none"}>
                <Button
                  key={item.id}
                  style={{
                    borderColor: "transparent",
                    backgroundColor: flagged ? COLOR_RED_0 : COLOR_LIGHT_GRAY,
                    color: flagged ? "#FFF" : "#0F0F11",
                  }}
                  onClick={() => handleIssueClicked()}
                >
                  <FlagFilled style={{ color: flagged ? "#FFF" : "#0F0F11" }} />
                  {configureFlagCopy()}
                </Button>
              </Col>
              {flagged ? (
                <Col flex={"auto"} style={{ marginLeft: "0" }}>
                  <Input
                    ref={issueDescInput}
                    onBlur={handleIssueDesc}
                    onPressEnter={() => {
                      issueDescInput.current.blur();
                    }}
                    placeholder={"Why was this item flagged?"}
                    defaultValue={issueDescription}
                    style={{
                      color: COLOR_RED_0,
                      borderTopLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      marginLeft: "-4px",
                      borderColor: "transparent",
                      fontWeight: "bold",
                    }}
                  />
                </Col>
              ) : null}
            </Row>
          )}
          <TextArea
            key={`${item.id}_${notes}`}
            onBlur={handleNote}
            defaultValue={notes}
            placeholder={"Add notes"}
            bordered={false}
            style={{ borderColor: "transparent", width: "100%" }}
            autoSize={true}
          />
        </Space>
      </div>
    </div>
  );
};

export default ItemCompletedTracker;
