import { createSlice } from "@reduxjs/toolkit";
import {
  ORG_COLLECTION,
  USERS_COLLECTION,
} from "../../app/utils/models/collections/collectionConstants";
import checkUser from "../../app/utils/models/checkers/checkUser";
import checkOrg from "../../app/utils/models/checkers/checkOrg";
import checkEmailSettings from "../../app/utils/models/checkers/checkEmailSettings";

import { ROUTE_LOGIN } from "../../app/routes";
import notificationError from "../../app/system-components/toasters/notificationError";
import { checkUndefinedOrNull } from "../../app/utils/models/checkers/checkUndefined";
import { minimumDefaultRoutePermissions } from "../admin-permissions/usePermissions";

export const initialState = {
  userData: null,
  orgData: null,
  permissionsData: minimumDefaultRoutePermissions,
  invoiceEmailSettings: null,
  estimateEmailSettings: null,
  // embeddedCheckoutSettings: null,
  authenticated: false,
  verifyingAuthInProgress: false,
  fromLogout: false,
  fsOrgPrefix: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /* From documentation: Insert reducers an object, where the keys will become action type strings,
		and the functions are reducers that will be run when that action type is dispatched.*/
    setAuthOrgData(state, { payload }) {
      state.userData = payload.userData;
      state.orgData = payload.orgData;
      state.permissionsData = payload.permissionsData;
      state.adminSettingsGeneral = payload.adminSettingsGeneral;
      state.estimateEmailSettings = payload.estimateEmailSettings;
      state.invoiceEmailSettings = payload.invoiceEmailSettings;
      // state.embeddedCheckoutSettings = payload.embeddedCheckoutSettings

      state.authenticated = true;
      state.fsOrgPrefix = `${ORG_COLLECTION}/${payload.orgData.id}/`;
    },
    setRefreshedOrgData(state, { payload }) {
      state.orgData = payload;
    },
    verifyingAuth(state, { payload }) {
      state.verifyingAuthInProgress = payload;
    },
    setLogout(state, { payload }) {
      state.userData = payload.userData;
      state.orgData = payload.orgData;
      state.authenticated = false;
    },
    setFromLogout(state, { payload }) {
      state.fromLogout = payload;
    },
  },
});

// Export keys as actions
export const {
  setAuthOrgData,
  verifyingAuth,
  setLogout,
  setFromLogout,
  setRefreshedOrgData,
} = authSlice.actions;

// Export selector as name from slice
export const authSelector = (state) => state.auth;

// Default export for reducer to get referenced in root reducer
export default authSlice.reducer;

// Thunk actions
export const login =
  ({ firebase, firestore }, payload, history) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password);

      const userId = firebase.auth().currentUser.uid;
      let userData = null;
      // find user
      const userGroupCollection = firestore
        .collectionGroup(USERS_COLLECTION)
        .where("id", "==", userId);
      userGroupCollection
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach((s) => {
            userData = checkUser(s);
          });
        })
        .then(async () => {
          // load org
          const orgSnap = await firestore
            .collection(ORG_COLLECTION)
            .doc(userData.orgId)
            .get();
          const orgData = checkOrg(orgSnap);

          const snapshot = await firestore
            .collection(`orgs/${orgData.id}/tags`)
            .get();
          

          if (snapshot.docs.length > 0) {
            const fetchedTags = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            Object.assign(orgData, { tags: checkUndefinedOrNull(fetchedTags, []) });
          }

          const invoiceEmailSettingsSnap = await orgSnap.ref
            .collection("settings")
            .doc("invoiceEmailSettings")
            .get();
          const invoiceEmailSettings = checkEmailSettings(
            invoiceEmailSettingsSnap
          );

          const estimateEmailSettingsSnap = await orgSnap.ref
            .collection("settings")
            .doc("estimateEmailSettings")
            .get();
          const estimateEmailSettings = checkEmailSettings(
            estimateEmailSettingsSnap
          );
          const permissionsSettingsSnap = await orgSnap.ref
          .collection("settings")
          .doc("permissions")
          .get();
          const permissionsData = permissionsSettingsSnap.exists ? permissionsSettingsSnap.data() : minimumDefaultRoutePermissions;

            if (!permissionsData.hasOwnProperty("/admin-billing")) {
              permissionsData["/admin-billing"] = ["admin"];
            }

            if (!permissionsData.hasOwnProperty("/preview")) {
              permissionsData["/invoices/preview/:id"] = ["admin", "office"];
            }
            if (!permissionsData.hasOwnProperty("/preview")) {
              permissionsData["/invoices/preview/"] = ["admin", "office"];
            }

          dispatch(
            setAuthOrgData({
              userData,
              orgData,
              permissionsData,
              estimateEmailSettings,
              invoiceEmailSettings,
            })
          );
          dispatch(verifyingAuth(false));
        });
    } catch (err) {
      console.log(err);
      notificationError(
        "Unable to login",
        "Password is incorrect or user does not exist"
      );
      dispatch(verifyingAuth(false));
      history.push(ROUTE_LOGIN);
    }
  };

export const verifyAuth =
  ({ firestore, firebase }, history) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          let userData = null;
          const userId = user.uid;
          // find user
          const userGroupCollection = firestore
            .collectionGroup(USERS_COLLECTION)
            .where("id", "==", userId);
          userGroupCollection
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach((s) => {
                userData = checkUser(s);
              });
            })
            .then(async () => {
              if (userData === null) {
                dispatch(verifyingAuth(false));
                dispatch(
                  setLogout({
                    userData: null,
                    orgData: null,
                    estimateEmailSettings: null,
                    permissionsData: null,
                  })
                );
                history.push(ROUTE_LOGIN);
                return;
              }
              // load org
              const orgSnap = await firestore
                .collection(ORG_COLLECTION)
                .doc(userData.orgId)
                .get();
              const orgData = checkOrg(orgSnap);

              const snapshot = await firestore
                .collection(`orgs/${orgData.id}/tags`)
                .get();
              const fetchedTags = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              Object.assign(orgData, { tags: fetchedTags });

              const invoiceEmailSettingsSnap = await orgSnap.ref
                .collection("settings")
                .doc("invoiceEmailSettings")
                .get();
              const invoiceEmailSettings = checkEmailSettings(
                invoiceEmailSettingsSnap
              );
              const estimateEmailSettingsSnap = await orgSnap.ref
                .collection("settings")
                .doc("estimateEmailSettings")
                .get();
              const estimateEmailSettings = checkEmailSettings(
                estimateEmailSettingsSnap
              );

              if (!invoiceEmailSettingsSnap.exists) {
                orgSnap.ref
                  .collection("settings")
                  .doc("invoiceEmailSettings")
                  .set(orgData.invoiceEmailSettings);
              }
              if (!estimateEmailSettingsSnap.exists) {
                orgSnap.ref
                  .collection("settings")
                  .doc("estimateEmailSettings")
                  .set(orgData.estimateEmailSettings);
              }

              const permissionsSettingsSnap = await orgSnap.ref
              .collection("settings")
              .doc("permissions")
              .get();
              const permissionsData = permissionsSettingsSnap.exists ? permissionsSettingsSnap.data() : minimumDefaultRoutePermissions;
              if (!permissionsData.hasOwnProperty("/admin-billing")) {
                permissionsData["/admin-billing"] = ["admin"];
              }
              if (!permissionsData.hasOwnProperty("/preview")) {
                permissionsData["/invoices/preview/:id"] = ["admin", "office"];
              }
              if (!permissionsData.hasOwnProperty("/preview")) {
                permissionsData["/invoices/preview/"] = ["admin", "office"];
              }
  
              
              dispatch(
                setAuthOrgData({
                  userData,
                  orgData,
                  permissionsData,
                  estimateEmailSettings,
                  invoiceEmailSettings,
                })
              );
              dispatch(verifyingAuth(false));
            });
        } else {
          // user does not exist
          dispatch(verifyingAuth(false));
          dispatch(
            setLogout({
              userData: null,
              orgData: null,
              permissionsData: null,
              estimateEmailSettings: null,
            })
          );
          history.push(ROUTE_LOGIN);
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(verifyingAuth(false));
      history.push(ROUTE_LOGIN);
    }
  };

export const logout =
  ({ firebase }) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(
          setLogout({
            userData: null,
            orgData: null,
            estimateEmailSettings: null,
            permissionsData: null,
          })
        );
        dispatch(verifyingAuth(false));
      });
  };

export const setLogoutState = () => async (dispatch) => {
  dispatch(setFromLogout(true));
};

export const refreshOrgState =
  ({ firestore }, orgData) =>
  async (dispatch) => {
    try {
      const orgRef = firestore.collection(ORG_COLLECTION).doc(orgData.id);

      const snapshot = await firestore
        .collection(`orgs/${orgData.id}/tags`)
        .get();
      const fetchedTags = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      await orgRef.get().then((orgSnap) => {
        const orgData = checkOrg(orgSnap);
        Object.assign(orgData, { tags: fetchedTags });
        dispatch(setRefreshedOrgData({ ...orgData }));
      });
    } catch (err) {
      console.log(err);
      notificationError("Something went wrong", "Please try again later");
    }
  };
