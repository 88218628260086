import { RECEIVE_METHOD_DELIVERY, RETURN_METHOD_ONSITE } from "../../../app/utils/models/modelConstants/modelConstants";

export type ReceiveDetailsProps = {
    receiveBy: string;
    receivePlace: google.maps.places.PlaceResult | null;
    useCustomerAddressReceive: boolean;
    specifyReceiveTime: boolean;
    selectedReceiveVenue: string;
    specifiedReceiveTime: Date | null;
    specifiedReceiveTimeEnd: Date | null;
    manualReceiveAddress: string;
    manualReceiveCity: string;
    manualReceiveState: string;
    manualReceiveZip: string;
    deliveryCustomerDisplayName: string;
  }
  
  export type ReturnDetailsProps = {
    returnBy: string;
    returnPlace: google.maps.places.PlaceResult | null;
    useCustomerAddressReturn: boolean;
    specifyReturnTime: boolean;
    selectedReturnVenue: string;
    specifiedReturnTime: Date | null;
    specifiedReturnTimeEnd: Date | null;
    manualReturnAddress: string;
    manualReturnCity: string;
    manualReturnState: string;
    manualReturnZip: string;
    onSiteCustomerDisplayName: string
  }

  export type ReceiveReturnDetailsProps = {
    receiveDetails: ReceiveDetailsProps;
    returnDetails: ReturnDetailsProps;
  }

  export type FullReceiveReturnDetailsProps = {
    receiveBy: string;
    returnBy: string;
    receivePlace: google.maps.places.PlaceResult | null;
    returnPlace: google.maps.places.PlaceResult | null;
    useCustomerAddressReceive: boolean;
    useCustomerAddressReturn: boolean;
    specifyReceiveTime: boolean;
    specifyReturnTime: boolean;
    selectedReceiveVenue: string;
    selectedReturnVenue: string;
    specifiedReceiveTime: Date | null;
    specifiedReturnTime: Date | null;
    specifiedReceiveTimeEnd: Date | null;
    specifiedReturnTimeEnd: Date | null;
    deliveryCustomerDisplayName: string;
    onSiteCustomerDisplayName: string;
    manualReceiveAddress: string;
    manualReceiveCity: string;
    manualReceiveState: string;
    manualReceiveZip: string;
    manualReturnAddress: string;
    manualReturnCity: string;
    manualReturnState: string;
    manualReturnZip: string;
  }

  export const defaultReceiveDetailsProps: ReceiveDetailsProps = {
    receiveBy: RECEIVE_METHOD_DELIVERY,
    receivePlace: null, // Google place
    useCustomerAddressReceive: false,
    specifyReceiveTime: false,
    selectedReceiveVenue: "",
    specifiedReceiveTime: null,
    specifiedReceiveTimeEnd: null,
    manualReceiveAddress: "",
    manualReceiveCity: "",
    manualReceiveState: "",
    manualReceiveZip: "",
    deliveryCustomerDisplayName: "",
  }
  
  export const defaultReturnDetailsProps: ReturnDetailsProps = {
    returnBy: RETURN_METHOD_ONSITE,
    returnPlace: null,  // Google place
    useCustomerAddressReturn: false,
    specifyReturnTime: false,
    selectedReturnVenue: "",
    specifiedReturnTime: null,
    specifiedReturnTimeEnd: null,
    manualReturnAddress: "",
    manualReturnCity: "",
    manualReturnState: "",
    manualReturnZip: "",
    onSiteCustomerDisplayName: "",
  }

  export const defaultReceiveReturnDetailsProps: FullReceiveReturnDetailsProps = {
    receiveBy: RECEIVE_METHOD_DELIVERY,
    returnBy: RETURN_METHOD_ONSITE,
    receivePlace: null, // Google place
    returnPlace: null,  // Google place
    useCustomerAddressReceive: false,
    useCustomerAddressReturn: false,
    specifyReceiveTime: false,
    specifyReturnTime: false,
    selectedReceiveVenue: "",
    selectedReturnVenue: "",
    specifiedReceiveTime: null,
    specifiedReturnTime: null,
    specifiedReceiveTimeEnd: null,
    specifiedReturnTimeEnd: null,
    manualReceiveAddress: "",
    manualReceiveCity: "",
    manualReceiveState: "",
    manualReceiveZip: "",
    manualReturnAddress: "",
    manualReturnCity: "",
    manualReturnState: "",
    manualReturnZip: "",
    deliveryCustomerDisplayName: "",
    onSiteCustomerDisplayName: "",
  }
