import React, { useEffect, useRef, useState } from "react";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { Box, CardContent, Divider, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";

import { makeStyles } from "@mui/styles";
import checkSendRecord from "../../../app/utils/models/checkers/checkSendRecord";
import { Card } from "antd";
import { formatDateToMonthDay } from "../../../app/utils/time/convertTimeToDayString";

const { useFirebase } = require("react-redux-firebase");

const InvoiceSendRecords = ({ invoiceId }) => {
  const useStyles = makeStyles({
    card: {
      marginBottom: "20px",
    },
    timeline: {
      paddingLeft: 0,
      "& .MuiTimelineItem-root": {
        minHeight: "24px",
      },
    },
  });

  const classes = useStyles();
  const firebase = useFirebase();
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const [records, setRecords] = useState([]);
  const [groupedRecords, setGroupedRecords] = useState([])
  const [fetching, setFetching] = useState(false);
  const listener = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      listener.current = firebase
        .firestore()
        .collection("orgs")
        .doc(orgData.id)
        .collection("invoices")
        .doc(invoiceId)
        .collection("sendRecords")
        .orderBy("createdOn", "desc")
        .onSnapshot((snapshot) => {
          let data = [];
          snapshot.docs.forEach((s) => {
            data.push(checkSendRecord(s, orgData.orgTimezone));
          });

          setRecords(data);

          const groupedData = [];

          // Loop through each sendRecord
          data.forEach((record) => {
            const sendRecordGroup = [];

            // Loop through each email event in the current sendRecord
            record.emailEvents.forEach((event) => {
              // Find the corresponding email group in the current sendRecordGroup array
              const emailGroup = sendRecordGroup.find((group) => group.email === event.email);

              if (!emailGroup) {
                // If the email group doesn't exist, create a new one and add it to the sendRecordGroup array
                sendRecordGroup.push({ email: event.email, events: [event] });
              } else {
                // If the email group exists, simply push the event to the existing email group
                emailGroup.events.push(event);
              }
            });

            // After processing all email events for the current sendRecord, push the sendRecordGroup to the groupedData array
            groupedData.push({ sendRecord: record, emailGroups: sendRecordGroup });
          });
          // const grouped = data.reduce((acc, record) => {
          //   record.emailEvents.forEach((event) => {
          //     if (!acc[event.email]) {
          //       acc[event.email] = [];
          //     }
          //     acc[event.email].push(event);
          //   });
          //   return acc;
          // }, {});
          console.log('groupedData', groupedData)

          setGroupedRecords(groupedData)

        });
    };

    fetchData();

    return () => {
      // This line only evaluates to true after the componentWillUnmount happens
      const ref = listener.current;
      ref();
    };
  }, []); // dont remove dependency array.

  const formatEventName = (event) => {
    const eventType = event.event;
    if (eventType === "delivered") {
      return "Delivered";
    } else if (eventType === "open") {
      return "Opened";
    } else if (eventType === "bounce") {
      return "Unable To Deliver";
    } else if (eventType === "click") {
      return "Clicked";
    }
  };
  const formatEventColor = (event) => {
    if (event === "delivered") {
      return "secondary";
    } else if (event === "open") {
      return "success";
    } else if (event === "bounce") {
      return "error";
    } else if (event === "click") {
      return "success";
    }
  };

  return (
    <div>
      <Card
        // style={{ margin: "0 20px 20px 20px" }}
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Email Tracking"} />
        }
      >
        {groupedRecords.map((group, index) => (
          <Box key={index}>
            <Box className={classes.box}>
              <Typography variant="body1">Send Record: {group.sendRecord.createdOn.toLocaleString()}</Typography>
              {group.emailGroups.map((emailGroup, emailIndex) => (
                <Box key={emailIndex}>
                  <Typography variant="body1">Email: {emailGroup.email}</Typography>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {emailGroup.events.map((event, eventIndex) => (
                      <TimelineItem key={eventIndex}>
                        <TimelineSeparator>
                          <TimelineDot color={formatEventColor(event.event)} />
                          {eventIndex < emailGroup.events.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <div>
                            <Typography>
                              {event.timestamp.toLocaleString()}
                            </Typography>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {formatEventName(event)}
                            </Typography>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Box>
              ))}
            </Box>
            <Divider />
            <br />
          </Box>
        ))}
      </Card>
    </div>
  );
};

export default InvoiceSendRecords;
