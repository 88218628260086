import { DateRange } from "@mui/x-date-pickers-pro";

import { checkUndefined } from "../../../app/utils/models/checkers/checkUndefined";
import { FullReceiveReturnDetailsProps, ReceiveReturnDetailsProps } from "../types/ReceiveReturnDetails";
import { formatDateOnWrite } from "../../../app/utils/time/formatTimezoneOffset";
import { OrganizationData } from "../../../app/interfaces/organizationData";

export const configureReceiveReturnTimes = (orgData: OrganizationData, receiveReturnDetails: FullReceiveReturnDetailsProps, rentalRange: DateRange<Date>, selectedItems: any[]) => {
  // console.log("RECEIVE RETURN DETAILS", receiveReturnDetails);
  // console.log("RENTAL RANGE", rentalRange);
  // console.log("SELECTED ITEMS", selectedItems);

  if (!rentalRange)
    return {
      rentalDates: [],
      // start
      rentalDateStart: null,
      hasSpecifiedReceiveTime: false,
      specifiedReceiveTime: null,
      specifiedReceiveTimeEnd: null,
      hasReceiveWindow: false,
      // end
      rentalDateEnd: null,
      hasSpecifiedReturnTime: false,
      specifiedReturnTime: null,
      specifiedReturnTimeEnd: null,
      hasReturnWindow: false,
      customerAddressChecked: {
        return: true,
        receive: true,
      },
    };

  const start = checkUndefined(rentalRange[0], null);
  const end = checkUndefined(rentalRange[1], null);

  const hasReceiveTime = receiveReturnDetails.specifyReceiveTime;
  const hasReturnTime = receiveReturnDetails.specifyReturnTime;

  const receiveTime = receiveReturnDetails.specifiedReceiveTime ? receiveReturnDetails.specifiedReceiveTime : null;

  const addedReceiveTime = (receiveTime: Date | null, start: Date) => {
    if (!receiveTime) return start;
  
    const timeOnly = receiveTime.getTime() % (24 * 60 * 60 * 1000); // Time portion in milliseconds
    const startDate = new Date(start);
    startDate.setHours(0, 0, 0, 0); // Reset time to start of day
    startDate.setMilliseconds(timeOnly); // Add the time portion
  
    return startDate;
  };
  

  const addedReturnTime = (returnEndTime: Date | null, returnTime: Date | null, end: Date) => {
    if (!returnTime && !returnEndTime) return end;
  
    const rt = returnEndTime || returnTime;
    if (!rt) return end;
  
    const endDate = new Date(end);
    const timeOnly = rt.getTime() % (24 * 60 * 60 * 1000); // Time portion in milliseconds
    endDate.setHours(0, 0, 0, 0); // Reset time to start of day
    endDate.setMilliseconds(timeOnly); // Add the time portion
  
    return endDate;
  };
  

  const returnTime = receiveReturnDetails.specifiedReturnTime ? receiveReturnDetails.specifiedReturnTime : null;
  const receiveEndTime = receiveReturnDetails.specifiedReceiveTimeEnd ? receiveReturnDetails.specifiedReceiveTimeEnd : null;
  const returnEndTime = receiveReturnDetails.specifiedReturnTimeEnd ? receiveReturnDetails.specifiedReturnTimeEnd : null;

  // get beginning of day for start and end
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 0, 0);
  const mobileEnd = new Date(end);
  mobileEnd.setHours(12, 0, 0);

  const mobileEndBeforeOffset = mobileEnd.toISOString().substring(0, 10);

  const rentalStart = hasReceiveTime ? addedReceiveTime(receiveTime, start) : start;

  const rentalEnd = hasReturnTime ? addedReturnTime(returnEndTime, returnTime, end) : end;

  const rentalTurnaroundEnd = (rentalEnd: Date, selectedItems: any[]) => {
    const dayStart = new Date(rentalEnd);
    const turnaround = selectedItems.map((i: any) => i.turnaround);

    const sorted = turnaround.sort(function (a: number, b: number) {
      return b - a;
    });
    if (!sorted[0]) return dayStart;

    const msPerHr = 3600000;
    const bufferMs = msPerHr * sorted[0] * 24;
    const buffer = new Date(dayStart).setMilliseconds(bufferMs);
    return new Date(buffer);
  };

  
  // Adjust for time zone offset
  const adjustedRentalStart = formatDateOnWrite({ date: rentalStart, orgTimezone: orgData.orgTimezone })
  const adjustedRentalEnd = formatDateOnWrite({ date: rentalEnd, orgTimezone: orgData.orgTimezone })
  
  const mobileStartBeforeOffset = start.toISOString().substring(0, 10);

  return {
    // form rental dates
    rentalDates: [adjustedRentalStart, adjustedRentalEnd],
    // start
    rentalDateStart: adjustedRentalStart,
    mobileStartDate: mobileStartBeforeOffset,
    hasSpecifiedReceiveTime: hasReceiveTime,
    specifiedReceiveTime: hasReceiveTime ? addedReceiveTime(receiveTime, start) : null,
    specifiedReceiveTimeEnd: receiveEndTime ? addedReceiveTime(receiveEndTime, start) : null,
    hasReceiveWindow: !!receiveEndTime,
    // end
    rentalDateEnd: adjustedRentalEnd,
    mobileEndDate: mobileEndBeforeOffset,
    hasSpecifiedReturnTime: hasReturnTime,
    specifiedReturnTime: hasReturnTime ? addedReturnTime(null, returnTime, end) : null,
    specifiedReturnTimeEnd: returnEndTime ? addedReturnTime(returnEndTime, returnTime, end) : null,
    hasReturnWindow: !!returnEndTime,
    rentalTurnaroundEnd: rentalTurnaroundEnd(adjustedRentalEnd, selectedItems),

    customerAddressChecked: {
      return: receiveReturnDetails.useCustomerAddressReturn,
      receive: receiveReturnDetails.useCustomerAddressReceive,
    },
  };
};
